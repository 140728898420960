import { object } from 'prop-types'
import { FormattedMessage } from '@divvy-web/i18n'
import React from 'react'
import { css } from '@emotion/core'
import Icon from '@divvy-web/skylab.icon'

/* these are the tiles that appear for the different statuses of the application */
const DashboardItemContent = ({ itemContent }) => {
  const {
    description,
    hasWarning,
    isPersonaInq,
    padImage,
    salesforceCreditId: creditAppId,
    shouldFlipImageOnMobile,
    statusDisplayText,
    statusImage,
    subText,
  } = itemContent

  return (
    <div
      className='status-tile-content'
      css={(theme) =>
        statusTileContentCss(
          { isPersonaInq, shouldFlipImage: shouldFlipImageOnMobile, shouldPadImage: padImage },
          theme,
        )
      }
    >
      <div className='status-heading'>
        <div className='status-image'>
          <img
            alt='app-status'
            src={`https://app.divvy.co/assets/illustrations/${statusImage}`}
          />
        </div>
        <div className='status-title'>
          <div
            className='status-main-title'
            data-testid={`${creditAppId}-status-text`}
          >
            {hasWarning && (
              <Icon
                className='warningIcon'
                name='warningColor'
                size='large'
              />
            )}
            {statusDisplayText || (
              <FormattedMessage
                defaultMessage='Application'
                id='sputnik.DashboardItemContent__jwhET3'
              />
            )}
          </div>
          <div
            className={hasWarning ? 'status-error-text' : 'status-sub-header'}
            data-testid='application-status-subheader'
          >
            {subText}
          </div>
        </div>
      </div>
      {description && <div className='status-description'>{description}</div>}
    </div>
  )
}

DashboardItemContent.propTypes = {
  itemContent: object.isRequired,
}

export default DashboardItemContent

const statusTileContentCss = ({ isPersonaInq, shouldFlipImage, shouldPadImage }, { mq, type }) => css`
  .status-heading {
    display: flex;
    gap: var(--tri-space-400);
    ${mq.xSmallMaxWidth({ display: 'block', marginBottom: 'var(--tri-space-200)' })}
  }

  .status-title {
    margin-top: var(--tri-space-300);
    background-color: var(--tri-color-fill-primary);
    ${mq.xSmallMaxWidth({ marginTop: 'var(--tri-space-100)' })};
  }

  .status-main-title {
    ${type.triFontDesktopTitleMediumEmphasis}
    ${mq.xSmallMaxWidth(type.triFontDesktopBodyMediumEmphasis)};
    ${mq.xSmallMaxWidth({ display: 'flex' })};
  }

  .Icon {
    margin-right: var(--tri-space-100);
    margin-bottom: var(--tri-space-50);
    ${mq.xSmallMaxWidth({ marginBottom: 0, marginRight: 0 })};
  }

  i.Icon {
    ${mq.xSmallMaxWidth({ padding: '2px 0' })};
  }

  .Icon > svg {
    ${mq.xSmallMaxWidth({ maxHeight: 'var(--tri-space-200)' })};
  }

  .status-image {
    height: 112px;
    ${mq.xSmallMaxWidth({ float: 'right', height: 'var(--tri-space-1200)', order: 2 })}
  }

  .status-image > img {
    width: 144px;
    ${shouldPadImage && 'padding: var(--tri-space-300)'};
    ${mq.xSmallMaxWidth({
      padding: shouldPadImage ? 'var(--tri-space-100)' : 0,
      paddingLeft: !shouldFlipImage && !shouldPadImage && 'var(--tri-space-100)',
      paddingRight: shouldFlipImage && !shouldPadImage && 'var(--tri-space-100)',
      transform: shouldFlipImage && 'scaleX(-1)',
      width: '96px',
    })}
  }

  .status-sub-header,
  .status-error-text {
    margin-top: var(--tri-space-100);
    margin-bottom: var(--tri-space-100);
    ${type.triFontDesktopBodyLarge}

    ${mq.xSmallMaxWidth(type.triFontDesktopLabelXSmall)}
    ${mq.xSmallMaxWidth({ overflowX: 'visible' })}
  }

  .status-sub-header {
    color: var(--tri-color-text-secondary);
  }

  .status-error-text {
    color: ${isPersonaInq ? 'var(--tri-color-text-primary)' : 'var(--tri-color-text-danger)'};
  }

  .status-description {
    ${type.triFontDesktopBodyMedium}
    margin-left: var(--tri-space-2200);
    background-color: var(--tri-color-fill-primary);

    ${mq.xSmallMaxWidth(type.triFontDesktopBodySmall)};
    ${mq.xSmallMaxWidth({ color: 'var(--tri-color-text-primary)', marginLeft: 0 })};
  }

  .status-description-items {
    ${type.triFontDesktopBodyMedium}
    margin-top: var(--tri-space-200);
    margin-left: var(--tri-space-250);
    margin-bottom: var(--tri-space-300);
    ${mq.xSmallMaxWidth(type.triFontDesktopBodySmall)}
  }

  .migration-status-description-items {
    margin-top: var(--tri-space-200);

    li {
      margin-left: var(--tri-space-300);
    }
  }
`

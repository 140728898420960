import { FormTextInput, useForm } from '@divvy-web/skylab.form'
import Icon from '@divvy-web/skylab.icon'
import { css } from '@emotion/core'
import { bool, func, node, object, string, oneOfType } from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import FormElementWrapper from '../../components/FormInputs/FormElementWrapper'
import { dateStringToEpoch, formatDateString, getFormattedDate, getFormattedEIN, getFormattedSsn } from '../utils'
import { formatMaskedValue } from './utils'

const MaskedFormInput = ({
  alwaysShowError,
  disabledForMac,
  isDOB,
  isMac,
  metadata = {},
  name,
  onBlur,
  placeholder,
  readOnly,
  unmaskButtonDisabled,
  ...props
}) => {
  const { getFormValue, getValidationErrors, setFormValue } = useForm()
  const displayFieldName = name
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholder)
  const validationErrors = useMemo(() => getValidationErrors(displayFieldName), [getValidationErrors, displayFieldName])
  const underlyingFieldName = displayFieldName?.replace('Display', '')
  const underlyingFieldValue = getFormValue(underlyingFieldName)
  const displayValue = getFormValue(displayFieldName)
  const [maskButtonDisabled, setMaskButtonDisabled] = useState(
    !!validationErrors || unmaskButtonDisabled || !underlyingFieldValue,
  )
  const isDobIncomplete = isDOB && displayValue?.length < 8

  const isMasked = (value) => {
    const maskRegex = new RegExp(/\*+/)
    return maskRegex.test(value)
  }

  const [showUnmasked, setShowUnmasked] = useState(!displayValue?.length || !isMasked(displayValue))

  useEffect(() => {
    if (readOnly && showUnmasked) {
      setMaskButtonDisabled(true)
      const timer = setTimeout(() => {
        if (showUnmasked) {
          setShowUnmasked(false)
          setFormValue(
            displayFieldName,
            formatMaskedValue(underlyingFieldName, displayValue, underlyingFieldValue, metadata),
          )
        }
        setMaskButtonDisabled(false)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [
    showUnmasked,
    readOnly,
    displayFieldName,
    displayValue,
    underlyingFieldName,
    underlyingFieldValue,
    metadata,
    setFormValue,
  ])

  const handleOnChange = (e) => {
    const { value } = e.target
    setShowUnmasked(true)
    setMaskButtonDisabled(false)
    formatUnmaskedValue(displayFieldName, isMasked(value) ? '' : value)
    if (isDOB) {
      setFormValue(underlyingFieldName, isMasked(value) ? '' : dateStringToEpoch(value))
    } else {
      setFormValue(underlyingFieldName, isMasked(value) ? '' : value)
    }
  }

  const handleBlur = (_) => {
    onBlur && onBlur()
    if (isDobIncomplete) {
      setFormValue(underlyingFieldName, NaN)
    } else {
      setShowUnmasked(false)
      setFormValue(
        displayFieldName,
        formatMaskedValue(underlyingFieldName, displayValue, underlyingFieldValue, metadata),
      )
    }
  }

  const onFocus = (_) => {
    if (isDOB) setCurrentPlaceholder('MM/DD/YYYY')

    if (underlyingFieldValue) {
      setShowUnmasked(true)
      formatUnmaskedValue(displayFieldName, underlyingFieldValue)
    }
  }

  const handleMaskingClick = () => {
    setShowUnmasked((showUnmasked) => !showUnmasked)
    if (displayValue?.length) {
      if (showUnmasked) {
        setFormValue(
          displayFieldName,
          formatMaskedValue(underlyingFieldName, displayValue, underlyingFieldValue, metadata),
        )
      } else {
        if (underlyingFieldValue) {
          formatUnmaskedValue(displayFieldName, underlyingFieldValue)
        }
      }
    }
  }

  const formatUnmaskedValue = useCallback(
    (displayFieldName, value) => {
      // This handles the inconsistency in how value are currently saved in salesforce & sign-up,
      // sometimes with hyphens, sometimes without)
      switch (displayFieldName?.replace(/^owner\d?_/, '')) {
        case 'taxIdDisplay': {
          if (metadata.isSsn) {
            setFormValue(displayFieldName, getFormattedSsn(value))
          } else {
            setFormValue(displayFieldName, getFormattedEIN(value))
          }
          break
        }
        case 'ssnDisplay': {
          setFormValue(displayFieldName, getFormattedSsn(value))
          break
        }
        case 'dobDisplay': {
          if (Number.isInteger(value)) {
            setFormValue(displayFieldName, getFormattedDate(value))
          } else {
            setFormValue(displayFieldName, formatDateString(value))
          }
          break
        }
        default: {
          setFormValue(displayFieldName, value)
        }
      }
    },
    [metadata.isSsn, setFormValue],
  )

  useEffect(() => {
    setMaskButtonDisabled(!!validationErrors || unmaskButtonDisabled || !underlyingFieldValue)
  }, [unmaskButtonDisabled, validationErrors, underlyingFieldValue])

  const maskToggle = (
    <button
      css={(theme) => buttonStyles(theme, maskButtonDisabled)}
      data-testid='maskedInput-toggleButton'
      disabled={maskButtonDisabled || isDobIncomplete}
      type='button'
      onClick={handleMaskingClick}
    >
      <Icon name={showUnmasked ? 'eye' : 'eyeClosed'} />
    </button>
  )

  const rightIcon = () => {
    if (isMac && underlyingFieldName?.includes('tax') && !underlyingFieldValue && !displayValue) {
      return 'warningFilled'
    }

    if (underlyingFieldValue || !displayValue) {
      return maskToggle
    }

    return null
  }

  return (
    <FormElementWrapper
      isMaskedFormInput
      isDate={isDOB}
    >
      <FormTextInput
        alwaysShowError={alwaysShowError}
        rightIcon={rightIcon()}
        {...props}
        autoComplete='none'
        disabled={disabledForMac}
        name={displayFieldName}
        placeholder={currentPlaceholder}
        readOnly={readOnly}
        onBlur={handleBlur}
        onChange={handleOnChange}
        onFocus={onFocus}
      />
    </FormElementWrapper>
  )
}

MaskedFormInput.propTypes = {
  alwaysShowError: bool,
  disabledForMac: bool,
  isDOB: bool,
  isMac: bool,
  metadata: object,
  name: string.isRequired,
  onBlur: func,
  placeholder: oneOfType([string, node]).isRequired,
  readOnly: bool,
  unmaskButtonDisabled: bool,
}

MaskedFormInput.defaultProps = {
  disabledForMac: false,
  isDOB: false,
  isMac: false,
  readOnly: false,
  unmaskButtonDisabled: false,
}

const buttonStyles = (maskButtonDisabled) =>
  css`
    ${maskButtonDisabled ? 'color: var(--tri-color-fill-secondary)' : null};
    pointer-events: ${maskButtonDisabled ? 'none' : 'all'};
  `

export default MaskedFormInput

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from '@divvy-web/i18n'
import useNameFormatter, { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import BasicButton from '@divvy-web/skylab.basicbutton'
import CustomerAssistanceButton from '../../components/CustomerAssistanceButton/CustomerAssistanceButton'
import ImageWrapper from '../../components/ImageWrapper'
import OneColorSidebar from '../../components/OneColorSidebar/OneColorSidebar'
import { PATHNAME_CONSTS } from '../../resources/constants'
import { successWorkingOutDetailsContainerCss } from './SuccessWorkingOutDetailsStyles'

const SuccessWorkingOutDetails = () => {
  const navigate = useNavigate()
  const [getClassName] = useNameFormatter('SuccessWorkingOutDetails')
  const makeTestId = useMakeTestId('SuccessWorkingOutDetails')

  const officeDeskLadyPath = 'https://app.divvy.co/assets/illustrations/office-desk-07b-01'
  const officeDeskLadySrcSet = `${officeDeskLadyPath}@3x.png, ${officeDeskLadyPath}@2x.png, ${officeDeskLadyPath}@1x.png`

  const closeClick = () => {
    navigate(PATHNAME_CONSTS.DASHBOARD_PATH)
  }

  return (
    <>
      <div
        className='fs-unmask'
        css={successWorkingOutDetailsContainerCss}
        data-testid={makeTestId('')}
      >
        <CustomerAssistanceButton shouldUseSpecialMobileStyles />
        <div className={getClassName('body-content')}>
          <div
            className={getClassName('body-text-content')}
            data-testid={makeTestId('body-text-content')}
          >
            <ImageWrapper
              alt='person-at-office-desk'
              class={getClassName('mobile-office-desk-image')}
              imageName='manual-review-application-mobile'
            />
            <h2
              className={getClassName('body-title')}
              data-testid={makeTestId('please-stand-by')}
            >
              <FormattedMessage
                defaultMessage="We're still processing your application"
                id='sputnik.SuccessWorkingOutDetails__334LMo'
              />
            </h2>
            <p
              className={getClassName('body-text')}
              data-testid={makeTestId('we-could-not-auto-approve')}
            >
              <FormattedMessage
                defaultMessage="We need to check a few more things before providing a decision. You'll receive an email with a decision usually within 5 to 10 business days."
                id='sputnik.SuccessWorkingOutDetails__kgsS1u'
              />
            </p>
            <BasicButton
              className={getClassName('close')}
              dataTestId={makeTestId('close')}
              onClick={closeClick}
            >
              <FormattedMessage
                defaultMessage='Close'
                id='sputnik.SuccessWorkingOutDetails__rbrahO'
              />
            </BasicButton>
          </div>
        </div>
        <OneColorSidebar srcImage={officeDeskLadySrcSet} />
      </div>
    </>
  )
}

export default SuccessWorkingOutDetails

export const getCanaryOverrides = (canaryKey) => {
  try {
    const overrideString = window.sessionStorage.getItem(canaryKey)
    return JSON.parse(overrideString || '{}')
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('Parsing canary overrides failed. Clearing field.', err)
    window.sessionStorage.removeItem(canaryKey)
  }
}

export const setCanaryOverrides = (canaryKey) => (_flag: string, _value: string | boolean, core) => {
  window.sessionStorage.setItem(canaryKey, JSON.stringify(core.overrides))
}

export const clearCanaryOverrides = (canaryKey) => () => window.sessionStorage.removeItem(canaryKey)

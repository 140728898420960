import { css } from '@emotion/core'

export const contentStyles = ({ mq, type }) => css`
  .PasswordTipsContent-tip-header {
    ${type.triFontDesktopBodyMediumEmphasis}
  }

  .PasswordTipsContent-tip-content {
    margin-bottom: var(--tri-space-300);
    ${mq.xxSmallMaxWidth({ marginBottom: 'var(--tri-space-100)' })}
  }
`

export const popsheetStyles = ({ mq, type }) => css`
  ${mq.xSmallMinWidth({ display: 'none' })}
  .PopSheetControl-base {
    @media screen and (min-height: 701px) {
      top: 20%;
    }
    .PasswordTipsPopsheet {
      .PopSheet-header-title {
        justify-content: center;
        display: flex;
        img {
          height: 170px;
        }
      }

      .PopSheet-header-subtitle {
        justify-content: center;
        color: var(--tri-color-icon-primary);
        ${type.triFontDesktopTitleMediumEmphasis}
        text-align: center;
      }

      .PopSheet-footer {
        .BasicButton {
          display: flex;
          border-radius: 0;
          height: 88px;
          position: fixed;
          width: 100%;
          left: 0;
          bottom: 0;
          margin-top: var(--tri-space-100);
        }
      }
    }
  }
`

export const modalStyles = ({ mq, type }) => css`
  ${mq.xxSmallMaxWidth({ display: 'none' })}
  .Modal-container .Modal-main {
    .Modal-header {
      padding: var(--tri-space-400) var(--tri-space-600) var(--tri-space-400) var(--tri-space-2000);
    }
    .Modal-header-title {
      ${type.triFontDesktopTitleMediumEmphasis}
    }
  }
`

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FormattedMessage } from '@divvy-web/i18n'
import { useForm, FormTextInput } from '@divvy-web/skylab.form'
import { DropdownInput, MaskedFormInput } from '../../components'
import { AddressFormFields } from '../../components/AddressForm'
import FormElementWrapper from '../../components/FormInputs/FormElementWrapper'
import FormEmailInput from '../../components/FormInputs/FormEmailInput'
import PhoneNumberInput from '../../components/FormInputs/PhoneNumberInput'
import FullNameInput from '../../components/FormInputs/FullNameInput'
import PassportInfo from '../../components/PassportInfo'
import { getUSStates, normalizeSmartQuotesAndEmdashes } from '../../components/utils'
import GetCountryCodes from '../gql/GetCountryCodes.gql'
import { countriesMapper } from '../../utils/countryUtils'
import { passportToggled } from '../../utils/dataUtils'
import { formStyles } from '../styles/applicationPageSharedStyles'
import { MIGRATION, PAGE_NAME_STRINGS, AUTHORIZED_SIGNER, MAJOR_ACCOUNT_CHANGE } from '../../resources/constants'
import IdTypeToggle from '../../components/IdTypeToggle/IdTypeToggle'
import { authorizedSignerTitleOptions } from './authorizedSignerFormUtils'
import AutoFillAdminInfoToggle from './AutoFillAdminInfoToggle'

interface AuthorizedSignerFormProps {
  alwaysShowError: boolean
  isReview?: boolean
  readOnly?: boolean
}

type Form = {
  _recordType: string
  _majorAccountChanges: string[]
  _savedSections: string[]
  _autoFillAdminInfoToggle: boolean
  _passportToggle: boolean
  passportExpiration: string
  passportNumberDisplay: string
  passportCountry: string
  email: string
  firstName: string
  lastName: string
  adminFirstName: string
  adminLastName: string
  adminPhoneNumber: string
  adminEmail: string
  phoneNumber: string
  passportNumber: string
  preferredFullName: string | null
  ssn: string
  ssnDisplay: string
}

type UseForm = <T extends object>() => Record<'setFormValue', <K extends keyof T>(x: K, value: T[K]) => void> &
  Record<'resetForm', () => void> &
  Record<'formValues', T>

const AuthorizedSignerForm = ({
  alwaysShowError = false,
  isReview = false,
  readOnly = false,
}: AuthorizedSignerFormProps) => {
  const bottomRef = useRef<HTMLDivElement>(null)
  const { data } = useQuery(GetCountryCodes)
  const { formValues, resetForm, setFormValue } = (useForm as unknown as UseForm)<Form>()
  const {
    _recordType,
    _majorAccountChanges,
    _savedSections,
    _autoFillAdminInfoToggle,
    passportNumberDisplay,
    passportExpiration,
    adminEmail,
    adminFirstName,
    adminLastName,
    adminPhoneNumber,
    email,
    firstName,
    lastName,
    phoneNumber,
    preferredFullName,
    ssnDisplay,
  } = formValues

  const isUsingPassport = passportToggled(formValues)

  const usStatesOptions = getUSStates()
  const countryOptions = countriesMapper(data?.countryCodes)
  const [pageLoaded, setPageLoaded] = useState(false)
  const { PAGE_AUTHORIZED_SIGNER } = PAGE_NAME_STRINGS
  const disableField = _recordType === MAJOR_ACCOUNT_CHANGE && !_majorAccountChanges?.includes(AUTHORIZED_SIGNER)

  const executeScrollToPassport = () => bottomRef.current?.scrollIntoView({ behavior: 'smooth' })

  const isAuthSignerNameTooLong = firstName?.length + lastName?.length > 21
  const showFullName = readOnly ? !!preferredFullName && isAuthSignerNameTooLong : isAuthSignerNameTooLong
  const isMigration = _recordType === MIGRATION

  const shouldShowFullNameTooltip =
    !isReview && (!preferredFullName || preferredFullName?.length > 21 || !_savedSections?.includes('AUTH_SIGNER'))

  const adminValuesEqual = useMemo(() => {
    return (
      adminFirstName === firstName &&
      adminLastName === lastName &&
      adminEmail === email &&
      adminPhoneNumber === phoneNumber
    )
  }, [adminFirstName, adminLastName, adminEmail, adminPhoneNumber, firstName, lastName, email, phoneNumber])

  useEffect(() => {
    if (!isAuthSignerNameTooLong) setFormValue('preferredFullName', null)
  }, [isAuthSignerNameTooLong, setFormValue])

  useEffect(() => {
    if (readOnly && ssnDisplay.includes('*****')) {
      resetForm()
    }
  }, [ssnDisplay, readOnly, resetForm])

  useEffect(() => {
    if (!adminValuesEqual && _autoFillAdminInfoToggle) {
      setFormValue('_autoFillAdminInfoToggle', false)
    }
  }, [_autoFillAdminInfoToggle, adminValuesEqual, setFormValue])

  useEffect(() => {
    if (!pageLoaded && adminValuesEqual) {
      setFormValue('_autoFillAdminInfoToggle', true)
      setPageLoaded(true)
    }
  }, [pageLoaded, adminValuesEqual, setFormValue, setPageLoaded])

  useEffect(() => {
    if (!readOnly && isUsingPassport && !passportNumberDisplay && !passportExpiration) {
      executeScrollToPassport()
    }
  }, [isUsingPassport, readOnly, passportNumberDisplay, passportExpiration])

  const toggleAutoFillAdminInfo = () => {
    setFormValue('_autoFillAdminInfoToggle', !_autoFillAdminInfoToggle)

    if (!_autoFillAdminInfoToggle) {
      setFormValue('firstName', adminFirstName)
      setFormValue('lastName', adminLastName)
      setFormValue('email', adminEmail)
      setFormValue('phoneNumber', adminPhoneNumber)
    }
  }

  const toggleSsn = () => {
    setFormValue('_passportToggle', !isUsingPassport)
    if (isUsingPassport) {
      setFormValue('passportNumber', '')
      setFormValue('passportNumberDisplay', '')
      setFormValue('passportCountry', '')
      setFormValue('passportExpiration', '')
    } else {
      setFormValue('ssnDisplay', '')
      setFormValue('ssn', '')
    }
  }

  const passportInformation = (
    <PassportInfo
      alwaysShowError={alwaysShowError}
      dataTestId='authorized-signer-form'
      pathName={PAGE_AUTHORIZED_SIGNER}
      readOnly={readOnly}
    />
  )

  return (
    <section css={(theme) => formStyles({ isReview, readOnly }, theme)}>
      <div className='auth-signer-form-field-wrapper'>
        {!readOnly && !isMigration && (
          <AutoFillAdminInfoToggle
            autoFillAdminDetails={_autoFillAdminInfoToggle}
            toggleAutoFillAdminInfo={toggleAutoFillAdminInfo}
          />
        )}
        <div className='field-row fs-unmask'>
          <div className='field-container'>
            <FormElementWrapper>
              <FormTextInput
                // This prop is passed through to TextInput where it does exist
                // @ts-expect-error
                alwaysShowError={alwaysShowError}
                autoComplete='none'
                className='fs-mask'
                dataTestId='authorized-signer-form-firstName'
                disabled={disableField}
                formFieldClassName='authorized-signer-first-name'
                label={
                  <FormattedMessage
                    defaultMessage='First name'
                    id='sputnik.AuthorizedSignerForm__pONqz8'
                  />
                }
                maxLength={30}
                name='firstName'
                normalize={normalizeSmartQuotesAndEmdashes}
                placeholder={
                  <FormattedMessage
                    defaultMessage='First name'
                    id='sputnik.AuthorizedSignerForm__pONqz8'
                  />
                }
                readOnly={readOnly}
              />
            </FormElementWrapper>
          </div>
          <div className='field-container'>
            <FormElementWrapper>
              <FormTextInput
                // This prop is passed through to TextInput where it does exist
                // @ts-expect-error
                alwaysShowError={alwaysShowError}
                autoComplete='none'
                className='fs-mask'
                dataTestId='authorized-signer-form-lastName'
                disabled={disableField}
                formFieldClassName='authorized-signer-last-name'
                label={
                  <FormattedMessage
                    defaultMessage='Last name'
                    id='sputnik.AuthorizedSignerForm__txUL0F'
                  />
                }
                maxLength={30}
                name='lastName'
                normalize={normalizeSmartQuotesAndEmdashes}
                placeholder={
                  <FormattedMessage
                    defaultMessage='Last name'
                    id='sputnik.AuthorizedSignerForm__txUL0F'
                  />
                }
                readOnly={readOnly}
              />
            </FormElementWrapper>
          </div>
        </div>
        {showFullName && (
          <div className='field-row'>
            <div className='field-container-full'>
              <FullNameInput
                alwaysShowError={alwaysShowError}
                dataTestId='authorized-signer-form-preferred-full-name'
                disabled={disableField}
                formFieldClassName='authorized-signer-preferred-full-name'
                name='preferredFullName'
                preferredFullName={preferredFullName}
                readOnly={readOnly}
                showTooltipAtStart={shouldShowFullNameTooltip}
              />
            </div>
          </div>
        )}
        <div className='field-row fs-unmask'>
          <div className='field-container'>
            <FormElementWrapper>
              <DropdownInput
                isSearchable
                alwaysShowError={readOnly}
                caption={undefined}
                className='fs-mask'
                dataTestId='authorized-signer-form-title'
                disabled={false}
                errorCaption={undefined}
                hasError={false}
                items={authorizedSignerTitleOptions}
                label={
                  <FormattedMessage
                    defaultMessage='Title'
                    id='sputnik.AuthorizedSignerForm__9a9+ww'
                  />
                }
                name='title'
                placeholder={
                  <FormattedMessage
                    defaultMessage='Title'
                    id='sputnik.AuthorizedSignerForm__9a9+ww'
                  />
                }
                readOnly={readOnly}
                onSelectionChange={undefined}
              />
            </FormElementWrapper>
          </div>
          <div className='field-container'>
            <MaskedFormInput
              isDOB
              alwaysShowError={alwaysShowError}
              className='authorized-signer-dob fs-mask'
              dataTestId='authorized-signer-form-dob'
              disabledForMac={disableField}
              inputMode='numeric'
              isMac={false}
              label={
                <FormattedMessage
                  defaultMessage='Date of birth'
                  id='sputnik.AuthorizedSignerForm__GFTdXw'
                />
              }
              maxLength={10}
              name='dobDisplay'
              pathName={PAGE_AUTHORIZED_SIGNER}
              placeholder={
                <FormattedMessage
                  defaultMessage='Date of birth'
                  id='sputnik.AuthorizedSignerForm__GFTdXw'
                />
              }
              readOnly={readOnly}
              unmaskButtonDisabled={false}
              onBlur={null}
            />
          </div>
        </div>
        <div className='field-row'>
          <div className='field-container fs-unmask'>
            <FormEmailInput
              alwaysShowError={alwaysShowError}
              autoComplete='on'
              className='authorized-signer-email fs-mask'
              dataTestId='authorized-signer-form-workEmail'
              disabled={disableField}
              label={
                <FormattedMessage
                  defaultMessage='Work email'
                  id='sputnik.AuthorizedSignerForm__HHRIuR'
                />
              }
              name='email'
              placeholder={
                <FormattedMessage
                  defaultMessage='Work email'
                  id='sputnik.AuthorizedSignerForm__HHRIuR'
                />
              }
              readOnly={readOnly}
              type='email'
            />
          </div>
          <div className='field-container'>
            <PhoneNumberInput
              alwaysShowError={alwaysShowError}
              disabled={disableField}
              inputId='phoneNumber'
              label={
                <FormattedMessage
                  defaultMessage='Mobile phone number'
                  id='sputnik.AuthorizedSignerForm__L0ijz8'
                />
              }
              readOnly={readOnly}
            />
          </div>
        </div>
        <AddressFormFields
          allowNonUsAddress
          alwaysShowError={alwaysShowError}
          countryOptions={countryOptions}
          disabled={disableField}
          label={
            <FormattedMessage
              defaultMessage='Residential address'
              id='sputnik.AuthorizedSignerForm__4iXhxD'
            />
          }
          labelLine2={
            <FormattedMessage
              defaultMessage='Residential address line 2'
              id='sputnik.AuthorizedSignerForm__1WWwX6'
            />
          }
          pageName='address'
          readOnly={readOnly}
          stateOptions={usStatesOptions}
        />
        <div className='field-row'>
          <div className='field-container fs-unmask'>
            {!isUsingPassport && (
              <MaskedFormInput
                alwaysShowError={alwaysShowError}
                className='authorized-signer-ssn fs-mask'
                dataTestId='authorized-signer-form-ssn'
                disabledForMac={disableField}
                inputMode='numeric'
                isDOB={false}
                isMac={false}
                label={
                  <FormattedMessage
                    defaultMessage='Social security number'
                    id='sputnik.AuthorizedSignerForm__GQ9iTd'
                  />
                }
                maxLength={11}
                name='ssnDisplay'
                pathName={PAGE_AUTHORIZED_SIGNER}
                placeholder={
                  <FormattedMessage
                    defaultMessage='Social security number'
                    id='sputnik.AuthorizedSignerForm__GQ9iTd'
                  />
                }
                readOnly={readOnly}
                unmaskButtonDisabled={false}
                onBlur={undefined}
              />
            )}
            {readOnly || (
              <IdTypeToggle
                isUsingPassport={isUsingPassport}
                toggleSsn={toggleSsn}
              />
            )}
          </div>
        </div>
        {isUsingPassport && passportInformation}
      </div>
      <div ref={bottomRef} />
    </section>
  )
}

export default AuthorizedSignerForm

/** ! WARNING: DO NOT EDIT ! **
  This file is generated by graphql codegen.
  Make changes to *.graphql documents, not this file
**/

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import type * as _Types from '_gql'
const defaultOptions = {} as const
export type SelectedDecision_selectedDecision_Decision = {
  readonly __typename: 'Decision'
  readonly id: string
  readonly salesforceCreditId: string | null
}

export type SelectedDecision_Mutation = {
  readonly __typename: 'Mutation'
  readonly selectedDecision: SelectedDecision_selectedDecision_Decision | null
}

export type SelectedDecisionVariables = _Types.Exact<{
  id: _Types.Scalars['String']['input']
  salesforceCreditId: _Types.Scalars['String']['input']
}>

export type SelectedDecision = SelectedDecision_Mutation

export const SelectedDecisionDocument = gql`
  mutation SelectedDecision($id: String!, $salesforceCreditId: String!) {
    selectedDecision(id: $id, salesforceCreditId: $salesforceCreditId) {
      id
      salesforceCreditId
    }
  }
`
export type SelectedDecisionMutationFn = Apollo.MutationFunction<SelectedDecision, SelectedDecisionVariables>

/**
 * __useSelectedDecision__
 *
 * To run a mutation, you first call `useSelectedDecision` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectedDecision` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectedDecision, { data, loading, error }] = useSelectedDecision({
 *   variables: {
 *      id: // value for 'id'
 *      salesforceCreditId: // value for 'salesforceCreditId'
 *   },
 * });
 */
export function useSelectedDecision(
  baseOptions?: Apollo.MutationHookOptions<SelectedDecision, SelectedDecisionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SelectedDecision, SelectedDecisionVariables>(SelectedDecisionDocument, options)
}
export type SelectedDecisionHookResult = ReturnType<typeof useSelectedDecision>
export type SelectedDecisionMutationResult = Apollo.MutationResult<SelectedDecision>
export type SelectedDecisionMutationOptions = Apollo.BaseMutationOptions<SelectedDecision, SelectedDecisionVariables>

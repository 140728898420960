import { formatDateString, getFormattedDate, getFormattedEIN, getFormattedSsn } from '../utils'

export const formatMaskedValue = (name, value, underlyingValue, metadata = {}) => {
  // Removes existing hyphens or slashes before formatting for display
  const nonNumRegex = /[/-]/g
  const strippedValue = value?.replace(nonNumRegex, '')
  switch (name?.replace(/^owner\d?_/, '')) {
    case 'dob':
      // gets correct date if only two digits are entered for the year
      if (strippedValue?.length < 8) {
        const dateString = getFormattedDate(underlyingValue)
        const strippedDateString = dateString?.replace(nonNumRegex, '')
        return formatDateString(strippedDateString, true)
      } else {
        return formatDateString(strippedValue, true)
      }
    case 'taxId':
      if (metadata?.isSsn) {
        return getFormattedSsn(strippedValue, true)
      } else {
        return getFormattedEIN(strippedValue, true)
      }
    case 'ssn':
      return getFormattedSsn(strippedValue, true)
    case 'accountNumber':
      return strippedValue?.replace(/.(?=.{4,}$)/g, '*')
    case 'passportNumber':
      return strippedValue?.replace(/.(?=.{4,}$)/g, '*')
    default:
      return strippedValue
  }
}

import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { string } from 'prop-types'
import { css } from '@emotion/core'
import Icon from '@divvy-web/skylab.icon'
import Tooltip, { TOOLTIP_POSITION_BOTTOM_START } from '@divvy-web/skylab.tooltip'

const ResidentialAddressLabel = ({ dataTestId }) => {
  return (
    <div
      className='residential-address-field'
      css={labelStyles}
      data-testid={dataTestId}
    >
      <FormattedMessage
        defaultMessage='Residential address'
        id='sputnik.ResidentialAddressLabel__4iXhxD'
      />
      <Tooltip
        dataTestId={dataTestId}
        html={
          <FormattedMessage
            defaultMessage='Your residential address in addition to business address is required to be approved.'
            id='sputnik.ResidentialAddressLabel__PqZm7a'
          />
        }
        position={TOOLTIP_POSITION_BOTTOM_START}
      >
        <Icon name='info' />
      </Tooltip>
    </div>
  )
}

const labelStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;

  .Tooltip {
    margin-left: var(--tri-space-500);
  }
`

ResidentialAddressLabel.propTypes = {
  dataTestId: string,
}

export default ResidentialAddressLabel

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import { css } from '@emotion/core'
import React from 'react'
import { FormCheckboxInput } from '@divvy-web/skylab.form'
import TextLink, { TextLinkSizes } from '@divvy-web/skylab.textlink'
import { useForm } from '@divvy-web/skylab.form'
import { URL } from '../../../resources/constants'

type Form = {
  creditReportConsent: boolean
}

type UseForm = <T extends object>() => Record<'getFormValue', <K extends keyof T>(x: K) => T[K]> &
  Record<'setFormValue', <K extends keyof T>(x: K, value: T[K]) => void>

const FCRATerms = () => {
  const [getClassName, makeTestId] = useNameFormatter('FCRATerms')
  const { getFormValue, setFormValue } = (useForm as unknown as UseForm)<Form>()
  const creditReportConsent = getFormValue('creditReportConsent')
  const { BILL_TERMS_AND_CONDITIONS } = URL
  const handleTermsClick = (e) => {
    setFormValue('creditReportConsent', !!e?.target?.checked)
  }

  return (
    <div css={termsCss}>
      <FormCheckboxInput
        checkboxLabel={
          <FormattedMessage
            defaultMessage='I have read and agree to the following:'
            id='sputnik.FCRATerms__RNICJi'
          />
        }
        checked={!!creditReportConsent}
        control={null}
        dataTestId={makeTestId('checkbox')}
        formDataTestId={null}
        name='creditReportConsent'
        value={creditReportConsent}
        onChange={handleTermsClick}
      />
      <div className={getClassName('list')}>
        <ul>
          <li>
            {/* TODO: replace this with <TermsAndConditionsLink /> */}
            <TextLink
              className={getClassName('credit-reporting-link')}
              dataTestId={makeTestId('credit-reporting-link')}
              href={BILL_TERMS_AND_CONDITIONS}
              size={TextLinkSizes.REGULAR}
              target='_blank'
              useDataTestId={makeTestId('credit-reporting-link')}
            >
              <FormattedMessage
                defaultMessage='Credit reporting consent'
                id='sputnik.FCRATerms__b1sY5l'
              />
            </TextLink>
          </li>
          <li>
            <FormattedMessage
              defaultMessage='Authorization to Obtain Credit Report. You authorize us to obtain a credit report from one or more credit
            reporting agencies. We may use your credit report for authentication, to make credit decisions, and for
            relation purpose.'
              id='sputnik.FCRATerms__aVmjRN'
            />
          </li>
          <li>
            <FormattedMessage
              defaultMessage='I certify to the best of my knowledge that the information provided above is complete and correct.'
              id='sputnik.FCRATerms__kfpX/1'
            />
          </li>
        </ul>
      </div>
    </div>
  )
}

const termsCss = ({ type }) => css`
  max-width: 100%;
  .FCRATerms-list {
    ul {
      margin-left: var(--tri-space-650);
    }
  }
  ${type.triFontDesktopBodySmall}
  color: var(--tri-color-text-primary)
`
export default FCRATerms

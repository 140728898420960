import { css } from '@emotion/core'

export const successPreApprovedDualOfferCss = ({ mq, type }) => css`
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;

  .main-container {
    flex: 2;
    margin: 0;
    padding: 0;
    overflow: scroll;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .main-container::-webkit-scrollbar {
    display: none;
  }

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: var(--tri-space-1900);
    ${mq.xxSmallMaxWidth({
      maxWidth: 'var(--tri-space-850)',
    })}
  }

  .SuccessPreApprovedDualOffer {
    &-page-content {
      padding: 0 var(--tri-space-800) 0 var(--tri-space-1700);
      display: flex;
      flex-direction: column;
      height: 100%;
      ${mq.mediumMaxWidth({
        padding: 'unset',
      })}
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      ${mq.xxSmallMaxWidth({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      })}
      ${mq.xSmallMinWidth({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 'var(--tri-space-200)',
      })}
    }

    &-title {
      ${type.triFontDesktopTitleLargeEmphasis}
      display: flex;
      flex-direction: column;
      padding-bottom: var(--tri-space-200);
      ${mq.xxSmallMaxWidth({
        flexDirection: 'row',
        gap: 'var(--tri-space-50)',
        padding: 'var(--tri-space-300) 0 var(--tri-space-100) var(--tri-space-250)',
        backgroundColor: 'var(--tri-color-fill-accent-primary)',
      })}
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopTitleSmallEmphasis,
      })}
    }

    &-subtitle {
      ${type.triFontDesktopBodyMedium};
      padding-bottom: var(--tri-space-700);
      ${mq.xxSmallMaxWidth({
        padding: '0 0 var(--tri-space-250) var(--tri-space-250)',
        backgroundColor: 'var(--tri-color-fill-accent-primary)',
        width: '100%',
      })}
    }

    &-tile-captions-and-content {
      ${mq.xxSmallMaxWidth({
        padding: 'var(--tri-space-150) var(--tri-space-150) var(--tri-space-500)',
      })}
    }

    &-tile-caption {
      ${type.triFontDesktopBodyMedium};
      padding-bottom: var(--tri-space-400);
      ${mq.xxSmallMaxWidth({
        padding: '0 var(--tri-space-100) var(--tri-space-300)',
      })}
    }

    &-standard-credit-line-tile,
    &-variable-credit-line-tile {
      max-width: 50rem;
      width: 100%;
      margin-bottom: var(--tri-space-400);
      ${mq.xxSmallMaxWidth({
        marginBottom: 'var(--tri-space-75)',
      })}
    }

    &-standard-mobile-popsheet-information,
    &-variable-mobile-popsheet-information,
    &-mobile-popsheets {
      ${mq.xSmallMinWidth({
        display: 'none',
      })}
    }

    &-standard-mobile-popsheet-information {
      margin-bottom: var(--tri-space-400);
    }

    &-standard-credit-line-tile-image-wrapper,
    &-variable-credit-line-tile-image-wrapper {
      padding-right: var(--tri-space-400);
      ${mq.xxSmallMaxWidth({
        paddingRight: 'var(--tri-space-75)',
      })}
    }

    &-standard-credit-line-tile-content,
    &-variable-credit-line-tile-content {
      display: flex;
      align-items: center;
      text-align: left;
      flex-wrap: nowrap;
    }

    &-standard-credit-line-tile-approved-amount,
    &-variable-credit-line-tile-approved-amount {
      ${type.triFontDesktopTitleLargeEmphasis}
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopTitleSmallEmphasis,
      })}
    }

    &-standard-credit-line-tile-title,
    &-variable-credit-line-tile-title {
      ${type.triFontDesktopBodyLarge}
      color: var(--tri-color-text-tertiary);
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopBodySmall,
      })}
    }

    &-standard-credit-line-tile-text,
    &-variable-credit-line-tile-text {
      ${type.triFontDesktopBodyMedium}
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopBodySmall,
      })}
    }

    &-standard-credit-line-textlink-caption,
    &-variable-credit-line-textlink-caption {
      text-decoration: none;
      padding-left: var(--tri-space-75);
    }

    &-dashboard-and-next-button-group {
      display: flex;
      justify-content: space-between;
      ${mq.xxSmallMaxWidth({
        paddingBottom: 'unset',
        bottom: '0',
        flexDirection: 'row',
        gap: '0',
        height: '88px',
        left: '0',
        position: 'fixed',
        width: '100%',
      })}
      ${mq.xSmallMinWidth({
        padding: 'var(--tri-space-400) var(--tri-space-350)',
      })}
    }

    &-dashboard-button,
    &-next-button {
      ${mq.xxSmallMaxWidth({
        borderRadius: '0',
        height: '100%',
        width: '100%',
      })}
    }

    &-dashboard-button {
      ${mq.xxSmallMaxWidth({
        backgroundColor: 'var(--tri-color-fill-primary)',
      })}
    }
  }
`

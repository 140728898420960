import { useState, useCallback, useMemo, useEffect } from 'react'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { formatMXConnectionsList, bankInfoWithMaskedNumber } from '../utils/dataUtils'
import { logError } from '../utils/loggerUtils'
import GetExternalBankOptions from '../pages/gql/GetExternalBankOptions.gql'
import GetIntegrationConnections from '../pages/gql/GetIntegrationConnections.gql'
import SyncBankConnections from '../pages/gql/mutations/SyncBankConnections.gql'
import useInterval from './useInterval'

const useExternalBankOptions = (divvyUuid, isReview, handleUnverifiedBankOptions, isExternalBankSelected) => {
  const { appId } = useParams()
  const [rawMxBankOptions, setRawMxBankOptions] = useState([])
  const [isPollingForFullBankInfo, setIsPollingForFullBankInfo] = useState(false)
  const [isSyncingBankConnection, setIsSyncingBankConnection] = useState(false)
  const [didLoadMxOptions, setDidLoadMxOptions] = useState(false)
  const [didLoadExternalOptions, setDidLoadExternalOptions] = useState(false)
  const [externalBankOptions, setExternalBankOptions] = useState([])

  const { data: pollingData, refetch } = useQuery(GetExternalBankOptions, {
    fetchPolicy: 'no-cache',
    onError: () => setDidLoadExternalOptions(true),
    skip: isReview,
    variables: { creditApplicationId: appId },
  })

  useEffect(() => {
    if (pollingData) {
      setDidLoadExternalOptions(true)
    }
    const options = pollingData?.creditApplication?.financeInfo?.externalBankOptions
    if (pollingData && options?.length !== externalBankOptions?.length) {
      setExternalBankOptions(options?.filter((option) => !!option).map(bankInfoWithMaskedNumber))
    }
  }, [externalBankOptions?.length, pollingData])

  const [fetchMX] = useLazyQuery(GetIntegrationConnections, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      addConnectionsToMxBankOptions(data?.integrationConnections?.filter((c) => c.connectionType === 'MX') || [], false)
      setDidLoadMxOptions(true)
    },
    onError: () => {
      setDidLoadMxOptions(true)
    },
    variables: { divvyUuid },
  })

  const [syncBankConnection] = useMutation(SyncBankConnections)

  // begin fetching when divvy uuid is populated
  useEffect(() => {
    if (divvyUuid && !isReview) {
      fetchMX()
    }
  }, [divvyUuid, fetchMX, isReview])

  const availableBdcBankOptions = useMemo(
    () => externalBankOptions?.filter((c) => c.source === 'BDC').sort((a, b) => (a.bankName > b.bankName ? 1 : -1)),
    [externalBankOptions],
  )

  const availableMxBankOptions = useMemo(
    () =>
      externalBankOptions
        ?.filter((c) => c.source === 'MONEY_MOVER')
        .sort((a, b) => (a.bankName > b.bankName ? 1 : -1))
        .map((bank) => {
          const mxBank = rawMxBankOptions.find((c) => c.id === bank.id)
          if (mxBank) {
            return {
              ...bank,
              institutionMediumLogoUrl: mxBank.institutionMediumLogoUrl,
              justAdded: mxBank.justAdded,
            }
          } else {
            return bank
          }
        }),
    [externalBankOptions, rawMxBankOptions],
  )

  const allAvailableBankOptions = useMemo(
    () => availableMxBankOptions?.concat(availableBdcBankOptions),
    [availableBdcBankOptions, availableMxBankOptions],
  )

  const loadingMxBankOptions = useMemo(
    () =>
      isPollingForFullBankInfo
        ? rawMxBankOptions
            .filter((c) => c.justAdded && !availableMxBankOptions?.some((available) => c.id === available.id))
            .sort((a, b) => (a.bankName > b.bankName ? 1 : -1))
        : [],
    [availableMxBankOptions, isPollingForFullBankInfo, rawMxBankOptions],
  )

  const onPollTimeoutError = useCallback(() => {
    if (!isExternalBankSelected) {
      handleUnverifiedBankOptions(loadingMxBankOptions)
    }
    setIsPollingForFullBankInfo(false)
    logError({
      attributes: {
        action: 'onConnectionAttempted',
        message: `MX Bank connection failed for credit app id: ${appId}`,
        result: { message: 'Timed out waiting for bank connection' },
      },
      eventName: 'BankManager',
    })
  }, [appId, handleUnverifiedBankOptions, isExternalBankSelected, loadingMxBankOptions])

  useInterval(refetch, isPollingForFullBankInfo ? 1000 : null)

  useEffect(() => {
    if (isPollingForFullBankInfo) {
      const timeout = setTimeout(onPollTimeoutError, 15000)
      return () => clearTimeout(timeout)
    }
  }, [isPollingForFullBankInfo, onPollTimeoutError])

  useEffect(() => {
    if (isPollingForFullBankInfo && loadingMxBankOptions.length === 0) {
      setIsPollingForFullBankInfo(false)
    }
  }, [isPollingForFullBankInfo, loadingMxBankOptions.length])

  // we know the bank option is not available to use for verification if:
  // * it's not in the list of available ones (duh)
  // AND
  // * it was not just added or it was just added but is done being polled for
  const unavailableMxBankOptions = useMemo(
    () =>
      rawMxBankOptions.filter(
        (c) => !availableMxBankOptions?.some((c2) => c2.id === c.id) && (!c.justAdded || !isPollingForFullBankInfo),
      ),
    [availableMxBankOptions, isPollingForFullBankInfo, rawMxBankOptions],
  )

  // this adds the banks as options and returns the bank accounts
  const addConnectionsToMxBankOptions = useCallback((connections, justAdded) => {
    let bankAccounts = []
    connections.forEach((connection) => {
      const list = formatMXConnectionsList(connection)

      bankAccounts = bankAccounts.concat(list)

      list.forEach((item) => (item.justAdded = justAdded))

      const listIds = new Set(list.map(({ id }) => id))
      setRawMxBankOptions((current) => [...current.filter(({ id }) => !listIds.has(id)), ...list])
    })
    return bankAccounts
  }, [])

  const syncBankConnectionAndUpdateInternalState = useCallback(
    (connectionGuid, userGuid, onSyncError) => {
      setIsSyncingBankConnection(true)
      syncBankConnection({
        variables: {
          connectionGuid: connectionGuid,
          divvyUuid: divvyUuid,
          userGuid: userGuid,
        },
      })
        .then((res) => {
          setIsSyncingBankConnection(false)
          const { connection } = res?.data || {}
          if ((connection?.bankAccounts?.length || 0) === 0) {
            onSyncError({ message: 'No bank accounts returned' })
          } else {
            if (connection.isVerifiable) {
              // this needs to be done this way to batch the update
              setIsPollingForFullBankInfo(() => {
                addConnectionsToMxBankOptions([connection], true)
                return true
              })
            } else {
              const bankAccounts = addConnectionsToMxBankOptions([connection], true)
              handleUnverifiedBankOptions(bankAccounts)
            }
          }
        })
        .catch(() => {
          setIsSyncingBankConnection(false)
          onSyncError()
        })
    },
    [addConnectionsToMxBankOptions, divvyUuid, handleUnverifiedBankOptions, syncBankConnection],
  )

  const setMxBankOptionNotJustAdded = useCallback((id) => {
    setRawMxBankOptions((rawMxBankOptions) =>
      rawMxBankOptions.map((c) => (c.id === id ? { ...c, justAdded: false } : c)),
    )
  }, [])

  const refetchConnections = (bankInfo, clearSelectedExternalId) => {
    const { routingNumber: routingNumberToRemove } = bankInfo

    setRawMxBankOptions((rawMxBankOptions) =>
      rawMxBankOptions.filter((option) => option.routingNumber !== routingNumberToRemove),
    )
    clearSelectedExternalId()
    refetch()
  }

  return {
    allAvailableBankOptions,
    availableBdcBankOptions,
    availableMxBankOptions,
    didLoadExternalOptions,
    didLoadMxOptions,
    isSyncingBankConnection,
    loadingMxBankOptions,
    refetchConnections,
    setIsSyncingBankConnection,
    setMxBankOptionNotJustAdded,
    syncBankConnectionAndUpdateInternalState,
    unavailableMxBankOptions,
  }
}

export default useExternalBankOptions

import decode from 'jwt-decode'
import { isDivvyAuthToken } from '../tokenUtils'
import {
  JWT_COMPANY_UUID_KEY,
  JWT_CREDIT_APP_ID_KEY,
  JWT_EXPIRATION_KEY,
  JWT_LOOKUP_ID_KEY,
  JWT_TYPE_KEY,
} from './jwtContants'

export const getDecodedToken = (token) => decode(token)

export const getCompanyUuidFromToken = (token) => {
  if (!token || isDivvyAuthToken(token)) return null
  const decodedToken = getDecodedToken(token)
  return decodedToken?.[JWT_COMPANY_UUID_KEY]
}

export const getCreditAppIdFromToken = (token) => {
  if (!token || isDivvyAuthToken(token)) return null
  const decodedToken = getDecodedToken(token)

  return decodedToken?.[JWT_CREDIT_APP_ID_KEY]
}

export const getJwtLookupIdFromToken = (token) => {
  if (!token || isDivvyAuthToken(token)) return null
  const decodedToken = getDecodedToken(token)

  return decodedToken?.[JWT_LOOKUP_ID_KEY]
}

export const getExpirationFromToken = (token) => {
  if (!token || isDivvyAuthToken(token)) return null
  const decodedToken = getDecodedToken(token)

  return decodedToken?.[JWT_EXPIRATION_KEY]
}

export const getFormattedExpirationDateFromToken = (token) => {
  const expiration = getExpirationFromToken(token)

  if (!expiration) return null

  const date = new Date(0)
  date.setUTCSeconds(expiration)
  return date
}

export const getTypeFromToken = (token) => {
  if (!token || isDivvyAuthToken(token)) return null
  const decodedToken = getDecodedToken(token)
  return decodedToken?.[JWT_TYPE_KEY]
}

export const isAccessToken = (token) => {
  return getTypeFromToken(token) === 'access_token'
}

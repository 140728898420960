import React, { ReactNode } from 'react'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import Icon from '@divvy-web/skylab.icon'
import { css } from '@emotion/core'
import Tile from '@divvy-web/skylab.tile'

interface ReviewCardProps {
  reviewText: ReactNode
  reviewersName: ReactNode
  reviewersCompany: ReactNode
  stars: number
}

const ReviewCard = ({ reviewText, reviewersName, reviewersCompany, stars }: ReviewCardProps) => {
  const [makeTestId, getClassName] = useNameFormatter('ReviewCard')
  return (
    <Tile
      css={reviewCardCss}
      dataTestId={makeTestId('')}
    >
      {stars > 0 && (
        <div data-testid={makeTestId('stars-list')}>
          {Array.from({ length: stars }, (_, i) => (
            <Icon
              key={`star-${i}`}
              dataTestId={makeTestId('star')}
              name='starFilled'
            />
          ))}
        </div>
      )}
      <div
        className={getClassName('review-text')}
        data-testid={makeTestId('review-text')}
      >
        {reviewText}
      </div>
      <div
        className={getClassName('reviewers-container')}
        data-testid={makeTestId('reviewers-container')}
      >
        <div data-testid={makeTestId('reviewers-name')}>{reviewersName}</div>
        <div data-testid={makeTestId('reviewers-company')}>{reviewersCompany}</div>
      </div>
    </Tile>
  )
}

const reviewCardCss = ({ type }) => css`
  min-width: 272px;
  max-width: 640px;

  .ReviewCard-review-text,
  .ReviewCard-reviewers-container {
    ${type.triFontDesktopBodySmall}
  }

  .ReviewCard-review-text {
    padding: var(--tri-space-200) 0;
  }

  [class='StarFilled Icon-svg'] {
    color: var(--tri-color-fill-warning);
  }
`

export default ReviewCard

// For QA tests to inject timing values
export const handleQATimeoutValues = (reduceAccessTokenTimeoutFlagValue) => {
  // expecting: QA test to append in the URL #canary={"reduceAccessTokenTimeout":"{\"countdownTime\":<number>,\"timeoutDelayTime\":<number>}"}
  // expecting: QA test to toggle the reduceAccessTokenTimeout flag in canary ui

  const falsyQATimeoutValues = {
    hasReduceAccessTokenTimeout: false,
    qaStartCountdownTime: 0,
    qaTimeoutDelayTime: 0,
  }

  if (!reduceAccessTokenTimeoutFlagValue) return falsyQATimeoutValues

  try {
    // parse json from reduceAccessTokenTimeout flag value
    const timeoutDurationObject = JSON.parse(reduceAccessTokenTimeoutFlagValue)

    if (!Object.keys(timeoutDurationObject).length) return falsyQATimeoutValues

    return {
      hasReduceAccessTokenTimeout: true,
      qaStartCountdownTime: timeoutDurationObject?.countdownTime || 0,
      qaTimeoutDelayTime: timeoutDurationObject?.timeoutDelayTime || 0,
    }
  } catch {
    return falsyQATimeoutValues
  }
}

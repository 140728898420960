import React, { useState } from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import Form, { FormTextInput } from '@divvy-web/skylab.form'
import Modal from '@divvy-web/skylab.modal'
import Spinner from '@divvy-web/skylab.spinner'
import TextLink from '@divvy-web/skylab.textlink'
import Toast, { TOAST_TYPE_DANGER, TOAST_TYPE_SUCCESS } from '@divvy-web/skylab.toast'
import { css } from '@emotion/core'
import { bool } from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { clearAllLocalStorageValues, useAuth } from '../../auth'
import { FormButtons } from '../../components'
import { AutoCompleteDisable } from '../../components/FormInputs'
import FormElementWrapper from '../../components/FormInputs/FormElementWrapper'
import MiscPageWrapper from '../../components/MiscPageWrapper/MiscPageWrapper'
import { logInfo } from '../../utils/loggerUtils'
import { PATHNAME_CONSTS } from '../../resources/constants'
import { validation } from './verifyCodeForm'
import { mainContentWrapper, resendCodeModalCss } from './verifyCodeStyles'

const VerifyCodeBlock = ({ loading }) => {
  const [shouldShowToastSuccess, setShouldShowToastSuccess] = useState(false)
  const [shouldShowToastFailure, setShouldShowToastFailure] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasError, setHasError] = useState(false)
  const { email, requestAuthCode, verifyAuthCode } = useAuth()
  const navigate = useNavigate()
  const { search, state } = useLocation()

  const [showResendModal, setShowResendModal] = useState(false)
  const onShowResendModal = () => setShowResendModal(true)
  const onHideResendModal = () => setShowResendModal(false)

  const handleSubmit = (_, { accessCode }) => {
    setIsSubmitting(true)

    logInfo({
      attributes: {
        action: 'handleNextClick',
        result: 'Verify code button clicked',
      },
      eventName: 'SignIn',
    })

    verifyAuthCode(accessCode.trim(), null, state?.referrer, search).catch(() => {
      setHasError(true)
      setIsSubmitting(false)
    })
  }

  const handleResendCode = () => {
    setIsSubmitting(true)

    logInfo({
      attributes: {
        action: 'handleResendCode',
        result: 'Resend auth code clicked',
      },
      eventName: 'SignIn',
    })

    requestAuthCode(email)
      .then(() => {
        setIsSubmitting(false)
        setShowResendModal(false)
      })
      .catch(() => {
        setShouldShowToastFailure(true)
        setIsSubmitting(false)
      })

    setShouldShowToastSuccess(true)
  }

  const errorCaption = (
    <FormattedMessage
      defaultMessage='This access code is incorrect or expired'
      id='sputnik.VerifyCodeBlock__kBKP+N'
    />
  )

  const navigateToAuth = () => {
    clearAllLocalStorageValues()
    navigate(
      {
        pathname: PATHNAME_CONSTS.AUTH_PATH,
        search,
      },
      { state },
    )
  }

  if (loading) return <Spinner fullPage />

  const accessCodeBlock = (
    <>
      <div data-testid='access-code'>
        <div
          className='info-text fs-unmask'
          css={accessCodeInfoTextCss}
          data-testid='email-text'
        >
          <FormattedMessage
            defaultMessage='An email was sent to {email}. It may take a few minutes to receive the code. The code will expire in 15 minutes.'
            id='sputnik.VerifyCodeBlock__zTLW0I'
            values={{ email }}
          />
        </div>
        <Form
          css={accessCodeFormCss}
          validation={validation}
          onChange={() => setHasError(false)}
          onSubmit={handleSubmit}
        >
          <div className='field-container fs-unmask'>
            <FormElementWrapper
              errorCaption={errorCaption}
              hasError={hasError}
            >
              <FormTextInput
                autoComplete='none'
                className='access-code fs-mask'
                dataTestId='access-code-field'
                inputMode='numeric'
                label={
                  <FormattedMessage
                    defaultMessage='Verification code'
                    id='sputnik.VerifyCodeBlock__/6gLBc'
                  />
                }
                name='accessCode'
                placeholder={
                  <FormattedMessage
                    defaultMessage='Verification code'
                    id='sputnik.VerifyCodeBlock__/6gLBc'
                  />
                }
              />
            </FormElementWrapper>
          </div>
          <AutoCompleteDisable />
          <TextLink
            className='resend-code-link fs-unmask'
            dataTestId='access-code-resend-link'
            onClick={onShowResendModal}
          >
            <FormattedMessage
              defaultMessage='Resend code'
              id='sputnik.VerifyCodeBlock__ZD48TW'
            />
          </TextLink>
          <div
            className='verify-code-form-button-wrapper'
            css={formButtonCss}
          >
            <FormButtons
              hideExit
              disableNext={isSubmitting}
              handlePreviousClick={navigateToAuth}
              nextButtonType='submit'
              showNextSpinner={isSubmitting}
            />
          </div>
        </Form>
      </div>
      <Modal
        className='resend-code fs-unmask'
        css={resendCodeModalCss}
        dataTestId='resend-code'
        isShowing={showResendModal}
        title={
          <FormattedMessage
            defaultMessage='Resend verification code'
            id='sputnik.VerifyCodeBlock__62beOi'
          />
        }
      >
        <FormattedMessage
          defaultMessage='Resend code to {email}?'
          id='sputnik.VerifyCodeBlock__SCepFg'
          values={{ email }}
        />
        <div className='resend-code-btn-container'>
          <BasicButton
            className='btn-resendcode-cancel'
            color='neutral'
            dataTestId='resend-code-cancel-button'
            disabled={isSubmitting}
            type={BASIC_BUTTON_TYPE_FLAT}
            onClick={onHideResendModal}
          >
            <FormattedMessage
              defaultMessage='Cancel'
              id='sputnik.VerifyCodeBlock__47FYwb'
            />
          </BasicButton>
          <BasicButton
            className='btn-resendcode'
            dataTestId='resend-code-button'
            disabled={isSubmitting}
            showSpinner={isSubmitting}
            onClick={handleResendCode}
          >
            <FormattedMessage
              defaultMessage='Resend'
              id='sputnik.VerifyCodeBlock__IXycMo'
            />
          </BasicButton>
        </div>
      </Modal>
    </>
  )

  return (
    <div css={mainContentWrapper}>
      <MiscPageWrapper
        pageName='VerifyEmail'
        pageSubTitle={
          <FormattedMessage
            defaultMessage="We're so excited you're here!"
            id='sputnik.VerifyCodeBlock__dg2Cyr'
          />
        }
        pageTitle={
          <FormattedMessage
            defaultMessage='Welcome'
            id='sputnik.VerifyCodeBlock__PwaN2o'
          />
        }
      >
        {accessCodeBlock}
        <div className='toast-wrapper'>
          <Toast
            autoHideDelay={5000}
            dataTestId='resend-code-success-toast'
            shouldShow={shouldShowToastSuccess}
            type={TOAST_TYPE_SUCCESS}
            onExitCallback={() => {
              setShouldShowToastSuccess(false)
            }}
          >
            <FormattedMessage
              defaultMessage='A new code was sent to {email}.'
              id='sputnik.VerifyCodeBlock__JbOBex'
              values={{ email }}
            />
          </Toast>
          <Toast
            autoHideDelay={5000}
            dataTestId='resend-code-failure-toast'
            shouldShow={shouldShowToastFailure}
            type={TOAST_TYPE_DANGER}
            onExitCallback={() => {
              setShouldShowToastFailure(false)
            }}
          >
            <FormattedMessage
              defaultMessage='Oops... Something went wrong. Please try again.'
              id='sputnik.VerifyCodeBlock__5+8dzT'
            />
          </Toast>
        </div>
      </MiscPageWrapper>
    </div>
  )
}

VerifyCodeBlock.propTypes = {
  loading: bool,
}

const accessCodeFormCss = css`
  .resend-code-link {
    margin-top: var(--tri-space-300);
  }
  .field-container {
    max-width: 256px;
  }
`

const accessCodeInfoTextCss = ({ mq, type }) => css`
  display: flex;
  margin-bottom: var(--tri-space-450);
  width: 75%;
  ${type.triFontDesktopBodyMedium}
  ${mq.xSmallMaxWidth({ marginBottom: 'var(--tri-space-550)', width: '100%' })}
`

const formButtonCss = css`
  position: relative;
  z-index: 3;
`

export default VerifyCodeBlock

import React, { ReactNode } from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import TextLink, { TextLinkSizes } from '@divvy-web/skylab.textlink'
import { URL } from '../../resources/constants'

interface TermsAndConditionsLinkProps {
  dataTestId?: string
  className?: string
  alternateLinkString?: ReactNode
}

const TermsAndConditionsLink = ({ dataTestId, className, alternateLinkString }: TermsAndConditionsLinkProps) => {
  const { BILL_TERMS_AND_CONDITIONS } = URL
  return (
    <TextLink
      className={className}
      dataTestId={dataTestId}
      href={BILL_TERMS_AND_CONDITIONS}
      size={TextLinkSizes.REGULAR}
      target='_blank'
      useDataTestId={dataTestId}
    >
      {!alternateLinkString && (
        <FormattedMessage
          defaultMessage='Terms & Conditions'
          id='sputnik.TermsAndConditionsLink__arPp4e'
        />
      )}
      {alternateLinkString && alternateLinkString}
    </TextLink>
  )
}

export default TermsAndConditionsLink

import { curry } from '@divvy-web/utils'
import { css } from '@emotion/core'

export const formButtonsCss = curry(
  (
    { isInline, isCentered, isReview, shouldUseNewReviewAndSignWidth, shouldUseNewSignInWidth, showSpinner },
    { mq },
  ) => css`
    background: var(--tri-color-fill-primary);
    width: ${shouldUseNewReviewAndSignWidth ? '67%' : isReview ? 'calc(100% - 120px)' : '75%'};
    max-width: ${isReview && '992px'};
    margin-top: ${isReview ? 'var(--tri-space-300)' : 'initial'};
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    z-index: ${isInline ? '0' : '5'};
    padding: ${
      isReview || isCentered
        ? 'var(--tri-space-100) 0 var(--tri-space-300) 0'
        : 'var(--tri-space-300) var(--tri-space-600) var(--tri-space-300) var(--tri-space-1300)'
    };
    ${mq.largeMaxWidth({ width: isReview || '100%' })};
    ${mq.largeMinWidth({ width: shouldUseNewSignInWidth && '65%' })};
    ${mq.xSmallMaxWidth({
      height: isReview ? 'initial' : 'var(--tri-space-1100)',
      padding: isReview ? 'var(--tri-space-100) 0 var(--tri-space-300) var(--tri-space-100)' : '0',
      width: isReview && '100%',
    })}}

    .button-group-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
    }

    .right-button-group {
      display: flex;
      justify-content: ${isReview && 'center'};
      gap: ${isReview ? 'var(--tri-space-400)' : 'var(--tri-space-700)'};
      ${mq.xSmallMaxWidth({ flexGrow: '1', gap: isReview ? 'var(--tri-space-250)' : 0 })};
      .next-button {
        ${isReview || mq.xSmallMaxWidth({ width: '100%' })};
        button {
          padding: ${
            showSpinner ? 'var(--tri-space-50) var(--tri-space-200)' : 'var(--tri-space-100) var(--tri-space-350)'
          };
        }
      }
    }

    .left-button-group {
      ${mq.xSmallMaxWidth({ display: 'none' })}
    }

    button {
      ${isReview || mq.xSmallMaxWidth({ borderRadius: '0', height: '100%', width: '100%' })}
    }
  `,
)

import { useLazyQuery, useMutation } from '@apollo/client'
import { FormattedMessage } from '@divvy-web/i18n'
import { TOAST_TYPE_DANGER } from '@divvy-web/skylab.toast'
import { parseQuery } from '@divvy-web/utils.routing'
import * as FS from '@fullstory/browser'
import axios from 'axios'
import { node, object } from 'prop-types'
import React, { useCallback, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import useLocalStorage from '../hooks/useLocalStorage'
import { useThreatMetrixProfiling } from '../hooks/useThreatMetrixProfiling'
import { LOCAL_STORAGE_KEYS, PATHNAME_CONSTS } from '../resources/constants'
import { getApplicantEmailHash } from '../utils/applicantEmailUtils'
import { logError, logInfo } from '../utils/loggerUtils'
import { setReturnFlowLocalStorage } from '../utils/neoRedirectUtils'
import GeneratePasswordResetToken from './gql/GeneratePasswordResetToken.gql'
import { GetAuthMethod } from './gql/GetAuthMethod.gql'
import GetCreditApplicant from './gql/GetCreditApplicant.gql'
import { RefreshToken } from './gql/RefreshToken.gql'
import { RequestApplicantToken } from './gql/RequestApplicantToken.gql'
import { RequestAuthCode } from './gql/RequestAuthCode.gql'
import { RequestDocumentUploadToken } from './gql/RequestDocumentUploadToken.gql'
import SubmitResetPassword from './gql/SubmitResetPassword.gql'
import { VerifyAuthCode } from './gql/VerifyAuthCode.gql'
import { getCreditAppIdFromToken, getCompanyUuidFromToken, getJwtLookupIdFromToken } from './jwt/jwt'
import { clearAllLocalStorageValues, getSalesforceCreditIdsFromStorage, getTokenFromStorage } from './localStorageUtils'
import { getOriginalAccessToken, isTokenExpired, setOriginalAccessToken, unsetOriginalAccessToken } from './tokenUtils'

const AuthContext = React.createContext()
export const AuthConsumer = AuthContext.Consumer

const useAuthNavigation = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const searchParams = search ?? ''

  const goToDashboard = (clearSearchParams = false, referrerSearch = '') => {
    const returnFlowParam = parseQuery(referrerSearch)?.returnFlow
    returnFlowParam && setReturnFlowLocalStorage(returnFlowParam)

    navigate(
      {
        pathname: PATHNAME_CONSTS.DASHBOARD_PATH,
        search: clearSearchParams ? '' : searchParams,
      },
      {
        replace: referrerSearch?.includes('redirect_to'),
        state: {
          referrer: PATHNAME_CONSTS.AUTH_PATH,
          referrerSearch,
        },
      },
    )
  }

  const goToAuth = (clearSearchParams = false) =>
    navigate(
      {
        pathname: PATHNAME_CONSTS.AUTH_PATH,
        search: clearSearchParams ? '' : searchParams,
      },
      { state: { logout: 'self' } },
    )

  const goToVerifyCode = (referrer = null, clearSearchParams = false) => {
    navigate(
      { pathname: PATHNAME_CONSTS.VERIFY_EMAIL_PATH, search: clearSearchParams ? '' : searchParams },
      { state: { referrer } },
    )
  }

  const goToSignup = (clearSearchParams = false) => {
    navigate(
      { pathname: PATHNAME_CONSTS.SIGN_UP_PATH, search: clearSearchParams ? '' : searchParams },
      { state: { referrer: PATHNAME_CONSTS.VERIFY_CODE_PATH } },
    )
  }

  const goToRightFitSurveyPage = (clearSearchParams = false) => {
    navigate({ pathname: PATHNAME_CONSTS.RIGHT_FIT_SURVEY_PATH, search: clearSearchParams ? '' : searchParams })
  }

  const goToReferrer = (referrer = null) => navigate(referrer)

  const setReferrer = (referrer) => navigate({}, { replace: true, state: { referrer } })

  return {
    goToAuth,
    goToDashboard,
    goToRightFitSurveyPage,
    goToReferrer,
    goToSignup,
    goToVerifyCode,
    setReferrer,
  }
}

export const useAuth = () => {
  const { goToAuth, goToVerifyCode, setReferrer } = useAuthNavigation()
  const context = useContext(AuthContext)
  return { ...context, goToAuth, goToVerifyCode, setReferrer }
}

export const setMaybeNeedsNewPassword = () => window.sessionStorage.setItem('maybeNeedsNewPassword', true)
export const getMaybeNeedsNewPassword = () => window.sessionStorage.getItem('maybeNeedsNewPassword')
export const unsetMaybeNeedsNewPassword = () => window.sessionStorage.removeItem('maybeNeedsNewPassword')

export const AuthProvider = ({ apolloClient, children }) => {
  const { goToAuth, goToDashboard, goToRightFitSurveyPage, goToReferrer, goToVerifyCode } = useAuthNavigation()
  const [token, setToken] = useLocalStorage(LOCAL_STORAGE_KEYS.AUTH_STORAGE_TOKEN_KEY, null)
  const [authId, setAuthId] = useLocalStorage(LOCAL_STORAGE_KEYS.APPLICANT_AUTH_ID, null)
  const [salesforceCreditIds, setSalesforceCreditIds] = useLocalStorage(LOCAL_STORAGE_KEYS.SALESFORCE_CREDIT_IDS, null)
  const [email, setEmail] = useLocalStorage(LOCAL_STORAGE_KEYS.APPLICANT_EMAIL_KEY, null)
  const [getCreditApplicant] = useLazyQuery(GetCreditApplicant)
  const launchDarklyClient = useLDClient()
  const LAUNCH_DARKLY_REST_TOKEN = process.env.LAUNCH_DARKLY_REST_TOKEN
  const LAUNCH_DARKLY_DISABLED = window.__DivvyEnvironment.LAUNCH_DARKLY_DISABLED

  const getCreditApplicantInfo = useCallback(
    (token) => {
      if (token)
        return getCreditApplicant({ variables: { token } }).then(({ data }) => {
          const { authId, salesforceCreditIds, isUserAccountComplete } = data?.creditApplicant || {}
          setAuthId(authId)
          setSalesforceCreditIds(salesforceCreditIds)
          !getMaybeNeedsNewPassword() &&
            !isUserAccountComplete &&
            getOriginalAccessToken() &&
            setMaybeNeedsNewPassword()
          return data?.creditApplicant
        })
    },
    [getCreditApplicant, setAuthId, setSalesforceCreditIds],
  )

  const sendAuthEventsToFullstory = useCallback(
    (token) => {
      if (FS.isInitialized()) {
        // TODO: replace with appId once the user clicks on an application on the dashboard
        const singleAppId = getCreditAppIdFromToken(token)
        authId && FS.event('Auth Session ID', { session_id: authId })
        singleAppId && FS.event('Auth Current Credit ID', { sf_current_credit_id_str: singleAppId })
        salesforceCreditIds?.length && FS.event('Auth Credit IDs', { sf_credit_id_strs: salesforceCreditIds })
      }
    },
    [authId, salesforceCreditIds],
  )

  useEffect(() => {
    if (token) {
      sendAuthEventsToFullstory(token)
    }
  }, [sendAuthEventsToFullstory, token])

  const isAuthExpired = () => {
    return isTokenExpired(token)
  }

  const appIdFromToken = () => {
    if (token) {
      return getCreditAppIdFromToken(token)
    }
  }

  const [requestAuthenticationCode] = useMutation(RequestAuthCode)
  const [getAuthMethod] = useMutation(GetAuthMethod)
  const [verifyCode] = useMutation(VerifyAuthCode)
  const [requestDocumentToken] = useMutation(RequestDocumentUploadToken)
  const [requestToken] = useMutation(RequestApplicantToken)
  const [refreshToken] = useMutation(RefreshToken)
  const [generatePasswordToken] = useMutation(GeneratePasswordResetToken)
  const [submitResetPassword] = useMutation(SubmitResetPassword)

  useThreatMetrixProfiling(authId)

  const isAuthenticated = !!token

  const getAuthenticationMethod = (userEmail) => {
    if (FS.isInitialized()) {
      FS.identify(userEmail)
    }

    return new Promise((resolve, reject) => {
      getAuthMethod({ variables: { email: userEmail } })
        .then((result) => {
          resolve(result?.data?.decideAuthMethod)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  const requestAuthCode = (requestedEmail) => {
    if (FS.isInitialized()) {
      FS.identify(requestedEmail)
    }
    return new Promise((resolve, reject) => {
      logInfo({
        attributes: {
          action: 'requestAuthCode',
          result: 'Start requesting authentication code',
        },
        eventName: 'SignIn',
      })

      setEmail(requestedEmail)
      requestAuthenticationCode({ variables: { email: requestedEmail } })
        .then((result) => {
          resolve(result)
        })
        .catch((e) => {
          logError({
            attributes: {
              action: 'requestAuthCode',
              message: e?.message,
              result: 'Error requesting auth code',
            },
            eventName: 'SignIn',
          })
          reject(e)
        })
    })
  }

  const requestDocumentUploadToken = (salesforceCreditId) => requestDocumentToken({ variables: { salesforceCreditId } })

  const refreshAuthToken = () => {
    return new Promise((resolve, reject) => {
      logInfo({
        attributes: {
          action: 'refreshAuthToken',
          result: 'Start refreshing auth token',
        },
        eventName: 'Timeout',
      })

      refreshToken()
        .then((result) => {
          const {
            data: {
              refreshToken: { token },
            },
          } = result

          setToken(token)

          logInfo({
            attributes: {
              action: 'refreshAuthToken',
              result: 'Auth token refreshed',
            },
            eventName: 'Timeout',
          })

          resolve()
        })
        .catch((e) => {
          logError({
            attributes: {
              action: 'refreshAuthToken',
              message: e?.message,
              result: 'Error refreshing auth token',
            },
            eventName: 'Timeout',
          })
          reject(e)
        })
    })
  }

  const verifyAuthCode = async (authCode, emailArg = null, referrer = null, referrerSearch) => {
    return new Promise((resolve, reject) => {
      logInfo({
        attributes: {
          action: 'verifyAuthCode',
          result: 'Start verifying access code',
        },
        eventName: 'SignIn',
      })

      if (emailArg) {
        setEmail(emailArg)
      }

      if (FS.isInitialized()) {
        FS.identify(emailArg || email)
      }

      verifyCode({ fetchPolicy: 'no-cache', variables: { authCode, email: emailArg || email } })
        .then(async (result) => {
          logInfo({
            attributes: {
              action: 'verifyAuthCode',
              result: 'Auth code verified',
            },
            eventName: 'SignIn',
          })

          const jwtToken = result?.data?.verifyAuthCode?.token
          setToken(jwtToken)
          setOriginalAccessToken(jwtToken)

          logInfo({
            attributes: {
              action: 'verifyAuthCode',
              result: 'Sending user to dashboard',
            },
            eventName: 'SignIn',
          })

          if (getApplicantEmailHash(emailArg || email)) {
            window['FS'] && window['FS'].identify(getApplicantEmailHash(emailArg || email))
          }

          if (referrer) {
            goToReferrer(referrer)
          } else {
            getCreditApplicantInfo(jwtToken)
              .then((data) => {
                const hasSalesforceCreditIds = data?.salesforceCreditIds.length > 0

                const shouldGoToDashboard =
                  hasSalesforceCreditIds || getCreditAppIdFromToken(jwtToken) || getJwtLookupIdFromToken(jwtToken)

                if (LAUNCH_DARKLY_REST_TOKEN && !LAUNCH_DARKLY_DISABLED) {
                  launchDarklyClient
                    ?.identify({
                      kind: 'multi',
                      creditApplicant: {
                        key: data?.authId,
                        email,
                        _meta: {
                          privateAttributes: ['/email'],
                        },
                      },
                    })
                    .then(() => {
                      logInfo({
                        attributes: {
                          action: 'identifyWithLaunchDarkly',
                          result: 'Susccessfully identified with LD',
                        },
                        eventName: 'IdentifyWithLDSuccess',
                      })
                    })
                    .catch((err) => {
                      logError({
                        attributes: {
                          action: 'identifyWithLaunchDarkly',
                          result: 'Failed to identify with LD',
                          message: err?.message,
                        },
                        eventName: 'IdentifyWithLDFailure',
                      })
                    })
                }

                return shouldGoToDashboard ? goToDashboard(true, referrerSearch) : goToRightFitSurveyPage()
              })
              .catch((e) => {
                logError({
                  attributes: {
                    action: 'verifyAuthCode',
                    message: e?.message,
                    result: 'Error getting credit applicant info while verifying auth code',
                  },
                  eventName: 'SignIn',
                })
                reject(e)
              })
          }
          resolve()
        })
        .catch((e) => {
          logError({
            attributes: {
              action: 'verifyAuthCode',
              message: e?.message,
              result: 'Error verifying access code',
            },
            eventName: 'SignIn',
          })
          reject(e)
        })
    })
  }

  /**
   * Requests an applicant token from the server.
   *
   * @param {string} salesforceCreditId - The Salesforce Credit ID of the credit application.
   * @param {boolean} [skipEmails=false] - Whether to skip scheduling application completion reminder emails.
   * @returns {Promise<string>} A promise that resolves with the applicant token.
   */
  const requestApplicantToken = (salesforceCreditId, skipEmails = false) => {
    return new Promise((resolve, reject) => {
      return requestToken({ variables: { salesforceCreditId, skipEmails } })
        .then((result) => {
          const jwt = result?.data?.requestApplicantToken?.token
          setToken(jwt)
          resolve(jwt)
        })
        .catch(reject)
    })
  }

  const logout = () => {
    logInfo({
      attributes: {
        action: 'logout',
        result: 'Log out and send user to /auth',
      },
      eventName: 'CreditAppLogOut',
      message: 'the user has logged out',
    })
    resetAuth()
    goToAuth(true)
  }

  const resetAuth = () => {
    clearAllLocalStorageValues()
    unsetMaybeNeedsNewPassword()
    unsetOriginalAccessToken()
    apolloClient.clearStore()
  }

  const handleResetAuthPassword = async ({
    accessToken,
    newPassword,
    onComplete,
    setIsSubmitting,
    showToast,
    isCreatingNewPassword,
  }) => {
    const resetToken = await generatePasswordToken({
      ...(accessToken && { context: { overrideAuthToken: accessToken } }),
      variables: { email },
    })

    if (resetToken?.errors?.length) {
      logError({
        attributes: {
          action: 'generatePasswordResetToken',
          message: resetToken?.errors?.[0]?.message,
          result: 'Error generating password reset token',
        },
        eventName: 'PasswordReset',
      })

      showToast(
        TOAST_TYPE_DANGER,
        <FormattedMessage
          defaultMessage='Error requesting password reset token'
          id='sputnik.auth__J07smA'
        ></FormattedMessage>,
        { autoHideDelay: 3000 },
      )
      setIsSubmitting(false)
      return
    }

    try {
      await axios.put(
        `${window.__DivvyEnvironment.AUTHENTICATION_BACKEND}/api/v1/reset-password`,
        { password: newPassword, token: resetToken?.data?.generatePasswordResetToken },
        {
          headers: {
            'X-Client-Type': 'WEB',
          },
          withCredentials: true,
        },
      )
    } catch (error) {
      if (error?.response?.data?.error?.title) {
        logError({
          attributes: {
            action: 'resetAuthServicePassword',
            message: error?.response?.data?.error?.title,
            result: 'Error resetting password in auth service',
          },
          eventName: 'PasswordReset',
        })

        showToast(
          TOAST_TYPE_DANGER,
          <FormattedMessage
            defaultMessage='Error: {message}'
            id='sputnik.auth__pULLlp'
            values={{ message: error?.response?.data?.error?.title }}
          ></FormattedMessage>,
          { autoHideDelay: 3000 },
        )
      } else {
        let errorMessage = 'Unknown error'

        if (typeof error === 'string') errorMessage = error
        else if (error?.message) errorMessage = error.message

        logError({
          attributes: {
            action: 'resetAuthServicePassword',
            message: errorMessage,
            result: 'Error resetting password in auth service',
          },
          eventName: 'PasswordReset',
        })

        showToast(
          TOAST_TYPE_DANGER,
          <FormattedMessage
            defaultMessage='Error resetting password for user account'
            id='sputnik.auth__Gcl60s'
          ></FormattedMessage>,
          { autoHideDelay: 3000 },
        )
      }
      setIsSubmitting(false)
      return
    }

    if (isCreatingNewPassword) {
      await refreshToken()
      const submitResetResult = await submitResetPassword({ variables: { email } })

      if (submitResetResult?.errors?.length) {
        logError({
          attributes: {
            action: 'submitResetPassword',
            message: submitResetResult?.errors?.[0]?.message,
            result: 'Error submitting password reset in onboarding',
          },
          eventName: 'PasswordReset',
        })
        showToast(
          TOAST_TYPE_DANGER,
          <FormattedMessage
            defaultMessage='Error creating new password for user account'
            id='sputnik.auth__RTCFgI'
          />,
          { autoHideDelay: 3000 },
        )
        setIsSubmitting(false)
        return
      }
    }
    onComplete()
  }

  const handleDivvyAuthLogin = async ({ email, password, setIsSubmitting, setLoginError }) => {
    setIsSubmitting(true)
    try {
      const loginResponse = await axios.post(
        `${window.__DivvyEnvironment.AUTHENTICATION_BACKEND}/api/v1/login`,
        { clientName: 'sputnik', email, password },
        { withCredentials: true },
      )
      setToken(loginResponse?.data?.access_token)
      window.localStorage.setItem('token_expires_at', loginResponse?.data?.expires_at)
      setIsSubmitting(false)
      goToDashboard()
    } catch (error) {
      setIsSubmitting(false)
      if (error?.response?.data?.error?.title) {
        logError({
          attributes: {
            action: 'authServiceLogin',
            message: error?.response?.data?.error?.title,
            result: 'Error logging in',
          },
          eventName: 'AuthLogin',
        })
        setLoginError(error?.response?.data?.error?.title)
      } else {
        logError({
          attributes: {
            action: 'authServiceLogin',
            message: 'Unknown error',
            result: 'Error logging in',
          },
          eventName: 'AuthLogin',
        })
        setLoginError('error')
      }
    }
  }

  const context = {
    appIdFromToken,
    email,
    getAuthenticationMethod,
    getSalesforceCreditIdsFromStorage,
    getTokenFromStorage,
    handleDivvyAuthLogin,
    handleResetAuthPassword,
    isAuthExpired,
    isAuthenticated,
    logout,
    refreshAuthToken,
    requestApplicantToken,
    requestAuthCode,
    requestDocumentUploadToken,
    resetAuth,
    setEmail,
    setToken,
    verifyAuthCode,
  }
  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  apolloClient: object,
  children: node.isRequired,
}

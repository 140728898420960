import { curry } from '@divvy-web/utils'
import { css } from '@emotion/core'

export const formElementWrapperStyles = curry(
  (
    {
      fieldType,
      isCurrency,
      isDate,
      isFieldEmpty,
      isFieldValid,
      isFocused,
      isMac,
      isMaskedFormInput,
      isSuggestion,
      readOnly,
      showDatePlaceholderGuide,
    },
    { mq, skylab, type },
  ) => {
    return css`
      .FormField,
      .SingleSelectDropdown {
        height: 56px;
        width: ${readOnly && isMaskedFormInput && !isMac ? 'fit-content' : ''};
        ${mq.xSmallMaxWidth({ height: '72px', width: '100%' })}
        &-label {
          ${type.triFontDesktopLabelSmall}
          margin-bottom: 1px;
          color: var(--tri-color-text-tertiary);
          visibility: ${!readOnly && isFieldEmpty && !isFocused && isFieldValid ? 'hidden' : 'visible'};
        }
      }

      .FormField span {
        right: ${readOnly && isMaskedFormInput && '0'};
        ${mq.xSmallMaxWidth({ marginTop: 'calc(var(--tri-space-100) * -1);', right: '0' })};
      }

      .FormField ul {
        z-index: 6;
      }

      .Icon-warningFilled {
        color: var(--tri-color-text-danger);
      }

      ${fieldType === 'DateInput' ? formTextInputCss({ readOnly, skylab }) : ''}
      ${fieldType === 'TextInput'
        ? formTextInputCss({
            isCurrency,
            isDate,
            isFieldEmpty,
            isFieldValid,
            isFocused,
            isSuggestion,
            mq,
            readOnly,
            showDatePlaceholderGuide,
            skylab,
          })
        : ''}
      ${fieldType === 'Dropdown' ? formDropdownCss({ isFieldValid, isFocused, readOnly, skylab }) : ''}
    `
  },
)

const formTextInputCss = ({
  isCurrency,
  isDate,
  isFieldEmpty,
  isFieldValid,
  isFocused,
  isSuggestion,
  mq,
  readOnly,
  showDatePlaceholderGuide,
  skylab,
}) => css`
  .TextInput {
    &-input {
      padding-left: 0;
      padding-right: 0;
    }

    &-caption {
      display: ${isFieldValid ? 'block' : 'none'};
    }

    &-placeholder {
      visibility: ${readOnly || (!isCurrency && !isDate && (isFocused || !isFieldValid)) ? 'hidden' : 'visible'};
      left: 0;

      ${showDatePlaceholderGuide && mq.xSmallMaxWidth({ top: '73%' })}

      ${showDatePlaceholderGuide &&
      `
        opacity: 1;
        top: 51%;
      `}
    }

    &-error-caption {
      margin-top: ${readOnly ? '0' : 'var(--tri-space-50)'};
    }

    &-error-caption-read-only {
      margin-top: -2px;
    }

    &-error-caption,
    &-caption {
      padding-left: 0;
    }

    &-input-wrapper {
      border-radius: 0;
      box-shadow: ${skylab.textInput.boxShadow} var(--tri-divider-color-border);
      border-radius: 0;

      input:-webkit-autofill {
        border-bottom: solid 1px var(--tri-divider-color-border);
        &:focus {
          border-bottom: solid 1px var(--tri-color-fill-primary-inverse);
        }
      }
    }

    &-input-wrapper-disabled {
      border: none;
      box-shadow: ${skylab.textInput.boxShadow} var(--tri-color-stroke-primary);
      input:-webkit-autofill {
        border-bottom: solid 1.5px var(--tri-color-stroke-primary);
      }
    }

    &-input-has-error {
      box-shadow: inset 0 -1px 0px ${isSuggestion ? 'var(--tri-divider-color-border)' : 'var(--tri-color-text-danger)'};
      input:-webkit-autofill {
        border-bottom: solid 1.5px ${isSuggestion ? 'var(--tri-divider-color-border)' : 'var(--tri-color-text-danger)'};
      }
    }

    button[class*='MaskedFormInput'] {
      visibility: ${isFieldEmpty && readOnly ? 'hidden' : 'visible'};
    }
  }

  .DateInput-date-placeholder {
    visibility: ${!isFieldValid && readOnly ? 'hidden' : 'visible'};
  }

  .website-field-wrapper .TextInput-placeholder {
    visibility: ${readOnly || !isFieldEmpty || isFocused ? 'hidden' : 'visible'};
  }
`

const formDropdownCss = ({ isFieldValid, isFocused, readOnly, skylab }) => css`
  .SingleSelectDropdown {
    &-input-container {
      padding-left: 0;
      box-shadow: ${skylab.textInput.boxShadow} var(--tri-divider-color-border);
    }
    &-error-caption {
      margin-left: 0;
      margin-top: ${readOnly ? '0' : 'var(--tri-space-50)'};
    }
    &-error-caption-read-only {
      margin-top: -2px;
    }
    &-input {
      padding-left: 0;
      padding-right: 0;
    }
    &-input-container-error {
      box-shadow: inset 0 -1px 0px var(--tri-color-text-danger);
    }
  }

  .Icon {
    &-caretDown,
    &-caretUp {
      margin-right: 0;
    }
  }

  [class*='SearchIconContainer'] {
    display: none;
  }

  [class*='PlaceholderLayout'] {
    left: 0;
    visibility: ${readOnly || isFocused || !isFieldValid ? 'hidden' : 'visible'};
  }

  .FormField div[class*='-Caption'] {
    margin-left: 0;
  }

  .FormField .SingleSelectDropdown-input-container-disabled {
    box-shadow: 0 1px;
  }

  .ValidationErrors-message {
    color: var(--tri-color-fill-danger-inverse);
  }
`

import useDidMount from '@divvy-web/hooks.usedidmount'
import { useParams } from 'react-router-dom'
import { useAuth } from '../auth'

const useApplicantTokenOnMount = () => {
  const { appId } = useParams()
  const { appIdFromToken, requestApplicantToken } = useAuth()

  useDidMount(() => {
    if (appIdFromToken() !== appId) requestApplicantToken(appId)
  })
}

export default useApplicantTokenOnMount

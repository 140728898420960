import { css } from '@emotion/core'

export const successWorkingOutDetailsContainerCss = ({ mq, type }) => css`
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: var(--tri-space-2700);
    ${mq.xSmallMinWidth({ display: 'none' })};
  }

  .SuccessWorkingOutDetails {
    margin-top: var(--tri-space-400);
    ${mq.xxSmallMaxWidth({
      margin: 'unset',
      padding: '0 var(--tri-space-200)',
    })}
    ${mq.xSmallMinWidth({
      margin: 'auto',
      padding: '0 var(--tri-space-500)',
    })}
    ${mq.largeMinWidth({
      margin: '0 0 0 var(--tri-space-2000)',
      padding: 'unset',
    })}

    &-body-content {
      display: flex;
      flex: 2;
      margin: auto;
      ${mq.xxSmallMaxWidth({
        alignItems: 'center',
        flexDirection: 'column',
        padding: 'var(--tri-space-500) 0 0',
        paddingBottom: 'var(--tri-space-1100)',
      })}
    }

    &-body-text-content {
      padding: 0 var(--tri-space-500) 0 var(--tri-space-1700);
      ${mq.xxSmallMaxWidth({
        margin: 'auto',
        padding: 'unset',
        textAlign: 'center',
      })}
    }

    &-body-title {
      ${type.triFontDesktopTitleLargeEmphasis}
      margin: var(--tri-space-500) 0 0;
      ${mq.xxSmallMaxWidth(type.triFontDesktopTitleSmallEmphasis, {
        margin: '0 var(--tri-space-500)',
      })}
    }

    &-body-text {
      color: var(--tri-color-text-secondary);
      ${type.triFontDesktopBodyMedium}
      width: min(100%, 34rem);
      margin: var(--tri-space-300) 0 0 0;
      ${mq.xxSmallMaxWidth({
        margin: 'var(--tri-space-200) 0 0 0',
      })}

      ${mq.xxSmallMaxWidth({
        margin: 'var(--tri-space-200) var(--tri-space-400)',
        width: 'auto',
      })}
    }

    &-close {
      margin-top: var(--tri-space-400);

      ${mq.xxSmallMaxWidth({
        borderRadius: '0',
        bottom: '0',
        height: '88px',
        left: '0',
        position: 'fixed',
        width: '100%',
      })}
    }
  }

  .sidebar-image {
    position: absolute;
    right: -190px;
    ${mq.xSmallMinWidth({
      bottom: '-20px',
      maxWidth: '375px',
      right: '-75px',
    })}
    ${mq.mediumMinWidth({
      bottom: '-25px',
      maxWidth: '450px',
      right: '-85px',
    })}
      ${mq.largeMinWidth({
      bottom: '-30px',
      maxWidth: '500px',
      right: '-100px',
    })};
    ${mq.xLargeMinWidth({
      bottom: '-35px',
      maxWidth: '650px',
      right: '-145px',
    })};
    ${mq.xxLargeMinWidth({
      bottom: '-35px',
      maxWidth: '969px',
      right: '-215px',
    })};
  }
`

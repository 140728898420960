import { logInfo } from '../../utils/loggerUtils'

// tested in ContactUsButton.test.js
export const onContactUsClick = () => {
  logInfo({
    attributes: {
      action: 'ContactusButtonClicked',
      result: `Sending user to path: https://help.bill.com/direct/s/?ref=marketing`,
    },
    eventName: 'ContactusButtonClicked',
  })
  window.open('https://help.bill.com/direct/s/?ref=marketing', '_blank', 'noopener, noreferrer')
}

import { css } from '@emotion/core'

export const popsheetStyles = ({ mq, type }) => css`
  ${mq.xSmallMinWidth({ display: 'none' })}
  .PopSheetControl-base {
    .ResetPasswordPopsheet {
      .PopSheet-header-title {
        justify-content: center;
        display: flex;
        img {
          height: 170px;
        }
      }

      .PopSheet-header-subtitle {
        justify-content: center;
        color: var(--tri-color-icon-primary);
        ${type.triFontDesktopTitleMediumEmphasis}
      }

      .ResetPasswordPopsheet-content {
        .ResetPasswordPopsheet-back-button {
          position: absolute;
          top: 24px;
        }
      }

      .PopSheet-footer {
        .BasicButton {
          display: flex;
          border-radius: 0;
          height: 88px;
          position: fixed;
          width: 100%;
          left: 0;
          bottom: 0;
          margin-top: var(--tri-space-100);
        }
      }
    }
  }
`

export const modalStyles = ({ mq, type }) => css`
  ${mq.xxSmallMaxWidth({ display: 'none' })}
  .Modal-container .Modal-main {
    .Modal-header-title {
      ${type.triFontDesktopTitleMediumEmphasis}
    }

    .Modal-content .ResetPasswordModal {
      .ResetPasswordModal-forgot-password-title {
        margin-bottom: var(--tri-space-300);
        ${type.triFontDesktopTitleSmallEmphasis}
      }
    }
  }
`

export const contentStyles = css`
  .ResetPasswordContent-reset-description-wrapper {
    margin-left: var(--tri-space-300);
  }
  .ResetPasswordContent-email-address-field-row {
    margin-top: var(--tri-space-400);
    .FormField {
      max-width: 272px;
    }
  }
`

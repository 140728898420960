import {
  emailValidation,
  legalCharactersForHumanNamePattern,
  illegalCharactersForHumanNameMessage,
  illegalCharactersForBusinessNameMessage,
  legalCharactersForBusinessNamePattern,
} from '../../utils/validationUtils'

export const sharedFields = {
  entityType: {
    presence: {
      message: 'Please select an entity type',
    },
  },
  industry: {
    presence: {
      message: 'Please select a valid industry',
    },
  },
  naicsCode: {
    presence: {
      message: 'Please select a valid industry',
    },
  },
}

export default {
  email: emailValidation,
  firstName: {
    format: {
      message: illegalCharactersForHumanNameMessage,
      pattern: legalCharactersForHumanNamePattern,
    },
    length: {
      maximum: 30,
      message: 'First name may not have more than 30 characters',
    },
    presence: {
      message: 'Please enter a first name',
    },
  },
  lastName: {
    format: {
      message: illegalCharactersForHumanNameMessage,
      pattern: legalCharactersForHumanNamePattern,
    },
    length: {
      maximum: 30,
      message: 'Last name may not have more than 30 characters',
    },
    presence: {
      message: 'Please enter a last name',
    },
  },
  legalBusinessName: {
    format: {
      flags: 'i',
      message: illegalCharactersForBusinessNameMessage,
      pattern: legalCharactersForBusinessNamePattern,
    },
    length: {
      lessThanOrEqualTo: 52,
      message: 'Business name cannot exceed 52 characters',
    },
    presence: {
      message: 'Please enter a business name',
    },
  },
  numOfEmployees: {
    numericality: {
      greaterThan: 0,
    },
    presence: {
      message: 'Please enter a valid number of employees',
    },
  },
  phoneNumber: {
    length: {
      is: 14,
      message: 'Please enter a valid US phone number',
    },
    presence: {
      message: 'Please enter a valid US phone number',
    },
  },
  ...sharedFields,
}

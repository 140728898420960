import React from 'react'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { bool, string } from 'prop-types'

const ImageWrapper = ({ dataTestId, imageName, isSvg, alt }) => {
  const cardFloat = 'https://app.divvy.co/assets/illustrations/' + imageName
  const makeTestId = useMakeTestId('ProgramImage')
  const altName = alt ? alt : 'program-img'

  if (isSvg) {
    return (
      <img
        alt={altName}
        className='program-image'
        data-testid={makeTestId(dataTestId)}
        src={`${cardFloat}.svg`}
      />
    )
  }

  const defaultImg = `${cardFloat}@1x.png`
  const mediumImg = `${cardFloat}@2x.png`
  const largeImg = `${cardFloat}@3x.png`
  const cardSourceSet = `${mediumImg}, ${largeImg}`

  return (
    <img
      alt={altName}
      className='program-image'
      data-testid={makeTestId(dataTestId)}
      src={defaultImg}
      srcSet={cardSourceSet}
    />
  )
}

ImageWrapper.propTypes = {
  dataTestId: string,
  imageName: string.isRequired,
  isSvg: bool,
  alt: string,
}

export default ImageWrapper

import { css } from '@emotion/core'

export const ApplicationDeclinedContainer = ({ mq, type }) => css`
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;

  .main-container {
    flex: 2;
    margin: 0;
    padding: 0;
  }

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: var(--tri-space-3000);
    ${mq.xSmallMinWidth({ display: 'none' })};
  }

  .ApplicationDeclined {
    &-page-content {
      height: 100%;
      ${mq.xxSmallMaxWidth({
        padding: 'unset',
      })}
      ${mq.xSmallMinWidth({
        padding: 'var(--tri-space-500)',
      })}
      ${mq.smallMinWidth({
        padding: '0 var(--tri-space-700) 0 var(--tri-space-1700)',
      })}
    }

    &-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      ${mq.xxSmallMaxWidth({
        alignItems: 'center',
      })}
    }

    &-title {
      ${type.triFontDesktopTitleLargeEmphasis}
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopTitleSmallEmphasis,
      })}
      ${mq.xxSmallMaxWidth({
        marginTop: 'var(--tri-space-300)',
        padding: '0 var(--tri-space-500)',
        textAlign: 'center',
      })}
    }

    &-application-declined-mobile-image-div {
      ${mq.xxSmallMaxWidth({
        alignItems: 'center',
        paddingLeft: 'var(--tri-space-500)',
      })}
      ${mq.xSmallMinWidth({
        display: 'none',
      })}
    }

    &-body-copy {
      padding-top: var(--tri-space-400);
      ${type.triFontDesktopTitleSmall}
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopBodyMedium,
      })}
      ${mq.xxSmallMaxWidth({
        padding: 'var(--tri-space-350) var(--tri-space-500)',
        textAlign: 'center',
      })}
      ${mq.xLargeMinWidth({
        width: 'min(65%, 950px)',
      })}
    }

    &-has-other-applications-buttons {
      margin-top: var(--tri-space-500);
      display: flex;
      gap: var(--tri-space-300);
      ${mq.xxSmallMaxWidth({
        bottom: '0',
        gap: '0',
        height: '88px',
        left: '0',
        position: 'fixed',
        width: '100%',
      })}
    }

    &-has-no-other-applications-button {
      margin-top: var(--tri-space-500);
      display: flex;
      ${mq.xxSmallMaxWidth({
        bottom: '0',
        gap: '0',
        height: '88px',
        left: '0',
        position: 'fixed',
        width: '100%',
      })}
    }

    &-dashboard,
    &-close-and-logout,
    &-close-and-logout-no-other-apps {
      ${mq.xxSmallMaxWidth({
        borderRadius: '0',
        height: '100%',
        width: '100%',
      })}
    }
  }

  .sidebar-image {
    position: absolute;
    bottom: -40px;
    right: -190px;
    ${mq.xSmallMinWidth({
      bottom: '-28px',
      maxWidth: '500px',
      right: '-130px',
    })}
    ${mq.mediumMinWidth({
      bottom: '-30px',
      maxWidth: '550px',
      right: '-145px',
    })}
      ${mq.largeMinWidth({
      bottom: '-35px',
      maxWidth: '600px',
      right: '-158px',
    })};
    ${mq.xLargeMinWidth({
      bottom: '-45px',
      maxWidth: '700px',
      right: '-185px',
    })};
    ${mq.xxLargeMinWidth({
      maxWidth: '839px',
      right: '-220px',
    })};
  }
`

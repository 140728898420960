import React from 'react'
import { node, string, func, oneOfType } from 'prop-types'
import { FormCheckboxInput } from '@divvy-web/skylab.form'

const CheckboxInput = ({ dataTestId, label, name, onChange, ...props }) => {
  return (
    <div>
      <FormCheckboxInput
        checkboxLabel={label}
        dataTestId={dataTestId}
        name={name}
        onChange={onChange}
        {...props}
      />
    </div>
  )
}

CheckboxInput.propTypes = {
  dataTestId: string,
  label: oneOfType([string, node]),
  name: string,
  onChange: func,
}

export default CheckboxInput

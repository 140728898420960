import React, { useContext } from 'react'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { FormContext } from '@divvy-web/skylab.form'
import { array } from 'prop-types'
import CheckboxInput from '../../components/FormInputs/CheckboxInput'

const ProgramFitForm = ({ formSections }) => {
  const makeTestId = useMakeTestId('ProgramFitForm')
  const { formValues, getFormValue, setFormValue } = useContext(FormContext)

  const handleOnChange = (name) => {
    const prevValue = getFormValue(name)
    setFormValue(name, !prevValue)

    const groupPrefix = name.substring(0, name.indexOf('_'))
    const checkBoxGroupKeys = Object.keys(formValues).filter((value) => value !== name && value.includes(groupPrefix))
    checkBoxGroupKeys.forEach((key) => setFormValue(key, false))
  }

  return (
    <>
      {formSections.map((checkBoxSection, index) => (
        <div
          key={`${checkBoxSection.checkBoxSectionTitle}${index}`}
          className='programFitFormSection'
        >
          <div className='sectionTitle'>
            <label>{checkBoxSection.checkBoxSectionTitle}</label>
          </div>
          <div className='checkBoxSection'>
            {checkBoxSection.checkBoxElements.map((checkBox, index) => (
              <div key={`${checkBox.name}${index}`}>
                <CheckboxInput
                  checkboxLabel={checkBox.label}
                  dataTestId={makeTestId(checkBox.name)}
                  name={checkBox.name}
                  onChange={(_) => handleOnChange(checkBox.name)}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  )
}

ProgramFitForm.propTypes = { formSections: array }

export default ProgramFitForm

export const RE_CAPTCHA_SITE_KEY = '6LdoewAVAAAAAPxgUde6OIWl8XiUezKV-jFyo-hL'

type OptionTypes = {
  label: string
  value: string
}

const entries = {
  BANK_OR_CREDIT_UNION: 'Bank/Bank holding co/credit union',
  CORPORATIONS: 'Corporations',
  GOVERNMENT_AGENCY: 'Federal/State/Local government agency or authority',
  INSURANCE_COMPANY: 'Insurance company',
  INVESTMENT_COMPANY: 'Investment company/adviser',
  LIMITED_LIABILITY_COMPANY: 'Limited liability companies',
  NON_PROFITS: 'Non-Profits',
  NON_STATUTORY_TRUST: 'Non-Statutory trust',
  PARTNERSHIP: 'Partnerships',
  PUBLIC_ACCOUNTING_FIRM: 'Public accounting firm',
  PUBLIC_COMPANY: 'Public company and majority owned affiliate',
  SOLE_PROPRIETORSHIP: 'Sole proprietorship',
  UNINCORPORATED_ASSOCIATION: 'Unincorporated association',
}

export const entityTypeOptions: OptionTypes[] = []

for (const [value, label] of Object.entries(entries)) {
  entityTypeOptions.push({ label, value })
}

const accountingSoftwareItems = {
  qboe2: 'QuickBooks Online',
  qbd: 'QuickBooks Pro/Premier',
  qbe: 'QuickBooks Enterprise',
  xero2: 'Xero',
  netsuite2: 'NetSuite',
  intacct2: 'Intacct',
  acumatica: 'Acumatica',
  blackbaud: 'Blackbaud',
  sap: 'SAP',
  sageplus: 'Sage (200/300/ERP)',
  freshbooks: 'Freshbooks',
  qbmac: 'QuickBooks for Mac',
  quicken: 'Quicken',
  sage100: 'Sage 100',
  sage50: 'Sage 50',
  reuters: 'Thomson Reuters',
  yardi: 'Yardi',
  other: 'Other',
  none: 'None',
  unknown: "I don't know",
  msdynamicsgp: 'MS Dynamics GP',
  msdynamicsbizcentral: 'MS Dynamics 365 Business Central',
  msdynamics: 'MS Dynamics (AX, NAV, SL, 365 F&O)',
}

export const accountingSoftwareOptions: OptionTypes[] = []

for (const [value, label] of Object.entries(accountingSoftwareItems)) {
  accountingSoftwareOptions.push({ label, value })
}

export const getAccountingSoftwareItems = (value) => {
  const selectedItem = accountingSoftwareOptions.find((item) => item.value === value)
  return {
    accountingSoftwareKey: selectedItem?.value,
    accountingSoftwareValue: selectedItem?.label,
  }
}

import React from 'react'
import { useGetClassName, useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import { string } from 'prop-types'
import { contentStyles } from './styles'

const PasswordTipsContent = ({ dataTestId }) => {
  const getClassName = useGetClassName('PasswordTipsContent')
  const makeTestId = useMakeTestId(`PasswordTipsContent-${dataTestId}`)

  return (
    <div css={contentStyles}>
      <div
        className={getClassName('tip-header')}
        data-testid={makeTestId('random-password-tip-header')}
      >
        <FormattedMessage
          defaultMessage='Four to five random, nonsense words'
          id='sputnik.PasswordTipsContent__e1omji'
        />
      </div>
      <div
        className={getClassName('tip-content')}
        data-testid={makeTestId('random-password-tip-content')}
      >
        <FormattedMessage
          defaultMessage='Long, simple passwords are more secure than short, complex passwords'
          id='sputnik.PasswordTipsContent__9eTWkJ'
        />
      </div>
      <div
        className={getClassName('tip-header')}
        data-testid={makeTestId('unique-password-tip-header')}
      >
        <FormattedMessage
          defaultMessage='Unique'
          id='sputnik.PasswordTipsContent__2N4cbr'
        />
      </div>
      <div
        className={getClassName('tip-content')}
        data-testid={makeTestId('unique-password-tip-content')}
      >
        <FormattedMessage
          defaultMessage='Reused passwords are less secure'
          id='sputnik.PasswordTipsContent__CWMgq8'
        />
      </div>
    </div>
  )
}

PasswordTipsContent.propTypes = { dataTestId: string }

export default PasswordTipsContent

/** ! WARNING: DO NOT EDIT ! **
  This file is generated by graphql codegen.
  Make sure your schema source is up to date by running "bcli localspace pull signup-gateway"
**/
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** The `Currency` scalar type will parse a Int or String and attempt to coerce it to an Integer */
  Currency: { input: any; output: any }
  /**
   * The `Date` scalar type will parse either a ISO8601 date `YYYY-MM-DD`
   * or datetime `YYYY-MM-DDTHH:mm:SS:sssZ` string into a native elixir `%Date{}` struct.
   *
   * When parsing an empty string or nil value, it will return nil.
   *
   * The serialized `Date` will be formatted as an ISO8601 date, `YYYY-MM-DD`.
   */
  Date: { input: any; output: any }
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: { input: any; output: any }
  /**
   * The `Naive DateTime` scalar type represents a naive date and time without
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string.
   */
  NaiveDateTime: { input: any; output: any }
  _FieldSet: { input: any; output: any }
}

export type Account = {
  readonly __typename?: 'Account'
  readonly id: Scalars['String']['output']
  readonly name: Maybe<Scalars['String']['output']>
}

export type AccountingSoftwareInput = {
  readonly accountingSoftwareKey?: InputMaybe<Scalars['String']['input']>
  readonly accountingSoftwareValue?: InputMaybe<Scalars['String']['input']>
}

export type AddressInput = {
  readonly city?: InputMaybe<Scalars['String']['input']>
  readonly countryCode?: InputMaybe<Scalars['String']['input']>
  readonly postalCode?: InputMaybe<Scalars['String']['input']>
  readonly state?: InputMaybe<Scalars['String']['input']>
  readonly street?: InputMaybe<Scalars['String']['input']>
  readonly streetAdditional?: InputMaybe<Scalars['String']['input']>
}

export type Auth = {
  readonly __typename?: 'Auth'
  /** JWT token to be used in future requests */
  readonly token: Scalars['String']['output']
}

export enum AuthMethod {
  /** Will authenticate user with access code */
  EMAIL = 'EMAIL',
  /** Will authenticate user with password */
  PASSWORD = 'PASSWORD',
}

/** Bank Account record. */
export type BankAccount = {
  readonly __typename?: 'BankAccount'
  /** Name of the connected bank account institution. */
  readonly accountHolderName: Maybe<Scalars['String']['output']>
  /** Length of the bank account number to be used as placeholder for the last 4 digits. */
  readonly accountNumberLength: Scalars['Int']['output']
  /** Last four digits of bank account number. */
  readonly bankAccountNumberLastFour: Maybe<Scalars['String']['output']>
  /** Routing number for this account */
  readonly bankRoutingNumber: Maybe<Scalars['String']['output']>
  /** The uuid for this account. */
  readonly id: Maybe<Scalars['String']['output']>
  /** Name of the connected bank account institution. */
  readonly institutionName: Maybe<Scalars['String']['output']>
  /** Is the bank account verified by a source. */
  readonly isVerified: Maybe<Scalars['Boolean']['output']>
  /** The name given by the user to identify the bank account. */
  readonly nickname: Maybe<Scalars['String']['output']>
  /** Type of owning entity of the bank account. */
  readonly ownerType: Scalars['String']['output']
  /** Owning entity uuid. */
  readonly ownerUuid: Scalars['String']['output']
  /** Verified by source type. */
  readonly verifiedByMethod: Maybe<Scalars['String']['output']>
  /** Person's uuid that verified the bank account. */
  readonly verifiedByUserUuid: Maybe<Scalars['String']['output']>
}

export type BankConnection = {
  readonly __typename?: 'BankConnection'
  readonly aggregatedAt: Maybe<Scalars['DateTime']['output']>
  readonly bankAccountUuids: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>
  readonly bankAccounts: Maybe<ReadonlyArray<Maybe<BankAccount>>>
  readonly connectionId: Scalars['String']['output']
  readonly connectionStatus: BankConnectionStatus
  readonly connectionType: Maybe<BankConnectionType>
  readonly institutionMediumLogoUrl: Maybe<Scalars['String']['output']>
  readonly institutionName: Scalars['String']['output']
  readonly institutionSmallLogoUrl: Maybe<Scalars['String']['output']>
  readonly isVerifiable: Maybe<Scalars['Boolean']['output']>
}

export enum BankConnectionStatus {
  CONNECTED = 'CONNECTED',
  DELETED = 'DELETED',
  DISCONNECTED = 'DISCONNECTED',
  UNKNOWN = 'UNKNOWN',
}

export enum BankConnectionType {
  MANUAL = 'MANUAL',
  MX = 'MX',
  PLAID = 'PLAID',
}

export type BankInfo = {
  readonly __typename?: 'BankInfo'
  readonly accountAvailableBalance: Maybe<Scalars['Int']['output']>
  readonly accountHolderName: Maybe<Scalars['String']['output']>
  readonly accountNickname: Maybe<Scalars['String']['output']>
  readonly accountNumberLastFour: Maybe<Scalars['String']['output']>
  readonly accountNumberLength: Maybe<Scalars['Int']['output']>
  readonly accountType: Maybe<CreditApplicationBankAccountType>
  readonly bankName: Maybe<Scalars['String']['output']>
  readonly externalConnectionGuid: Maybe<Scalars['String']['output']>
  readonly id: Maybe<Scalars['String']['output']>
  readonly logoUrl: Maybe<Scalars['String']['output']>
  readonly routingNumber: Maybe<Scalars['String']['output']>
  readonly source: Maybe<CreditApplicationBankSource>
}

export type BankInfoInput = {
  readonly accountHolderName?: InputMaybe<Scalars['String']['input']>
  /** This field only allows numeric values */
  readonly accountNumber?: InputMaybe<Scalars['String']['input']>
  readonly bankName?: InputMaybe<Scalars['String']['input']>
  /** This field only allows numeric values */
  readonly routingNumber?: InputMaybe<Scalars['String']['input']>
}

/** Bank Integration Widget for MX or Plaid. */
export type BankIntegrations = {
  readonly __typename?: 'BankIntegrations'
  readonly widgetUrl: Scalars['String']['output']
}

/** Billing cycle when credit has been approved */
export enum BillingCycle {
  /** Will be billed twice a month */
  BI_MONTHLY = 'BI_MONTHLY',
  /** Will be billed daily */
  DAILY = 'DAILY',
  /** Will be billed once a month, worst rewards incentives */
  MONTHLY = 'MONTHLY',
  /** Prepay billing cycle */
  PREPAY = 'PREPAY',
  /** Will be billed weekly, has best rewards incentives */
  WEEKLY = 'WEEKLY',
}

export type BusinessInfoInput = {
  readonly accountingSoftware?: InputMaybe<AccountingSoftwareInput>
  readonly annualRevenue?: InputMaybe<Scalars['Currency']['input']>
  readonly entityType?: InputMaybe<CreditApplicationBusinessEntity>
  readonly formedOn?: InputMaybe<Scalars['Date']['input']>
  readonly industry?: InputMaybe<Scalars['String']['input']>
  readonly legalBusinessName?: InputMaybe<Scalars['String']['input']>
  readonly mailingAddress?: InputMaybe<AddressInput>
  /** This field only allows numeric values */
  readonly naicsCode?: InputMaybe<Scalars['String']['input']>
  readonly numOfEmployees?: InputMaybe<Scalars['Float']['input']>
  readonly ownership?: InputMaybe<CreditApplicationBusinessOwnership>
  readonly physicalAddress?: InputMaybe<AddressInput>
  readonly preferredAccountName?: InputMaybe<Scalars['String']['input']>
  /** This field only allows numeric values */
  readonly taxId?: InputMaybe<Scalars['String']['input']>
  readonly website?: InputMaybe<Scalars['String']['input']>
}

/** Clone Credit application response */
export type CloneCreditApplicationResponse = {
  readonly __typename?: 'CloneCreditApplicationResponse'
  readonly salesforceCreditId: Scalars['String']['output']
  readonly token: Scalars['String']['output']
}

export type Contact = {
  readonly __typename?: 'Contact'
  /** Accounts where this is the primary contact */
  readonly accounts: Maybe<ReadonlyArray<Account>>
  readonly id: Scalars['ID']['output']
}

export type CountryCode = {
  readonly __typename?: 'CountryCode'
  readonly displayName: Scalars['String']['output']
  readonly isoCode2: Scalars['String']['output']
  readonly isoCode3: Scalars['String']['output']
}

/** A Spend & Expense credit applicant */
export type CreditApplicant = {
  readonly __typename?: 'CreditApplicant'
  /** The unique email belongs to the applicant */
  readonly authEmail: Scalars['String']['output']
  /** The unique authentication ID of the applicant */
  readonly authId: Scalars['String']['output']
  /** The Company Divvy UUIDs of the credit applications that grant access to the applicant */
  readonly companyDivvyUuids: ReadonlyArray<Scalars['ID']['output']>
  /** Status of user account completion according to Users table */
  readonly isUserAccountComplete: Scalars['Boolean']['output']
  /** The Salesforce Credit IDs of the credit applications that grant access to the applicant */
  readonly salesforceCreditIds: ReadonlyArray<Scalars['ID']['output']>
}

export type CreditApplication = {
  readonly __typename?: 'CreditApplication'
  /** The ID of the account to which this credit application belongs. */
  readonly accountId: Maybe<Scalars['ID']['output']>
  /**
   * Divvy Admin
   * The person that will be the admin of the new Divvy company.
   */
  readonly admin: Maybe<CreditApplicationPerson>
  /** Version of the application used to determine internal flows */
  readonly appVersion: Maybe<Scalars['Int']['output']>
  /**
   * Authorized Signer
   * The person authorized to sign documents on behalf of the business.
   */
  readonly authorizedSigner: Maybe<CreditApplicationPerson>
  /** The BDC Organization ID for applications created via Bill.com sync event. */
  readonly bdcOrgId: Maybe<Scalars['String']['output']>
  /** The BDC Parent Organization ID for applications created via Bill.com sync event. */
  readonly bdcParentOrgId: Maybe<Scalars['String']['output']>
  /**
   * Beneficial Owners
   * People who own at least 25% of the business (max of 4).
   */
  readonly beneficialOwners: Maybe<ReadonlyArray<Maybe<CreditApplicationPerson>>>
  /** The frequency of billing specified on this credit application. */
  readonly billingCycle: Maybe<BillingCycle>
  /**
   * Business Info
   * Basic information about the business; addresses, name, etc.
   */
  readonly businessInfo: Maybe<CreditApplicationBusinessInfo>
  /** The client's time zone */
  readonly clientTimeZone: Maybe<Scalars['String']['output']>
  /** The global UUID used in the eventual creation of the company to which this credit application relates. */
  readonly companyDivvyUuid: Maybe<Scalars['String']['output']>
  /** CreditAppOrigin to specify if the credit app was initiated in AP or not */
  readonly creditAppOrigin: Maybe<Scalars['String']['output']>
  /**
   * The Divvy Service Agreement version applicable to this credit application.
   * Can either be "J_S" (joint and several) or "BUSINESS_ONLY"
   */
  readonly dsaVersion: Maybe<Scalars['String']['output']>
  /** UTC time when the credit application is fetched. */
  readonly fetchedAt: Maybe<Scalars['NaiveDateTime']['output']>
  /**
   * Finance Info
   * Financial information for the business, including revenue, bank info, etc.
   */
  readonly financeInfo: Maybe<CreditApplicationFinanceInfo>
  /** The date this credit application was first signed. Null if it wasn't signed yet. */
  readonly firstSignedAt: Maybe<Scalars['DateTime']['output']>
  /**
   * Fundable Limit
   * Contains the amount (in cents) approved by underwriting.
   */
  readonly fundableLimit: Maybe<Scalars['Int']['output']>
  /**
   * Has Beneficial Owner
   * Describes if the user has explicitly selected the beneficial owners or not. Is true by default, but without an
   * accompanying selection of users, causes a validation error on the review-and-sign page.
   */
  readonly hasBeneficialOwner: Maybe<Scalars['Boolean']['output']>
  /**
   * Has Outsourced Accountant
   * Specifies whether or not a credit application has an outsourced accountant.
   * If true, email, first name, and last name are required on the outsourced_accountant field.
   */
  readonly hasOutsourcedAccountant: Maybe<Scalars['Boolean']['output']>
  /**
   * Invited Users Emails
   * List of invited user emails related to the credit application.
   */
  readonly invitedUsersEmails: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>
  /** The date this credit application was updated by applicant. */
  readonly lastUpdatedByApplicantAt: Maybe<Scalars['DateTime']['output']>
  /** A list of changes for a major account change credit application. Null if it isn't a major account change app. */
  readonly majorAccountChanges: Maybe<ReadonlyArray<Maybe<MajorAccountChange>>>
  /** The name of the credit application. */
  readonly name: Scalars['String']['output']
  /**
   * Outsourced Accountant
   * Outsourced accountants are individuals outside an organization who have been engaged to
   * provide accounting services for an organization. They will have admin access, but will not
   * be issued Divvy cards.
   */
  readonly outsourcedAccountant: Maybe<CreditApplicationPerson>
  /** 3rd party partner reference code. */
  readonly partnerCode: Maybe<Scalars['String']['output']>
  /** A list of incomplete Persona inquiries for the credit application. */
  readonly personaInquiries: ReadonlyArray<PersonaInquiry>
  /**
   * A type descriptor for this credit application.
   * Can either be "MIGRATION" or "NEW" or "MAJOR_ACCOUNT_CHANGE"
   */
  readonly recordType: Maybe<Scalars['String']['output']>
  /**
   * Reopened Expired Application
   * Specifies whether the credit application was expired and then used to reopen/clone.
   */
  readonly reopenedExpiredApp: Scalars['Boolean']['output']
  /** A list of documents that must be provided by the applicant in order to continue with the application. */
  readonly requiredDocs: Maybe<ReadonlyArray<CreditApplicationDocument>>
  /** Return URL for AP customers upon app completion. Based on credit_app_origin and return_url field in metadata */
  readonly returnUrl: Maybe<Scalars['String']['output']>
  /** The ID of this credit application coming from Salesforce. */
  readonly salesforceCreditId: Scalars['ID']['output']
  /**
   * Sections of the credit apps that have been saved by user.
   * @deprecated Use saved_sections_info instead
   */
  readonly savedSections: Maybe<ReadonlyArray<Maybe<CreditApplicationPage>>>
  /** Sections of the credit apps that have been saved by user and their timestamps of the first save. */
  readonly savedSectionsInfo: Maybe<ReadonlyArray<Maybe<SavedSectionsInfo>>>
  /** A list of application fields that must be corrected by the applicant in order to be reviewed. */
  readonly sectionsToCorrect: Maybe<ReadonlyArray<CreditApplicationSection>>
  /** The date this credit application will expire: 30 days from the signed date or 60 days from the created date if it hasn't been signed. */
  readonly shouldBeExpiredAt: Maybe<Scalars['DateTime']['output']>
  /**
   * The date this credit application was signed. Null if it wasn't signed yet.
   * @deprecated Use firstSignedAt instead
   */
  readonly signedAt: Maybe<Scalars['DateTime']['output']>
  /** The date this credit application was started. Null if it wasn't started yet. */
  readonly startedAt: Maybe<Scalars['DateTime']['output']>
  /** The current status of the credit application. */
  readonly status: Maybe<CreditApplicationStatus>
  /** The date the current credit application status was set. */
  readonly statusUpdatedAt: Maybe<Scalars['DateTime']['output']>
  /** Truth in Lending Act (TILA) disclosure for the credit application (if required). */
  readonly tila: Maybe<SignableCreditApplicationDocument>
  /**
   * Contains any validation errors that would prevent the application
   * from being submitted
   */
  readonly validationErrors: Maybe<ReadonlyArray<Maybe<CreditApplicationValidationError>>>
}

export type CreditApplicationtilaArgs = {
  underwritingDecisionUuid?: InputMaybe<Scalars['ID']['input']>
  underwritingOfferType?: InputMaybe<UnderwritingOffer>
}

export type CreditApplicationvalidationErrorsArgs = {
  startsWith?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>
}

/** Accounting Software as part of the business_info object */
export type CreditApplicationAccountingSoftware = {
  readonly __typename?: 'CreditApplicationAccountingSoftware'
  readonly accountingSoftwareKey: Maybe<Scalars['String']['output']>
  readonly accountingSoftwareValue: Maybe<Scalars['String']['output']>
}

export type CreditApplicationAddress = {
  readonly __typename?: 'CreditApplicationAddress'
  readonly city: Maybe<Scalars['String']['output']>
  readonly countryCode: Maybe<Scalars['String']['output']>
  readonly postalCode: Maybe<Scalars['String']['output']>
  readonly state: Maybe<Scalars['String']['output']>
  readonly street: Maybe<Scalars['String']['output']>
  readonly streetAdditional: Maybe<Scalars['String']['output']>
}

export enum CreditApplicationBankAccountType {
  /** Checking bank account type */
  CHECKING = 'CHECKING',
  /** Savings bank account type */
  SAVINGS = 'SAVINGS',
}

export enum CreditApplicationBankSource {
  /** Bank info was received from BDC */
  BDC = 'BDC',
  /** Bank info was entered in manually */
  MANUAL = 'MANUAL',
  /** Bank info was received from Money Mover internally (likely coming from MX) */
  MONEY_MOVER = 'MONEY_MOVER',
  /** Bank info was verified with plaid */
  PLAID = 'PLAID',
}

/** Entity type for the business */
export enum CreditApplicationBusinessEntity {
  /** Bank/Bank Holding Co/Credit Union */
  BANK_OR_CREDIT_UNION = 'BANK_OR_CREDIT_UNION',
  /** Corporations */
  CORPORATIONS = 'CORPORATIONS',
  /** Federal/State/Local Government Agency or Authority */
  GOVERNMENT_AGENCY = 'GOVERNMENT_AGENCY',
  /** Insurance Company */
  INSURANCE_COMPANY = 'INSURANCE_COMPANY',
  /** Investment Company/Adviser */
  INVESTMENT_COMPANY = 'INVESTMENT_COMPANY',
  /** Limited Liability Companies */
  LIMITED_LIABILITY_COMPANY = 'LIMITED_LIABILITY_COMPANY',
  /** Natural Person */
  NATURAL_PERSON = 'NATURAL_PERSON',
  /** Non-Profits */
  NON_PROFITS = 'NON_PROFITS',
  /** Non-Statutory Trust */
  NON_STATUTORY_TRUST = 'NON_STATUTORY_TRUST',
  /** Partnerships */
  PARTNERSHIP = 'PARTNERSHIP',
  /** Public Accounting Firm */
  PUBLIC_ACCOUNTING_FIRM = 'PUBLIC_ACCOUNTING_FIRM',
  /** Public Company and Majority Owned Affiliate */
  PUBLIC_COMPANY = 'PUBLIC_COMPANY',
  /** Sole Proprietorship */
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  /** Unincorporated Association */
  UNINCORPORATED_ASSOCIATION = 'UNINCORPORATED_ASSOCIATION',
}

/** Information about the business applying for credit */
export type CreditApplicationBusinessInfo = {
  readonly __typename?: 'CreditApplicationBusinessInfo'
  readonly accountingSoftware: Maybe<CreditApplicationAccountingSoftware>
  readonly annualRevenue: Maybe<Scalars['Currency']['output']>
  readonly entityType: Maybe<CreditApplicationBusinessEntity>
  readonly formedOn: Maybe<Scalars['Date']['output']>
  readonly industry: Maybe<Scalars['String']['output']>
  readonly legalBusinessName: Maybe<Scalars['String']['output']>
  readonly mailingAddress: Maybe<CreditApplicationAddress>
  readonly mailingPhysicalAddressesEqual: Maybe<Scalars['Boolean']['output']>
  readonly naicsCode: Maybe<Scalars['String']['output']>
  readonly ownership: Maybe<CreditApplicationBusinessOwnership>
  readonly physicalAddress: Maybe<CreditApplicationAddress>
  readonly preferredAccountName: Maybe<Scalars['String']['output']>
  readonly taxIdLastFour: Maybe<Scalars['String']['output']>
  readonly website: Maybe<Scalars['String']['output']>
}

/** Ownership of the business */
export enum CreditApplicationBusinessOwnership {
  /** Private */
  PRIVATE = 'PRIVATE',
  /** Public */
  PUBLIC = 'PUBLIC',
}

export type CreditApplicationDocument = {
  readonly __typename?: 'CreditApplicationDocument'
  readonly documentType: CreditDocument
  readonly documentUuid: Scalars['String']['output']
  readonly isReRequested: Scalars['Boolean']['output']
  readonly statusMessage: Maybe<Scalars['String']['output']>
}

export type CreditApplicationFinanceInfo = {
  readonly __typename?: 'CreditApplicationFinanceInfo'
  /** The manual bank info. This can co-exist with an externally selected bank (allowing the user to switch back and forth without losing data) */
  readonly bankInfo: Maybe<BankInfo>
  /** Number to signify how much credit the applicant is requesting. */
  readonly desiredCredit: Maybe<Scalars['Currency']['output']>
  /** The monthly estimated amount the company will spend with Divvy. */
  readonly expectedSpend: Maybe<Scalars['Currency']['output']>
  /** The list of external bank info options (e.g. bank info received from BDC) */
  readonly externalBankOptions: Maybe<ReadonlyArray<Maybe<BankInfo>>>
  /** The currently selected bank info. This could be the manual bank info or an external bank info option. */
  readonly selectedBankInfo: Maybe<BankInfo>
}

export type CreditApplicationOrContact = Contact | CreditApplication

/** A credit application page */
export enum CreditApplicationPage {
  /** Authorized Signer */
  AUTH_SIGNER = 'AUTH_SIGNER',
  /** Business Info */
  BUSINESS_INFO = 'BUSINESS_INFO',
  /** Company Owners */
  COMPANY_OWNERS = 'COMPANY_OWNERS',
  /** Finance Info */
  FINANCE_INFO = 'FINANCE_INFO',
}

export type CreditApplicationPerson = {
  readonly __typename?: 'CreditApplicationPerson'
  readonly address: Maybe<CreditApplicationAddress>
  readonly birthYear: Maybe<Scalars['Int']['output']>
  readonly cacheRole: Maybe<Scalars['String']['output']>
  readonly dob: Maybe<Scalars['Date']['output']>
  readonly email: Maybe<Scalars['String']['output']>
  readonly firstName: Maybe<Scalars['String']['output']>
  readonly id: Maybe<Scalars['String']['output']>
  readonly lastName: Maybe<Scalars['String']['output']>
  readonly passportCountry: Maybe<Scalars['String']['output']>
  readonly passportExpiration: Maybe<Scalars['Date']['output']>
  readonly passportNumberLastFour: Maybe<Scalars['String']['output']>
  readonly passportNumberLength: Maybe<Scalars['Int']['output']>
  readonly phoneNumber: Maybe<Scalars['String']['output']>
  readonly preferredFullName: Maybe<Scalars['String']['output']>
  readonly ssnLastFour: Maybe<Scalars['String']['output']>
  readonly title: Maybe<Scalars['String']['output']>
}

/** A credit application correction section */
export enum CreditApplicationSection {
  /** Authorized Signer */
  AS = 'AS',
  /** Beneficial Owner 1 */
  BO1 = 'BO1',
  /** Beneficial Owner 2 */
  BO2 = 'BO2',
  /** Beneficial Owner 3 */
  BO3 = 'BO3',
  /** Beneficial Owner 4 */
  BO4 = 'BO4',
  /** Business Info */
  BUSINESS = 'BUSINESS',
  /** Financial Info */
  FINANCIAL = 'FINANCIAL',
}

/** Status of the credit application */
export enum CreditApplicationStatus {
  /** Credit has been approved */
  APPROVED = 'APPROVED',
  /** Completed but waiting to be signed */
  AWAITING_SIGNATURE = 'AWAITING_SIGNATURE',
  /** Account is being built */
  BUILD_ACCOUNT = 'BUILD_ACCOUNT',
  /** Application closed since it was a duplicate of another application */
  CLOSED_AS_DUPLICATE = 'CLOSED_AS_DUPLICATE',
  /** Application closed due to a reason other than expired, closed_as_duplicate, customer_canceled or rejected. */
  CLOSED_LOST = 'CLOSED_LOST',
  /** Account has been built */
  COMPANY_BUILT = 'COMPANY_BUILT',
  /** One or more sections of the application need to be corrected */
  CORRECTION_REQUESTED = 'CORRECTION_REQUESTED',
  /** Application was canceled by the user */
  CUSTOMER_CANCELED = 'CUSTOMER_CANCELED',
  /** Application is in draft mode awaiting the Divvy support team */
  DRAFT = 'DRAFT',
  /** Application has expired */
  EXPIRED = 'EXPIRED',
  /** Authorized signers credit is frozen */
  FROZEN_CREDIT = 'FROZEN_CREDIT',
  /** Some info has been filled out */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Being reviewed by credit and risk teams */
  IN_REVIEW = 'IN_REVIEW',
  /** Migration Approved - Pending */
  MIGRATION_APPROVED = 'MIGRATION_APPROVED',
  /** Migration - Complete */
  MIGRATION_COMPLETE = 'MIGRATION_COMPLETE',
  /** Migration - Customer Validation In Progress */
  MIGRATION_IN_PROGRESS = 'MIGRATION_IN_PROGRESS',
  /** Migration - Divvy Validation In Progress */
  MIGRATION_IN_REVIEW = 'MIGRATION_IN_REVIEW',
  /** Migration - Invitation Sent but validation has not started */
  MIGRATION_NOT_STARTED = 'MIGRATION_NOT_STARTED',
  /** Migration - Customer Form Submitted */
  MIGRATION_SUBMITTED = 'MIGRATION_SUBMITTED',
  /** More information requested before approval/rejection can be given */
  MORE_INFO_NEEDED = 'MORE_INFO_NEEDED',
  /** Credit application is empty */
  NOT_STARTED = 'NOT_STARTED',
  /** Credit offering was accepted */
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  /** Credit offering was declined */
  OFFER_DECLINED = 'OFFER_DECLINED',
  /** Application has been denied */
  REJECTED = 'REJECTED',
  /** Credit offer needs to be reviewed */
  REVIEW_OFFER = 'REVIEW_OFFER',
  /** Completed and signed */
  SUBMITTED = 'SUBMITTED',
}

export enum CreditApplicationUpdateTargets {
  SAVE_AND_EXIT = 'SAVE_AND_EXIT',
  UPDATE_AUTH_SIGNER = 'UPDATE_AUTH_SIGNER',
  UPDATE_BUSINESS_INFO = 'UPDATE_BUSINESS_INFO',
  UPDATE_FINANCE_INFO = 'UPDATE_FINANCE_INFO',
}

export type CreditApplicationValidationError = {
  readonly __typename?: 'CreditApplicationValidationError'
  /** Validation error string */
  readonly error: Maybe<Scalars['String']['output']>
  /** Path of the potentially nested field that failed validation */
  readonly path: Maybe<Scalars['String']['output']>
}

/** Document required for the credit application process */
export enum CreditDocument {
  /** Bank Statement */
  BANK_STATEMENT = 'BANK_STATEMENT',
  /** Bank Verification */
  BANK_VERIFICATION = 'BANK_VERIFICATION',
  /** BO1 Driver's License */
  BO1_DRIVERS_LICENSE = 'BO1_DRIVERS_LICENSE',
  /** BO1 Green Card */
  BO1_GREEN_CARD = 'BO1_GREEN_CARD',
  /** BO1 Passport */
  BO1_PASSPORT = 'BO1_PASSPORT',
  /** BO1 Physical Address Verification */
  BO1_PHYSICAL_ADDRESS_VERIFICATION = 'BO1_PHYSICAL_ADDRESS_VERIFICATION',
  /** BO1 SSN Card */
  BO1_SSN_CARD = 'BO1_SSN_CARD',
  /** BO1 US Visa */
  BO1_US_VISA = 'BO1_US_VISA',
  /** BO2 Driver's License */
  BO2_DRIVERS_LICENSE = 'BO2_DRIVERS_LICENSE',
  /** BO2 Green Card */
  BO2_GREEN_CARD = 'BO2_GREEN_CARD',
  /** BO2 Passport */
  BO2_PASSPORT = 'BO2_PASSPORT',
  /** BO2 Physical Address Verification */
  BO2_PHYSICAL_ADDRESS_VERIFICATION = 'BO2_PHYSICAL_ADDRESS_VERIFICATION',
  /** BO2 SSN Card */
  BO2_SSN_CARD = 'BO2_SSN_CARD',
  /** BO2 US Visa */
  BO2_US_VISA = 'BO2_US_VISA',
  /** BO3 Driver's License */
  BO3_DRIVERS_LICENSE = 'BO3_DRIVERS_LICENSE',
  /** BO3 Green Card */
  BO3_GREEN_CARD = 'BO3_GREEN_CARD',
  /** BO3 Passport */
  BO3_PASSPORT = 'BO3_PASSPORT',
  /** BO3 Physical Address Verification */
  BO3_PHYSICAL_ADDRESS_VERIFICATION = 'BO3_PHYSICAL_ADDRESS_VERIFICATION',
  /** BO3 SSN Card */
  BO3_SSN_CARD = 'BO3_SSN_CARD',
  /** BO3 US Visa */
  BO3_US_VISA = 'BO3_US_VISA',
  /** BO4 Driver's License */
  BO4_DRIVERS_LICENSE = 'BO4_DRIVERS_LICENSE',
  /** BO4 Green Card */
  BO4_GREEN_CARD = 'BO4_GREEN_CARD',
  /** BO4 Passport */
  BO4_PASSPORT = 'BO4_PASSPORT',
  /** BO4 Physical Address Verification */
  BO4_PHYSICAL_ADDRESS_VERIFICATION = 'BO4_PHYSICAL_ADDRESS_VERIFICATION',
  /** BO4 SSN Card */
  BO4_SSN_CARD = 'BO4_SSN_CARD',
  /** BO4 US Visa */
  BO4_US_VISA = 'BO4_US_VISA',
  /** BPAV */
  BPAV = 'BPAV',
  /** Business Registration */
  BUSINESS_REGISTRATION = 'BUSINESS_REGISTRATION',
  /** Business Status */
  BUSINESS_STATUS = 'BUSINESS_STATUS',
  /** Business Tax Return */
  BUSINESS_TAX_RETURN = 'BUSINESS_TAX_RETURN',
  /** Company Prepared */
  COMPANY_PREPARED = 'COMPANY_PREPARED',
  /** CPA Audited */
  CPA_AUDITED = 'CPA_AUDITED',
  /** CPA Compiled */
  CPA_COMPILED = 'CPA_COMPILED',
  /** CPA Review */
  CPA_REVIEW = 'CPA_REVIEW',
  /** CP Driver's License */
  CP_DRIVERS_LICENSE = 'CP_DRIVERS_LICENSE',
  /** CP Green Card */
  CP_GREEN_CARD = 'CP_GREEN_CARD',
  /** CP Passport */
  CP_PASSPORT = 'CP_PASSPORT',
  /** CP Physical Address Verification */
  CP_PHYSICAL_ADDRESS_VERIFICATION = 'CP_PHYSICAL_ADDRESS_VERIFICATION',
  /** CP SSN Card */
  CP_SSN_CARD = 'CP_SSN_CARD',
  /** CP US Visa */
  CP_US_VISA = 'CP_US_VISA',
  /** CRB PG Agreement */
  CRB_PG_AGREEMENT = 'CRB_PG_AGREEMENT',
  FINANCIAL_DOCUMENTS = 'FINANCIAL_DOCUMENTS',
  INTERIM_FINANCIALS = 'INTERIM_FINANCIALS',
  /** NDA */
  NDA = 'NDA',
  OTHER = 'OTHER',
  /** Personal Tax Return */
  PERSONAL_TAX_RETURN = 'PERSONAL_TAX_RETURN',
  /** Public Interim Financials */
  PUBLIC_INTERIM_FINANCIALS = 'PUBLIC_INTERIM_FINANCIALS',
  /** Tax ID */
  TAX_ID = 'TAX_ID',
}

export type Decision = {
  readonly __typename?: 'Decision'
  /** Approved amount in cents */
  readonly approvedAmountInCents: Scalars['Int']['output']
  /** Company id */
  readonly companyId: Scalars['String']['output']
  /** Credit limit id */
  readonly creditLimitId: Maybe<Scalars['String']['output']>
  /** Decision id */
  readonly id: Scalars['String']['output']
  /** Offer type */
  readonly offerType: UnderwritingOffer
  /** Salesforce credit id */
  readonly salesforceCreditId: Maybe<Scalars['String']['output']>
  /** underwriting method */
  readonly underwritingMethod: Scalars['String']['output']
  /** underwriting version */
  readonly underwritingVersion: Scalars['String']['output']
}

export type DocumentUploadInfo = {
  readonly __typename?: 'DocumentUploadInfo'
  readonly documentUuid: Scalars['String']['output']
  readonly presignedUrl: Scalars['String']['output']
}

export type FinanceInfoInput = {
  /** Used to update the manual bank info. */
  readonly bankInfo?: InputMaybe<BankInfoInput>
  readonly desiredCredit?: InputMaybe<Scalars['Currency']['input']>
  /** The monthly estimated amount the company will spend with Divvy. */
  readonly expectedSpend?: InputMaybe<Scalars['Currency']['input']>
  /**
   * If set to a string, indicates that the external (e.g. BDC) bank info with the matching id should be the selected bank info.
   * If set to null, indicates that the manual bank info should be the selected bank info.
   * If not set, no change is made to the selected bank info.
   */
  readonly selectedExternalId?: InputMaybe<Scalars['String']['input']>
}

/** Institution record. */
export type Institution = {
  readonly __typename?: 'Institution'
  /** Unique code that identifies this institution on MX's Api. */
  readonly code: Scalars['String']['output']
  /** Medium logo for the institution. */
  readonly mediumLogoUrl: Maybe<Scalars['String']['output']>
  /** Name of the institution. */
  readonly name: Scalars['String']['output']
  /** Small logo for the institution. */
  readonly smallLogoUrl: Maybe<Scalars['String']['output']>
  /** Whether the institution supports verification mode or not. */
  readonly supportsAccountVerification: Scalars['Boolean']['output']
  /** URL for the institution. */
  readonly url: Scalars['String']['output']
}

export enum MajorAccountChange {
  AUTHORIZED_SIGNER = 'AUTHORIZED_SIGNER',
  BENEFICIAL_OWNER_0 = 'BENEFICIAL_OWNER_0',
  BENEFICIAL_OWNER_1 = 'BENEFICIAL_OWNER_1',
  BENEFICIAL_OWNER_2 = 'BENEFICIAL_OWNER_2',
  BENEFICIAL_OWNER_3 = 'BENEFICIAL_OWNER_3',
  LEGAL_BUSINESS_NAME = 'LEGAL_BUSINESS_NAME',
  TAX_ID = 'TAX_ID',
}

/** Lead values from marketo cookie */
export type MarketoBillLeadInput = {
  readonly fbclid?: InputMaybe<Scalars['String']['input']>
  readonly ftAffiliateId?: InputMaybe<Scalars['String']['input']>
  readonly ftChannelId?: InputMaybe<Scalars['String']['input']>
  readonly ftCurrentUrl?: InputMaybe<Scalars['String']['input']>
  readonly ftDevice?: InputMaybe<Scalars['String']['input']>
  readonly ftLandingPage?: InputMaybe<Scalars['String']['input']>
  readonly ftOfferId?: InputMaybe<Scalars['String']['input']>
  readonly ftPlatformId?: InputMaybe<Scalars['String']['input']>
  readonly ftPpcPlacement?: InputMaybe<Scalars['String']['input']>
  readonly ftPreviousPage?: InputMaybe<Scalars['String']['input']>
  readonly ftReferrer?: InputMaybe<Scalars['String']['input']>
  readonly ftTimestamp?: InputMaybe<Scalars['String']['input']>
  readonly ftUtmCampaign?: InputMaybe<Scalars['String']['input']>
  readonly ftUtmContent?: InputMaybe<Scalars['String']['input']>
  readonly ftUtmMedium?: InputMaybe<Scalars['String']['input']>
  readonly ftUtmSource?: InputMaybe<Scalars['String']['input']>
  readonly ftUtmTerm?: InputMaybe<Scalars['String']['input']>
  readonly gclid?: InputMaybe<Scalars['String']['input']>
  readonly isSelfServe?: InputMaybe<Scalars['Boolean']['input']>
  readonly liclid?: InputMaybe<Scalars['String']['input']>
  readonly ltAffiliateId?: InputMaybe<Scalars['String']['input']>
  readonly ltChannelId?: InputMaybe<Scalars['String']['input']>
  readonly ltCurrentUrl?: InputMaybe<Scalars['String']['input']>
  readonly ltDevice?: InputMaybe<Scalars['String']['input']>
  readonly ltLandingPage?: InputMaybe<Scalars['String']['input']>
  readonly ltOfferId: Scalars['String']['input']
  readonly ltPlatformId?: InputMaybe<Scalars['String']['input']>
  readonly ltPpcPlacement?: InputMaybe<Scalars['String']['input']>
  readonly ltPreviousPage?: InputMaybe<Scalars['String']['input']>
  readonly ltReferrer?: InputMaybe<Scalars['String']['input']>
  readonly ltTimestamp?: InputMaybe<Scalars['String']['input']>
  readonly ltUtmCampaign?: InputMaybe<Scalars['String']['input']>
  readonly ltUtmContent?: InputMaybe<Scalars['String']['input']>
  readonly ltUtmMedium?: InputMaybe<Scalars['String']['input']>
  readonly ltUtmSource?: InputMaybe<Scalars['String']['input']>
  readonly ltUtmTerm?: InputMaybe<Scalars['String']['input']>
  readonly msclkid?: InputMaybe<Scalars['String']['input']>
  readonly pact?: InputMaybe<Scalars['String']['input']>
}

export type Mutation = {
  readonly __typename?: 'Mutation'
  readonly acceptTila: Maybe<SignableCreditApplicationDocument>
  readonly amendSentToSignerApp: CreditApplication
  /** Authorize payment bank account during sign up. For use with Sputnik. */
  readonly authorizeSignUp: Maybe<PaymentBankAccount>
  readonly cloneCreditApplication: CloneCreditApplicationResponse
  /** Mutation to retrieve authentication method */
  readonly decideAuthMethod: Maybe<AuthMethod>
  readonly e2eCleanUpForEmail: Maybe<Scalars['String']['output']>
  readonly generateDocumentUploadUrl: DocumentUploadInfo
  /** Mutation to generate a password reset token. Requires bearing an AccessToken. */
  readonly generatePasswordResetToken: Scalars['String']['output']
  /**
   * Mutation to initiate a new credit application.
   * If an existing contact record with accounts is found, it will be returned instead of creating
   * the application and can be used to rerun the mutation
   */
  readonly initCreditApplication: CreditApplicationOrContact
  readonly inviteUsers: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>
  /** Mutation to refresh tokens. It works with ApplicantToken and AccessToken, returning the type provided. */
  readonly refreshToken: Maybe<Auth>
  readonly removeConnection: Scalars['Boolean']['output']
  readonly removeInvitedUsers: ReadonlyArray<Maybe<Scalars['String']['output']>>
  /** Initiates reprocessing a credit application */
  readonly reprocessCreditApplication: Maybe<Status>
  /** Mutation to generate a token to grant applicant access to a credit application */
  readonly requestApplicantToken: Maybe<Auth>
  /** Mutation to request an authorization code to be sent */
  readonly requestAuthCode: Maybe<RequestAuthCode>
  /** Mutation to generate a token to grant document upload access to a credit application */
  readonly requestDocumentUploadToken: Maybe<Auth>
  readonly requestSignature: CreditApplication
  readonly restartPersonaInquiry: PersonaInquiry
  /** Selects a decision as chosen */
  readonly selectedDecision: Maybe<Decision>
  /** Mutation to submit a new credit application */
  readonly submitCreditApplication: CreditApplication
  /** Mutation to submit verification of data (used for bank migration) */
  readonly submitDataVerification: CreditApplication
  /**
   * Mutation to submit a major account change after credit app has been signed.
   * Requires the DMC initiateMajorAccountChange mutation to be completed first.
   */
  readonly submitMajorAccountChange: CreditApplication
  /** Mutation to handle onboarding side of submitting password reset */
  readonly submitResetPassword: Maybe<SuccessResult>
  /** Manually Sync Bank connections. For use with Sputnik. */
  readonly syncBankConnections: Maybe<BankConnection>
  /** Mutation to request and send reset password emails. */
  readonly triggerPasswordResetEmail: Maybe<SuccessResult>
  /** Initiates company unfreeze and resets credit pull retries */
  readonly unfreezeAndResetCreditPullRetries: Maybe<Status>
  readonly updateBeneficialOwners: CreditApplication
  /** Mutation to update an existing credit application before submission */
  readonly updateCreditApplication: CreditApplication
  /** Mutation to verify an authorization code and receive a JWT token */
  readonly verifyAuthCode: Maybe<Auth>
}

export type MutationacceptTilaArgs = {
  salesforceCreditId: Scalars['ID']['input']
  underwritingDecisionUuid?: InputMaybe<Scalars['ID']['input']>
  underwritingOfferType: UnderwritingOffer
}

export type MutationamendSentToSignerAppArgs = {
  salesforceCreditId: Scalars['ID']['input']
}

export type MutationauthorizeSignUpArgs = {
  bankAccountId: Scalars['String']['input']
  divvyUuid: Scalars['String']['input']
}

export type MutationcloneCreditApplicationArgs = {
  salesforceCreditId: Scalars['ID']['input']
}

export type MutationdecideAuthMethodArgs = {
  email: Scalars['String']['input']
}

export type MutationgenerateDocumentUploadUrlArgs = {
  documentDate?: InputMaybe<Scalars['String']['input']>
  documentType?: InputMaybe<CreditDocument>
  documentUuid?: InputMaybe<Scalars['String']['input']>
  fileName: Scalars['String']['input']
  inline?: InputMaybe<Scalars['Boolean']['input']>
  salesforceCreditId?: InputMaybe<Scalars['String']['input']>
}

export type MutationinitCreditApplicationArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>
  affiliateId?: InputMaybe<Scalars['Float']['input']>
  appVersion?: InputMaybe<Scalars['Int']['input']>
  applicant: PersonInput
  businessInfo: BusinessInfoInput
  clientTimeZone?: InputMaybe<Scalars['String']['input']>
  contactId?: InputMaybe<Scalars['ID']['input']>
  marketoBillLeadInput?: InputMaybe<MarketoBillLeadInput>
  partnerCode?: InputMaybe<Scalars['String']['input']>
  recaptchaToken: Scalars['String']['input']
}

export type MutationinviteUsersArgs = {
  creditApplicationId: Scalars['ID']['input']
  emails?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>
}

export type MutationremoveConnectionArgs = {
  externalConnectionGuid: Scalars['String']['input']
}

export type MutationremoveInvitedUsersArgs = {
  creditApplicationId: Scalars['ID']['input']
  emails: ReadonlyArray<InputMaybe<Scalars['String']['input']>>
}

export type MutationreprocessCreditApplicationArgs = {
  reason: Scalars['String']['input']
  salesforceCreditId: Scalars['String']['input']
}

export type MutationrequestApplicantTokenArgs = {
  creditApplicationId: Scalars['ID']['input']
  skipEmails?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationrequestAuthCodeArgs = {
  email: Scalars['String']['input']
}

export type MutationrequestDocumentUploadTokenArgs = {
  creditApplicationId: Scalars['ID']['input']
}

export type MutationrequestSignatureArgs = {
  creditApplicationId: Scalars['ID']['input']
}

export type MutationrestartPersonaInquiryArgs = {
  inquiryId: Scalars['String']['input']
}

export type MutationselectedDecisionArgs = {
  id: Scalars['String']['input']
  salesforceCreditId: Scalars['String']['input']
}

export type MutationsubmitCreditApplicationArgs = {
  clientTimeZone?: InputMaybe<Scalars['String']['input']>
  creditApplicationFetchedAt: Scalars['NaiveDateTime']['input']
  creditApplicationId: Scalars['ID']['input']
  creditTerms: Scalars['Boolean']['input']
  fraudPreventionData?: InputMaybe<Scalars['String']['input']>
}

export type MutationsubmitDataVerificationArgs = {
  clientTimeZone?: InputMaybe<Scalars['String']['input']>
  creditApplicationFetchedAt?: InputMaybe<Scalars['NaiveDateTime']['input']>
  creditApplicationId: Scalars['ID']['input']
  creditTerms: Scalars['Boolean']['input']
}

export type MutationsubmitMajorAccountChangeArgs = {
  creditApplicationFetchedAt?: InputMaybe<Scalars['NaiveDateTime']['input']>
  creditApplicationId: Scalars['ID']['input']
  creditTerms: Scalars['Boolean']['input']
}

export type MutationsubmitResetPasswordArgs = {
  email: Scalars['String']['input']
}

export type MutationsyncBankConnectionsArgs = {
  connectionGuid: Scalars['String']['input']
  divvyUuid: Scalars['String']['input']
  userGuid: Scalars['String']['input']
}

export type MutationtriggerPasswordResetEmailArgs = {
  email: Scalars['String']['input']
}

export type MutationunfreezeAndResetCreditPullRetriesArgs = {
  companyDivvyUuid: Scalars['String']['input']
  salesforceCreditId: Scalars['String']['input']
}

export type MutationupdateBeneficialOwnersArgs = {
  beneficialOwners?: InputMaybe<ReadonlyArray<PersonInput>>
  clientTimeZone?: InputMaybe<Scalars['String']['input']>
  creditApplicationId: Scalars['ID']['input']
  hasBeneficialOwner?: InputMaybe<Scalars['Boolean']['input']>
  validateRequired?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationupdateCreditApplicationArgs = {
  admin?: InputMaybe<PersonInput>
  authorizedSigner?: InputMaybe<PersonInput>
  beneficialOwners?: InputMaybe<ReadonlyArray<InputMaybe<PersonInput>>>
  billingCycle?: InputMaybe<BillingCycle>
  businessInfo?: InputMaybe<BusinessInfoInput>
  clientTimeZone?: InputMaybe<Scalars['String']['input']>
  creditApplicationId: Scalars['ID']['input']
  financeInfo?: InputMaybe<FinanceInfoInput>
  hasBeneficialOwner?: InputMaybe<Scalars['Boolean']['input']>
  hasOutsourcedAccountant?: InputMaybe<Scalars['Boolean']['input']>
  outsourcedAccountant?: InputMaybe<PersonInput>
  step: Scalars['Int']['input']
  target?: InputMaybe<CreditApplicationUpdateTargets>
}

export type MutationverifyAuthCodeArgs = {
  authCode: Scalars['String']['input']
  email: Scalars['String']['input']
}

export type NaicsRecord = {
  readonly __typename?: 'NaicsRecord'
  readonly naicsCode: Maybe<Scalars['String']['output']>
  readonly title: Maybe<Scalars['String']['output']>
}

/** Bank Integration Widget for MX or Plaid. */
export type PaymentBankAccount = {
  readonly __typename?: 'PaymentBankAccount'
  /** The user id used to authorized the payment bank account */
  readonly authorizedBy: Maybe<Scalars['String']['output']>
  /** The bank account that this payment_bank_account contains. */
  readonly bankAccount: Maybe<ReadonlyArray<Maybe<BankAccount>>>
  /** Denotes if this payment_bank_account contains the bank account that autodraft payments will come out of. */
  readonly isPrimary: Maybe<Scalars['Boolean']['output']>
}

export type PersonInput = {
  readonly address?: InputMaybe<AddressInput>
  readonly dob?: InputMaybe<Scalars['Date']['input']>
  readonly email?: InputMaybe<Scalars['String']['input']>
  readonly firstName?: InputMaybe<Scalars['String']['input']>
  readonly id?: InputMaybe<Scalars['String']['input']>
  readonly lastName?: InputMaybe<Scalars['String']['input']>
  readonly passportCountry?: InputMaybe<Scalars['String']['input']>
  readonly passportExpiration?: InputMaybe<Scalars['Date']['input']>
  readonly passportNumber?: InputMaybe<Scalars['String']['input']>
  /** This field only allows numeric values */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>
  readonly preferredFullName?: InputMaybe<Scalars['String']['input']>
  /** This field only allows numeric values */
  readonly ssn?: InputMaybe<Scalars['String']['input']>
  readonly title?: InputMaybe<Scalars['String']['input']>
}

export type PersonaInquiry = {
  readonly __typename?: 'PersonaInquiry'
  readonly inquiryId: Scalars['String']['output']
  readonly person: SignedCreditApplicationPerson
  readonly status: PersonaInquiryStatus
}

export enum PersonaInquiryStatus {
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  PENDING = 'PENDING',
}

export type Query = {
  readonly __typename?: 'Query'
  /** Get all bank accounts for divvy_uuid. For use with Sputnik. */
  readonly bankAccounts: ReadonlyArray<BankAccount>
  /** Get the Widget URL to connect user to MX or Plaid. For use with Sputnik. */
  readonly bankIntegrations: BankIntegrations
  readonly complianceHealthy: Maybe<Scalars['Boolean']['output']>
  readonly countryCodes: ReadonlyArray<CountryCode>
  /** The credit applicant who is currently authenticated */
  readonly creditApplicant: CreditApplicant
  /** Fetch an existing credit application by its id */
  readonly creditApplication: Maybe<CreditApplication>
  /** Fetch an existing credit application by its id (using the database only) */
  readonly creditApplicationBypassingSf: Maybe<CreditApplication>
  /** All of the credit applications associated with the current authenticated users email */
  readonly creditApplications: Maybe<ReadonlyArray<CreditApplication>>
  readonly decisions: Maybe<ReadonlyArray<Maybe<Decision>>>
  readonly frozenAuthorizedSigner: Maybe<Scalars['Boolean']['output']>
  /** Search for all verifiable institutions */
  readonly institutions: ReadonlyArray<Institution>
  /** Get all connection-related bank accounts for the given divv_uuid (not including manually added accounts). For use with Sputnik. */
  readonly integrationConnections: Maybe<ReadonlyArray<Maybe<BankConnection>>>
  readonly moneyMoverHealthy: Maybe<Scalars['Boolean']['output']>
  /** search naics codes */
  readonly naicsCodes: Maybe<ReadonlyArray<NaicsRecord>>
  readonly salesforceContactExists: Scalars['Boolean']['output']
  readonly underwritingDeclined: Maybe<Scalars['Boolean']['output']>
  readonly underwritingHealthy: Maybe<Scalars['Boolean']['output']>
}

export type QuerybankAccountsArgs = {
  divvyUuid: Scalars['String']['input']
}

export type QuerybankIntegrationsArgs = {
  divvyUuid: Scalars['String']['input']
  institutionCode?: InputMaybe<Scalars['String']['input']>
  isMobile: Scalars['Boolean']['input']
  mode: Scalars['String']['input']
}

export type QuerycreditApplicationArgs = {
  creditApplicationId: Scalars['ID']['input']
}

export type QuerycreditApplicationBypassingSfArgs = {
  creditApplicationId: Scalars['ID']['input']
}

export type QuerydecisionsArgs = {
  companyId: Scalars['String']['input']
}

export type QueryfrozenAuthorizedSignerArgs = {
  companyId: Scalars['String']['input']
}

export type QueryinstitutionsArgs = {
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryintegrationConnectionsArgs = {
  divvyUuid: Scalars['String']['input']
}

export type QuerynaicsCodesArgs = {
  query: Scalars['String']['input']
}

export type QueryunderwritingDeclinedArgs = {
  companyId: Scalars['String']['input']
}

export type RequestAuthCode = {
  readonly __typename?: 'RequestAuthCode'
  readonly email: Maybe<Scalars['String']['output']>
}

/** A credit application section and when it was first saved */
export type SavedSectionsInfo = {
  readonly __typename?: 'SavedSectionsInfo'
  readonly firstSavedAt: Scalars['DateTime']['output']
  readonly sectionName: SectionName
}

export enum SectionName {
  AUTH_SIGNER = 'AUTH_SIGNER',
  BUSINESS_INFO = 'BUSINESS_INFO',
  COMPANY_OWNERS = 'COMPANY_OWNERS',
  FINANCE_INFO = 'FINANCE_INFO',
}

/** A document that can be signed by the applicant */
export type SignableCreditApplicationDocument = {
  readonly __typename?: 'SignableCreditApplicationDocument'
  /** Content of the document */
  readonly content: Scalars['String']['output']
  /** Metadata for the document */
  readonly meta: SignableCreditApplicationDocumentMeta
  /** Name of the document */
  readonly type: SignableCreditApplicationDocumentType
}

/** Metadata for a signable document */
export type SignableCreditApplicationDocumentMeta = {
  readonly __typename?: 'SignableCreditApplicationDocumentMeta'
  /** Business physical address state of the credit application */
  readonly businessState: Maybe<Scalars['String']['output']>
  /** Timestamp when the document was signed */
  readonly signedAt: Maybe<Scalars['DateTime']['output']>
  /** If the document is related to an Underwriting decision, the UUID of the decision. */
  readonly underwritingDecisionUuid: Maybe<Scalars['String']['output']>
  /** If the document is related to an Underwriting offer, the type of the offer. */
  readonly underwritingOfferType: Maybe<UnderwritingOffer>
}

/** Type of signable document */
export enum SignableCreditApplicationDocumentType {
  /** Truth in Lending Act (TILA) disclosure */
  TILA = 'TILA',
}

export type SignedCreditApplicationPerson = {
  readonly __typename?: 'SignedCreditApplicationPerson'
  readonly firstName: Scalars['String']['output']
  readonly lastName: Scalars['String']['output']
}

export type Status = {
  readonly __typename?: 'Status'
  readonly message: Maybe<Scalars['String']['output']>
}

export type SuccessResult = {
  readonly __typename?: 'SuccessResult'
  readonly error: Maybe<Scalars['String']['output']>
  readonly success: Maybe<Scalars['Boolean']['output']>
}

/** Type of Underwriting offer */
export enum UnderwritingOffer {
  FIXED_LINE = 'FIXED_LINE',
  SECURED_LINE = 'SECURED_LINE',
  VARIABLE_LINE = 'VARIABLE_LINE',
}

import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { FormattedMessage } from '@divvy-web/i18n'
import Icon from '@divvy-web/skylab.icon'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import { useForm } from '@divvy-web/skylab.form'
import Tag, { TAG_TYPE_ERROR, TAG_TYPE_SUCCESS } from '@divvy-web/skylab.tag'
import { TOAST_TYPE_DANGER, TOAST_TYPE_SUCCESS, useToast } from '@divvy-web/skylab.toast'
import { css } from '@emotion/core'
import { func, node, string, bool, array } from 'prop-types'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FormButtons } from '../../components'
import getValidations from '../../components/FormWrapper/getValidations'
import useRemainingCorrections from '../../hooks/useRemainingCorrections'
import {
  AUTHORIZED_SIGNER,
  BENEFICIAL_OWNER_0,
  BENEFICIAL_OWNER_1,
  BENEFICIAL_OWNER_2,
  BENEFICIAL_OWNER_3,
  CreditApplicationStatus,
  LEGAL_BUSINESS_NAME,
  MAJOR_ACCOUNT_CHANGE,
  PAGE_NAME_STRINGS,
  PATHNAME_CONSTS,
  TAX_ID,
} from '../../resources/constants'
import { logInfo } from '../../utils/loggerUtils'
import { getPageMutationQuery, getPageMutationVariables, handleMutationError } from '../../utils/mutationUtils'
import useInitialBusinessValuesForMac from '../../hooks/useInitialBusinessValuesForMac'
import { initialValues } from '../FormPages/initialValues.js'
import { sharedFields as signupBusinessFieldsValidations } from '../SignUp/signupValidations'
import { formStyles } from '../styles/applicationPageSharedStyles'
import { mapAppSections } from './utils'

const ReviewSectionWrapper = ({
  component,
  correctionSection,
  route,
  sectionName,
  sectionTitle,
  refetchCreditAppTilaInfo,
}) => {
  const { formValues, getFormValue, resetForm, setFormValue, validationErrors } = useForm()
  const { _savedSections: savedSections } = formValues
  const requestingCorrection = getFormValue('status') === CreditApplicationStatus.CORRECTION_REQUESTED
  const { appId } = useParams()
  const [getInitialMacBusinessValues] = useInitialBusinessValuesForMac(appId)
  const { search, state } = useLocation()
  const navigate = useNavigate()
  const [editMode, setEditMode] = useState(false)
  const showToast = useToast()
  const gqlMutation = getPageMutationQuery(sectionName)
  const gqlValues = initialValues({}, sectionName)
  Object.keys(gqlValues).forEach((key) => (gqlValues[key] = formValues?.[key]))
  const gqlPageValues = { [sectionName]: gqlValues }
  const gqlVariables = getPageMutationVariables(sectionName, gqlPageValues, appId)
  const recordType = getFormValue('_recordType')
  const { PAGE_BUSINESS_INFO, PAGE_AUTHORIZED_SIGNER, PAGE_COMPANY_OWNERS, PAGE_FINANCE_INFO } = PAGE_NAME_STRINGS
  const hasEmptyInitialMacBusinessValue = !!Object.values(getInitialMacBusinessValues() || {}).filter((item) => !item)
    .length
  const isMajorAccountChange = recordType === MAJOR_ACCOUNT_CHANGE
  const majorAccountChanges = getFormValue('_majorAccountChanges')
  const hasBusinessInfoMajorAccountChange =
    majorAccountChanges?.includes(LEGAL_BUSINESS_NAME) || majorAccountChanges?.includes(TAX_ID)
  const hasAuthorizedSignerMajorAccountChange = majorAccountChanges?.includes(AUTHORIZED_SIGNER)
  const hasCompanyOwnersMajorAccountChange =
    majorAccountChanges?.includes(BENEFICIAL_OWNER_0) ||
    majorAccountChanges?.includes(BENEFICIAL_OWNER_1) ||
    majorAccountChanges?.includes(BENEFICIAL_OWNER_2) ||
    majorAccountChanges?.includes(BENEFICIAL_OWNER_3)

  const hasPersonMajorAccountChange = hasAuthorizedSignerMajorAccountChange || hasCompanyOwnersMajorAccountChange

  const navigateToDashboard = useCallback(
    () => navigate({ pathname: PATHNAME_CONSTS.DASHBOARD_PATH, search }, { state }),
    [state, search, navigate],
  )

  const showErrorToast = useCallback(
    (msg) =>
      showToast(TOAST_TYPE_DANGER, msg, {
        autoHideDelay: 5000,
        css: toastCss,
        dataTestId: 'page-submission-fail',
      }),
    [showToast],
  )

  const { completedCorrections, remainingCorrections, removeFromRemainingCorrections } = useRemainingCorrections(
    appId,
    getFormValue('sectionsToCorrect'),
    requestingCorrection,
  )

  let validationsForSection = getValidations({
    owner: null,
    pageName: route,
    requestedCorrections: requestingCorrection && getFormValue('sectionsToCorrect'),
  })

  if (sectionName === PAGE_BUSINESS_INFO) {
    validationsForSection = { ...validationsForSection, ...signupBusinessFieldsValidations }
  }

  const getHasValidationErrorsForSection = useCallback(
    (validationsForSection) => {
      const validationKeys = validationErrors ? Reflect.ownKeys(validationErrors) : []
      for (const key of validationKeys) {
        if (validationsForSection[key]) return true
      }
      return false
    },
    [validationErrors],
  )

  const hasValidationErrorsForSection = !!getHasValidationErrorsForSection(validationsForSection)

  const [updatePageMutation, { loading }] = useMutation(gqlMutation, {
    ...gqlVariables,
    onCompleted: () => {
      if (requestingCorrection && remainingCorrections.includes(correctionSection)) {
        removeFromRemainingCorrections(correctionSection)
      }

      if (refetchCreditAppTilaInfo) refetchCreditAppTilaInfo()

      setEditMode(false)

      logInfo({
        attributes: {
          action: 'reviewAndSignSectionUpdated',
          result: `Successfully updated section ${sectionName} from review and sign`,
        },
        eventName: 'ReviewSectionUpdate',
      })

      showToast(
        TOAST_TYPE_SUCCESS,
        <FormattedMessage
          defaultMessage='Successfully updated {sectionTitle}.'
          id='sputnik.ReviewSectionWrapper__zqFNCJ'
          values={{ sectionTitle }}
        />,
        {
          autoHideDelay: 5000,
          css: toastCss,
          dataTestId: 'section-submission-success',
        },
      )
    },
    onError: (error) => {
      const action = `${sectionName}Update`
      const eventName = 'ReviewAndSignSectionSave'

      handleMutationError({
        action,
        appId,
        error,
        eventName,
        navigateToDashboard,
        showErrorToast,
      })
    },
  })

  const handlePreviousClick = () => {
    resetForm()
    setEditMode(false)
  }

  const handleEditClick = () => {
    switch (sectionName) {
      case PAGE_COMPANY_OWNERS:
        navigate(`/app/${appId}/section/company-owners`, { state: { isUpdateMode: true } })
        break

      case PAGE_FINANCE_INFO:
        navigate(`/app/${appId}/section/finance-info`, { state: { isUpdateMode: true } })
        break

      default:
        setEditMode(true)
        break
    }
  }

  useEffect(() => {
    setFormValue('_isReviewEdit', editMode)
  }, [editMode, setFormValue])

  const correctionRequired = remainingCorrections.includes(correctionSection)
  const correctionCompleted = completedCorrections.includes(correctionSection)
  const correctionRequestedForOtherSections = requestingCorrection && !correctionRequired && !correctionCompleted
  const titleTag = correctionRequired ? <NeedsReviewTag /> : correctionCompleted ? <UpdatedTag /> : null

  const disableSectionForMac = useCallback(() => {
    if (!isMajorAccountChange || editMode) {
      return false
    }

    switch (sectionName) {
      case PAGE_BUSINESS_INFO:
        if (hasEmptyInitialMacBusinessValue) return false
        return !hasBusinessInfoMajorAccountChange
      case PAGE_AUTHORIZED_SIGNER:
        if (!getFormValue('title')) return false
        return !hasAuthorizedSignerMajorAccountChange
      case PAGE_COMPANY_OWNERS:
        return !hasPersonMajorAccountChange
      default:
        return false
    }
  }, [
    PAGE_AUTHORIZED_SIGNER,
    PAGE_BUSINESS_INFO,
    PAGE_COMPANY_OWNERS,
    editMode,
    getFormValue,
    hasAuthorizedSignerMajorAccountChange,
    hasBusinessInfoMajorAccountChange,
    hasEmptyInitialMacBusinessValue,
    hasPersonMajorAccountChange,
    isMajorAccountChange,
    sectionName,
  ])

  const childProps = {
    alwaysShowError: true,
    disabled: correctionRequestedForOtherSections || disableSectionForMac(),
    isReview: true,
    readOnly: !editMode,
  }

  return (
    <>
      <div css={reviewSectionOverlayCss(editMode)} />
      <div css={reviewSectionWrapperCss(editMode)}>
        <div
          className='section-title fs-unmask'
          data-testid={`section-title-${sectionName}`}
        >
          <span>
            {sectionTitle} {titleTag}
            {isMajorAccountChange && (
              <MajorAccChangeWarningIcon
                hasPersonMajorAccountChange={hasPersonMajorAccountChange}
                hasValidationErrorsForSection={hasValidationErrorsForSection}
                majorAccountChanges={majorAccountChanges}
                savedSections={savedSections}
                sectionName={sectionName}
              />
            )}
          </span>
          {editMode || disableSectionForMac() || correctionRequestedForOtherSections || (
            <BasicButton
              className='section-edit'
              color='neutral'
              dataTestId={`edit-btn-${sectionName}`}
              disabled={getFormValue('_isReviewEdit')}
              icon='edit'
              name='edit'
              type={BASIC_BUTTON_TYPE_FLAT}
              onClick={handleEditClick}
            />
          )}
          {editMode && (
            <BasicButton
              data-testid='informative-banner-dismiss'
              icon='close'
              name='close'
              type={BASIC_BUTTON_TYPE_FLAT}
              onClick={handlePreviousClick}
            />
          )}
        </div>
        <div
          className='review-section-content fs-unmask'
          css={(theme) => formStyles(childProps, theme)}
        >
          {sectionName === PAGE_COMPANY_OWNERS ? component() : component(childProps)}
        </div>
        {editMode && (
          <FormButtons
            hideExit
            isInline
            isReview
            dataTestId={`${sectionName}-`}
            disableNext={hasValidationErrorsForSection || loading}
            disableSaveOnInvalid={false}
            handleNextClick={updatePageMutation}
            handlePreviousClick={handlePreviousClick}
            nextButtonText={
              <FormattedMessage
                defaultMessage='Save'
                id='sputnik.ReviewSectionWrapper__jvo0vs'
              />
            }
            previousButtonText={
              <FormattedMessage
                defaultMessage='Cancel'
                id='sputnik.ReviewSectionWrapper__47FYwb'
              />
            }
            showNextSpinner={loading}
          />
        )}
      </div>
    </>
  )
}

ReviewSectionWrapper.propTypes = {
  component: func.isRequired,
  correctionSection: string,
  route: string.isRequired,
  sectionName: string.isRequired,
  sectionTitle: node.isRequired,
  refetchCreditAppTilaInfo: func,
}

const NeedsReviewTag = () => (
  <Tag
    css={tagCss}
    type={TAG_TYPE_ERROR}
  >
    <FormattedMessage
      defaultMessage='Review needed'
      id='sputnik.ReviewSectionWrapper__2NYUhF'
    />
  </Tag>
)

const UpdatedTag = () => (
  <Tag
    css={tagCss}
    type={TAG_TYPE_SUCCESS}
  >
    <FormattedMessage
      defaultMessage='Updated'
      id='sputnik.ReviewSectionWrapper__xrk6zg'
    />
  </Tag>
)

const reviewSectionOverlayCss = (editMode) => css`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.18);
  z-index: 15;
  width: 100%;
  height: 100%;
  opacity: ${editMode ? '1' : '0'};
  pointer-events: none;
  transition: all 0.2s ease-out;
`

const reviewSectionWrapperCss = (editMode) => css`
  ${
    editMode &&
    `
    max-width: 992px;
    z-index: 20;
    position: relative;
    background-color: var(--tri-color-fill-primary);
    outline: 32px solid var(--tri-color-fill-primary);
    border-radius: 8px;
    `
  }

  .FormButtons-container {
    position: relative;
  }

  [class^='BasicButton section-edit']:hover, {
  [class^='BasicButton section-edit']:disabled {
    background: none;
  }

  .section-title > span { 
    display: flex;  
    align-items: center; 
  }
`

const tagCss = css`
  vertical-align: text-bottom;
  margin-top: var(--tri-space-50);
  margin-left: var(--tri-space-100);
`

export default ReviewSectionWrapper

const MajorAccChangeWarningIcon = ({
  hasPersonMajorAccountChange,
  hasValidationErrorsForSection,
  majorAccountChanges,
  savedSections,
  sectionName,
}) => {
  if (
    (sectionName === PAGE_NAME_STRINGS.PAGE_AUTHORIZED_SIGNER &&
      hasValidationErrorsForSection &&
      majorAccountChanges.includes('AUTHORIZED_SIGNER')) ||
    (sectionName === PAGE_NAME_STRINGS.PAGE_COMPANY_OWNERS &&
      hasPersonMajorAccountChange &&
      !savedSections.includes(mapAppSections.companyOwners))
  ) {
    return (
      <Icon
        css={iconCss}
        name='warningFilled'
        size='large'
      />
    )
  }
  return null
}

MajorAccChangeWarningIcon.defaultProps = { savedSections: [] }

MajorAccChangeWarningIcon.propTypes = {
  hasPersonMajorAccountChange: bool,
  hasValidationErrorsForSection: bool,
  majorAccountChanges: array,
  savedSections: array,
  sectionName: string.isRequired,
}

const iconCss = css`
  color: var(--tri-color-text-danger);
  margin-left: var(--tri-space-100);
  margin-top: var(--tri-space-50);
`
const toastCss = ({ mq }) => css`
  width: 75%;

  ${mq.largeMaxWidth({ width: '100%' })}
`

import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import { css } from '@emotion/core'
import React from 'react'
import TextLink, { TextLinkSizes } from '@divvy-web/skylab.textlink'
import CheckboxInput from '@divvy-web/skylab.checkboxinput'
import { termsMapper } from '../../../pages/ReviewAndSign/utils'
import { URL, MIGRATION } from '../../../resources/constants'

interface ApplicationTermsProps {
  bankAccountLastFour: string
  dsaVersion: string
  hasAgreedToTerms: boolean
  disableApplicationTerms: boolean
  recordType: string
  onCheckboxClick: (e: React.ChangeEvent) => void
}

const ApplicationTerms = ({
  bankAccountLastFour = '',
  dsaVersion,
  hasAgreedToTerms,
  recordType,
  disableApplicationTerms,
  onCheckboxClick,
}: ApplicationTermsProps) => {
  const [getClassName, makeTestId] = useNameFormatter('ApplicationTerms')
  const { BILL_PRIVACY_NOTICE, BILL_TERMS_AND_CONDITIONS } = URL
  const termLink = `${termsMapper[recordType][dsaVersion]}`
  const isMigration = recordType === MIGRATION

  return (
    <div
      css={termsCss}
      data-testId={makeTestId('')}
    >
      <CheckboxInput
        checked={hasAgreedToTerms}
        dataTestId={makeTestId('checkbox')}
        disabled={disableApplicationTerms}
        isSmall={false}
        label={
          <FormattedMessage
            defaultMessage='I have read and agree to the following:'
            id='sputnik.ApplicationTerms__RNICJi'
          />
        }
        name='applicationAgreementTerms'
        onChange={onCheckboxClick}
      />
      <div className={getClassName('list')}>
        <ul>
          <li>
            <TextLink
              className={getClassName('creditTerms-link')}
              dataTestId={makeTestId('creditTerms-link')}
              href={termLink}
              size={TextLinkSizes.REGULAR}
              target='_blank'
            >
              <FormattedMessage
                defaultMessage='Credit terms'
                id='sputnik.ApplicationTerms__bz/EmN'
              />
            </TextLink>
          </li>
          <li>
            {/* TODO: replace this with <TermsAndConditionsLink /> */}
            <TextLink
              className={getClassName('terms-and-conditions-link')}
              dataTestId={makeTestId('terms-and-conditions-link')}
              href={BILL_TERMS_AND_CONDITIONS}
              size={TextLinkSizes.REGULAR}
              target='_blank'
            >
              <FormattedMessage
                defaultMessage='Terms & conditions'
                id='sputnik.ApplicationTerms__bPyOFk'
              />
            </TextLink>
          </li>
          <li>
            {/* TODO: replace with <PrivacyPolicyLink /> */}
            <TextLink
              className={getClassName('privacy-policy-link')}
              dataTestId={makeTestId('privacy-policy-link')}
              href={BILL_PRIVACY_NOTICE}
              size={TextLinkSizes.REGULAR}
              target='_blank'
            >
              <FormattedMessage
                defaultMessage='Privacy policy'
                id='sputnik.ApplicationTerms__cPwv2c'
              />
            </TextLink>
          </li>
          <li>
            <FormattedMessage
              defaultMessage='<textlink>ACH authorization</textlink> (account ending in {bankAccountLastFour})'
              id='sputnik.ApplicationTerms__BQ+Tma'
              values={{
                textlink: (chunks) => (
                  <TextLink
                    className={getClassName('ach-auth-link')}
                    dataTestId={makeTestId('ach-auth-link')}
                    href={termLink}
                    size={TextLinkSizes.REGULAR}
                    target='_blank'
                  >
                    {chunks}
                  </TextLink>
                ),
                bankAccountLastFour,
              }}
            />
          </li>
          {isMigration && (
            <li>
              <FormattedMessage
                defaultMessage='I certify to the best of my knowledge that the information provided above is complete and correct.'
                id='sputnik.ApplicationTerms__6Qd2rG'
              />
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

const termsCss = ({ type }) => css`
  max-width: 100%;
  .ApplicationTerms-list {
    ul {
      margin-left: var(--tri-space-650);
    }
  }
  ${type.triFontDesktopBodyMedium}
  color: var(--tri-color-text-primary)

  .ApplicationTerm-list {
    margin-top: var(--tri-space-150);
    ${type.triFontDesktopBodySmall}
  }
`
export default ApplicationTerms

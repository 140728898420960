import React from 'react'
import { object, bool } from 'prop-types'
import Spinner from '@divvy-web/skylab.spinner'

const ExternalConnectionLogo = ({ connectionInfo, isLoading }) => {
  const logoUrl = connectionInfo.logoUrl

  if (isLoading) {
    return <Spinner />
  }

  if (!logoUrl) {
    return null
  }

  return (
    <img
      alt='Connection Icon'
      height='31'
      src={logoUrl}
    />
  )
}

ExternalConnectionLogo.propTypes = {
  connectionInfo: object.isRequired,
  isLoading: bool,
}

export default ExternalConnectionLogo

import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'

export const countriesMapper = (countries = [], filterUnitedStates = false) => {
  const mapped = countries.map((country) => ({
    label: country?.displayName,
    value: country?.isoCode2,
  }))
  if (filterUnitedStates) {
    return mapped.filter(({ value }) => value !== 'US')
  } else {
    return mapped
  }
}

export const phoneNumberPlaceHolder = (isForeignAddress) => {
  return isForeignAddress ? (
    <FormattedMessage
      defaultMessage='Enter phone number'
      id='sputnik.countryUtils__OTAs3L'
    />
  ) : (
    '(___)___-____'
  )
}

export const phoneNumberMaxLength = (isForeignAddress) => {
  return isForeignAddress ? 20 : 14
}

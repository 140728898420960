// parse and return the results from address details query for autocompletion
export const parseAddressDetails = (addressDetails) => {
  const { address_components: addressComponents } = addressDetails || {}
  const addressKeys = ['street_number', 'route', 'locality', 'administrative_area_level_1', 'country', 'postal_code']

  return (addressComponents || []).reduce((accum, addressDetail) => {
    const { long_name: longName, short_name: shortName, types } = addressDetail
    const addressKeysInType = addressKeys.filter((key) => types.includes(key))
    if (addressKeysInType?.length > 0) {
      accum = {
        ...accum,
        [addressKeysInType[0]]: {
          longName,
          shortName,
        },
      }
    }

    return accum
  }, {})
}

export const parseState = (stateOptions, state) => {
  const shortName = state?.shortName ?? ''
  if (shortName.length === 2) {
    return shortName
  }

  return stateOptions.find((option) => option.label.toLowerCase() == shortName.toLowerCase())?.value || ''
}

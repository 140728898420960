import useDidMount from '@divvy-web/hooks.usedidmount'
import { css } from '@emotion/core'
import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useForm } from '@divvy-web/skylab.form'
import {
  CreditApplicationAddress,
  CreditApplicationBusinessEntity,
  CreditApplicationBusinessOwnership,
  CreditApplicationPerson,
  CreditApplicationStatus,
} from '_gql'
import { useAuth } from '../../auth'
import useRemainingCorrections from '../../hooks/useRemainingCorrections'
import { MAJOR_ACCOUNT_CHANGE, PAGE_NAME_STRINGS } from '../../resources/constants'
import { matchEmail } from '../../utils/dataUtils'
import CompanyOwnersReviewForm from '../CompanyOwners/CompanyOwnersReviewForm'
import { regularPages } from '../FormPages/pages'
import useInitialBusinessValuesForMac from '../../hooks/useInitialBusinessValuesForMac'
import { useGetCreditApplicationTilaInfo } from '../gql/GetCreditApplicationTilaInfo.gql'
import ApplicationAgreements from '../../components/ApplicationAgreements'
import ApplicationCorrectionBanner from './ApplicationCorrectionBanner'
import ReviewSectionWrapper from './ReviewSectionWrapper'
import ReviewTerms from './ReviewTerms'
import FCRATerms from './FCRATerms/FCRATerms'

interface ReviewAndSignProps {
  isMigration?: boolean
  isAppVersion2: boolean
  serverBusinessValues?: {
    admin: CreditApplicationPerson
    mailingAddress: CreditApplicationAddress
    physicalAddress: CreditApplicationAddress
    entityType: CreditApplicationBusinessEntity
    formedOn: string
    industry: string
    accountingSoftware: string
    legalBusinessName: string
    naicsCode: string
    ownership: CreditApplicationBusinessOwnership
    preferredAccountName: string
    website: string
    taxIdLastFour: string
  }
}

type Form = {
  _recordType: string
  status: typeof CreditApplicationStatus[keyof typeof CreditApplicationStatus]
  sectionsToCorrect: string[]
  email: string
  appVersion: number
  creditReportConsent: boolean
  physicalAddressState: string
}

type UseForm = <T extends object>() => Record<'getFormValue', <K extends keyof T>(x: K) => T[K]> &
  Record<'setFormValue', <K extends keyof T>(x: K, value: T[K]) => void>

const ReviewAndSign = ({ isMigration = false, serverBusinessValues, isAppVersion2 = false }: ReviewAndSignProps) => {
  const { email: currentEmail, goToVerifyCode, resetAuth } = useAuth()
  const { getFormValue, setFormValue } = (useForm as unknown as UseForm)<Form>()
  const { appId } = useParams<string>()
  const { pathname, search } = useLocation()
  const { PAGE_REVIEW_AND_SIGN, PAGE_COMPANY_OWNERS } = PAGE_NAME_STRINGS
  const isMajorAccountChange = getFormValue('_recordType') === MAJOR_ACCOUNT_CHANGE
  const requestingCorrection = getFormValue('status') === CreditApplicationStatus.CORRECTION_REQUESTED
  const { remainingCorrections } = useRemainingCorrections(
    appId,
    getFormValue('sectionsToCorrect'),
    requestingCorrection,
  )
  const loggedInUserIsAuthorizedSigner = matchEmail(getFormValue('email'), currentEmail) as boolean
  const shouldSkipTila = !loggedInUserIsAuthorizedSigner || !isMigration || !isAppVersion2

  const {
    admin,
    mailingAddress,
    physicalAddress,
    entityType,
    accountingSoftware,
    formedOn,
    industry,
    legalBusinessName,
    naicsCode,
    ownership,
    preferredAccountName,
    website,
    taxIdLastFour,
  } = serverBusinessValues || {}
  const [initialMacBusinessValues, setInitialMacBusinessValues] = useInitialBusinessValuesForMac(appId) as any
  useDidMount(() => {
    if (isMajorAccountChange && !initialMacBusinessValues()) {
      setInitialMacBusinessValues({
        adminEmail: admin?.email,
        adminFirstName: admin?.firstName,
        adminLastName: admin?.lastName,
        adminPhoneNumber: admin?.phoneNumber,
        adminPreferredFullName: admin?.preferredFullName,
        entity: entityType,
        formationDate: formedOn,
        industry: industry,
        accountingSoftware: accountingSoftware,
        legalBusinessName: legalBusinessName,
        mailingAddressCity: mailingAddress?.city,
        mailingAddressPostalCode: mailingAddress?.postalCode,
        mailingAddressState: mailingAddress?.state,
        mailingAddressStreet: mailingAddress?.street,
        mailingAddressStreetAdditional: mailingAddress?.streetAdditional,
        naicsCode: naicsCode,
        ownership: ownership,
        physicalAddressCity: physicalAddress?.city,
        physicalAddressPostalCode: physicalAddress?.postalCode,
        physicalAddressState: physicalAddress?.state,
        physicalAddressStreet: physicalAddress?.street,
        physicalAddressStreetAdditional: physicalAddress?.streetAdditional,
        preferredAccountName: preferredAccountName,
        taxId: taxIdLastFour,
        website: website,
      })
    }
  })

  const {
    data: creditApplicationTilaInfoData,
    loading: isLoadingCreditApplicationTilaInfo,
    refetch: refetchCreditAppTilaInfo,
  } = useGetCreditApplicationTilaInfo({
    variables: { creditApplicationId: appId as string, skipTila: shouldSkipTila as boolean },
    skip: shouldSkipTila,
  })

  const isSignerDifferentFromCurrentUser = useMemo(() => {
    // eslint-disable-next-line no-undef
    const signerEmail = new URLSearchParams(search).get('email')
    return signerEmail && !matchEmail(signerEmail, currentEmail)
  }, [currentEmail, search])

  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false)
  const isTypeCreditApplication = creditApplicationTilaInfoData?.creditApplication?.__typename === 'CreditApplication'

  const creditApplication = isTypeCreditApplication ? creditApplicationTilaInfoData?.creditApplication : null
  const { signedAt, underwritingDecisionUuid, underwritingOfferType, businessState } =
    creditApplication?.tila?.meta || {}

  const showReviewTerms = loggedInUserIsAuthorizedSigner && !isAppVersion2 && !isLoadingCreditApplicationTilaInfo
  const showAuthSignerFCRA = loggedInUserIsAuthorizedSigner && isAppVersion2 && !isLoadingCreditApplicationTilaInfo
  const needsToSignTila = !!creditApplication?.tila && !signedAt
  const hasAgreedToOfferSummary = !!signedAt && businessState == getFormValue('physicalAddressState')

  const handleTermsCheckboxClick = (event) => {
    setHasAgreedToTerms(!hasAgreedToTerms)
    setFormValue('creditReportConsent', !!event?.target?.checked && !needsToSignTila)
  }

  const sections = useMemo(
    () =>
      regularPages(isMigration, requestingCorrection, isMajorAccountChange).filter(
        ({ name }) => name !== PAGE_REVIEW_AND_SIGN,
      ),

    [PAGE_REVIEW_AND_SIGN, isMajorAccountChange, isMigration, requestingCorrection],
  )

  useEffect(() => {
    if (isSignerDifferentFromCurrentUser) {
      resetAuth()
      goToVerifyCode(pathname)
    }
  }, [goToVerifyCode, pathname, isSignerDifferentFromCurrentUser, resetAuth])

  return (
    <div css={reviewAndSignStyles}>
      <img
        alt='friends-walking'
        className='people-friends-img'
        src='https://app.divvy.co/assets/illustrations/people_friends_01.svg'
      />
      {!!remainingCorrections.length && <ApplicationCorrectionBanner />}
      {sections.map(({ component, correctionSection, name, route, title }) => {
        return (
          <ReviewSectionWrapper
            key={`review-${name}-section`}
            component={name === PAGE_COMPANY_OWNERS ? CompanyOwnersReviewForm : component}
            correctionSection={correctionSection}
            refetchCreditAppTilaInfo={shouldSkipTila ? null : refetchCreditAppTilaInfo}
            route={route}
            sectionName={name}
            sectionTitle={title}
          />
        )
      })}
      {showReviewTerms && (
        <section className='review-terms-and-conditions'>
          <ReviewTerms />
        </section>
      )}
      {showAuthSignerFCRA && !isMigration && (
        <section className='review-terms-and-conditions'>
          <FCRATerms />
        </section>
      )}
      {showAuthSignerFCRA && isMigration && creditApplication && (
        <ApplicationAgreements
          creditApplication={creditApplication}
          decisionId={underwritingDecisionUuid}
          hasAgreedToOfferSummary={hasAgreedToOfferSummary}
          hasAgreedToTerms={hasAgreedToTerms}
          offerType={underwritingOfferType}
          onCheckboxClick={(e: React.ChangeEvent) => handleTermsCheckboxClick(e)}
        />
      )}
    </div>
  )
}

const reviewAndSignStyles = ({ mq, type }) => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--tri-space-800);
  ${mq.xSmallMaxWidth({
    gap: 'var(--tri-space-600)',
    marginTop: ' calc(var(--tri-space-1000) * -1)',
  })}

  .people-friends-img {
    ${mq.xSmallMinWidth({ display: 'none' })};
    height: 260px;
    width: 260px;
    align-self: center;
    margin-right: var(--tri-space-300);
  }

  .review-terms-and-conditions {
    background-color: var(--tri-color-fill-primary);
    padding-top: var(--tri-space-400);
    padding-bottom: var(--tri-space-150);
    ${mq.xSmallMaxWidth({ paddingBottom: 'var(--tri-space-300)' })};
    position: -webkit-sticky;
    position: sticky;
    bottom: 80px;
    left: 0;
    z-index: 2;
  }

  .section-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 992px;
    width: 100%;
    height: 40px;
    width: 100%;
    gap: var(--tri-space-300);
    margin-bottom: var(--tri-space-200);
    ${mq.xSmallMaxWidth({ height: 'min-content', marginBottom: 'var(--tri-space-100)' })}
  }

  .section-title > span {
    ${type.triFontDesktopTitleMedium}
    ${mq.xSmallMaxWidth(type.triFontDesktopTitleSmallEmphasis)}
  }

  .section-title > [class^='BasicButton section-edit'] {
    color: var(--tri-color-fill-info-inverse);
  }

  .TextInput-action-area-read-only {
    height: 24px;
  }

  .finance-form-field-wrapper .error-text {
    margin-top: var(--tri-space-300);
  }
`

export default ReviewAndSign

export const getRegExStartOfString = (matchString) => {
  const symbolsTester = /[-!$%^&*()_+|~=`{}[\]:";'<>?,./\\]/g
  const matchStringWithSymbol = matchString?.replace(symbolsTester, '\\$&')
  const matcherExpression = `^${matchStringWithSymbol}`
  const matcher = new RegExp(matcherExpression)
  return matcher
}

export const getRegExContainsString = (matchString) => {
  const matcher = new RegExp(matchString)
  return matcher
}

import { css } from '@emotion/core'

export const dashboardTitleCss = ({ mq, type }) => css`
  ${type.triFontDesktopTitleMediumEmphasis}
  margin-bottom: var(--tri-space-150);

  ${mq.xxSmallMaxWidth(type.triFontDesktopTitleSmallEmphasis)}
`

export const statusButtonsCss = ({ mq }) => css`
  ${mq.xxSmallMaxWidth({ display: 'flex' })}
  .StatusButtons {
    ${mq.xxSmallMaxWidth({ flexDirection: 'column', minWidth: '0' })}

    button {
      ${mq.xxSmallMaxWidth({ margin: 'var(--tri-space-50) 0', width: 'var(--tri-space-3000)' })}
    }
  }
`

import { FormattedMessage } from '@divvy-web/i18n'
import { css } from '@emotion/core'
import React from 'react'
import TextLink from '@divvy-web/skylab.textlink'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { URL } from '../../resources/constants'

const RecaptchaDisclaimer = () => {
  const [makeTestId] = useNameFormatter('RecaptchaDisclaimer')
  const { GOOGLE_TERMS_OF_SERVICE, GOOGLE_PRIVACY_POLICY } = URL

  return (
    <div
      className='recaptcha-container fs-unmask'
      css={recaptchaDisclaimerCss}
    >
      <FormattedMessage
        defaultMessage='This site is protected by reCAPTCHA and the Google {privacyPolicy} and {termsOfService} apply.'
        id='sputnik.RecaptchaDisclaimer__2V6H6u'
        values={{
          privacyPolicy: (
            <TextLink
              dataTestId={makeTestId('privacy-policy-link')}
              href={GOOGLE_PRIVACY_POLICY}
              target='_blank'
            >
              <FormattedMessage
                defaultMessage='Privacy Policy'
                id='sputnik.RecaptchaDisclaimer__vx0nkZ'
              />
            </TextLink>
          ),
          termsOfService: (
            <TextLink
              dataTestId={makeTestId('terms-of-service-link')}
              href={GOOGLE_TERMS_OF_SERVICE}
              target='_blank'
            >
              <FormattedMessage
                defaultMessage='Terms of Service'
                id='sputnik.RecaptchaDisclaimer__32rBNK'
              />
            </TextLink>
          ),
        }}
      />
    </div>
  )
}

const recaptchaDisclaimerCss = ({ type }) => css`
  margin: var(--tri-space-100) 0 var(--tri-space-200);
  ${type.triFontDesktopCaptionSmall}
`

export default RecaptchaDisclaimer

import React from 'react'
import { node, func } from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../auth'
import { PATHNAME_CONSTS } from '../../resources/constants'

const RequireAuth = ({ children, component: Component }) => {
  const { isAuthExpired, isAuthenticated } = useAuth()
  const location = useLocation()

  if (isAuthenticated && !isAuthExpired()) {
    if (Component) {
      return <Component />
    } else {
      return children
    }
  } else {
    return (
      <Navigate
        state={{ referrer: location?.pathname }}
        to={{
          pathname: PATHNAME_CONSTS.AUTH_PATH,
          search: location?.search ?? '',
        }}
      />
    )
  }
}

RequireAuth.propTypes = {
  children: node,
  component: func,
}

export default RequireAuth

import { css } from '@emotion/core'
import { curry } from '@divvy-web/utils'

export const signupFormStyles = curry(
  ({ mq, type }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--tri-space-400);
    padding-bottom: var(--tri-space-1100);
    margin-right: var(--tri-space-1500);
    ${mq.xSmallMaxWidth({ gap: 'var(--tri-space-200)', height: 'auto', margin: 0 })}

    .phone-number {
      .TextInput-placeholder {
        margin-left: var(--tri-space-900);
      }

      .TextInput-input-wrapper-has-left-icon {
        padding-left: var(--tri-space-900);
        label {
          width: 56px;
        }
      }
    }

    .acknowledgement {
      margin-top: var(--tri-space-400);
      max-width: 1104px;
      width: 100%;
      ${type.triFontDesktopCaptionLarge}
      ${mq.xSmallMaxWidth(type.triFontDesktopCaptionSmall)}
    }

    .field-row {
      display: flex;
      gap: var(--tri-space-800);
      ${mq.xSmallMaxWidth({ flexDirection: 'column', gap: 'var(--tri-space-200)' })}
    }

    .field-container {
      display: block;
    }

    .field-container [class*='input-wrapper-read-only'] {
      padding-top: 7px;
      padding-bottom: 6px;
    }

    .FormField-value div[class*='caption'] {
      margin-left: 0;
    }
  `,
)

export const signupWrapperStyles = ({ mq }) => css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mq.xSmallMaxWidth({ height: 'auto' })}

  [class='Spinner BasicButton-spinner'] {
    ${mq.xSmallMaxWidth({ display: 'none' })}
  }
`

export const usCountryCodeStyles = css`
  color: var(--tri-color-fill-primary-inverse);
  width: 56px;
`

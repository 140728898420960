import React, { ReactNode } from 'react'
import { rum } from '@divvy-web/utils.log'
import { CanaryProvider } from '@bill/canary.react'
import { getCanaryOverrides, setCanaryOverrides, clearCanaryOverrides } from '../utils/canaryUtils'
import canaryConfig from './canaryConfig'

const LAUNCH_DARKLY_DISABLED: boolean = !!(window.__DivvyEnvironment as any).DISABLE_LAUNCH_DARKLY
const LAUNCH_DARKLY_REST_TOKEN = process.env.LAUNCH_DARKLY_REST_TOKEN as any

interface SputnikCanaryProviderProps {
  children: ReactNode
}

export const SputnikCanaryProvider = ({ children }: SputnikCanaryProviderProps) => {
  return (
    <CanaryProvider
      canaryHashName='#canary-ui'
      config={canaryConfig}
      globalName='canary-ui'
      launchDarklyRestToken={LAUNCH_DARKLY_DISABLED ? null : LAUNCH_DARKLY_REST_TOKEN}
      overrides={getCanaryOverrides('canary')}
      onEvaluateFlag={(key, value) => {
        rum && rum.addFeatureFlagEvaluation(key, value)
      }}
      onFlagChange={setCanaryOverrides('canary')}
      onResetOverrides={clearCanaryOverrides('canary')}
    >
      {children}
    </CanaryProvider>
  )
}

import React, { useContext, useState } from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import CheckboxInput from '@divvy-web/skylab.checkboxinput'
import { FormContext, FormDropdown, FormTextInput } from '@divvy-web/skylab.form'
import { array, bool, node, object, string } from 'prop-types'
import FormElementWrapper from '../../components/FormInputs/FormElementWrapper'
import { getFormattedZipcode } from '../utils'
import StreetInput from './StreetInput'

const AddressFormFields = ({
  // allowNonUsAddress is only true for CompanyOwnerForm and AuthorizedSignerForm
  allowNonUsAddress = false,
  alwaysShowError = false,
  captions,
  companyOwnersClass = '',
  countryOptions,
  disabled = false,
  label,
  labelLine2,
  pageName,
  readOnly,
  stateOptions,
}) => {
  const { getFormValue, setFormValue } = useContext(FormContext)
  const getInputName = (field) => `${pageName}${field}`
  const addressTwo = getFormValue(getInputName('StreetAdditional'))
  const hasAddressTwo = !!addressTwo && addressTwo !== ''
  const countryCode = getFormValue(getInputName('CountryCode'))
  const [isForeignAddress, setIsForeignAddress] = useState(allowNonUsAddress && countryCode !== 'US')

  const handleForeignAddressToggle = () => {
    if (isForeignAddress) {
      setIsForeignAddress(false)
      setFormValue(getInputName('PostalCode'), '')
      setFormValue(getInputName('State'), '')
      setFormValue(getInputName('CountryCode'), 'US')
    } else {
      setIsForeignAddress(true)
      setFormValue(getInputName('PostalCode'), '')
      setFormValue(getInputName('State'), '')
      setFormValue(getInputName('CountryCode'), '')
    }
  }

  const truncateZipCode = (e) => {
    const value = e.target.value
    const fieldName = e.target.name
    if (value.length < 10) {
      setFormValue(fieldName, getFormattedZipcode(value.slice(0, 5)))
    }
  }

  return (
    <>
      {allowNonUsAddress && !readOnly && (
        <CheckboxInput
          autoComplete='none'
          checked={isForeignAddress}
          dataTestId='non-US-address'
          disabled={disabled}
          label={
            <FormattedMessage
              defaultMessage='I have a non US address'
              id='sputnik.AddressFormFields__/9CqYJ'
            />
          }
          name={getInputName('foreignAddressToggle')}
          onChange={handleForeignAddressToggle}
        />
      )}

      <StreetInput
        alwaysShowError={alwaysShowError}
        caption={captions?.street}
        companyOwnersClass={companyOwnersClass}
        disabled={disabled}
        getInputName={getInputName}
        isForeignAddress={isForeignAddress}
        label={label}
        readOnly={readOnly}
        stateOptions={stateOptions}
      />

      {(!readOnly || (readOnly && hasAddressTwo)) && (
        <div className={`field-row ${companyOwnersClass}`}>
          <div className={`field-container-full fs-unmask ${companyOwnersClass}`}>
            <FormElementWrapper>
              <FormTextInput
                className='fs-mask'
                disabled={disabled}
                label={labelLine2}
                maxLength={99}
                name={getInputName('StreetAdditional')}
                placeholder={labelLine2}
                readOnly={readOnly}
              />
            </FormElementWrapper>
          </div>
        </div>
      )}
      <div className={`field-row ${companyOwnersClass}`}>
        <div className={`field-container fs-unmask ${companyOwnersClass}`}>
          <FormElementWrapper>
            <FormTextInput
              alwaysShowError={alwaysShowError}
              autoComplete='none'
              className='fs-mask'
              disabled={disabled}
              label={
                <FormattedMessage
                  defaultMessage='City'
                  id='sputnik.AddressFormFields__TE4fIS'
                />
              }
              maxLength={39}
              name={getInputName('City')}
              placeholder={
                <FormattedMessage
                  defaultMessage='City'
                  id='sputnik.AddressFormFields__TE4fIS'
                />
              }
              readOnly={readOnly}
            />
          </FormElementWrapper>
        </div>

        <div className={`field-container fs-unmask ${companyOwnersClass}`}>
          {isForeignAddress && (
            <FormElementWrapper>
              <FormTextInput
                alwaysShowError={alwaysShowError}
                autoComplete='none'
                disabled={disabled}
                label={
                  <FormattedMessage
                    defaultMessage='State/Province'
                    id='sputnik.AddressFormFields__ZZUA38'
                  />
                }
                name={getInputName('State')}
                placeholder={
                  <FormattedMessage
                    defaultMessage='State/Province'
                    id='sputnik.AddressFormFields__ZZUA38'
                  />
                }
                readOnly={readOnly}
              />
            </FormElementWrapper>
          )}
          {!isForeignAddress && (
            <FormElementWrapper>
              <FormDropdown
                isSearchable
                alwaysShowError={readOnly || alwaysShowError}
                autoComplete='none'
                disabled={disabled}
                label={
                  <FormattedMessage
                    defaultMessage='State'
                    id='sputnik.AddressFormFields__ku+mDU'
                  />
                }
                name={getInputName('State')}
                options={stateOptions}
                placeholder={
                  <FormattedMessage
                    defaultMessage='State'
                    id='sputnik.AddressFormFields__ku+mDU'
                  />
                }
                readOnly={readOnly}
              />
            </FormElementWrapper>
          )}
        </div>
      </div>
      <div className={`field-row ${companyOwnersClass}`}>
        {!isForeignAddress && (
          <div className={`field-container fs-unmask ${companyOwnersClass}`}>
            <FormElementWrapper>
              <FormTextInput
                alwaysShowError={alwaysShowError}
                autoComplete='none'
                className='fs-mask'
                disabled={disabled}
                inputMode='numeric'
                label={
                  <FormattedMessage
                    defaultMessage='Zip code'
                    id='sputnik.AddressFormFields__2gnKy+'
                  />
                }
                maxLength={10}
                name={getInputName('PostalCode')}
                normalize={getFormattedZipcode}
                placeholder={
                  <FormattedMessage
                    defaultMessage='Zip code'
                    id='sputnik.AddressFormFields__2gnKy+'
                  />
                }
                readOnly={readOnly}
                onBlur={(e) => truncateZipCode(e)}
              />
            </FormElementWrapper>
          </div>
        )}
        {isForeignAddress && (
          <>
            <div className={`field-container fs-unmask ${companyOwnersClass}`}>
              <FormElementWrapper>
                <FormTextInput
                  alwaysShowError={alwaysShowError}
                  autoComplete='none'
                  className='fs-mask'
                  disabled={disabled}
                  inputMode='numeric'
                  label={
                    <FormattedMessage
                      defaultMessage='Postal code'
                      id='sputnik.AddressFormFields__3EnruA'
                    />
                  }
                  maxLength={10}
                  name={getInputName('PostalCode')}
                  normalize={undefined}
                  placeholder={
                    <FormattedMessage
                      defaultMessage='Postal code'
                      id='sputnik.AddressFormFields__3EnruA'
                    />
                  }
                  readOnly={readOnly}
                />
              </FormElementWrapper>
            </div>
            <div className={`field-container fs-unmask ${companyOwnersClass}`}>
              <FormElementWrapper>
                <FormDropdown
                  alwaysShowError={alwaysShowError}
                  autoComplete='none'
                  disabled={disabled}
                  label={
                    <FormattedMessage
                      defaultMessage='Country'
                      id='sputnik.AddressFormFields__vONi+O'
                    />
                  }
                  name={getInputName('CountryCode')}
                  options={countryOptions}
                  placeholder={
                    <FormattedMessage
                      defaultMessage='Country'
                      id='sputnik.AddressFormFields__vONi+O'
                    />
                  }
                  readOnly={readOnly}
                />
              </FormElementWrapper>
            </div>
          </>
        )}
      </div>
    </>
  )
}

AddressFormFields.propTypes = {
  allowNonUsAddress: bool,
  alwaysShowError: bool,
  captions: object,
  companyOwnersClass: string,
  countryOptions: array,
  disabled: bool,
  label: node,
  labelLine2: node,
  pageName: string,
  readOnly: bool,
  stateOptions: array,
}

export default AddressFormFields

/** ! WARNING: DO NOT EDIT ! **
  This file is generated by graphql codegen.
  Make changes to *.graphql documents, not this file
**/

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import type * as _Types from '_gql'
const defaultOptions = {} as const
export type InitCreditApplication_initCreditApplication_Contact = { readonly __typename: 'Contact' }

export type InitCreditApplication_initCreditApplication_CreditApplication = {
  readonly __typename: 'CreditApplication'
  readonly salesforceCreditId: string
  readonly companyDivvyUuid: string | null
  readonly appVersion: number | null
}

export type InitCreditApplication_initCreditApplication =
  | InitCreditApplication_initCreditApplication_Contact
  | InitCreditApplication_initCreditApplication_CreditApplication

export type InitCreditApplication_Mutation = {
  readonly __typename: 'Mutation'
  readonly initCreditApplication: InitCreditApplication_initCreditApplication
}

export type InitCreditApplicationVariables = _Types.Exact<{
  accountId?: _Types.InputMaybe<_Types.Scalars['ID']['input']>
  affiliateId?: _Types.InputMaybe<_Types.Scalars['Float']['input']>
  applicant: _Types.PersonInput
  businessInfo: _Types.BusinessInfoInput
  recaptchaToken: _Types.Scalars['String']['input']
  clientTimeZone?: _Types.InputMaybe<_Types.Scalars['String']['input']>
  marketoBillLeadInput?: _Types.InputMaybe<_Types.MarketoBillLeadInput>
  appVersion?: _Types.InputMaybe<_Types.Scalars['Int']['input']>
  partnerCode?: _Types.InputMaybe<_Types.Scalars['String']['input']>
}>

export type InitCreditApplication = InitCreditApplication_Mutation

export const InitCreditApplicationDocument = gql`
  mutation InitCreditApplication(
    $accountId: ID
    $affiliateId: Float
    $applicant: PersonInput!
    $businessInfo: BusinessInfoInput!
    $recaptchaToken: String!
    $clientTimeZone: String
    $marketoBillLeadInput: MarketoBillLeadInput
    $appVersion: Int
    $partnerCode: String
  ) {
    initCreditApplication(
      accountId: $accountId
      affiliateId: $affiliateId
      applicant: $applicant
      partnerCode: $partnerCode
      businessInfo: $businessInfo
      recaptchaToken: $recaptchaToken
      clientTimeZone: $clientTimeZone
      marketoBillLeadInput: $marketoBillLeadInput
      appVersion: $appVersion
    ) {
      ... on CreditApplication {
        salesforceCreditId
        companyDivvyUuid
        appVersion
      }
    }
  }
`
export type InitCreditApplicationMutationFn = Apollo.MutationFunction<
  InitCreditApplication,
  InitCreditApplicationVariables
>

/**
 * __useInitCreditApplication__
 *
 * To run a mutation, you first call `useInitCreditApplication` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitCreditApplication` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initCreditApplication, { data, loading, error }] = useInitCreditApplication({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      affiliateId: // value for 'affiliateId'
 *      applicant: // value for 'applicant'
 *      businessInfo: // value for 'businessInfo'
 *      recaptchaToken: // value for 'recaptchaToken'
 *      clientTimeZone: // value for 'clientTimeZone'
 *      marketoBillLeadInput: // value for 'marketoBillLeadInput'
 *      appVersion: // value for 'appVersion'
 *      partnerCode: // value for 'partnerCode'
 *   },
 * });
 */
export function useInitCreditApplication(
  baseOptions?: Apollo.MutationHookOptions<InitCreditApplication, InitCreditApplicationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InitCreditApplication, InitCreditApplicationVariables>(
    InitCreditApplicationDocument,
    options,
  )
}
export type InitCreditApplicationHookResult = ReturnType<typeof useInitCreditApplication>
export type InitCreditApplicationMutationResult = Apollo.MutationResult<InitCreditApplication>
export type InitCreditApplicationMutationOptions = Apollo.BaseMutationOptions<
  InitCreditApplication,
  InitCreditApplicationVariables
>

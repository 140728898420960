const useInitialBusinessValuesForMac = (appId) => {
  const setInitialMacBusinessValues = (initialValuesToSet) => {
    window.localStorage.setItem(`initialMacBusinessValues-${appId}`, JSON.stringify(initialValuesToSet))
  }

  const getInitialMacBusinessValues = () => {
    const macBusinessValuesSet = window.localStorage.getItem(`initialMacBusinessValues-${appId}`)
    return macBusinessValuesSet ? JSON.parse(macBusinessValuesSet) : null
  }

  return [getInitialMacBusinessValues, setInitialMacBusinessValues]
}

export default useInitialBusinessValuesForMac

import { css } from '@emotion/core'
import React from 'react'
import { replaceBreakableSpaces } from '../components/utils'

const useGetBillProduct = (isUwPage = true) => {
  const billLogo = 'https://app.divvy.co/assets/logos/bill-logo-color'
  const defaultLogo = `${billLogo}@1x.png`
  const mediumLogo = `${billLogo}@2x.png`
  const largeLogo = `${billLogo}@3x.png`

  const billSideBarIcon = (
    <div
      className='bill-logo'
      css={css`
        align-self: flex-end;
        height: 34px;
        margin: ${isUwPage ? 'var(--tri-space-500)' : 'var(--tri-space-350) 0 0 auto'};

        .bill-logo-img {
          width: 52px;
        }
      `}
    >
      <img
        alt='bill-logo'
        className='bill-logo-img'
        data-testid='image-bill-logo'
        src={defaultLogo}
        srcSet={`${mediumLogo}, ${largeLogo}`}
      />
    </div>
  )

  const productContent = {
    company: 'BILL',
    loadingLogo: 'brand-loading',
    product: replaceBreakableSpaces('BILL Spend & Expense'),
    qrCodeIcon: 'bill-logo-mark-black.svg',
    sideBarIcon: billSideBarIcon,
  }

  return {
    ...productContent,
  }
}

export default useGetBillProduct

import moment from 'moment'
import { getTokenFromStorage } from './localStorageUtils'
import { getDivvyAuthTokenExpirationDate } from './paseto/paseto'
import { getFormattedExpirationDateFromToken } from './jwt'

export const getOriginalAccessToken = () => window.sessionStorage.getItem('originalToken')
export const setOriginalAccessToken = (originalToken) => window.sessionStorage.setItem('originalToken', originalToken)
export const unsetOriginalAccessToken = () => window.sessionStorage.removeItem('originalToken')

export const isDivvyAuthToken = (token) => {
  const currentToken = token || getTokenFromStorage()
  return !!currentToken?.startsWith('v2.public')
}

export const isTokenExpired = (token) => {
  const currentToken = token || getTokenFromStorage()
  if (!currentToken) return true
  const isDivvyAuth = isDivvyAuthToken(currentToken)
  const expDate = isDivvyAuth ? getDivvyAuthTokenExpirationDate() : getFormattedExpirationDateFromToken(currentToken)
  if (!expDate) return true
  const currentDateEpoch = moment(new Date().toUTCString()).valueOf()
  return isDivvyAuth ? expDate < currentDateEpoch : !(expDate.valueOf() > new Date().valueOf())
}

// TODO: write tests

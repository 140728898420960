import React, { useState } from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import Form, { FormTextInput } from '@divvy-web/skylab.form'
import { css } from '@emotion/core'
import TextLink from '@divvy-web/skylab.textlink'
import { PAGE_NAME_STRINGS } from '../../resources/constants'
import { AutoCompleteDisable, FormElementWrapper } from '../../components/FormInputs'
import MiscPageWrapper from '../../components/MiscPageWrapper/MiscPageWrapper'
import PageViewWrapper from '../../components/PageViewWrapper/PageViewWrapper'
import { FormButtons } from '../../components'
import ResetPasswordContainer from '../../components/ResetPasswordContainer'
import { getEmailFromStorage, useAuth } from '../../auth'

const INCORRECT_CREDENTIALS_ERROR = 'Incorrect username or password.'

const validation = {
  passwordDisplay: {
    presence: {
      message: 'Please enter a password',
    },
  },
}

const VerifyPassword = () => {
  const { handleDivvyAuthLogin } = useAuth()
  const [isResetPasswordModalShowing, setIsResetPasswordModalShowing] = useState(false)
  const [loginError, setLoginError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = (_, { passwordDisplay }) => {
    handleDivvyAuthLogin({ email: getEmailFromStorage(), password: passwordDisplay, setIsSubmitting, setLoginError })
  }

  const handleResetPasswordClick = () => setIsResetPasswordModalShowing(true)

  const errorCaption =
    loginError === INCORRECT_CREDENTIALS_ERROR ? (
      <FormattedMessage
        defaultMessage='Invalid login credentials.'
        id='sputnik.VerifyPassword__PoE03Q'
      />
    ) : (
      <FormattedMessage
        defaultMessage='An error occured during login.'
        id='sputnik.VerifyPassword__Jso/42'
      />
    )

  return (
    <PageViewWrapper pageName={PAGE_NAME_STRINGS.PAGE_VERIFY_CODE}>
      <MiscPageWrapper
        pageName='VerifyEmail'
        pageSubTitle={
          <FormattedMessage
            defaultMessage="We're so excited you're here!"
            id='sputnik.VerifyPassword__dg2Cyr'
          />
        }
        pageTitle={
          <FormattedMessage
            defaultMessage='Welcome'
            id='sputnik.VerifyPassword__PwaN2o'
          />
        }
      >
        <div
          className='info-text fs-unmask'
          css={passwordInfoTextCss}
          data-testid='password-text'
        >
          <FormattedMessage
            defaultMessage='Please enter the password associated with your email {email}.'
            id='sputnik.VerifyPassword__nwhmgK'
            values={{ email: getEmailFromStorage() }}
          />
        </div>
        <Form
          css={passwordFieldCss}
          validation={validation}
          onChange={() => setLoginError(null)}
          onSubmit={handleSubmit}
        >
          <div className='field-container fs-unmask'>
            <FormElementWrapper
              errorCaption={errorCaption}
              hasError={!!loginError}
            >
              <FormTextInput
                autoComplete='none'
                className='password fs-mask'
                dataTestId='verify-password-field'
                label={
                  <FormattedMessage
                    defaultMessage='Password'
                    id='sputnik.VerifyPassword__5sg7KC'
                  />
                }
                name='passwordDisplay'
                placeholder={
                  <FormattedMessage
                    defaultMessage='Password'
                    id='sputnik.VerifyPassword__5sg7KC'
                  />
                }
                type='password'
              />
            </FormElementWrapper>
          </div>
          <AutoCompleteDisable />
          <TextLink
            className='reset-password-btn fs-unmask'
            dataTestId='reset-password-link'
            onClick={handleResetPasswordClick}
          >
            <FormattedMessage
              defaultMessage='Reset password'
              id='sputnik.VerifyPassword__Yy/yDL'
            />
          </TextLink>
          <div
            className='reset-password-form-button-wrapper'
            css={formButtonCss}
          >
            <FormButtons
              hideExit
              disableNext={isSubmitting}
              nextButtonType='submit'
              showNextSpinner={isSubmitting}
              onClick={handleSubmit}
            />
          </div>
        </Form>
        <ResetPasswordContainer
          isShowing={isResetPasswordModalShowing}
          onClose={() => setIsResetPasswordModalShowing(false)}
        />
      </MiscPageWrapper>
    </PageViewWrapper>
  )
}

export default VerifyPassword

const passwordFieldCss = css`
  .reset-password-btn {
    margin-top: var(--tri-space-300);
  }
  .field-container {
    max-width: 256px;
  }
`

const formButtonCss = css`
  position: relative;
  z-index: 3;
`

const passwordInfoTextCss = ({ mq, type }) => css`
  display: flex;
  margin-bottom: var(--tri-space-450);
  width: 75%;
  ${type.triFontDesktopBodyMedium}
  ${mq.xSmallMaxWidth({ marginBottom: 'var(--tri-space-550)', width: '100%' })}
`

import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { useForm } from '@divvy-web/skylab.form'
import { css } from '@emotion/core'
import { arrayOf, bool, number } from 'prop-types'
import CompanyOwnerForm from './CompanyOwnerForm'

const CompanyOwnersReviewForm = () => {
  const { getFormValue } = useForm()

  const ownersCreated = [0, 1, 2, 3].map((idx) => getFormValue(`owner${idx}_isCreated`))

  return (
    <section css={companyOwnersStyle}>
      <div className='owners-container'>
        {ownersCreated.filter((x) => x).length ? (
          <>
            <CompanyOwnerView
              readOnly
              ownerIndex={0}
              ownersCreated={ownersCreated}
            />
            <CompanyOwnerView
              readOnly
              ownerIndex={1}
              ownersCreated={ownersCreated}
            />
            <CompanyOwnerView
              readOnly
              ownerIndex={2}
              ownersCreated={ownersCreated}
            />
            <CompanyOwnerView
              readOnly
              ownerIndex={3}
              ownersCreated={ownersCreated}
            />
          </>
        ) : (
          <div>
            <FormattedMessage
              defaultMessage='You did not add any company owners.'
              id='sputnik.CompanyOwnersReviewForm__1XaJAQ'
            />
          </div>
        )}
      </div>
    </section>
  )
}

const CompanyOwnerView = ({ ownerIndex, ownersCreated }) => {
  if (!ownersCreated[ownerIndex]) return null

  return (
    <>
      {ownersCreated.filter((x) => x).length > 1 && (
        <h3>
          <FormattedMessage
            defaultMessage='Company Owner {ownerOrder}'
            id='sputnik.CompanyOwnersReviewForm__Aua5IC'
            values={{ ownerOrder: ownerIndex + 1 }}
          />
        </h3>
      )}
      <CompanyOwnerForm
        readOnly
        ownerIndex={ownerIndex}
        ownersCreated={ownersCreated}
      />
    </>
  )
}

CompanyOwnerView.propTypes = {
  ownerIndex: number.isRequired,
  ownersCreated: arrayOf(bool).isRequired,
}

export default CompanyOwnersReviewForm

const companyOwnersStyle = ({ type }) => css`
  .owners-container {
    display: flex;
    flex-direction: column;
    gap: var(--tri-space-300);
  }

  .owners-container h3 {
    margin-bottom: 0;
    ${type.triFontDesktopLabelMediumEmphasis}
  }
`

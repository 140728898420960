import axios from 'axios'

export const uploadToS3 = async (file, signedUrl) => {
  const options = {
    headers: {
      'Content-Disposition': 'attachment',
      'Content-Type': file.type,
    },
  }

  return axios.put(signedUrl, file, options)
}

import { CreditApplicationBusinessEntity } from '_gql'
import { getFormattedDate } from '../../components/utils'
import { CREDIT_APPLICATION, MAJOR_ACCOUNT_CHANGE, MIGRATION, PAGE_NAME_STRINGS } from '../../resources/constants'
import {
  bankInfoWithMaskedNumber,
  getEmptyCompanyOwner,
  getSelectedExternalId,
  matchEmail,
  stringDateToEpoch,
} from '../../utils/dataUtils'
import { MANUAL_SOURCE } from '../FinanceInfo/financeInfoConstants'
import { titleOptions } from '../../pages/AuthorizedSigner/authorizedSignerFormUtils.js'

export const initialValues = (creditApp, pageName) => {
  switch (pageName) {
    case PAGE_NAME_STRINGS.PAGE_BUSINESS_INFO:
      return businessInfoInitialValues(creditApp)
    case PAGE_NAME_STRINGS.PAGE_FINANCE_INFO:
      return financeInfoInitialValues(creditApp)
    case PAGE_NAME_STRINGS.PAGE_AUTHORIZED_SIGNER:
      return authorizedSignerInitialValues(creditApp)
    case PAGE_NAME_STRINGS.PAGE_COMPANY_OWNERS:
      return companyOwnersInitialValues(creditApp)
    case CREDIT_APPLICATION:
      return {
        ...businessInfoInitialValues(creditApp),
        ...(creditApp?.recordType !== MAJOR_ACCOUNT_CHANGE && financeInfoInitialValues(creditApp)),
        ...authorizedSignerInitialValues(creditApp),
        ...companyOwnersInitialValues(creditApp),
        _isReviewEdit: false,
        _majorAccountChanges: creditApp?.majorAccountChanges ?? [],
        _recordType: creditApp?.recordType,
        _savedSections: creditApp?.savedSections,
        achAuthorization: false,
        authorizedSignerId: creditApp?.authorizedSigner?.id,
        bdcOrgId: creditApp?.bdcOrgId,
        creditApplicationFetchedAt: creditApp?.fetchedAt,
        creditTerms: false,
        dsaVersion: creditApp?.dsaVersion,
        electronicCommunicationTerms: false,
        id: creditApp?.id,
        creditReportConsent: false,
        appVersion: creditApp?.appVersion,
      }
  }
}

const businessInfoInitialValues = (creditApp = {}) => {
  const businessInfo = creditApp?.businessInfo ?? {}
  const {
    email,
    firstName,
    id: adminId,
    lastName,
    phoneNumber,
    preferredFullName,
  } = creditApp?.admin ?? {
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    phoneNumber: '',
    preferredFullName: null,
  }
  const formationDateEpoch = stringDateToEpoch(businessInfo?.formedOn)

  const maskedTaxId =
    businessInfo?.entityType === CreditApplicationBusinessEntity.SOLE_PROPRIETORSHIP
      ? `***-**-${businessInfo?.taxIdLastFour}`
      : `**-***${businessInfo?.taxIdLastFour}`

  const adminDetails = {
    adminEmail: email,
    adminFirstName: firstName,
    adminId,
    adminLastName: lastName,
    adminPhoneNumber: phoneNumber,
    adminPreferredFullName: preferredFullName,
  }

  const businessInfoDetails = {
    entityType: businessInfo?.entityType,
    formationDate: getFormattedDate(formationDateEpoch),
    industry: businessInfo?.naicsCode ? businessInfo?.industry : '',
    accountingSoftware: businessInfo?.accountingSoftware ? businessInfo?.accountingSoftware?.accountingSoftwareKey : '',
    legalBusinessName: businessInfo?.legalBusinessName ?? '',
    mailingAddressCity: businessInfo?.mailingAddress?.city ?? '',
    mailingAddressPostalCode: businessInfo?.mailingAddress?.postalCode ?? '',
    mailingAddressState: businessInfo?.mailingAddress?.state ?? '',
    mailingAddressStreet: businessInfo?.mailingAddress?.street ?? '',
    mailingAddressStreetAdditional: businessInfo?.mailingAddress?.streetAdditional ?? '',
    mailingPhysicalAddressesEqual: businessInfo?.mailingPhysicalAddressesEqual,
    naicsCode: businessInfo?.naicsCode,
    ownership: businessInfo?.ownership ?? '',
    physicalAddressCity: businessInfo?.physicalAddress?.city ?? '',
    physicalAddressPostalCode: businessInfo?.physicalAddress?.postalCode ?? '',
    physicalAddressState: businessInfo?.physicalAddress?.state ?? '',
    physicalAddressStreet: businessInfo?.physicalAddress?.street ?? '',
    physicalAddressStreetAdditional: businessInfo?.physicalAddress?.streetAdditional ?? '',
    preferredAccountName: businessInfo?.preferredAccountName ?? '',
    taxId: '',
    taxIdDisplay: businessInfo?.taxIdLastFour ? maskedTaxId : '',
    validateMailAddressFormFields: true,
    website: businessInfo?.website ?? '',
  }

  return creditApp?.recordType === MIGRATION ? { ...businessInfoDetails } : { ...adminDetails, ...businessInfoDetails }
}

const currencyFormatter = new Intl.NumberFormat('us-en', {
  currency: 'USD',
  minimumFractionDigits: 0,
  style: 'currency',
})

const financeInfoInitialValues = (creditApp = {}) => {
  const financeInfo = creditApp?.financeInfo ?? {
    bankInfo: { source: MANUAL_SOURCE },
    selectedBankInfo: { source: MANUAL_SOURCE },
  }
  const { bankInfo: manualBankInfo, desiredCredit, expectedSpend, selectedBankInfo } = financeInfo

  const annualRevenue = creditApp?.businessInfo?.annualRevenue

  const hasOutsourcedAccountant = ['firstName', 'lastName', 'email'].some(
    (key) => !!creditApp?.outsourcedAccountant?.[key],
  )
    ? 'yes'
    : 'no'

  const { email, firstName, id, lastName } = creditApp?.outsourcedAccountant ?? {
    email: '',
    firstName: '',
    id: '',
    lastName: '',
  }

  return {
    annualRevenue:
      annualRevenue && Number(annualRevenue) ? currencyFormatter.format(Math.floor(annualRevenue / 100)) : '',
    billingCycle: creditApp?.billingCycle,
    desiredCredit:
      desiredCredit && Number(desiredCredit) ? currencyFormatter.format(Math.floor(desiredCredit / 100)) : '',
    expectedSpend:
      expectedSpend && Number(expectedSpend) ? currencyFormatter.format(Math.floor(expectedSpend / 100)) : '',
    hasAttemptedBankLink: financeInfo?.hasAttemptedBankLink ?? '',
    hasOutsourcedAccountant,
    oaEmail: email,
    oaFirstName: firstName,
    oaId: id,
    oaLastName: lastName,
    selectedBankInfo: bankInfoWithMaskedNumber(selectedBankInfo),
    selectedExternalId: getSelectedExternalId(selectedBankInfo),
    ...bankInfoWithMaskedNumber(manualBankInfo),
  }
}

const authorizedSignerInitialValues = (creditApp = {}) => {
  const authorizedSigner = formatPersonForInput(creditApp?.authorizedSigner ?? {})
  return {
    ...authorizedSigner,
    authorizedSignerId: authorizedSigner?.id,
    recordType: creditApp?.recordType,
  }
}

const companyOwnersInitialValues = (creditApp = {}) => {
  const companyOwners = formatCompanyOwnersForInput(creditApp) ?? []
  const companyOwnersFormElements = flattenCompanyOwners(companyOwners)
  const recordType = creditApp?.recordType
  const status = creditApp?.status
  const sectionsToCorrect = creditApp?.sectionsToCorrect
  const hasCompanyOwnerRadio = creditApp?.hasBeneficialOwner || creditApp?.beneficialOwners?.length > 0
  return {
    beneficialOwners: companyOwners?.length > 0 ? companyOwners : [],
    isAuthorizedSignerAnOwner: companyOwners?.some((owner) => owner?.isAuthorizedSigner),
    majorityHolders: hasCompanyOwnerRadio ? 'yes' : 'no',
    ...companyOwnersFormElements,
    owner0IsValid: false,
    owner1IsValid: false,
    owner2IsValid: false,
    owner3IsValid: false,
    recordType,
    sectionsToCorrect,
    status,
  }
}

export const formatCompanyOwnersForInput = (creditApp) => {
  const beneficialOwners = creditApp?.beneficialOwners ?? []
  const authorizedSigner = creditApp?.authorizedSigner ?? {}

  return beneficialOwners?.reduce((final, owner) => {
    final.push({
      ...formatPersonForInput(owner),
      isAuthorizedSigner: matchEmail(owner?.email, authorizedSigner?.email),
    })
    return final
  }, [])
}

const flattenCompanyOwners = (owners) => {
  const allOwners = [{}, {}, {}, {}]
  const allOwnersFilled = allOwners?.map((currentObj, index) =>
    owners[index] === undefined ? currentObj : owners[index],
  )

  return allOwnersFilled?.reduce((accumulator, current, index) => {
    if (Reflect.ownKeys(current).length) {
      return { ...accumulator, ...generateOwnerObj(index, current, false) }
    }
    return { ...accumulator, ...generateOwnerObj(index, getEmptyCompanyOwner(), true) }
  }, {})
}

const generateOwnerObj = (index, obj, isEmpty) => {
  const generatedObj = Object.keys(obj)?.reduce((accumulator, key) => {
    const indexedOwnerKey = `owner${index}_${key}`
    return { ...accumulator, [indexedOwnerKey]: obj[key] }
  }, {})

  return { ...generatedObj, [`owner${index}_isCreated`]: !isEmpty }
}

export const setPersonTitle = (titleValue) => {
  if (titleValue) {
    const titleizedPersonTitleValue = titleValue?.toLowerCase()?.replace(/\b\w/g, (char) => char?.toUpperCase())

    if (titleOptions.includes(titleizedPersonTitleValue)) {
      return titleizedPersonTitleValue
    }
  }

  return ''
}

const formatPersonForInput = (person) => {
  const passportNumberMaskedString =
    person?.passportNumberLength >= 4 ? '*'.repeat(person?.passportNumberLength - 4) : ''
  const maskedPassportNumber = person?.passportNumberLastFour
    ? `${passportNumberMaskedString}${person?.passportNumberLastFour}`
    : ''
  const passportExpiration = getFormattedDate(stringDateToEpoch(person?.passportExpiration)) || null

  const passportToggle =
    !!person?.passportNumberLastFour && !!person?.passportCountry && !!passportExpiration && !person?.ssnLastFour

  return {
    _passportToggle: passportToggle,
    addressCity: person?.address?.city ?? '',
    addressCountryCode: person?.address?.countryCode ?? 'US',
    addressPostalCode: person?.address?.postalCode ?? '',
    addressState: person?.address?.state ?? '',
    addressStreet: person?.address?.street ?? '',
    addressStreetAdditional: person?.address?.streetAdditional ?? '',
    dob: '',
    dobDisplay: person?.birthYear ? `**/**/${person?.birthYear}` : '',
    email: person?.email ?? '',
    firstName: person?.firstName ?? '',
    id: person?.id,
    lastName: person?.lastName ?? '',
    passportCountry: person?.passportCountry ?? '',
    passportExpiration,
    passportNumber: '',
    passportNumberDisplay: maskedPassportNumber,
    phoneNumber: person?.phoneNumber ?? '',
    preferredFullName: person?.preferredFullName ?? null,
    ssn: '',
    ssnDisplay: person?.ssnLastFour ? `***-**-${person?.ssnLastFour}` : '',
    title: setPersonTitle(person?.title),
  }
}

import React from 'react'
import { css } from '@emotion/core'
import { string } from 'prop-types'

// `items` is a string using typical Markdown bulleted list style like this:
// * thing 1
// * thing 2
// * thing 3
//
// This component formats that string into a proper ul > li html structure.
// Additional, if anything in the string is surrounded by `|` like `foo |bar| baz`,
// that portion will be bold.

const FormattedList = ({ items }) => {
  return (
    <ul>
      {items
        .split('* ')
        .splice(1)
        .map((item) => (
          <li
            key={item}
            css={styles}
            data-testid='formatted-list-li'
          >
            {item.split('|').reduce((output, current, index) => {
              if (index % 2 === 1) {
                return (
                  <>
                    {output}
                    <b data-testid='formatted-list-li-b'>{current}</b>
                  </>
                )
              } else {
                return (
                  <>
                    {output}
                    {current}
                  </>
                )
              }
            }, '')}
          </li>
        ))}
    </ul>
  )
}

const styles = css`
  margin-left: var(--tri-space-300);
`

FormattedList.propTypes = {
  items: string,
}

export default FormattedList

export default {
  confirmPassword: {
    equality: 'password',
    length: {
      message: '10 or more characters',
      minimum: 10,
    },
  },
  password: {
    length: {
      message: '10 or more characters',
      minimum: 10,
    },
  },
}

import { curry } from '@divvy-web/utils'
import { css } from '@emotion/core'

export const formStyles = curry(
  ({ disabled, isReview, readOnly }, { mq, type }) => css`
    ${readOnly ? readOnlyFormStyles({ isReview, mq, type }) : formLayoutCss({ isReview, mq })}
    ${disabled &&
    `
      color: var(--tri-color-text-tertiary);

      [class$="body"] {
        color: var(--tri-color-text-tertiary);
      }
    `}
  `,
)

const readOnlyFormStyles = ({ isReview, mq, type }) => css`
  [class$='-form-field-wrapper'] {
    display: flex;
    gap: var(--tri-space-200);
    width: 100%;
    flex-direction: column;
    ${mq.xSmallMaxWidth({ gap: 'var(--tri-space-350)' })}
  }

  .company-owners-read-only.owner-form-field-wrapper {
    display: flex;
    gap: var(--tri-space-200);
    width: 100%;
    flex-direction: column;
    ${mq.xSmallMaxWidth({ gap: 'var(--tri-space-350)' })}
  }

  .field-row,
  .field-row-entity,
  .field-row.company-owners-read-only {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 992px;
    gap: var(--tri-space-400);
    ${mq.xSmallMaxWidth({ flexDirection: 'column', gap: 'var(--tri-space-350)' })}
  }

  .field-container-full,
  .field-container-full.company-owners-read-only {
    min-width: 200px;
    width: calc(66% - 192px);
  }

  .field-container,
  .field-container.company-owners-read-only {
    min-width: 350px;
    width: calc(33% - 128px);
    ${type.triFontDesktopCurrencyBodyMedium}
    ${mq.xSmallMaxWidth({ minWidth: '320px', width: '100%' })}
  }

  .FormField .TextInput-input-wrapper-read-only {
    box-shadow: none;
  }

  .field-container div.FormField,
  .field-container-full div.FormField {
    ${mq.xSmallMaxWidth({ height: 'var(--tri-space-500)' })}
  }

  [class$='downshiftContainerCss'] > div:nth-of-type(1) {
    height: ${isReview && 'var(--tri-space-300)'};
  }
`

const formLayoutCss = ({ isReview, mq }) => css`
  [class$='-form-field-wrapper'] {
    display: flex;
    flex-direction: column;
    gap: ${isReview ? 'var(--tri-space-300)' : 'var(--tri-space-550)'};
    margin-right: var(--tri-space-1500);
    ${mq.xSmallMaxWidth({ gap: 'var(--tri-space-200)', margin: 0 })}
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: var(--tri-space-550);
    ${mq.xSmallMaxWidth({ gap: 'var(--tri-space-200)', margin: 0 })}
  }

  .field-row,
  .field-row-entity {
    display: flex;
    max-width: ${isReview ? '992px' : '1184px'};
    gap: var(--tri-space-800);
    ${mq.xSmallMaxWidth({ flexDirection: 'column', gap: 'var(--tri-space-200)' })}
  }

  .field-container {
    display: flex;
    flex-direction: column;
    gap: var(--tri-space-200);
    max-width: ${isReview ? '496px' : '992px'};
    width: calc(50% - 32px);
    ${mq.xSmallMaxWidth({ maxWidth: '100%', width: '100%' })}
  }

  .field-container-full {
    max-width: ${isReview ? '992px' : '1184px'};
    position: relative;
    width: 100%;
  }
`

import Banner, { BANNER_TYPE_ERROR } from '@divvy-web/skylab.banner'
import { css } from '@emotion/core'
import { bool, string } from 'prop-types'
import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'

const IncompleteInfoBanner = ({ buttonOption, isReviewAndSignPage, isSaving }) => {
  return (
    <section
      className='form-oops-banner'
      css={bannerStyles({ isReviewAndSignPage })}
      data-testid='responsive-invalid-input-banner'
    >
      <Banner
        bannerType={BANNER_TYPE_ERROR}
        className='banner-header'
        messageContent={
          isSaving ? (
            <FormattedMessage
              defaultMessage='Please make sure to fill out the form fields correctly before saving. '
              id='sputnik.IncompleteInfoBanner__yuFrBe'
            />
          ) : (
            <FormattedMessage
              defaultMessage='Please make sure to fill out every form field before clicking {buttonOption}. '
              id='sputnik.IncompleteInfoBanner__vB9NtR'
              values={{ buttonOption }}
            />
          )
        }
        titleText='Oops!'
      />
    </section>
  )
}

IncompleteInfoBanner.propTypes = {
  buttonOption: string,
  isReviewAndSignPage: bool,
  isSaving: bool,
}
IncompleteInfoBanner.defaultProps = {
  buttonOption: 'next',
  isReviewAndSignPage: false,
  isSaving: false,
}

const bannerStyles = ({ isReviewAndSignPage }) => css`
  margin-bottom: ${isReviewAndSignPage ? 'var(--tri-space-1500)' : 'var(--tri-space-800)'};
  ${isReviewAndSignPage && 'margin-top: calc(var(--tri-space-900) * -1);'}

  .Banner {
    padding: 0;
  }

  .banner-header {
    position: relative;
  }
`

export default IncompleteInfoBanner

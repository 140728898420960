import { useMemo, useCallback } from 'react'
import { getAuthIdFromStorage } from '../auth'
import useLocalStorage from './useLocalStorage'

const sections = ['AS', 'BUSINESS', 'FINANCIAL', 'BO']

const getRemainingCorrections = (sectionsToCorrect, completedCorrections) => {
  const requestedCorrections = [...new Set((sectionsToCorrect || []).map((r) => r.split(/(_|\d)/)[0]))]
  return requestedCorrections.filter((c) => !completedCorrections.includes(c))
}

const useRemainingCorrections = (creditAppId, sectionsToCorrect = [], requestingCorrection = true) => {
  // TODO: this hook is being call for every key stroke on the page, is that something we want?
  const authId = useMemo(() => getAuthIdFromStorage(), [])
  const storageKey = useMemo(() => `completedCorrections-${creditAppId}-${authId}`, [authId, creditAppId])

  const [completedCorrections, setCompletedCorrections] = useLocalStorage(storageKey, [], true)

  const remainingCorrections = useMemo(
    () => getRemainingCorrections(sectionsToCorrect, completedCorrections),
    [completedCorrections, sectionsToCorrect],
  )

  const removeFromRemainingCorrections = useCallback(
    (correction) => {
      const localStorageValue = window.localStorage.getItem(storageKey)
      if (
        localStorageValue &&
        !localStorageValue.includes(correction) &&
        sections.some((section) => localStorageValue.includes(section))
      ) {
        setCompletedCorrections([...sections.filter((section) => localStorageValue.includes(section)), correction])
      } else {
        setCompletedCorrections([...completedCorrections, correction])
      }
    },
    [completedCorrections, setCompletedCorrections, storageKey],
  )

  if (!requestingCorrection) return { completedCorrections: [], remainingCorrections: [] }

  return { completedCorrections, remainingCorrections, removeFromRemainingCorrections }
}

export default useRemainingCorrections

import { css } from '@emotion/core'

export const AcceptOfferCss = ({ mq, type }) => css`
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
  ${mq.xxSmallMaxWidth({
    flexDirection: 'column',
    overflow: 'scroll',
  })}

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: var(--tri-space-2700);
    ${mq.xSmallMinWidth({ display: 'none' })};
  }

  .AcceptOffer {
    &-main-content-section {
      display: flex;
      flex: 2;
      align-items: center;
      justify-content: center;
      padding: 0 var(--tri-space-500) 0 var(--tri-space-1700);
      ${mq.xxSmallMaxWidth({
        alignItems: 'center',
        justifyContent: 'center',
        padding: 'var(--tri-space-400) 0 0',
        textAlign: 'center',
      })}
    }

    &-text-and-mobile-image {
      ${mq.xxSmallMaxWidth({
        padding: '0 var(--tri-space-650) var(--tri-space-1100)',
      })}
    }

    &-title {
      ${type.triFontDesktopTitleLargeEmphasis};
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopTitleSmallEmphasis,
      })}
    }

    &-body {
      width: min(75%, 645px);
      margin-bottom: var(--tri-space-600);
      ${type.triFontDesktopBodyLarge};
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopBodyMedium,
      })}
      ${mq.xxSmallMaxWidth({
        width: '100%',
      })}
    }
  }

  .sidebar-image {
    position: absolute;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    ${mq.xSmallMinWidth({
      bottom: '-52px',
      maxWidth: '450px',
      right: '-100px',
    })}
    ${mq.mediumMinWidth({
      bottom: '-58px',
      maxWidth: '513px',
      right: '-110px',
    })}
  }

  .BasicButton {
    ${mq.xxSmallMaxWidth({
      borderRadius: '0',
      bottom: '0',
      height: '88px',
      left: '0',
      position: 'fixed',
      width: '100%',
    })}
  }
`

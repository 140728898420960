import React, { Component } from 'react'
import { node } from 'prop-types'
import ErrorPage from '../ErrorPage'
import { logError } from '../../utils/loggerUtils'

class PageErrorBoundary extends Component {
  static propTypes = {
    children: node.isRequired,
  }

  componentDidCatch(error, errorInfo) {
    logError({
      attributes: {
        stackTrace: errorInfo?.componentStack,
      },
      eventName: 'PageErrorBoundary',
      message: error?.toString(),
    })
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  state = { error: null }

  resetErrorState = () => {
    this.setState({ error: null })
  }

  render() {
    const { children } = this.props
    const { error } = this.state

    if (error) {
      return <ErrorPage />
    }

    return children
  }
}

export default PageErrorBoundary

import { useQuery } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import useDidMount from '@divvy-web/hooks.usedidmount'
import { useAuth } from '../../auth'
import { logInfo } from '../../utils/loggerUtils'
import UploaderTileList from '../../components/UploaderTileList/UploaderTileList'
import useExpiration from '../../hooks/useExpiration'
import GetRequiredDocumentsMobileUpload from '../gql/GetRequiredDocumentsMobileUpload.gql'
import { documentUploadPageWrapperCss } from './DocumentUploadStyles'
import MobileDocumentUploadStatus from './MobileDocumentUploadStatus'

// container to the mobile-document-upload page
const UploadContent = () => {
  const [remainingDocs, setRemainingDocs] = useState([])
  const milliSecondsToExpiry = new Date(Date.now() + 15 * 60 * 1000)
  const isExpired = useExpiration(milliSecondsToExpiry)

  const { appIdFromToken } = useAuth()
  const salesforceCreditId = appIdFromToken()

  useDidMount(() =>
    logInfo({
      attributes: {
        action: 'QR Mobile document upload auth',
        creditApplicationId: salesforceCreditId,
        result: 'User authenticated with mobile document upload',
      },
      eventName: 'MobileDeviceQrDocumentUpload',
    }),
  )

  const { data, loading, error } = useQuery(GetRequiredDocumentsMobileUpload, {
    fetchPolicy: 'network-only',
    variables: {
      creditApplicationId: salesforceCreditId,
    },
  })

  const creditApp = data?.creditApplication
  const documents = creditApp?.requiredDocs

  const requiredDocuments = useMemo(() => (Array.isArray(documents) ? documents : []), [documents])
  useEffect(() => {
    requiredDocuments && setRemainingDocs(requiredDocuments.map((document) => document?.documentType))
  }, [requiredDocuments])

  if (remainingDocs.length === 0 && loading === false) {
    return <MobileDocumentUploadStatus page='complete' />
  }

  if (isExpired) {
    return <MobileDocumentUploadStatus page='invalidLink' />
  }

  if (error) {
    return <MobileDocumentUploadStatus page='error' />
  }

  return (
    <div
      className='fs-unmask'
      css={documentUploadPageWrapperCss}
      data-testid='mobile-document-upload-container'
    >
      <div className='page-area'>
        <div className='main-header'>
          <div className='main-header-text'>
            <div className='main-title'>
              <FormattedMessage
                defaultMessage='Let’s get you verified!'
                id='sputnik.UploadContent__xQCTP/'
              />
            </div>
            <div className='main-caption'>
              <FormattedMessage
                defaultMessage='Please make sure your document is clean, clear, and readable.  Select the document you would like to upload.'
                id='sputnik.UploadContent__AgypUS'
              />
            </div>
          </div>
        </div>
        <div className='page-content'>
          <UploaderTileList
            creditApp={creditApp}
            remainingDocs={remainingDocs}
            requiredDocuments={requiredDocuments}
            setRemainingDocs={setRemainingDocs}
          />
        </div>
      </div>
    </div>
  )
}

export default UploadContent

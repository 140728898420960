import axios from 'axios'
import { useEffect } from 'react'
import { getTankUrl } from '../utils/billUtils'

export const POLL_TIME = 5 * 1000 * 60

export const useKeepNeoSessionAlive = (bdcOrgId) => {
  const pingNeoSession = async (bdcOrgId) => {
    const tankUrl = getTankUrl(bdcOrgId)
    if (!tankUrl) return false
    try {
      const resp = await axios.post(tankUrl, '{"query":"query Query { currentTime }","operationName":"Query"}', {
        headers: {
          'content-type': 'application/json',
        },
        withCredentials: true,
      })
      // BDC_1109 is returned when the session is invalid
      return !resp?.data?.errors?.some((err) => err?.extensions?.code === 'BDC_1109')
    } catch (err) {
      return false
    }
  }

  useEffect(() => {
    if (!bdcOrgId) return
    let id
    pingNeoSession(bdcOrgId).then((hasNeoSession) => {
      if (hasNeoSession) {
        id = setInterval(() => pingNeoSession(bdcOrgId), POLL_TIME)
      }
    })
    return () => {
      clearInterval(id)
    }
  }, [bdcOrgId])
}

import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import Modal from '@divvy-web/skylab.modal'
import { object, func } from 'prop-types'

const ExternalBankDeleteModal = ({ bankInfo, onCancel, onConfirm }) => {
  const { bankName, externalConnectionGuid } = bankInfo

  const modalIconPath = 'https://app.divvy.co/assets/illustrations/coins-in-hand'
  const mainModalIconSrc = `${modalIconPath}@1x.png`
  const modalIconSrcSet = `${modalIconPath}@3x.png, ${modalIconPath}@2x.png, ${modalIconPath}@1x.png`
  const headerImage = (
    <img
      alt='post-it'
      src={mainModalIconSrc}
      srcSet={modalIconSrcSet}
    />
  )

  const modalActionButtons = [
    <BasicButton
      key='cancel'
      color='neutral'
      dataTestId='finance-info-form-unavailable-info-modal'
      type={BASIC_BUTTON_TYPE_FLAT}
      onClick={(_) => onCancel({})}
    >
      <FormattedMessage
        defaultMessage='Cancel'
        id='sputnik.ExternalBankDeleteModal__47FYwb'
      />
    </BasicButton>,
    <BasicButton
      key='confirm'
      dataTestId='finance-info-form-unavailable-info-modal'
      onClick={(_) => onConfirm(externalConnectionGuid)}
    >
      <FormattedMessage
        defaultMessage='Confirm'
        id='sputnik.ExternalBankDeleteModal__N2IrpM'
      />
    </BasicButton>,
  ]

  return (
    <Modal
      actions={modalActionButtons}
      dataTestId='finance-info-form-unavailable-info-modal'
      headerColor='accent'
      headerImage={headerImage}
      isShowing={Boolean(externalConnectionGuid)}
      title={
        <FormattedMessage
          defaultMessage='Delete bank account'
          id='sputnik.ExternalBankDeleteModal__BQTTYi'
        />
      }
    >
      <p>
        <FormattedMessage
          defaultMessage='Please confirm you want to disconnect from {bankName}.'
          id='sputnik.ExternalBankDeleteModal__Ge0kVE'
          values={{
            bankName,
          }}
        />
      </p>
    </Modal>
  )
}

ExternalBankDeleteModal.propTypes = {
  bankInfo: object,
  onCancel: func,
  onConfirm: func,
}

export default ExternalBankDeleteModal

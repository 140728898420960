const invalidTaxIdValueType = {
  type: {
    message: 'Please enter a valid Tax ID/EIN #',
    type: () => false,
  },
}

const einContainsNineConsecutiveNumbers = (taxId) => {
  const taxIdWithNoDashes = taxId?.replace('-', '')
  return /^(123456789|012345678|987654321).*$/.test(taxIdWithNoDashes)
}

const containIdenticalNumbers = (taxId) => {
  let taxIdWithNoDashes = taxId?.replace('-', '')
  return /^(0{9}|1{9}|2{9}|4{9}|5{9}|6{9}|7{9}|8{9}|9{9}).*$/.test(taxIdWithNoDashes)
}

export const validateTaxId = (taxId, taxIdDisplay) => {
  if (!taxId && !taxIdDisplay) return invalidTaxIdValueType
  const validLength = 9
  if (taxId && taxId?.replace(/\D/g, '')?.length < validLength) return invalidTaxIdValueType
  if (taxId && containIdenticalNumbers(taxId)) return invalidTaxIdValueType
  if (taxId && einContainsNineConsecutiveNumbers(taxId)) return invalidTaxIdValueType
  return null
}

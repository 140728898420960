import { css } from '@emotion/core'

export const popsheetStyles = ({ mq, type }) => css`
  ${mq.xSmallMinWidth({ display: 'none' })}
  .PopSheetControl-base {
    .CreatePasswordPopsheet {
      .PopSheet-header-title {
        justify-content: center;
        display: flex;
        img {
          height: 170px;
        }
      }

      .PopSheet-header-subtitle {
        justify-content: center;
        color: var(--tri-color-icon-primary);
        ${type.triFontDesktopTitleMediumEmphasis}
      }

      .CreatePasswordPopsheet-content {
        .CreatePasswordPopsheet-back-button {
          position: absolute;
          top: 24px;
        }

        .password-modal-subtitle {
          margin-bottom: var(--tri-space-300);
        }

        .CreatePasswordContentMobile .password-wrapper {
          display: flex;
          flex-direction: column;
          gap: var(--tri-space-500);
        }
      }

      .PopSheet-footer {
        .BasicButton {
          display: flex;
          border-radius: 0;
          height: 88px;
          position: fixed;
          width: 100%;
          left: 0;
          bottom: 0;
          margin-top: var(--tri-space-100);
        }
      }
    }
  }
`

export const modalStyles = ({ mq, type }) => css`
  ${mq.xxSmallMaxWidth({ display: 'none' })}
  .Modal-container .Modal-main {
    min-width: 650px;
    .Modal-header {
      padding: var(--tri-space-400) var(--tri-space-600) var(--tri-space-400) var(--tri-space-1800);
    }
    .Modal-header-image {
      top: 0;
    }
    .Modal-header-actions {
      margin-left: var(--tri-space-400);
    }
    .Modal-header-title {
      ${type.triFontDesktopTitleMediumEmphasis}
    }

    .Modal-content .CreatePasswordModal {
      .password-modal-subtitle {
        margin-bottom: var(--tri-space-300);
      }

      .CreatePasswordContent {
        .username-field-row {
          margin-bottom: var(--tri-space-400);
        }

        .password-wrapper {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
      }
    }
  }
`

import { useMutation } from '@apollo/client'
import useDidMount from '@divvy-web/hooks.usedidmount'
import { TOAST_TYPE_DANGER, useToast } from '@divvy-web/skylab.toast'
import { array, func, object, string } from 'prop-types'
import React, { useEffect } from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { logError, logInfo } from '../../utils/loggerUtils'
import GenerateRequestedDocUploadUrl from '../UploaderTileList/GenerateRequestedDocUploadUrl.gql'
import { uploadToS3 } from '../UploaderTileList/s3Uploader'

const allowedFileTypes = ['image/jpeg', 'application/pdf']

const MobileSourceUploadSelector = ({ appId, imageBtnRef, remainingDocs, selectedDocument, setIsUploading }) => {
  const showDangerToast = useToast(TOAST_TYPE_DANGER)
  const { selectedDocumentType: documentType, selectedDocumentUuid: documentUuid } = selectedDocument

  useDidMount(() =>
    logInfo({
      attributes: {
        action: 'Initiate document upload',
        creditApplicationId: appId,
        result: 'User initiated a document upload on the current machine',
      },
      eventName: 'SameDeviceDocumentUpload',
    }),
  )

  useEffect(() => {
    if (!remainingDocs.includes(selectedDocument?.selectedDocumentType)) {
      setIsUploading(false)
    }
  }, [remainingDocs, selectedDocument, setIsUploading])

  const handleError = (action) => (e) => {
    logError({
      attributes: {
        action,
        result: e,
      },
      eventName: 'MobileUploadDocument',
    })
    showDangerToast(
      <FormattedMessage
        defaultMessage='There was an error uploading this document'
        id='sputnik.MobileSourceUploadSelector__7NF4bS'
      />,
      { autoHideDelay: 5000 },
    )

    setIsUploading(false)
  }

  const [getSignedUrl, { loading }] = useMutation(GenerateRequestedDocUploadUrl, {
    onError: handleError('GenerateRequestedDocUploadUrl'),
  })

  const handleFileSelection = async (event) => {
    const selectedFile = event?.target?.files?.[0]

    if (selectedFile) {
      if (!allowedFileTypes.includes(selectedFile?.type)) {
        showDangerToast(
          <FormattedMessage
            defaultMessage='The specified file "{fileName}" could not be uploaded. Files with the following extensions are allowed JPG, JPEG, PDF.'
            id='sputnik.MobileSourceUploadSelector__6bRMbb'
            values={{ fileName: selectedFile?.name }}
          />,
          { autoHideDelay: 7000 },
        )
        return
      }
      setIsUploading(true)

      await getSignedUrl({
        variables: {
          documentUuid,
          fileName: selectedFile.name,
        },
      })
        .then(({ data }) => {
          const uploadUrl = data?.docUploadInfo?.url

          if (uploadUrl) {
            return uploadToS3(selectedFile, uploadUrl)
          }

          throw new Error('No Upload URL returned from the server')
        })
        .then((_) => {
          logInfo({
            attributes: {
              action: 'UploadToS3',
              result: `Document uploaded to S3: ${documentType}`,
            },
            eventName: 'MobileUploadDocument',
          })
        })
        .catch(handleError('UploadToS3'))
    }
  }

  return (
    <input
      ref={imageBtnRef}
      accept={allowedFileTypes}
      disabled={loading}
      style={{ display: 'none' }}
      type='file'
      onChange={(e) => {
        handleFileSelection(e)
        imageBtnRef.current.value = null
      }}
    />
  )
}

MobileSourceUploadSelector.propTypes = {
  appId: string,
  imageBtnRef: object,
  remainingDocs: array,
  selectedDocument: object,
  setIsUploading: func,
}

export default MobileSourceUploadSelector

import React from 'react'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { useLocation, useNavigate } from 'react-router-dom'
import { css } from '@emotion/core'
import BasicButton from '@divvy-web/skylab.basicbutton'
import TextLink, { TextLinkSizes } from '@divvy-web/skylab.textlink'
import { FormattedMessage } from '@divvy-web/i18n'
import { useCanary } from '@bill/canary.react'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import PageViewWrapper from '../../components/PageViewWrapper/PageViewWrapper'
import MiscPageWrapper from '../../components/MiscPageWrapper/MiscPageWrapper'
import { PAGE_NAME_STRINGS, PATHNAME_CONSTS, PRODUCT_CONST_STRINGS, URL } from '../../resources/constants'
import ImageWrapper from '../../components/ImageWrapper/ImageWrapper'
import { logInfo } from '../../utils/loggerUtils'

const NotGoodFit = () => {
  // TODO: remove useLocation and useNavigate when removeing show-web-bank canary
  const { search } = useLocation()
  const navigate = useNavigate()
  const [makeTestId, getClassName] = useNameFormatter('NotGoodFit')
  const showWebBank = useCanary('show-web-bank')
  const { PAGE_NOT_GOOD_FIT } = PAGE_NAME_STRINGS
  const { BILL_SPEND_AND_EXPENSE } = PRODUCT_CONST_STRINGS
  const { ELIGIBILITY_REQUIREMENTS_LINK } = URL
  const { isMobile } = useDeviceDetect()

  const navigateToEligibilityRequirements = () => {
    logInfo({
      attributes: {
        action: 'Learn about eligibility click',
        result: `Sending user to path: ${ELIGIBILITY_REQUIREMENTS_LINK}`,
      },
      eventName: 'navigateToEligibilityRequirements',
    })
    window.open(ELIGIBILITY_REQUIREMENTS_LINK, '_blank', 'noopener,noreferrer')
  }

  return (
    <div
      className='fs-unmask'
      css={isMobile && mobileStyles}
      data-testid={makeTestId('')}
    >
      {isMobile && (
        <ImageWrapper
          alt='lady-at-desk'
          dataTestId={makeTestId('lady-at-desk')}
          imageName='office_desk_10'
        />
      )}
      <PageViewWrapper pageName={PAGE_NOT_GOOD_FIT}>
        <MiscPageWrapper
          headingStyles={headingStyles}
          pageName={PAGE_NOT_GOOD_FIT}
          pageSubTitle={
            <FormattedMessage
              defaultMessage='Based on your responses, we likely cannot offer a credit line to you at this time. We hope you return and re-apply in the future.'
              id='sputnik.NotGoodFit__OO3PFn'
            />
          }
          pageTitle={
            <FormattedMessage
              defaultMessage='{BILL_SPEND_AND_EXPENSE} may not be a good fit for you right now.'
              id='sputnik.NotGoodFit__n5V0Tj'
              values={{
                BILL_SPEND_AND_EXPENSE,
              }}
            />
          }
        >
          {!showWebBank && (
            <TextLink
              className={getClassName('signup-link')}
              css={divvyLinkstyles}
              dataTestId={makeTestId('signup-link')}
              size={TextLinkSizes.SMALL}
              onClick={() =>
                navigate(
                  { pathname: PATHNAME_CONSTS.SIGN_UP_PATH, search },
                  { state: { referrer: PATHNAME_CONSTS.VERIFY_CODE_PATH } },
                )
              }
            >
              <FormattedMessage
                defaultMessage='Apply anyway'
                id='sputnik.NotGoodFit__F6+ppL'
              />
            </TextLink>
          )}
          <BasicButton
            className={getClassName('learn-divvy-btn')}
            css={buttonStyles}
            dataTestId={makeTestId('learn-about-eligibility')}
            onClick={() => navigateToEligibilityRequirements()}
          >
            <FormattedMessage
              defaultMessage='Learn about eligibility'
              id='sputnik.NotGoodFit__W4z/VU'
            />
          </BasicButton>
        </MiscPageWrapper>
      </PageViewWrapper>
    </div>
  )
}

const mobileStyles = ({ type }) => css`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .program-image {
    width: 260px;
    margin-top: var(--tri-space-600);
    margin-bottom: var(--tri-space-500);
  }

  .notGoodFit-page-heading,
  .heading-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-top: 0;
    padding: var(--tri-space-300) var(--tri-space-300);
  }

  .notGoodFit-page-heading-title {
    ${type.triFontDesktopTitleMediumEmphasis}
  }

  .notGoodFit-page-heading-subtitle {
    ${type.triFontDesktopBodyMedium}
  }

  .page-wrapper-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: var(--tri-space-300);

    .NotGoodFit-signup-link {
      margin-left: 0;
      margin-top: calc(var(--tri-space-200) * -1);
    }

    .NotGoodFit-learn-divvy-btn {
      border-radius: 0;
      bottom: 0;
      height: 88px;
      left: 0;
      margin-left: 0;
      position: fixed;
      width: 100vw;
    }
  }
`

const headingStyles = ({ type }) => css`
  margin-top: 34vh;
  margin-left: var(--tri-space-2400);
  max-width: 720px;

  .notGoodFit-page-heading-title {
    ${type.triFontDesktopTitleLargeEmphasis}
  }

  .notGoodFit-page-heading-subtitle {
    margin-top: var(--tri-space-300);
    max-width: 550px;
    ${type.triFontDesktopTitleSmall}
  }
`
const divvyLinkstyles = css`
  margin-left: var(--tri-space-900);
  margin-top: var(--tri-space-200);
`

const buttonStyles = css`
  margin-left: var(--tri-space-900);
  margin-top: var(--tri-space-500);
`

export default NotGoodFit

import { css } from '@emotion/core'

export const sectionViewCss =
  (isNonOwnerAuthorizedSigner) =>
  ({ type }) =>
    css`
      width: 100%;
      padding-top: var(--tri-space-100);
      padding-bottom: var(--tri-space-100);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ownerTextSection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .ownerHeadingText {
        ${type.triFontDesktopBodyMediumEmphasis}
        ${isNonOwnerAuthorizedSigner && 'color: var(--tri-color-text-tertiary)'}
      }
    `

export const ownerCaptionCss =
  (hasError, isNonOwnerAuthorizedSigner) =>
  ({ type }) =>
    css`
      ${type.triFontDesktopBodySmall}
      color: ${hasError
        ? 'var(--tri-color-text-danger)'
        : isNonOwnerAuthorizedSigner
        ? 'var(--tri-divider-color-border)'
        : 'var(--tri-color-text-secondary)'};
    `

export const ownerButtonCss = (isNonOwnerAuthorizedSigner) => css`
  flex: 1;
  margin-top: var(--tri-space-100);
  padding-top: var(--tri-space-250);
  padding-bottom: var(--tri-space-250);

  ${isNonOwnerAuthorizedSigner ||
  `
    &:hover {
    background-color: var(--tri-color-fill-tertiary);
  }
  `}

  .Avatar {
    width: 100%;
    ${isNonOwnerAuthorizedSigner && 'filter: grayscale(1);'}
  }

  .Avatar > div {
    ${isNonOwnerAuthorizedSigner || 'cursor: pointer;'}
  }
`

import React, { useContext } from 'react'
import { string, func, bool, node } from 'prop-types'
import { FormContext } from '@divvy-web/skylab.form'
import FormField from '@divvy-web/skylab.formfield'
import { useGetClassName } from '@divvy-web/hooks.usenameformatter'
import TextInput from '@divvy-web/skylab.textinput'
import { captionWrapperCss } from '../sharedStyles'

const NoAutoCompleteTextInput = ({
  alwaysShowError,
  caption,
  className,
  dataTestId,
  fieldName,
  label,
  onKeyUp,
  placeholder,
  readOnly,
  warningMessage,
}) => {
  const { getFormValue, getValidationErrors, makeTestId, setFormValue } = useContext(FormContext)
  const getClassName = useGetClassName('NoAutoCompleteTextInput', className)

  const handleOnChange = (e) => {
    setFormValue(fieldName, e.target.value)
  }

  const generatedName = Date.now().toString() + fieldName
  const testId = dataTestId || makeTestId(generatedName)
  let fieldError = getValidationErrors(fieldName)
  const fieldValue = getFormValue(fieldName)

  if (!fieldError && warningMessage) {
    fieldError = warningMessage
  }

  return (
    <FormField
      className={getClassName('')}
      css={captionWrapperCss(!!warningMessage)}
      dataTestId={testId}
      inputId={generatedName}
      label={label}
    >
      <TextInput
        darkened
        alwaysShowError={alwaysShowError}
        autoComplete='none'
        caption={caption}
        dataTestId={testId}
        errorCaption={fieldError}
        hasError={!!fieldError}
        id={generatedName}
        name={generatedName}
        placeholder={placeholder}
        readOnly={readOnly}
        value={fieldValue}
        onChange={handleOnChange}
        onKeyUp={onKeyUp}
      />
    </FormField>
  )
}

NoAutoCompleteTextInput.defaultProps = {
  className: '',
}

NoAutoCompleteTextInput.propTypes = {
  alwaysShowError: bool,
  caption: node,
  className: string,
  dataTestId: string,
  fieldName: string,
  label: node,
  onKeyUp: func,
  placeholder: node,
  readOnly: bool,
  warningMessage: node,
}

export default NoAutoCompleteTextInput

import { FormattedMessage } from '@divvy-web/i18n'
import { func } from 'prop-types'
import React from 'react'
import { onContactUsClick } from '../../../components/ContactUsButton/contactUsUtils'
import { useAuth } from '../../../auth'
import DashboardItem from './DashboardItem'

const DashboardError = ({ refetch }) => {
  const { email } = useAuth()

  const dashboardErrorContent = {
    hasWarning: true,
    onPrimaryClick: onContactUsClick,
    onSecondaryClick: refetch,
    primaryButtonText: 'Contact us',
    secondaryButtonText: 'Try again',
    statusDisplayText: (
      <FormattedMessage
        defaultMessage='Error'
        id='sputnik.DashboardError__KN7zKn'
      />
    ),
    statusImage: 'dashboard-application-error-status@1x.png',
    subText: (
      <FormattedMessage
        defaultMessage='Oops... something went wrong. We weren’t able to find an application under the email {email}. Please
          try again or begin a new application.'
        id='sputnik.DashboardError__GiObtA'
        values={{ email }}
      />
    ),
  }

  return (
    <div data-testid='NoApplicationStatus-status'>
      <DashboardItem itemContent={dashboardErrorContent} />
    </div>
  )
}

DashboardError.propTypes = {
  refetch: func.isRequired,
}

export default DashboardError

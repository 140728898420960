import { css } from '@emotion/core'

export const popsheetCss = ({ type }) => css`
  display: flex;
  flex-direction: column;
  padding-bottom: var(--tri-space-500);

  .program-image {
    max-width: var(--tri-space-2800);
  }

  .Tooltip-label {
    display: flex;
  }

  .agreement-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-left: var(--tri-space-350);
    }
  }

  .agreement-table {
    width: 80%;
    margin-left: var(--tri-space-100);
  }

  .AgreementPopsheet-popsheet-top-footer-button {
    border-radius: 0;
    height: var(--tri-space-600);
    width: 100%;
    border-top: 1px solid var(--neutral-soft);
  }

  .AgreementPopsheet {
    &-popsheet-title-image {
      max-width: var(--tri-space-2800);
    }

    &-header {
      text-align: center;
      padding: 0 var(--tri-space-300);
      margin: unset;
    }

    &-header-actions {
      margin: unset;
    }

    &-header-subtitle {
      ${type.triFontDesktopTitleMediumEmphasis};
      color: var(--tri-color-text-primary);
    }

    &-content {
      padding: var(--tri-space-300) var(--tri-space-200) var(--tri-space-1200) var(--tri-space-300);
    }

    &-popsheet-bottom-footer-button {
      border-radius: 0;
      height: var(--tri-space-600);
      width: 100%;
      border-top: 1px solid var(--neutral-soft);
    }
  }
`

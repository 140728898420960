import { useQuery } from '@apollo/client'
import { useCanary } from '@bill/canary.react'
import useDidMount from '@divvy-web/hooks.usedidmount'
import { FormattedMessage } from '@divvy-web/i18n'
import Spinner from '@divvy-web/skylab.spinner'
import { TOAST_TYPE_BASIC, useToast } from '@divvy-web/skylab.toast'
import { css } from '@emotion/core'
import * as FS from '@fullstory/browser'
import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getMaybeNeedsNewPassword, unsetMaybeNeedsNewPassword, useAuth } from '../../auth'
import { DelayedLoader } from '../../components'
import CreatePasswordContainer from '../../components/CreatePasswordContainer/CreatePasswordContainer'
import NoCreditApplicationsPage from '../../components/NoCreditApplicationsPage'
import PageViewWrapper from '../../components/PageViewWrapper/PageViewWrapper'
import TimeoutWarningModal from '../../components/TimeoutWarningModal'
import { useBillSessionInfo } from '../../hooks/useBillSessionInfo'
import { useKeepNeoSessionAlive } from '../../hooks/useKeepNeoSessionAlive'
import GlobalTopNav from '../../remotes/GlobalTopNav/GlobalTopNav'
import {
  CreditApplicationStatus,
  MAJOR_ACCOUNT_CHANGE,
  PAGE_NAME_STRINGS,
  PATHNAME_CONSTS,
} from '../../resources/constants'
import { getBillApolloClient } from '../../utils/billApolloUtils'
import { matchEmail } from '../../utils/dataUtils'
import {
  getDealSourceIdFromCreditApplications,
  getDealSourceIdFromStorage,
  setDealSourceIdInStorage,
} from '../../utils/dealSourceUtils'
import { logInfo } from '../../utils/loggerUtils'
import { isAccessToken } from '../../auth/jwt/jwt'
import InviteCollaborators from './InviteCollaborators'
import DashboardError from './Status/DashboardError'
import { GetApplicationStatuses } from './gql/GetApplicationStatuses.gql'

const DashboardApplication = lazy(() => import('./Status/DashboardApplication'))

const getApplicationIds = (applications) => {
  return applications.reduce((appIdAcc, app) => {
    appIdAcc.push(app?.salesforceCreditId)
    return appIdAcc
  }, [])
}

const getPotentialUserEmails = (applications) => {
  return applications?.reduce((appEmailAcc, { admin, authorizedSigner, outsourcedAccountant }) => {
    admin?.email && appEmailAcc.push(admin?.email?.toLowerCase())

    authorizedSigner?.email &&
      !appEmailAcc?.includes(authorizedSigner?.email) &&
      appEmailAcc.push(authorizedSigner?.email?.toLowerCase())

    outsourcedAccountant?.email &&
      !appEmailAcc?.includes(outsourcedAccountant?.email) &&
      appEmailAcc.push(outsourcedAccountant?.email?.toLowerCase())

    return appEmailAcc
  }, [])
}

const Dashboard = () => {
  const { appIdFromToken, getTokenFromStorage, requestApplicantToken } = useAuth()
  const showNewAuth = useCanary('useNewAuth')
  const showToast = useToast()
  const { email } = useAuth()
  const navigate = useNavigate()
  const { search, state } = useLocation()
  const [showCreatePasswordModal, setShowCreatePasswordModal] = useState('loading')
  const [showedLoginToast, setShowedLoginToast] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [selectedAppId, setSelectedAppId] = useState('')
  const [bdcOrgId, setBdcOrgId] = useState('')
  const [billApolloClient, setBillApolloClient] = useState(null)
  const [shouldTopNavBeVisible, setShouldTopNavBeVisible] = useState(false)
  const [currentCompanyName, setCurrentCompanyName] = useState('')

  useDidMount(() => {
    window.sessionStorage.removeItem('submissionPendingUrl')
  })

  const toggleDrawer = async (selectedAppId) => {
    if (selectedAppId) {
      setSelectedAppId(selectedAppId)
      if (appIdFromToken() !== selectedAppId) {
        await requestApplicantToken(selectedAppId)
      }

      setIsShowing(true)
    }
  }

  const { data, error, loading, refetch } = useQuery(GetApplicationStatuses, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const applications = useMemo(
    () =>
      [].concat(data?.creditApplications ?? []).sort((app1, app2) => {
        if (app1.statusUpdatedAt && !app2.statusUpdatedAt) {
          return -1
        }
        if (!app1.statusUpdatedAt && app2.statusUpdatedAt) {
          return 1
        }
        if (app1.statusUpdatedAt > app2.statusUpdatedAt) {
          return -1
        }
        if (app1.statusUpdatedAt < app2.statusUpdatedAt) {
          return 1
        }

        return 0
      }),
    [data?.creditApplications],
  )

  const referrerQueryParams = new URLSearchParams(state?.referrerSearch || '')
  const redirectToParam = referrerQueryParams.get('redirect_to')?.toLowerCase()
  const creditIdParam = referrerQueryParams.get('credit_id')?.toLowerCase()
  const bdcOrgIdParam = referrerQueryParams.get('bdc_org_id')?.toLowerCase()
  const { PAGE_DASHBOARD } = PAGE_NAME_STRINGS
  const shouldStayOnDashboard = redirectToParam === PAGE_DASHBOARD

  const handleCreatePasswordSubmit = () => {
    unsetMaybeNeedsNewPassword()
    setShowCreatePasswordModal(false)
  }

  const loggedInAsPotentialUser = getPotentialUserEmails(applications)?.includes(email?.toLowerCase())
  const applicationCount = applications?.length
  const onlyHasOneCreditApp = applicationCount === 1
  const firstCreditApp = applications?.[0]
  const bdcOrgApplications = applications.filter((application) => {
    return application?.bdcOrgId !== null && application?.bdcOrgId !== undefined
  })
  const bdcOrgCurrentApplications = bdcOrgApplications.filter((application) => {
    return application?.status !== CreditApplicationStatus.REJECTED
  })
  const hasApplications = applicationCount > 0
  const canStartNewApplication =
    bdcOrgApplications.length > 0
      ? bdcOrgCurrentApplications.length === 0
      : !!data?.salesforceContactExists || !hasApplications
  const shouldRedirectToSignup = !shouldStayOnDashboard && !error && !data?.salesforceContactExists && !hasApplications
  const hasOneNewAppForAdmin =
    onlyHasOneCreditApp &&
    (!firstCreditApp?.savedSections?.length || firstCreditApp?.status === CreditApplicationStatus.NOT_STARTED) &&
    matchEmail(email, firstCreditApp?.admin?.email)

  const appIdsArray = useMemo(
    () => (hasApplications ? getApplicationIds(applications) : []),
    [applications, hasApplications],
  )

  useEffect(() => {
    if (applications) {
      const bdcOrgId = applications[0]?.bdcOrgId
      setBdcOrgId(bdcOrgId)
      setBillApolloClient(getBillApolloClient(bdcOrgId))
    }
  }, [applications])

  const { data: sessionInfoData } = useBillSessionInfo(billApolloClient)

  useEffect(() => {
    if (sessionInfoData) {
      setShouldTopNavBeVisible(sessionInfoData?.ossType === 'bdcUp' || sessionInfoData?.isConsole)
      setCurrentCompanyName(sessionInfoData?.organizationName || applications[0]?.businessInfo?.legalBusinessName)
    }
  }, [applications, sessionInfoData])

  useKeepNeoSessionAlive(bdcOrgId)

  useEffect(() => {
    if (!loading && !showedLoginToast) {
      showToast(
        TOAST_TYPE_BASIC,
        <FormattedMessage
          defaultMessage='You are signed in as {email}'
          id='sputnik.Dashboard__RMGhLe'
          values={{ email }}
        />,
        {
          closeMsg: (
            <span css={dismissButtonCss}>
              <FormattedMessage
                defaultMessage='Dismiss'
                id='sputnik.Dashboard__TDaF6J'
              />
            </span>
          ),
          dataTestId: 'signedin-email-toast',
        },
      )

      setShowedLoginToast(true)

      if (FS.isInitialized()) {
        FS.identify(email)
        FS.event('Dashboard', { sf_credit_id_strs: appIdsArray })
      }

      logInfo({
        attributes: {
          message: hasApplications ? `Credit App Id(s): ${appIdsArray.join(', ')}` : '',
          result: `Dashboard page loaded with ${applicationCount} application(s)`,
        },
        eventName: 'PageLoad',
      })
    }
  }, [applicationCount, hasApplications, appIdsArray, email, loading, showToast, showedLoginToast])

  useEffect(() => {
    const shouldShowCreatePasswordModal =
      showNewAuth &&
      !!getMaybeNeedsNewPassword() &&
      loggedInAsPotentialUser &&
      !hasOneNewAppForAdmin &&
      isAccessToken(getTokenFromStorage())

    const shouldNotShowCreatePasswordModal =
      !getMaybeNeedsNewPassword() ||
      !showNewAuth ||
      (hasApplications && (!loggedInAsPotentialUser || hasOneNewAppForAdmin)) ||
      !isAccessToken(getTokenFromStorage())

    if (shouldShowCreatePasswordModal) setShowCreatePasswordModal(true)
    if (shouldNotShowCreatePasswordModal) setShowCreatePasswordModal(false)
  }, [
    showNewAuth,
    applications,
    firstCreditApp,
    showCreatePasswordModal,
    loading,
    hasApplications,
    hasOneNewAppForAdmin,
    loggedInAsPotentialUser,
    getTokenFromStorage,
  ])

  if (loading) return <Spinner fullPage />

  if (shouldRedirectToSignup) {
    navigate({ pathname: PATHNAME_CONSTS.RIGHT_FIT_SURVEY_PATH })
  }

  const dealSourceIdFromQuery = getDealSourceIdFromCreditApplications(applications)
  const dealSourceIdFromStorage = getDealSourceIdFromStorage()

  // set deal source id if it does not match local storage
  const isNewDealSourceId = dealSourceIdFromQuery !== dealSourceIdFromStorage
  if (isNewDealSourceId) {
    setDealSourceIdInStorage(dealSourceIdFromQuery)
  }

  const matchingBdcApp =
    bdcOrgIdParam && applications.find((creditApp) => creditApp.bdcOrgId?.toLowerCase() === bdcOrgIdParam)

  const matchingCreditApp =
    matchingBdcApp ||
    (creditIdParam && applications.find((creditApp) => creditApp.salesforceCreditId?.toLowerCase() === creditIdParam))

  const creditAppToRedirectTo = onlyHasOneCreditApp ? firstCreditApp : matchingCreditApp

  const applicationNotStartedOrInProgress = [
    CreditApplicationStatus.NOT_STARTED,
    CreditApplicationStatus.IN_PROGRESS,
  ].includes(creditAppToRedirectTo?.status)

  const isLggedInAsAwaitedSigner = () => {
    if (onlyHasOneCreditApp) {
      return (
        CreditApplicationStatus.AWAITING_SIGNATURE === firstCreditApp?.status &&
        matchEmail(email, firstCreditApp?.authorizedSigner?.email)
      )
    }
    return undefined
  }

  const shouldRedirectToAppSection =
    !shouldStayOnDashboard &&
    state?.referrer === PATHNAME_CONSTS.AUTH_PATH &&
    creditAppToRedirectTo &&
    (applicationNotStartedOrInProgress || !!isLggedInAsAwaitedSigner()) &&
    !showCreatePasswordModal

  if (shouldRedirectToAppSection) {
    const appId = creditAppToRedirectTo?.salesforceCreditId
    const isMac = creditAppToRedirectTo?.recordType === MAJOR_ACCOUNT_CHANGE
    const path = isMac ? 'review-and-sign' : 'business-info'

    logInfo({
      attributes: {
        isBdcOrg: !!matchingBdcApp,
        result: `Bypassing dashboard to go to the credit application ${appId}`,
      },
      eventName: 'PageLoad',
      message: `Credit App Id: ${appId}`,
    })

    navigate(
      { pathname: `/app/${appId}/section/${path}`, search: search ?? '' },
      {
        state: {
          ...state,
          autoRedirectIndex: path === 'business-info' ? 0 : undefined,
          referrer: 'dashboard',
        },
      },
    )

    return <Spinner fullPage />
  }

  return (
    <>
      {shouldTopNavBeVisible && (
        <GlobalTopNav
          apolloClient={billApolloClient}
          bdcOrgId={bdcOrgId}
          companyName={currentCompanyName}
          isConsole={sessionInfoData?.isConsole || false}
        />
      )}
      <PageViewWrapper
        bdcOrgId={bdcOrgId}
        canStartNewApplication={canStartNewApplication}
        pageName={PAGE_DASHBOARD}
      >
        <Suspense fallback={<DelayedLoader centered />}>
          {showNewAuth && !hasApplications && !error && <NoCreditApplicationsPage />}
          {!showNewAuth && (!hasApplications || error) && <DashboardError refetch={refetch} />}
          {hasApplications && (
            <div
              className='applications-container'
              css={appsContainerCss}
            >
              {applications.map((creditApp) => (
                <DashboardApplication
                  key={creditApp.salesforceCreditId}
                  creditApp={creditApp}
                  refetch={refetch}
                  toggleEmailDrawer={toggleDrawer}
                />
              ))}
            </div>
          )}
          <TimeoutWarningModal pageName={PAGE_DASHBOARD} />
        </Suspense>
        <div>
          <InviteCollaborators
            appId={selectedAppId}
            isShowing={isShowing}
            setIsShowing={setIsShowing}
          />
        </div>
      </PageViewWrapper>
      <CreatePasswordContainer
        isShowing={showCreatePasswordModal === true}
        usernameEmail={email}
        onSubmit={handleCreatePasswordSubmit}
      />
    </>
  )
}

const dismissButtonCss = css`
  color: var(--tri-color-icon-info-inverse);
`

const appsContainerCss = ({ mq }) => css`
  display: flex;
  flex-direction: column;
  gap: var(--tri-space-1200);

  ${mq.mediumMaxWidth({ gap: 'var(--tri-space-500)' })}
`

export default Dashboard

import React from 'react'

const AutoCompleteDisable = () => (
  <input
    readOnly
    autoComplete='on'
    style={style}
    type='text'
    value=''
  />
)

const style = {
  display: 'none',
  left: '-100000px',
  opacity: 0,
  position: 'absolute',
}

export default AutoCompleteDisable

import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { css } from '@emotion/core'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { useCanary } from '@bill/canary.react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormButtons } from '../../components'
import MiscPageWrapper from '../../components/MiscPageWrapper/MiscPageWrapper'
import PageViewWrapper from '../../components/PageViewWrapper/PageViewWrapper'
import { replaceBreakableSpaces } from '../../components/utils'
import useGetBillProduct from '../../hooks/useGetBillProduct'
import { PAGE_NAME_STRINGS, PATHNAME_CONSTS } from '../../resources/constants'
import ImageWrapper from '../../components/ImageWrapper'

const GoodFitPage = () => {
  const { search } = useLocation()
  const navigate = useNavigate()
  const { product } = useGetBillProduct()
  const { PAGE_GOOD_FIT } = PAGE_NAME_STRINGS
  const [getClassName, makeTestId] = useNameFormatter('GoodFitPage')
  const isSecuredLine = useCanary('show-secured-line')
  const isStateBasedDisclosures = useCanary('state-based-disclosures')
  const showSecuredLineGoodFitPage = isSecuredLine || isStateBasedDisclosures

  return (
    <>
      {!showSecuredLineGoodFitPage && (
        <PageViewWrapper
          data-testid={makeTestId('retiring')}
          pageName={PAGE_GOOD_FIT}
        >
          <div
            className='good-fit-page-wrapper fs-unmask'
            css={goodFitWrapperCss}
          >
            <ImageWrapper
              alt='lady-at-desk'
              imageName='laptops_05'
              isSvg={true}
            />
            <MiscPageWrapper
              headingStyles={headingStyles}
              pageName={PAGE_GOOD_FIT}
              pageStyles={goodfitStyles}
              pageSubTitle={
                <FormattedMessage
                  defaultMessage='{product} was built for companies just like yours. We’re so glad you found us. Let’s get started.'
                  id='sputnik.GoodFitPage__vTfqaY'
                  values={{ product }}
                />
              }
              pageTitle={
                <FormattedMessage
                  defaultMessage='Yay, we were made for {text}!'
                  id='sputnik.GoodFitPage__/f6zjq'
                  values={{
                    text: replaceBreakableSpaces('each other'),
                  }}
                />
              }
            >
              <FormButtons
                hideExit
                hidePrevious
                handleNextClick={() =>
                  navigate(
                    {
                      pathname: PATHNAME_CONSTS.SIGN_UP_PATH,
                      search,
                    },
                    { state: { referrer: PATHNAME_CONSTS.VERIFY_CODE_PATH } },
                  )
                }
                nextButtonText={
                  <FormattedMessage
                    defaultMessage='Start Credit Application'
                    id='sputnik.GoodFitPage__EfUOa7'
                  />
                }
              />
            </MiscPageWrapper>
          </div>
        </PageViewWrapper>
      )}
      {showSecuredLineGoodFitPage && (
        <PageViewWrapper pageName={PAGE_GOOD_FIT}>
          <div
            className={getClassName('good-fit-page-wrapper')}
            css={goodFitWrapperCss}
            data-testid={makeTestId('updated')}
          >
            <MiscPageWrapper
              headingStyles={headingStyles}
              pageName={PAGE_GOOD_FIT}
              pageStyles={goodfitStyles}
              pageSubTitle={
                <FormattedMessage
                  defaultMessage='{product} was built for companies just like yours. Next, let’s see which types of credit you qualify for.'
                  id='sputnik.GoodFitPage__uwUKeE'
                  values={{ product }}
                />
              }
              pageTitle={
                <FormattedMessage
                  defaultMessage='Good news—you’re a great fit!!'
                  id='sputnik.GoodFitPage__CtDxev'
                />
              }
            >
              <div
                className={getClassName('info-container')}
                data-testid={makeTestId('info-container')}
              >
                <div className={getClassName('info-title')}>
                  <FormattedMessage
                    defaultMessage='Here’s what you’ll need:'
                    id='sputnik.GoodFitPage__cPnW8P'
                  />
                </div>
                <div className={getClassName('info-list')}>
                  <ul>
                    <li>
                      <FormattedMessage
                        defaultMessage='Authorized signer’s information'
                        id='sputnik.GoodFitPage__QL6GAm'
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        defaultMessage='Company owner’s information (if applicable)'
                        id='sputnik.GoodFitPage__lYoau7'
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        defaultMessage='Business bank account information'
                        id='sputnik.GoodFitPage__54dHhO'
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        defaultMessage='Business tax ID number & formation date'
                        id='sputnik.GoodFitPage__xr7Wkx'
                      />
                    </li>
                  </ul>
                </div>
                <div className={getClassName('info-disclaimer')}>
                  <FormattedMessage
                    defaultMessage='The credit application can take up to 15 minutes to complete.'
                    id='sputnik.GoodFitPage__KlWdyj'
                  />
                </div>
              </div>
              <FormButtons
                hideExit
                hidePrevious
                dataTestId={makeTestId('start-credit-application')}
                handleNextClick={() =>
                  navigate(
                    {
                      pathname: PATHNAME_CONSTS.SIGN_UP_PATH,
                      search,
                    },
                    { state: { referrer: PATHNAME_CONSTS.VERIFY_CODE_PATH } },
                  )
                }
                nextButtonText={
                  <FormattedMessage
                    defaultMessage='Start Credit Application'
                    id='sputnik.GoodFitPage__EfUOa7'
                  />
                }
              />
            </MiscPageWrapper>
          </div>
        </PageViewWrapper>
      )}
    </>
  )
}

const headingStyles = ({ mq, type }) => css`
  margin-left: var(--tri-space-1500);

  ${mq.xLargeMaxWidth({
    marginLeft: 'var(--tri-space-600)',
    marginRight: 'var(--tri-space-600)',
    width: '75%',
  })}
  ${mq.xSmallMaxWidth({
    margin: 0,
    padding: 0,
    width: '100%',
  })};

  .goodFit-page-heading-header {
    ${mq.xSmallMaxWidth({ padding: '0 var(--tri-space-100)' })}
  }

  .goodFit-page-heading-title {
    ${type.triFontDesktopTitleLargeEmphasis};
    margin-bottom: var(--tri-space-200);
    ${mq.xSmallMaxWidth(type.triFontDesktopTitleMediumEmphasis)};
    ${mq.xSmallMaxWidth({
      margin: 0,
      padding: 'var(--tri-space-400) var(--tri-space-200) var(--tri-space-200) var(--tri-space-200)',
      width: '100%',
    })}
  }

  .goodFit-page-heading-subtitle {
    padding-right: var(--tri-space-1900);
    ${type.triFontDesktopTitleSmall};
    ${mq.xSmallMaxWidth(type.triFontDesktopBodySmall)};
    ${mq.xSmallMaxWidth({
      color: 'var(--tri-color-text-primary)',
      marginBottom: 0,
      padding: '0 var(--tri-space-600) var(--tri-space-300) var(--tri-space-200)',
      width: '100%',
    })}
  }
`

const goodFitWrapperCss = ({ mq, type }) => css`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  align-items: center;
  margin-right: calc(var(--tri-space-2000) * -1);

  .program-image {
    ${mq.smallMinWidth({ display: 'none' })};
    width: 180px;
    margin-top: var(--tri-space-600);
    margin-bottom: var(--tri-space-500);
  }

  ${mq.xSmallMaxWidth({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: '0 auto',
  })}

  [class*='FormButtons-container'] {
    background: none;
    ${mq.smallMinWidth({
      paddingLeft: 'var(--tri-space-1500)',
      paddingTop: 'var(--tri-space-400)',
      position: 'static',
    })};
    ${mq.xLargeMaxWidth({ paddingLeft: 'var(--tri-space-600)' })};
    ${mq.xSmallMaxWidth({ padding: '0' })};
    .button-group-container {
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  // styles for new good fit page.
  .GoodFitPage-info-container {
    margin: var(--tri-space-500) 0 var(--tri-space-200) var(--tri-space-1500);
    ${type.triFontDesktopBodyMedium};
    ${mq.xSmallMaxWidth(type.triFontDesktopBodySmall)};
    display: flex;
    flex-direction: column;
    gap: var(--tri-space-200);
    ${mq.xLargeMaxWidth({ margin: 'var(--tri-space-200) 0 var(--tri-space-200) var(--tri-space-600)' })};
    ${mq.xSmallMaxWidth({ marginBottom: 'var(--tri-space-2000)' })};
  }

  .GoodFitPage-info-list {
    margin-left: var(--tri-space-300);
  }
`

const goodfitStyles = ({ mq, type }) => css`
  ${mq.smallMinWidth({ marginRight: 'var(--tri-space-180)', maxWidth: '768px' })};

  .review {
    padding: var(--tri-space-300) var(--tri-space-500);
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: var(--tri-space-200);
    justify-content: space-between;
    box-shadow: 0 var(--tri-space-100) var(--tri-space-850) var(--tri-color-fill-secondary);

    ${mq.xSmallMaxWidth(type.triFontDesktopCaptionSmall)}
  }

  [class='StarFilled Icon-svg'] {
    color: var(--tri-color-icon-warning);
  }
`

export default GoodFitPage

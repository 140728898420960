/* global __DivvyEnvironment */
import { useEffect, useRef } from 'react'

const THREAT_METRIX_SCRIPT_ID = 'threat-metrix'

export const useThreatMetrixProfiling = (authId) => {
  const mountedScriptSrc = useRef(null)
  const scriptSrc =
    authId &&
    `https://h.online-metrix.net/tags.js?org_id=${__DivvyEnvironment.THREAT_METRIX_ORG_ID}&session_id=${authId}`

  useEffect(() => {
    if (scriptSrc && scriptSrc !== mountedScriptSrc.current) {
      removeTmxScript()
      const head = document.getElementsByTagName('head').item(0)
      const script = document.createElement('script')
      script.setAttribute('type', 'text/javascript')
      script.setAttribute('src', scriptSrc)
      script.setAttribute('id', THREAT_METRIX_SCRIPT_ID)
      head.appendChild(script)
      mountedScriptSrc.current = scriptSrc
    }
    return removeTmxScript
  }, [scriptSrc])
}

const removeTmxScript = () => {
  const scriptElement = document.getElementById(THREAT_METRIX_SCRIPT_ID)
  if (scriptElement) {
    scriptElement.parentElement.removeChild(scriptElement)
    if (window?.tmx_profiling_started) {
      window.tmx_profiling_started = false
    }
  }
}

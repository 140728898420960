import { css } from '@emotion/core'

export const captionWrapperCss = (isWarning) => css`
  .TextInput-error-caption {
    color: ${isWarning ? 'var(--tri-color-text-primary)' : 'var(--tri-color-text-danger)'};
  }

  .TextInput-input-has-error {
    box-shadow: inset 0 -1px 0px ${isWarning ? 'var(--tri-color-text-primary)' : 'var(--tri-color-text-danger)'};
  }
`

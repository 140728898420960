import { css } from '@emotion/core'

export const mainContentWrapper = css`
  position: relative;
  height: 100vh;
`

export const accessCodeInfoTextCss = () => css`
  display: flex;
  margin-bottom: var(--tri-space-600);
`

export const accessCodeFormCss = css`
  max-width: 240px;

  .resend-code-link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .button-group {
    display: flex;
    padding-top: var(--tri-space-400);
    justify-content: space-between;
    width: 240px;
  }
`

export const resendCodeModalCss = ({ mq }) => css`
  .resend-code-main {
    max-width: 544px;
    ${mq.xSmallMaxWidth({ margin: 'var(--tri-space-200)' })};
  }

  .resend-code-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--tri-space-750);
  }

  .btn-resendcode-cancel {
    margin-right: var(--tri-space-400);
  }

  .Modal {
    &-header {
      ${mq.xSmallMaxWidth({
        padding: 'var(--tri-space-200) var(--tri-space-300) 0 var(--tri-space-300)',
      })};
    }

    &-content {
      padding: var(--tri-space-300) var(--tri-space-600);
      ${mq.xSmallMaxWidth({ padding: 'var(--tri-space-300)' })};
    }
  }
`

export const buttonStyles = ({ mq }) => css`
  position: absolute;
  display: flex;
  ${mq.xSmallMinWidth({
    bottom: '24px',
    justifyContent: 'space-around',
    right: '40px',
    width: '256px',
  })}

  ${mq.xSmallMaxWidth({
    bottom: '0',
    position: 'fixed',
    width: '100%',
  })}

  .BasicButton {
    ${mq.xSmallMaxWidth({
      borderRadius: '0',
      height: 'var(--tri-space-1100)',
      justifyContent: 'center',
      width: '100%',
    })}
  }
`

import React from 'react'
import { bool, func, node } from 'prop-types'
import Modal from '@divvy-web/skylab.modal'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import { useForm } from '@divvy-web/skylab.form'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import { modalStyles } from './styles'

const ResetPasswordModal = ({ children, headerImage, isShowing, isLoading, onClose, onSubmit, title }) => {
  const [getClassName, makeTestId] = useNameFormatter('ResetPasswordModal')
  const { validationErrors, getFormValue } = useForm()

  const actionButtons = [
    <BasicButton
      key='cancel-action'
      dataTestId={makeTestId('close')}
      type={BASIC_BUTTON_TYPE_OUTLINED}
      onClick={onClose}
    >
      <FormattedMessage
        defaultMessage='Close'
        id='sputnik.ResetPasswordModal__rbrahO'
      />
    </BasicButton>,
    <BasicButton
      key='submit-action'
      dataTestId={makeTestId('submit')}
      disabled={!!validationErrors || isLoading}
      showSpinner={isLoading}
      onClick={() => onSubmit(getFormValue('userEmail'))}
    >
      <FormattedMessage
        defaultMessage='Submit'
        id='sputnik.ResetPasswordModal__wSZR47'
      />
    </BasicButton>,
  ]

  return (
    <Modal
      actions={actionButtons}
      css={modalStyles}
      dataTestId={makeTestId('')}
      headerColor='accent'
      headerImage={headerImage}
      isShowing={isShowing}
      subTitle={
        <FormattedMessage
          defaultMessage='We can help!'
          id='sputnik.ResetPasswordModal__wpb9M/'
        />
      }
      title={title}
      onCloseClick={() => onClose()}
    >
      <div className={getClassName('')}>
        <div className={getClassName('forgot-password-title')}>
          <FormattedMessage
            defaultMessage='Forgot your password?'
            id='sputnik.ResetPasswordModal__cyRU1N'
          />
        </div>
        {children}
      </div>
    </Modal>
  )
}

ResetPasswordModal.propTypes = {
  children: node,
  headerImage: node,
  isShowing: bool,
  onClose: func,
  onSubmit: func,
  title: node,
  isLoading: bool,
}

export default ResetPasswordModal

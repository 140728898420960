import React from 'react'
import { bool, node } from 'prop-types'
import { useGetClassName, useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import PopSheet, { PopSheetControl } from '@divvy-web/skylab.popsheet'
import { popsheetStyles } from './styles'

const PasswordTipsPopsheet = ({ actionButton, children, headerImage, isShowing, subtitle }) => {
  const makeTestId = useMakeTestId('PasswordTipsPopsheet')
  const getClassName = useGetClassName('PasswordTipsPopsheet')

  return (
    <PopSheetControl
      css={popsheetStyles}
      dataTestId={makeTestId('container')}
      isShowing={isShowing}
    >
      <PopSheet
        className={getClassName('')}
        dataTestId={makeTestId('')}
        footer={actionButton}
        subtitle={subtitle}
        title={headerImage}
      >
        {children}
      </PopSheet>
    </PopSheetControl>
  )
}

PasswordTipsPopsheet.defaultProps = { isLoading: false, isShowing: false }

PasswordTipsPopsheet.propTypes = {
  actionButton: node,
  children: node,
  headerImage: node,
  isShowing: bool,
  subtitle: node,
}

export default PasswordTipsPopsheet

import { css } from '@emotion/core'

export const modalCss = ({ mq, type }) => css`
  display: flex;
  justify-content: center;

  .Modal-main {
    .Modal-content {
      padding: var(--tri-space-50);
    }

    .AgreementModal-react-markdown-container {
      .agreement-header {
        margin-bottom: var(--tri-space-200);
        ${mq.mediumMinWidth(type.triFontDesktopBodySmall)}
      }

      .agreement-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        ${mq.smallMaxWidth({
          width: '100%',
        })}
        ${mq.smallMinWidth({
          padding: 'var(--tri-space-50)',
        })}
      }

      .agreement-table,
      p {
        border-collapse: collapse;
        max-width: 800px;
      }

      .agreement-table td {
        padding: var(--tri-space-100);
      }

      .agreement-table th,
      .agreement-table td {
        border: 2px solid var(--tri-color-text-primary);
      }

      .agreement-signature-table {
        width: 100%;
      }

      .agreement-signature-table-gap {
        margin: 0;
        width: 100%;
      }

      .agreement-signature-value {
        margin: 0;
        min-width: 200px;
        text-align: left !important;

        pre {
          margin: 0;
          padding: 0;
        }
      }

      .agreement-signature-label {
        border-top: 2px solid var(--tri-color-text-primary);
        margin: 0;
        text-align: left !important;
        min-width: 200px;
      }
    }
  }
`

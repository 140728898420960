import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import TextLink, { TextLinkSizes } from '@divvy-web/skylab.textlink'
import { css } from '@emotion/core'
import { useCanary } from '@bill/canary.react'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import SideSectionImage from '../../../components/SideSectionImage'
import RewardsLink from '../../../components/RewardsLink'
import ReviewCard from '../../ReviewCard/ReviewCard'
import PrivacyPolicyLink from '../../../components/PrivacyPolicyLink'
import { PAGE_NAME_STRINGS, PRODUCT_CONST_STRINGS, URL } from '../../../resources/constants'
import TipCard from '../../TipCard/TipCard'
import TypesOfCreditInfoBox from '../../TypesOfCreditInfoBox'

interface SideSectionContentProps {
  pageName: string
  shouldShowAuthorizedSignerProTip: boolean
}

/** this component exposes the side section image and the tip and review cards for each page */
// TODO: when pages are broken out into their own views instead of the object mapper, move each condition to their own page
const SideSectionContent = ({ pageName, shouldShowAuthorizedSignerProTip }: SideSectionContentProps) => {
  const isSecuredLine = useCanary('show-secured-line')
  const isStateBasedDisclosures = useCanary('state-based-disclosures')
  const showWebBank = useCanary('show-web-bank')
  const [makeTestId] = useNameFormatter('SideSectionContent')
  const showSecuredLineGoodFitPage = isSecuredLine || isStateBasedDisclosures
  const {
    PAGE_AUTHORIZED_SIGNER,
    PAGE_BUSINESS_INFO,
    PAGE_COMPANY_OWNERS,
    PAGE_BENEFICIAL_OWNERS,
    PAGE_FINANCE_INFO,
    PAGE_REVIEW_AND_SIGN,
    PAGE_REVIEW_AND_VALIDATE,
    PAGE_SIGNUP,
    PAGE_GOOD_FIT,
    PAGE_DIVVY_PROGRAM_FIT,
    PAGE_SIGN_IN,
    PAGE_NOT_GOOD_FIT,
    PAGE_DASHBOARD,
    PAGE_APPLICATION_SUBMITTED,
    PAGE_RESET_PASSWORD,
    PAGE_VERIFY_CODE,
    PAGE_RIGHT_FIT_SURVEY,
  } = PAGE_NAME_STRINGS

  const { BILL_SPEND_AND_EXPENSE, BILL } = PRODUCT_CONST_STRINGS
  const isValidPageName = (pageName: string) => {
    return [
      PAGE_AUTHORIZED_SIGNER,
      PAGE_BUSINESS_INFO,
      PAGE_COMPANY_OWNERS,
      PAGE_BENEFICIAL_OWNERS,
      PAGE_FINANCE_INFO,
      PAGE_REVIEW_AND_SIGN,
      PAGE_REVIEW_AND_VALIDATE,
      PAGE_SIGNUP,
      PAGE_GOOD_FIT,
      PAGE_DIVVY_PROGRAM_FIT,
      PAGE_SIGN_IN,
      PAGE_NOT_GOOD_FIT,
      PAGE_DASHBOARD,
      PAGE_APPLICATION_SUBMITTED,
      PAGE_RESET_PASSWORD,
      PAGE_VERIFY_CODE,
      PAGE_RIGHT_FIT_SURVEY,
    ].includes(pageName)
  }

  if (!isValidPageName(pageName)) {
    return null
  }

  return (
    <div data-testid={makeTestId('container')}>
      {/* pages that return TipCard or ReviewCard  */}
      {(pageName === PAGE_RESET_PASSWORD || pageName === PAGE_VERIFY_CODE) && (
        <div css={tipsAndReviewsCss}>
          <TipCard
            bodyText={
              <FormattedMessage
                defaultMessage='{showWebBank, select, 
                  true {We take security very seriously. Verification only takes a few minutes and helps us protect your privacy.}
                  other {We take security very seriously at {BILL}. Verification only takes a few minutes and helps us protect your privacy.}
                }'
                id='sputnik.SideSectionContent__CQIZ19'
                values={{ BILL, showWebBank }}
              />
            }
            title={
              <FormattedMessage
                defaultMessage='Why do we need to verify your identity?'
                id='sputnik.SideSectionContent__G8cvge'
              />
            }
          />
          <ReviewCard
            reviewersCompany={
              <FormattedMessage
                defaultMessage='Crumbl'
                id='sputnik.SideSectionContent__TO2pmm'
              />
            }
            reviewersName={
              <FormattedMessage
                defaultMessage='Michael Card'
                id='sputnik.SideSectionContent__YFTtDn'
              />
            }
            reviewText={
              <FormattedMessage
                defaultMessage='"{BILL_SPEND_AND_EXPENSE} saves my finance team anywhere from 30-50 hours a month. Now, my departments have instant access to the funds they need to get the job done."'
                id='sputnik.SideSectionContent__/Njk8h'
                values={{ BILL_SPEND_AND_EXPENSE }}
              />
            }
            stars={5}
          />
        </div>
      )}

      {pageName === PAGE_REVIEW_AND_VALIDATE && (
        <div css={tipsAndReviewsCss}>
          <TipCard
            bodyText={
              <FormattedMessage
                defaultMessage='Gathering a few details up front will help us know if {BILL_SPEND_AND_EXPENSE} is a good match for you before you start the application. The more info we have, the better we can help.'
                id='sputnik.SideSectionContent__hu/2LL'
                values={{ BILL_SPEND_AND_EXPENSE }}
              />
            }
            title={
              <FormattedMessage
                defaultMessage='Why are we asking for this information?'
                id='sputnik.SideSectionContent__dv9AyQ'
              />
            }
          />
        </div>
      )}

      {pageName === PAGE_DIVVY_PROGRAM_FIT && (
        <div css={tipsAndReviewsCss}>
          <TipCard
            bodyText={
              <FormattedMessage
                defaultMessage='Gathering a few details up front will help us know if {BILL_SPEND_AND_EXPENSE} is a good match for you before you start the application. The more info we have, the better we can help.'
                id='sputnik.SideSectionContent__hu/2LL'
                values={{ BILL_SPEND_AND_EXPENSE }}
              />
            }
            title={
              <FormattedMessage
                defaultMessage='Why are we asking for this information?'
                id='sputnik.SideSectionContent__dv9AyQ'
              />
            }
          />
        </div>
      )}

      {pageName === PAGE_RIGHT_FIT_SURVEY && (
        <div css={tipsAndReviewsCss}>
          <TipCard
            bodyText={
              <FormattedMessage
                defaultMessage='Gathering a few details up front will help us know if {BILL_SPEND_AND_EXPENSE} is a good match for you before you start the application. The more info we have, the better we can help.'
                id='sputnik.SideSectionContent__hu/2LL'
                values={{ BILL_SPEND_AND_EXPENSE }}
              />
            }
            title={
              <FormattedMessage
                defaultMessage='Why are we asking for this information?'
                id='sputnik.SideSectionContent__dv9AyQ'
              />
            }
          />
        </div>
      )}

      {pageName === PAGE_DASHBOARD && (
        <div css={tipsAndReviewsCss}>
          <TipCard
            bodyText={
              <FormattedMessage
                defaultMessage='Gathering a few details up front will help us know if {BILL_SPEND_AND_EXPENSE} is a good match for you before you start the application. The more info we have, the better we can help.'
                id='sputnik.SideSectionContent__hu/2LL'
                values={{ BILL_SPEND_AND_EXPENSE }}
              />
            }
            title={
              <FormattedMessage
                defaultMessage='Why are we asking for this information?'
                id='sputnik.SideSectionContent__dv9AyQ'
              />
            }
          />
        </div>
      )}

      {pageName === PAGE_BUSINESS_INFO && (
        <div css={tipsAndReviewsCss}>
          <TipCard
            bodyText={
              <FormattedMessage
                defaultMessage='{showWebBank, select, 
                true {Like any credit application, we need to verify your business and financial information to get started. We know it may feel like a lot, so we provided this help article to explain what we’re asking for and why. Rest assured, your data belongs to you. We do not sell personal or business info and will only use it for verification purposes. View our {privacyLink}.}
                other {Like any credit application, we need to verify your business and financial information to get started. We know it may feel like a lot, so we provided this help article to explain what we’re asking for and why. Rest assured, your data belongs to you. {BILL_SPEND_AND_EXPENSE} doesn’t sell personal or business info and will only use it for verification purposes. View our {privacyLink}.}
                }'
                id='sputnik.SideSectionContent__UoYmBg'
                values={{ BILL_SPEND_AND_EXPENSE, privacyLink: <PrivacyPolicyLink />, showWebBank }}
              />
            }
            title={
              <FormattedMessage
                defaultMessage='{showWebBank, select,
                true {Why do we need this information?}
                other {Why does {BILL_SPEND_AND_EXPENSE} need this information?}
                }'
                id='sputnik.SideSectionContent__nebDRK'
                values={{ BILL_SPEND_AND_EXPENSE, showWebBank }}
              />
            }
          />
          <TipCard
            bodyText={
              <FormattedMessage
                defaultMessage='This is the date your business was created.'
                id='sputnik.SideSectionContent__auKHuS'
              />
            }
            title={
              <FormattedMessage
                defaultMessage='What is the Formation date?'
                id='sputnik.SideSectionContent__t8hXDH'
              />
            }
          />
        </div>
      )}

      {pageName === PAGE_SIGNUP && (
        <div css={tipsAndReviewsCss}>
          <TipCard
            bodyText={
              <FormattedMessage
                defaultMessage='Make sure you have access to the business email address you provide as you’ll need to access your inbox multiple times throughout this application.'
                id='sputnik.SideSectionContent__GoMTCo'
              />
            }
            title={
              <FormattedMessage
                defaultMessage='Pro tip'
                id='sputnik.SideSectionContent__0oV4Vc'
              />
            }
          />
          <ReviewCard
            reviewersCompany={
              <FormattedMessage
                defaultMessage='Artemis Health'
                id='sputnik.SideSectionContent__nb+Xtd'
              />
            }
            reviewersName={
              <FormattedMessage
                defaultMessage='Steve Plowman'
                id='sputnik.SideSectionContent__XNexR4'
              />
            }
            reviewText={
              <FormattedMessage
                defaultMessage='“A free card service that also gives us rewards — you can’t beat that.”'
                id='sputnik.SideSectionContent__er80vh'
              />
            }
            stars={5}
          />
        </div>
      )}

      {pageName === PAGE_FINANCE_INFO && (
        <div css={tipsAndReviewsCss}>
          <TipCard
            bodyText={
              <FormattedMessage
                defaultMessage='{showWebBank, select, 
                true {Connecting your business bank account allows us to instantly verify your bank and offer the highest line of credit possible based on your financial information. We use this connection for verification and repayment purposes. View our {privacyLink}.}
                other {Connecting your business bank account allows {BILL_SPEND_AND_EXPENSE} to instantly verify your bank and offer the highest line of credit possible based on your financial information. We’ll only use this connection for verification and repayment purposes.}}'
                id='sputnik.SideSectionContent__oQemkI'
                values={{ BILL_SPEND_AND_EXPENSE, showWebBank, privacyLink: <PrivacyPolicyLink /> }}
              />
            }
            title={
              <FormattedMessage
                defaultMessage='Why do you have to connect your bank account?'
                id='sputnik.SideSectionContent__lUYpIj'
              />
            }
          />
          <TipCard
            bodyText={
              <FormattedMessage
                defaultMessage='Earn 7x on restaurants, 5x on hotels and 2x on subscriptions for your first $5,000 spent with a weekly billing cycle. Learn more about {rewardsLink}.'
                id='sputnik.SideSectionContent__shc/8K'
                values={{ rewardsLink: <RewardsLink /> }}
              />
            }
            title={
              <FormattedMessage
                defaultMessage='Accelerate your rewards with a shorter billing cycle.'
                id='sputnik.SideSectionContent__m9KKOG'
              />
            }
          />
        </div>
      )}

      {pageName === PAGE_AUTHORIZED_SIGNER && (
        <div css={tipsAndReviewsCss}>
          <TipCard
            bodyText={
              <FormattedMessage
                defaultMessage='An authorized signer is a control person with significant responsibility to control, manage, or direct the company. E.g CEO, CFO, COO, managing member, general partner, president, vice president, treasurer or any other individual who regularly performs similar functions. This individual should have the ability to enter into this relationship and agreement.'
                id='sputnik.SideSectionContent__Lgffzv'
              />
            }
            title={
              <FormattedMessage
                defaultMessage='What is an authorized signer?'
                id='sputnik.SideSectionContent__yc1NAt'
              />
            }
          />
          {shouldShowAuthorizedSignerProTip && (
            <TipCard
              bodyText={
                <FormattedMessage
                  defaultMessage='{showWebBank, select, 
                  true {Once your application is submitted, we will perform a soft inquiry of the business and the authorized signer’s personal credit to determine the best credit limit for your business. This will not affect your business credit score or the authorized signer’s credit score. Learn more {creditScoreLink}.}
                  other {Once your application is submitted, Divvy will perform a soft inquiry of the business and the authorized signer’s personal credit to determine the best credit limit for your business. This will not affect your business credit score or the authorized signer’s credit score. Learn more {creditScoreLink}.}
                  }'
                  id='sputnik.SideSectionContent__jfwArG'
                  values={{
                    creditScoreLink: (
                      <TextLink
                        href={URL.CREDIT_IMPACT_LEARN_MORE}
                        size={TextLinkSizes.REGULAR}
                        target='_blank'
                      >
                        <FormattedMessage
                          defaultMessage='here'
                          id='sputnik.SideSectionContent__hniz8Z'
                        />
                      </TextLink>
                    ),
                    showWebBank,
                  }}
                />
              }
              title={
                <FormattedMessage
                  defaultMessage='{BILL_SPEND_AND_EXPENSE} does not do a hard inquiry or “hard pull” of your business credit.'
                  id='sputnik.SideSectionContent__7VZ5IH'
                  values={{ BILL_SPEND_AND_EXPENSE }}
                />
              }
            />
          )}
        </div>
      )}

      {(pageName === PAGE_COMPANY_OWNERS || pageName === PAGE_BENEFICIAL_OWNERS) && (
        <div css={tipsAndReviewsCss}>
          <TipCard
            bodyText={
              <FormattedMessage
                defaultMessage='{showWebBank, select, 
                true {Each individual, if any, who, directly or indirectly, through any contract, arrangement, understanding, relationship or otherwise, owns 25% or more of the equity interests of the company applying. Your company may or may not have a beneficial owner.}
                other {Also known as a beneficial owner, a company owner is anyone who owns at least 25% of the company. Your company may or may not have a company owner/beneficial owner.}
                }'
                id='sputnik.SideSectionContent__gisCsL'
                values={{ showWebBank }}
              />
            }
            title={
              <FormattedMessage
                defaultMessage='Who do I add as a company owner?'
                id='sputnik.SideSectionContent__Sl2Ic7'
              />
            }
          />
          <TipCard
            bodyText={
              <FormattedMessage
                defaultMessage='In accordance with the Bank Secrecy Act’s Customer Due Diligence Rule, we require this information to improve financial transparency and prevent criminals and terrorists from misusing companies to disguise illicit activities. The short of it is that it helps us prevent identity theft and application fraud.'
                id='sputnik.SideSectionContent__z5fZpP'
              />
            }
            title={
              <FormattedMessage
                defaultMessage='Why do we need you to list the company owner(s)?'
                id='sputnik.SideSectionContent__nzCbQ1'
              />
            }
          />
        </div>
      )}

      {/*  pages that return images */}
      {pageName === PAGE_SIGN_IN && (
        <div css={signInImageCss}>
          <SideSectionImage
            imageName='office_desk_03'
            isSvg={true}
          />
        </div>
      )}

      {pageName === PAGE_NOT_GOOD_FIT && (
        <div css={notGoodFitImageCss}>
          <SideSectionImage
            imageName='office_desk_10'
            isSvg={true}
          />
        </div>
      )}

      {pageName === PAGE_REVIEW_AND_SIGN && (
        <div css={reviewAndSignImageCss}>
          <SideSectionImage
            imageName='people_friends_01'
            isSvg={true}
          />
        </div>
      )}

      {pageName === PAGE_APPLICATION_SUBMITTED && (
        <div css={submittedImageCss}>
          <SideSectionImage
            imageName='mobile-upload-status-success'
            isSvg={false}
          />
        </div>
      )}

      {pageName === PAGE_GOOD_FIT && (
        <>
          {!showSecuredLineGoodFitPage && (
            <div css={goodFitImageCss}>
              <SideSectionImage
                imageName='laptops_05'
                isSvg={true}
              />
            </div>
          )}
          {showSecuredLineGoodFitPage && <TypesOfCreditInfoBox />}
        </>
      )}
    </div>
  )
}

const tipsAndReviewsCss = () => css`
  display: flex;
  flex-direction: column;
  gap: var(--tri-space-500);
  margin-top: var(--tri-space-500);
`

const signInImageCss = () => css`
  .program-image {
    top: 8%;
    right: -40%;
    height: 112%;
  }
`

const reviewAndSignImageCss = () => css`
  width: 500px;
  .program-image {
    top: 6vh;
    right: -35%;
    height: 80%;
  }
`
const goodFitImageCss = () => css`
  .program-image {
    top: 11vw;
    right: -25%;
    height: 112%;
  }
`
const notGoodFitImageCss = () => css`
  .program-image {
    bottom: -30%;
    right: -33%;
    height: 112%;
  }
`
const submittedImageCss = () => css`
  .program-image {
    bottom: -30%;
    right: -33%;
    height: 112%;
  }
`

export default SideSectionContent

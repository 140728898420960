import { css } from '@emotion/core'
export const headingStyles = ({ mq, type }) => css`
  margin-left: var(--tri-space-1500);
  ${mq.xSmallMaxWidth({ margin: 0, padding: 0 })}

  .signIn-page-heading-title {
    ${type.triFontDesktopTitleLargeEmphasis}
    max-width: 623px;
    margin-bottom: var(--tri-space-250);
    ${mq.xSmallMaxWidth(type.triFontDesktopTitleSmallEmphasis)}
    ${mq.xSmallMaxWidth({
      backgroundColor: 'var(--tri-color-fill-accent-primary)',
      margin: '0',
      padding: 'var(--tri-space-450) var(--tri-space-300) var(--tri-space-400) var(--tri-space-300)',
      maxWidth: '100%',
    })}
  }

  .signIn-page-heading-subtitle {
    max-width: 467px;
    margin-bottom: var(--tri-space-550);
    ${type.triFontDesktopBodyMedium}

    ${mq.xSmallMaxWidth({
      backgroundColor: 'var(--tri-color-fill-primary)',
      color: 'var(--tri-color-text-primary)',
      marginBottom: '0',
      padding: 'var(--tri-space-350) var(--tri-space-400) var(--tri-space-100) var(--tri-space-300)',
      width: '100%',
    })}
  }
`

export const signinStyles = ({ mq, type }) => css`
  margin-top: 20vh;

  ${mq.xSmallMaxWidth({ margin: 0 })}

  .sign-in-form {
    margin-left: var(--tri-space-1500);
    ${mq.xSmallMaxWidth({
      marginLeft: 0,
      marginTop: 'var(--tri-space-600)',
      padding: '0 var(--tri-space-400) var(--tri-space-300) var(--tri-space-300)',
    })}

    .disclaimers-container {
      max-width: 577px;
    }
  }

  .field-container {
    max-width: 577px;
  }

  .already-use,
  .terms-and-conditions {
    ${type.triFontDesktopBodySmall}
    margin-top: var(--tri-space-500);
    ${mq.xSmallMaxWidth(type.triFontDesktopCaptionSmall)}
    ${mq.xSmallMaxWidth({ marginTop: 'var(--tri-space-500)' })}
  }

  .bill-description {
    ${type.triFontDesktopCaptionSmall}
  }

  [class*='FormButtons-container'] {
    margin-top: var(--tri-space-450);
    padding: 0;
    ${mq.smallMinWidth({ paddingLeft: 0, position: 'relative' })}
  }

  [class*='FormButtons-container'] > .button-group-container {
    ${mq.smallMinWidth({ width: 'auto' })}
  }
`

import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import Banner, { BANNER_TYPE_ERROR } from '@divvy-web/skylab.banner'
import Tag, { TAG_TYPE_ERROR } from '@divvy-web/skylab.tag'
import TextLink from '@divvy-web/skylab.textlink'
import { css } from '@emotion/core'
import { onContactUsClick } from '../../components/ContactUsButton/contactUsUtils'

const ApplicationCorrectionBanner = () => (
  <div
    css={applicationCorrectionBannerStyle}
    data-testid='application-correction-banner'
  >
    <Banner
      bannerType={BANNER_TYPE_ERROR}
      className='application-correction-banner-header'
      titleText="Uh-Oh! Some of this information doesn't look quite right"
    />
    <p data-testid='banner-description'>
      <FormattedMessage
        defaultMessage="The information below doesn't match our records. Please review the sections that are tagged {reviewNeededTag} and resubmit your application.{br}{br}Need help? <contactUsLink>Start a chat with us here.</contactUsLink>"
        id='sputnik.ApplicationCorrectionBanner__3BOU+U'
        values={{
          br: <br />,
          contactUsLink: (...str) => <TextLink onClick={onContactUsClick}>{str}</TextLink>,
          reviewNeededTag: (
            <Tag
              css={tagStyles}
              type={TAG_TYPE_ERROR}
            >
              <FormattedMessage
                defaultMessage='Review needed'
                id='sputnik.ApplicationCorrectionBanner__2NYUhF'
              />
            </Tag>
          ),
        }}
      />
    </p>
  </div>
)

const applicationCorrectionBannerStyle = ({ mq }) => css`
  margin: 0;
  width: 80%;
  ${mq.xxSmallMaxWidth({ width: '100%' })}

  button {
    text-align: left;
  }

  .application-correction-banner-header {
    padding: var(--tri-space-250) 0 0 0;
    ${mq.xxSmallMaxWidth({ paddingTop: 'var(--tri-space-350)' })}
  }

  .application-correction-banner-header [class*='bodyEmphasis'] {
    ${mq.xxSmallMaxWidth({ marginLeft: '0' })}
  }

  .application-correction-banner-header i {
    ${mq.xxSmallMaxWidth({ display: 'none' })}
  }
`
const tagStyles = css`
  vertical-align: text-bottom;
`

export default ApplicationCorrectionBanner

import { css } from '@emotion/core'

export const wrapperStyles = css`
  background: var(--tri-color-fill-primary);
  position: relative;
  z-index: calc(var(--sky-elevation-navigation) + 1);
  @media all and (max-width: 600px) {
    z-index: var(--sky-elevation-scrim);
  }
  .company-info .action-menu .TriDropdown {
    .TriButton .mobile-company-wrapper {
      span {
        font: var(--tri-font-desktop-body-medium);
      }
    }

    .action-menu-list-wrapper .action-menu-list {
      .ActionMenuItem::first-of-type:not(.TriListItem) {
        .ActionMenuItemContainer {
          padding: var(--tri-space-200);
          border-bottom: var(--tri-list-item-effect-border);
          :hover {
            background-color: var(--tri-color-interactive-background-action-hover);
          }
        }
      }
    }
  }

  .action-items.secondary .action-menu .TriDropdown .action-menu-list-wrapper .action-menu-list {
    .ActionMenuItem::first-of-type:not(.TriListItem) {
      .ActionMenuItemContainer {
        padding: var(--tri-space-200);
        border-bottom: var(--tri-list-item-effect-border);
        :hover {
          background-color: var(--tri-color-interactive-background-action-hover);
        }
      }
    }
  }
`

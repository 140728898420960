import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { FormattedMessage } from '@divvy-web/i18n'
import { FormContext } from '@divvy-web/skylab.form'
import { TOAST_TYPE_DANGER, useToast } from '@divvy-web/skylab.toast'
import { useParams } from 'react-router-dom'
import UpdateBeneficialOwners from '../pages/gql/mutations/UpdateBeneficialOwners.gql'
import { generateCompanyOwnersList } from '../utils/dataUtils'
import { logError } from '../utils/loggerUtils'
import { prepCompanyOwners } from '../utils/mutationUtils'

export const useUpdateCompanyOwners = () => {
  const showToast = useToast()
  const { appId: creditApplicationId } = useParams()
  const { formValues } = useContext(FormContext)
  const beneficialOwners = generateCompanyOwnersList(formValues)
  const formattedBeneficialOwners = prepCompanyOwners(beneficialOwners)

  const [updateCompanyOwners, { loading }] = useMutation(UpdateBeneficialOwners, {
    onError: () => {
      logError({
        attributes: {
          action: 'Failure',
          message: `Credit App ID: ${creditApplicationId}`,
          result: `Error occured when updating company owners`,
        },
        eventName: 'UpdateCompanyOwners',
      })

      showToast(
        TOAST_TYPE_DANGER,
        <FormattedMessage
          defaultMessage='Error occured while saving changes.'
          id='sputnik.useUpdateCompanyOwners__v0GWGq'
        />,
        {
          autoHideDelay: 5000,
          dataTestId: 'company-owners-update-fail',
        },
      )
    },
    variables: {
      beneficialOwners: formattedBeneficialOwners,
      creditApplicationId,
      hasBeneficialOwner: !!formattedBeneficialOwners.length,
    },
  })
  return { loading, updateCompanyOwners }
}

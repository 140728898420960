import React, { useState } from 'react'
import useDidMount from '@divvy-web/hooks.usedidmount'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import AppHeader from '@divvy-web/skylab.appheader'
import BasicButton from '@divvy-web/skylab.basicbutton'
import Modal from '@divvy-web/skylab.modal'
import { css } from '@emotion/core'
import { bool } from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useDebounce } from '../../hooks/useDebounce'

const BdcLanding = () => {
  const { search } = useLocation()
  const makeTestId = useMakeTestId('bdc-landing')
  const params = new URLSearchParams(search)
  const email = params.get('email') || (
    <FormattedMessage
      defaultMessage='your email'
      id='sputnik.BdcLanding__4sARcm'
    />
  )
  const [isModalShowing, setModalIsShowing] = useState(false)
  const isModalShowingDebounced = useDebounce(isModalShowing, 1000)

  useDidMount(() => {
    setTimeout(() => setModalIsShowing(true), 60_000)
  })

  return (
    <div
      css={pageStyleCss}
      data-testid={makeTestId('page')}
    >
      <a href='/'>
        <div
          css={logoImageCss}
          data-testid={makeTestId('logo')}
        />
      </a>
      <div css={mainContentCss}>
        <section
          css={textContentCss}
          data-testid={makeTestId('message')}
          name='message'
        >
          <AppHeader>
            <h1>
              <FormattedMessage
                defaultMessage='Save time on your credit application'
                id='sputnik.BdcLanding__HGZtrc'
              />
            </h1>
          </AppHeader>
          <div css={descriptionCss}>
            <h2 css={nextStepsCss}>
              <FormattedMessage
                defaultMessage='Next steps'
                id='sputnik.BdcLanding__uhBRmg'
              />
            </h2>
            <ol>
              <li>
                <FormattedMessage
                  defaultMessage='Check {email} for a link to start your credit application.'
                  id='sputnik.BdcLanding__4oRnLY'
                  values={{ email }}
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage='Review the information that has been pre-filled from your BILL account to make sure everything looks accurate.'
                  id='sputnik.BdcLanding__EPfSP3'
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage='Once everything looks good, submit your application.'
                  id='sputnik.BdcLanding__v1fbA1'
                />
              </li>
            </ol>
          </div>
        </section>
      </div>
      <CheckEmailModal isShowing={isModalShowingDebounced} />
    </div>
  )
}

const CheckEmailModal = ({ isShowing }) => {
  const [isDismissed, setIsDismissed] = useState(false)

  return (
    <Modal
      actions={[
        <BasicButton
          key='gotit'
          onClick={() => setIsDismissed(true)}
        >
          <FormattedMessage
            defaultMessage='Got It'
            id='sputnik.BdcLanding__g+ZfxV'
          />
        </BasicButton>,
      ]}
      css={modalCss}
      isShowing={isShowing && !isDismissed}
      title={
        <FormattedMessage
          defaultMessage="Don't forget to check your inbox!"
          id='sputnik.BdcLanding__dfgODe'
        />
      }
    >
      <p>
        <FormattedMessage
          defaultMessage='We sent you a personalized email to help you start your credit application. Please keep an eye out for the invitation!'
          id='sputnik.BdcLanding__AAAtSl'
        />
      </p>
    </Modal>
  )
}

CheckEmailModal.propTypes = {
  isShowing: bool,
}

const descriptionCss = ({ mq, type }) => css`
  ${type.triFontDesktopBodyLarge}
  ${mq.mediumMaxWidth(css`
    ${type.triFontDesktopBodyMedium}
    padding-left: 0;
    padding-top: var(--tri-space-500);
  `)}
`

const logoImageCss = css`
  background: url(//app.divvy.co/assets/logos/divvy-plus-bdc-logo-dark@2x.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 384px;
  height: 44px;
  margin: var(--tri-space-400);
`

const mainContentCss = ({ mq }) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  ${mq.mediumMaxWidth(css`
    flex-direction: column;
    margin-top: var(--tri-space-400);
  `)}
`
const modalCss = css`
  .Modal-main {
    max-width: 550px;
  }
`
const nextStepsCss = ({ mq, type }) => css`
  ${type.triFontDesktopBodyLargeEmphasis}
  margin-top: var(--tri-space-600);
  ${mq.mediumMaxWidth(css`
    margin-top: 0;
    ${type.triFontDesktopTitleSmallEmphasis}
  `)}
`

const pageStyleCss = ({ mq }) => css`
  height: 100%;
  ${mq.mediumMaxWidth(css`
    height: unset;
  `)}
`

const textContentCss = ({ mq }) => css`
  margin: 0 0 var(--tri-space-1200) var(--tri-space-1200);
  ${mq.mediumMaxWidth(css`
    margin: 0;
    padding: 0 var(--tri-space-400);
  `)}
`

export default BdcLanding

import React from 'react'
import { bool, node } from 'prop-types'
import Modal from '@divvy-web/skylab.modal'
import { modalStyles } from './styles'

const PasswordTipsModal = ({ actionButton, children, headerImage, isShowing, title }) => {
  return (
    <Modal
      actions={actionButton}
      css={modalStyles}
      dataTestId='PasswordTipsModal'
      headerColor='accent'
      headerImage={headerImage}
      isShowing={isShowing}
      title={title}
    >
      {children}
    </Modal>
  )
}

PasswordTipsModal.defaultProps = { isLoading: false, isShowing: false }

PasswordTipsModal.propTypes = {
  actionButton: node,
  children: node,
  headerImage: node,
  isShowing: bool,
  title: node,
}

export default PasswordTipsModal

import React, { useState, useEffect } from 'react'
import { func, bool, string } from 'prop-types'
import { css } from '@emotion/core'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import { useQuery, useMutation } from '@apollo/client'
import { TOAST_TYPE_DANGER, TOAST_TYPE_SUCCESS, useToast } from '@divvy-web/skylab.toast'
import PopSheet, { PopSheetControl } from '@divvy-web/skylab.popsheet'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import Scrim from '@divvy-web/skylab.scrim'
import Drawer, { DrawerView } from '@divvy-web/skylab.drawer'
import { useForm } from 'react-hook-form'
import { validationResolver } from '@divvy-web/skylab.form'
import { logInfo, logError } from '../../../utils/loggerUtils'
import InviteUsers from '../gql/InviteUsers.gql'
import { useAuth } from '../../../auth'
import ImageWrapper from '../../../components/ImageWrapper'
import useDeviceDetect from '../../../hooks/useDeviceDetect'
import GetApplicationEmails from '../gql/GetApplicationEmails.gql'
import RemoveInvitedUsers from '../gql/RemoveInvitedUsers.gql'
import InviteCollaboratorsForm from './InviteCollaboratorsForm'

const customValidationResolver = (constraints, options, emails) => {
  const validator = validationResolver(constraints, options)

  return async (values) => {
    const validation = await validator(values)
    if (values.collaboratorsEmailInput) {
      const enteredEmails = values.collaboratorsEmailInput.split(',')
      const hasDuplicateEmails = enteredEmails?.some((email) => emails.includes(email.trim().toLowerCase()))
      const message = (
        <FormattedMessage
          defaultMessage='One or more entered email(s) is already a collaborator'
          id='sputnik.InviteCollaborators__Vcg+hN'
        />
      )

      if (hasDuplicateEmails) {
        return {
          errors: {
            ...validation.errors,
            collaboratorsEmailInput: {
              message: message,
            },
          },
          values: {},
        }
      }
    }

    return validation
  }
}

const InviteCollaborators = ({ appId, isShowing, setIsShowing }) => {
  const [makeTestId, getClassName] = useNameFormatter('InviteCollaborators')
  const { isMobile } = useDeviceDetect()
  const { email: signedInEmail } = useAuth()
  const showToast = useToast()
  const [currentCollaboratorEmails, setCurrentCollaboratorEmails] = useState([])
  const [inviteUsers, { loading: inviteUsersIsLoading }] = useMutation(InviteUsers)
  const [removeInvitedUsers, { loading: isRemovingUsers }] = useMutation(RemoveInvitedUsers)
  const errorToastMessage = (
    <FormattedMessage
      defaultMessage='Something happened submitting this request. Please try again.'
      id='sputnik.InviteCollaborators__OmsYvz'
    />
  )

  const { data: applicationEmailsData, error } = useQuery(GetApplicationEmails, {
    fetchPolicy: 'network-only',
    skip: !isShowing || !appId,
    variables: {
      creditApplicationId: appId,
    },
  })

  const { invitedUsersEmails, admin, authorizedSigner, beneficialOwners, outsourcedAccountant } =
    applicationEmailsData?.creditApplication || {}

  const creditApplicationPeopleEmails = [
    ...new Set([
      admin?.email || '',
      authorizedSigner?.email || '',
      ...(beneficialOwners?.map((owner) => owner.email) || ''),
      outsourcedAccountant?.email || '',
    ]),
  ]

  const allCollaboratorEmails = [...new Set([...creditApplicationPeopleEmails, ...currentCollaboratorEmails])].filter(
    (email) => email.trim(),
  )

  const constraints = () => {
    const pattern = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?)$/
    const commaMessage = (
      <FormattedMessage
        defaultMessage='Enter only valid email addresses separated by commas'
        id='sputnik.InviteCollaborators__HCKlRM'
      />
    )

    return {
      collaboratorsEmailInput: {
        presence: true,
        format: {
          flags: 'i',
          message: commaMessage,
          pattern: pattern,
        },
      },
    }
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: customValidationResolver(constraints, { fullMessages: false }, allCollaboratorEmails),
    mode: 'onChange',
  })

  const onCloseForm = () => {
    reset()
    setIsShowing(false)
  }

  useEffect(() => {
    const emails = invitedUsersEmails
    if (emails) {
      const uniqueEmailsExcludingSignedIn = [...new Set(emails)].filter((email) => email !== signedInEmail)
      setCurrentCollaboratorEmails(uniqueEmailsExcludingSignedIn)
    }
  }, [invitedUsersEmails, signedInEmail])

  const handleCollaboratorEmailsSubmit = (formValues) => {
    const newEmailArray = formValues.collaboratorsEmailInput
      .split(',')
      .filter((email) => email !== '')
      .map((email) => email.trim())
    const newEmailArrayUnique = [...new Set(newEmailArray)]

    const input = {
      creditApplicationId: appId,
      emails: [...newEmailArrayUnique],
    }

    inviteUsers({
      variables: input,
      refetchQueries: ['GetApplicationEmails'],
      onCompleted: () => {
        logInfo({
          attributes: {
            action: 'inviteCollaboratorsByEmail',
            result: 'Successfully sent collaborators email list',
          },
          eventName: 'SignUp',
        })

        reset()
        showToast(
          TOAST_TYPE_SUCCESS,
          <FormattedMessage
            defaultMessage='Invites successfully sent.'
            id='sputnik.InviteCollaborators__tZSXPg'
          />,
          {
            autoHideDelay: 5000,
          },
        )
      },
      onError: (e) => {
        logError({
          attributes: {
            action: 'inviteCollaboratorsByEmail',
            message: e?.message,
            result: 'Error in sending collaborator email list',
          },
          eventName: 'SignUp',
        })

        showToast(TOAST_TYPE_DANGER, errorToastMessage, {
          autoHideDelay: 5000,
        })
      },
    })
  }

  const handleCollaboratorEmailRemoval = (emailToRemove) => {
    const input = {
      creditApplicationId: appId,
      emails: [emailToRemove],
    }

    removeInvitedUsers({
      variables: input,
      onCompleted: () => {
        logInfo({
          attributes: {
            action: 'removeInvitedCollaboratorsByEmail',
            result: 'Successfully removed collaborators email from list',
          },
          eventName: 'SignUp',
        })

        const updatedEmailList = currentCollaboratorEmails.filter((email) => email !== emailToRemove)
        setCurrentCollaboratorEmails(updatedEmailList)
      },
      onError: (e) => {
        logError({
          attributes: {
            action: 'removeInvitedCollaboratorsByEmail',
            message: e?.message,
            result: 'Error in removing collaborator email from list',
          },
          eventName: 'SignUp',
        })

        showToast(TOAST_TYPE_DANGER, errorToastMessage, {
          autoHideDelay: 5000,
        })
      },
    })
  }

  const form = (
    <InviteCollaboratorsForm
      allCollaboratorEmails={allCollaboratorEmails}
      control={control}
      creditApplicationPeopleEmails={creditApplicationPeopleEmails}
      isMobile={isMobile}
      onEmailRemovalClick={handleCollaboratorEmailRemoval}
    />
  )

  const actionButtons = (
    <div
      className='fs-unmask'
      css={isMobile ? popsheetButtonStyles : drawerButtonStyles}
    >
      <BasicButton
        color='neutral'
        dataTestId={makeTestId('cancel')}
        type={BASIC_BUTTON_TYPE_FLAT}
        onClick={onCloseForm}
      >
        <FormattedMessage
          defaultMessage='Cancel'
          id='sputnik.InviteCollaborators__47FYwb'
        />
      </BasicButton>
      <BasicButton
        dataTestId={makeTestId('Invite')}
        disabled={!isValid || inviteUsersIsLoading}
        showSpinner={inviteUsersIsLoading}
        onClick={handleSubmit(handleCollaboratorEmailsSubmit)}
      >
        <FormattedMessage
          defaultMessage='Invite'
          id='sputnik.InviteCollaborators__hYOE+U'
        />
      </BasicButton>
    </div>
  )

  if (isMobile) {
    return (
      <PopSheetControl
        css={mobilePopsheetStyles}
        dataTestId={makeTestId('')}
        isShowing={isShowing}
      >
        <div>
          <PopSheet
            className={getClassName('popsheet-form')}
            dataTestId={makeTestId('')}
            footer={actionButtons}
            title={
              <ImageWrapper
                alt='musicians playing instruments'
                className={getClassName('image')}
                dataTestId={makeTestId('image')}
                imageName='invite-collaborators-popsheet'
              />
            }
          >
            {form}
          </PopSheet>
        </div>
      </PopSheetControl>
    )
  }

  if (!isMobile) {
    return (
      <div>
        <Scrim isShowing={isShowing}>
          <Drawer
            activeViewKey='default'
            css={webDrawerStyles}
            isShowing={isShowing}
            views={{
              default: (
                <DrawerView
                  className={getClassName('drawer-view')}
                  dataTestId={makeTestId('')}
                  footerActions={actionButtons}
                  headerLeftIcon='close'
                  headerLeftIconName='close'
                  title={
                    <FormattedMessage
                      defaultMessage='Invite collaborators'
                      id='sputnik.InviteCollaborators__ni18Y2'
                    />
                  }
                  onLeftIconClick={onCloseForm}
                >
                  {form}
                </DrawerView>
              ),
            }}
            onClose={onCloseForm}
          />
        </Scrim>
      </div>
    )
  }
}

InviteCollaborators.propTypes = {
  appId: string,
  isShowing: bool,
  setIsShowing: func,
}

const mobilePopsheetStyles = ({ mq }) => css`
  .program-image {
    max-height: 154px;
  }
`

const popsheetButtonStyles = ({ mq }) => css`
  display: flex;
  height: 88px;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  margin-top: var(--tri-space-100);

  button {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`

const webDrawerStyles = css`
  .InviteCollaborators-drawer-view-content {
    height: 100%;
    padding: var(--tri-space-400) var(--tri-space-300) var(--tri-space-300);
  }
`

const drawerButtonStyles = css`
  display: flex;
  flex-direction: row;

  .BasicButton {
    flex: 1;
    border-radius: 0;
    min-height: 88px;
  }
`

export default InviteCollaborators

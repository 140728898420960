import { curry } from '@divvy-web/utils'
import { css } from '@emotion/core'
import React, { ReactNode } from 'react'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { PAGE_NAME_STRINGS } from '../../resources/constants'
import CustomerAssistanceButton from '../CustomerAssistanceButton/CustomerAssistanceButton'
import DashboardHeading from '../DashboardHeading/DashboardHeading'
import ReviewsSideSection from '../ReviewsSideSection/ReviewsSideSection'

interface PageViewWrapperProps {
  bdcOrgId?: string
  canStartNewApplication?: boolean
  children: ReactNode
  dsaVersion?: string
  inviteCollaborators?: boolean
  pageName: string
}

const PageViewWrapper = ({
  bdcOrgId,
  canStartNewApplication = false,
  children,
  dsaVersion = '',
  inviteCollaborators,
  pageName,
}: PageViewWrapperProps) => {
  const {
    PAGE_GOOD_FIT,
    PAGE_MIGRATION_SUCCESS,
    PAGE_DIVVY_PROGRAM_FIT,
    PAGE_SIGN_IN,
    PAGE_NOT_GOOD_FIT,
    PAGE_DASHBOARD,
    PAGE_REVIEW_AND_SIGN,
    PAGE_APPLICATION_SUBMITTED,
  } = PAGE_NAME_STRINGS
  const isGoodFitPage = pageName === PAGE_GOOD_FIT
  const isMigrationSuccess = pageName === PAGE_MIGRATION_SUCCESS
  const isDivvyProgramFitPage = pageName === PAGE_DIVVY_PROGRAM_FIT
  const isSignin = pageName === PAGE_SIGN_IN
  const isNotGoodFit = pageName === PAGE_NOT_GOOD_FIT
  const isDashboard = pageName === PAGE_DASHBOARD
  const isReviewAndSign = pageName === PAGE_REVIEW_AND_SIGN
  const isApplicationSubmitted = pageName === PAGE_APPLICATION_SUBMITTED
  const [makeTestId] = useNameFormatter('PageViewWrapper')

  const shouldHaveOneColorWidth = isGoodFitPage || isNotGoodFit || isSignin || isReviewAndSign

  if (isDashboard || isMigrationSuccess) {
    return (
      <div
        className='dashboard-wrapper'
        data-testid={makeTestId('')}
      >
        <CustomerAssistanceButton shouldUseSpecialMobileStyles={false} />
        <DashboardHeading
          bdcOrgId={bdcOrgId}
          canStartNewApplication={canStartNewApplication}
          isMigrationSuccess={isMigrationSuccess}
        />
        <div css={dashboardStyles}>{children}</div>
      </div>
    )
  }

  return (
    <div
      css={pageViewWrapperStyles({
        isApplicationSubmitted,
        isDivvyProgramFitPage,
        isGoodFitPage,
        isNotGoodFit,
        isSignin,
        shouldHaveOneColorWidth,
      })}
    >
      <div className='main-page-wrapper'>
        <CustomerAssistanceButton shouldUseSpecialMobileStyles />
        {children}
      </div>
      <div className='reviews-side-section-wrapper'>
        <ReviewsSideSection
          dsaVersion={dsaVersion}
          inviteCollaborators={inviteCollaborators}
          pageName={pageName}
        />
      </div>
    </div>
  )
}

export default PageViewWrapper

const pageViewWrapperStyles = curry(
  (
    { isApplicationSubmitted, isDivvyProgramFitPage, isGoodFitPage, isNotGoodFit, isSignin, shouldHaveOneColorWidth },
    { mq },
  ) => css`
    display: flex;
    min-height: 100vh;
    max-height: 100vh;
    gap: 0;
    ${mq.xSmallMaxWidth({ maxHeight: 'auto', minHeight: 'auto' })}
    ${isGoodFitPage && mq.xSmallMaxWidth({ display: 'block' })}

    .reviews-side-section-wrapper {
      width: ${shouldHaveOneColorWidth
        ? '35%'
        : isSignin || isApplicationSubmitted || isDivvyProgramFitPage || isGoodFitPage || isNotGoodFit
        ? '40%'
        : '25%'};
      min-height: 100vh;
      overflow-y: auto;
      background-color: var(--tri-color-fill-accent-primary);
      max-width: ${isGoodFitPage && '960px'};

      ${!isSignin && !isGoodFitPage && !isApplicationSubmitted && mq.largeMaxWidth({ display: 'none' })};
      ${shouldHaveOneColorWidth && isGoodFitPage && mq.xSmallMaxWidth({ display: 'none' })};
      ${isGoodFitPage && mq.mediumMaxWidth({ minWidth: '240px' })}
      ${(isSignin || isApplicationSubmitted || isGoodFitPage) && mq.xSmallMaxWidth({ display: 'none' })};
    }

    .main-page-wrapper {
      z-index: 3;
      overflow: ${!isGoodFitPage && 'auto'};
      overflow-x: ${isGoodFitPage && 'visible'};
      width: ${isGoodFitPage ? '60%' : '75%'};
      flex-grow: ${isGoodFitPage && 2};
      ${mq.largeMaxWidth({ width: isGoodFitPage ? '60%' : '100%' })};
      ${mq.xSmallMaxWidth({ overflow: 'visible', width: '100%' })}
    }

    .review-wrap:nth-of-type(2) {
      margin-right: ${isGoodFitPage && 'var(--tri-space-500)'};
      ${isGoodFitPage && mq.xLargeMaxWidth({ marginRight: 0 })}
    }

    .review-wrap:nth-of-type(3) {
      margin-right: ${isGoodFitPage && 'var(--tri-space-100)'};
      ${isGoodFitPage && mq.xSmallMaxWidth({ overflow: 'visible', width: '100%' })}
      ${isGoodFitPage && mq.xLargeMaxWidth({ marginRight: 0 })}
    }

    .review {
      border-radius: ${isGoodFitPage && '16px'};
    }
  `,
)

const dashboardStyles = ({ mq }) => css`
  padding-bottom: var(--tri-space-1200);
  margin: var(--tri-space-800) var(--tri-space-1500);

  ${mq.mediumMaxWidth({ margin: 'var(--tri-space-500)', paddingBottom: 'var(--tri-space-1500)' })}
`

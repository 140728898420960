import { css } from '@emotion/core'

export const webSuccessPreApprovedOffersCss = ({ mq, type }) => css`
  height: 100%;

  .main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: var(--tri-space-1900);
  }

  .SuccessPreApprovedOffers {
    &-header-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &-title-and-subtitle {
      padding-left: var(--tri-space-1500);
    }

    &-title {
      ${type.triFontDesktopTitleLargeEmphasis}
      padding-top: var(--tri-space-1000);
    }

    &-subtitle {
      ${type.triFontDesktopBodyMedium}
    }

    &-offer-info-and-tiles {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: var(--tri-space-700);
      margin: var(--tri-space-850) var(--tri-space-550) var(--tri-space-750);
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      height: 100%;
      ${mq.xLargeMaxWidth(css`
        height: auto;
      `)}
    }

    &-offer-info {
      height: 546px;
      max-width: 342px;
      min-width: 342px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding: 0 var(--tri-space-250) 0;
    }

    &-offer-info-list-title {
      ${type.triFontDesktopTitleSmallEmphasis}
    }

    &-offer-info-list,
    &-offer-info-list-item {
      text-align: left;
      padding-top: var(--tri-space-250);
    }

    &-offer-info-list {
      padding-left: var(--tri-space-750);
    }
  }
`

export const mobileSuccessPreApprovedOffersCss = ({ type }) => css`
  height: 100%;

  .main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: var(--tri-space-850);
  }

  .SuccessPreApprovedOffers {
    &-header-content {
      display: flex;
      justify-content: center;
      padding: var(--tri-space-100) 0 var(--tri-space-100) var(--tri-space-250);
      background-color: var(--tri-color-fill-accent-primary);
    }

    &-title {
      align-self: center;
      ${type.triFontDesktopTitleSmallEmphasis};
    }

    &-subtitle {
      align-self: center;
      ${type.triFontDesktopBodySmall};
    }

    &-title-and-subtitle {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-offer-info-and-tiles {
      display: flex;
      flex-direction: column;
      gap: var(--tri-space-150);
      align-items: center;
      text-align: center;
      height: 100%;
      padding-bottom: var(--tri-space-500);
    }

    &-offer-info {
      width: 342px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
      padding: var(--tri-space-300) 0 0;
    }

    &-offer-info-list-title {
      ${type.triFontDesktopBodySmall};
    }

    &-offer-info-list,
    &-offer-info-list-item {
      ${type.triFontDesktopBodySmall};
      text-align: left;
    }

    &-offer-info-list {
      padding-left: var(--tri-space-250);
    }

    &-secured-line-title {
      ${type.triFontDesktopTitleSmallEmphasis};
      color: var(--tri-color-text-primary);
      padding-bottom: var(--tri-space-50);
    }

    &-return-to-dashboard {
      width: 100%;
      height: var(--tri-space-1100);
      bottom: 0;
      left: 0;
    }
  }
`

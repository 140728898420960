import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import React from 'react'
import Spinner from '@divvy-web/skylab.spinner'
import ImageWrapper from '../../../components/ImageWrapper'
import useDeviceDetect from '../../../hooks/useDeviceDetect'
import { mobileProcessingApplicationCss, webProcessingApplicationCss } from '../processingApplicationStyles'
import OneColorSidebar from '../../../components/OneColorSidebar'

interface PollingPageContentProps {
  mainText: React.ReactNode
  subText: React.ReactNode
}

const PollingPageContent = ({ mainText, subText }: PollingPageContentProps) => {
  const peopleYogaPath = 'https://app.divvy.co/assets/illustrations/people-yoga'
  const peopleYogaSrcSet = `${peopleYogaPath}@3x.png, ${peopleYogaPath}@2x.png, ${peopleYogaPath}@1x.png`

  const { isMobile } = useDeviceDetect()
  const [makeTestId] = useNameFormatter('PollingPageContent')

  return (
    <div
      css={isMobile ? mobileProcessingApplicationCss : webProcessingApplicationCss}
      data-testid={makeTestId('')}
    >
      <div className='main-content-section fs-unmask'>
        <div
          className='body-text'
          data-testid={makeTestId('body-text')}
        >
          {isMobile && (
            <ImageWrapper
              alt='yoga-mobile'
              dataTestId={makeTestId('yoga-mobile-image')}
              imageName='processing-application-mobile'
            />
          )}
          <Spinner
            centered
            className='spinner'
            dataTestId={makeTestId('spinner')}
            isSmall={isMobile}
          />
          <h1
            className='title'
            data-testid={makeTestId('title')}
          >
            {mainText}
          </h1>
          <h2
            className='subtitle'
            data-testid={makeTestId('subtitle')}
          >
            {subText}
          </h2>
        </div>
      </div>
      {!isMobile && <OneColorSidebar srcImage={peopleYogaSrcSet} />}
    </div>
  )
}

export default PollingPageContent

import React from 'react'
import { css } from '@emotion/core'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import Image from '@divvy-web/skylab.image'

const NoCreditApplicationsPage = () => {
  const [getClassName, makeTestId] = useNameFormatter('NoCreditApplicationsPage')

  return (
    <div css={pageStyles}>
      <div className={getClassName('content')}>
        <Image
          alt='girl-with-plug'
          assetName='signup-dashboard-no-applications'
          className='dashboard-no-apps-image'
          dataTestId={makeTestId('')}
        />
        <div
          className='text-wrapper'
          data-testid={makeTestId('text')}
        >
          <FormattedMessage
            defaultMessage='It looks like your account is no longer associated with any current applications. Please begin a new application to get started.'
            id='sputnik.NoCreditApplicationsPage__xNHneH'
          />
        </div>
      </div>
    </div>
  )
}

const pageStyles = ({ mq }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .NoCreditApplicationsPage-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      ${mq.xxSmallMaxWidth({ padding: 'var(--tri-space-300) 0' })}
    }
    .text-wrapper {
      max-width: 592px;
      text-align: center;
    }
  }
`

export default NoCreditApplicationsPage

import { array, arrayOf, bool, string, func, object, shape } from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import LoadingLogo from '../LoadingLogo'
import { useAuth } from '../../auth'
import DocumentUploadTypeModal from '../DocumentUploadTypeModal'
import MobileSourceUploadSelector from '../MobileSourceUploadSelector'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import useGetRequiredDocs from '../../hooks/useRequiredDocs'
import { logError } from '../../utils/loggerUtils'
import UploaderTile from './UploaderTile'
import { getFormattedOwnerNames } from './requiredDocumentMapper'

const UploaderTileList = ({ creditApp, remainingDocs, requiredDocuments, setRemainingDocs }) => {
  const [isDocumentTypeUploadModalShowing, setIsDocumentTypeUploadModalShowing] = useState(false)
  const [mobileDocumentUploadToken, setMobileDocumentUploadToken] = useState('')
  const [selectedDocument, setSelectedDocument] = useState({})
  const { requestDocumentUploadToken } = useAuth()
  const { isMobile } = useDeviceDetect()
  const [wasUploaderShownOnce, setWasUploaderShownOnce] = useState(false)
  const requiredDocsPollerRef = useRef()
  const [isUploading, setIsUploading] = useState(false)
  const appId = creditApp?.salesforceCreditId
  const authorizedSigner = creditApp?.authorizedSigner
  const beneficialOwners = creditApp?.beneficialOwners
  const accountNumberLastFour = creditApp?.financeInfo?.bankInfo?.accountNumberLastFour
  const desiredCredit = creditApp?.financeInfo?.desiredCredit

  const formattedNames = getFormattedOwnerNames(beneficialOwners, authorizedSigner)

  const imageBtnRef = useRef()

  const [getRequiredDocs, { refetch }] = useGetRequiredDocs(appId)

  useEffect(() => {
    if (wasUploaderShownOnce && !requiredDocsPollerRef?.current) {
      getRequiredDocs().then(() => {
        requiredDocsPollerRef.current = setInterval(async () => {
          const { data } = await refetch()
          const currentRemainingDocs = data?.creditApplicationBypassingSf?.requiredDocs?.map((doc) => doc.documentType)
          const changesDetected = JSON.stringify(currentRemainingDocs?.sort()) !== JSON.stringify(remainingDocs?.sort())

          if (currentRemainingDocs && changesDetected) {
            setRemainingDocs(currentRemainingDocs)

            if (!currentRemainingDocs.includes(selectedDocument?.selectedDocumentType)) {
              setIsDocumentTypeUploadModalShowing(false)
            }
          }
        }, 1500)
      })
    }

    return () => {
      clearInterval(requiredDocsPollerRef?.current)
      requiredDocsPollerRef.current = null
    }
  }, [selectedDocument, getRequiredDocs, refetch, remainingDocs, setRemainingDocs, wasUploaderShownOnce, appId])

  const onTileSelect = async (selectedDocumentType, selectedDocumentUuid) => {
    setSelectedDocument({ selectedDocumentType, selectedDocumentUuid })

    if (isMobile) {
      setWasUploaderShownOnce(true)
      imageBtnRef.current.click()
      return
    }

    return requestDocumentUploadToken(appId)
      .then((result) => {
        const documentToken = result?.data?.requestDocumentUploadToken?.token || ''
        setMobileDocumentUploadToken(documentToken)
      })
      .catch((e) => {
        logError({
          attributes: {
            action: 'RequestMobileDocumentUploadToken',
            message: 'Error while generating mobile document upload token',
            result: e,
          },
          eventName: 'UploadDocument',
        })
      })
      .finally((_) => {
        setIsDocumentTypeUploadModalShowing(true)
        setWasUploaderShownOnce(true)
      })
  }

  return (
    <>
      <LoadingLogo isShowing={isUploading && isMobile} />
      {requiredDocuments?.map(({ documentType, documentUuid, statusMessage }) => (
        <div key={documentUuid}>
          <UploaderTile
            accountNumberLastFour={accountNumberLastFour}
            appId={appId}
            desiredCredit={desiredCredit}
            documentType={documentType}
            documentUuid={documentUuid}
            formattedNames={formattedNames}
            isMobile={isMobile}
            remainingDocs={remainingDocs}
            setRemainingDocs={setRemainingDocs}
            statusMessage={statusMessage}
            onSelect={(_) => onTileSelect(documentType, documentUuid)}
          />
        </div>
      ))}
      {!isMobile && (
        <DocumentUploadTypeModal
          appId={appId}
          isShowing={isDocumentTypeUploadModalShowing}
          selectedDocument={selectedDocument}
          setIsModalShowing={setIsDocumentTypeUploadModalShowing}
          token={mobileDocumentUploadToken}
        />
      )}
      {isMobile && (
        <MobileSourceUploadSelector
          appId={appId}
          imageBtnRef={imageBtnRef}
          remainingDocs={remainingDocs}
          selectedDocument={selectedDocument}
          setIsUploading={setIsUploading}
        />
      )}
    </>
  )
}

UploaderTileList.propTypes = {
  creditApp: object,
  remainingDocs: arrayOf(
    shape({
      documentType: string,
      documentUuid: string,
      statusMessage: string,
    }),
  ),
  requiredDocuments: array,
  setRemainingDocs: func,
}

export default UploaderTileList

import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { css } from '@emotion/core'
import { func, node } from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import TextInput from '@divvy-web/skylab.textinput'
import { debounce } from '@divvy-web/utils'
import { FormattedMessage } from '@divvy-web/i18n'
import Spinner from '@divvy-web/skylab.spinner'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import SearchInstitutions from '../gql/SearchInstitutions.gql'
import { MX_INSTITUTION_DEBOUNCE_TIME } from '../../resources/constants'

const MXInstitutionSearch = ({ bottomContent, setSelectedInstitution }) => {
  const [getClassName, makeTestId] = useNameFormatter('MXInstitutionSearch')
  const [searchString, setSearchString] = useState('')
  const [searchInstitutions, { data, error, loading }] = useLazyQuery(SearchInstitutions)
  const showFavorites = useMemo(() => searchString.length === 0, [searchString.length])
  const shouldSearch = useCallback((string) => string.length > 2, [])
  const [debouncingFromTooFewCharacters, setDebouncingFromTooFewCharacters] = useState(false)

  useEffect(() => {
    if (showFavorites) {
      searchInstitutions()
    }
  }, [searchInstitutions, showFavorites])

  const search = useCallback(
    (value) => {
      setDebouncingFromTooFewCharacters(false)
      if (shouldSearch(value)) {
        searchInstitutions({ variables: { search: value } })
      }
    },
    [shouldSearch, searchInstitutions],
  )
  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedSearch = useCallback(debounce(search, MX_INSTITUTION_DEBOUNCE_TIME), [])

  const handleChange = (value) => {
    if (searchString.length > 0 && searchString.length < 3) {
      setDebouncingFromTooFewCharacters(true)
    }
    setSearchString(value)
    debouncedSearch(value)
  }

  const numberOfSearchResults = () => {
    const count = data?.institutions?.length
    switch (count) {
      case 0:
        return (
          <FormattedMessage
            defaultMessage='0 search results'
            id='sputnik.MXInstitutionSearch__ccQ77N'
          />
        )
      case 1:
        return (
          <FormattedMessage
            defaultMessage='1 search result'
            id='sputnik.MXInstitutionSearch__uEcGGT'
          />
        )
      case 50:
        return (
          <FormattedMessage
            defaultMessage='50+ search results'
            id='sputnik.MXInstitutionSearch__0Ez50H'
          />
        )
      case undefined:
        return null
      default:
        return (
          <FormattedMessage
            defaultMessage='{count} search results'
            id='sputnik.MXInstitutionSearch__9ahL5M'
            values={{ count }}
          />
        )
    }
  }

  return (
    <>
      <div css={institutionSearchCss}>
        <div className={getClassName('label')}>
          <FormattedMessage
            defaultMessage='Select your institution'
            id='sputnik.MXInstitutionSearch__qn2qEf'
          />
        </div>
        <TextInput
          className={getClassName('institution-search')}
          dataTestId={makeTestId('search-input')}
          leftIcon='search'
          placeholder='Search'
          value={searchString}
          onChange={(e) => handleChange(e.target.value)}
        />
        {shouldSearch(searchString) || showFavorites ? (
          <>
            {showFavorites && (
              <div className={getClassName('most-popular')}>
                <FormattedMessage
                  defaultMessage='Most popular'
                  id='sputnik.MXInstitutionSearch__dC3u7Z'
                />
              </div>
            )}
            {loading || debouncingFromTooFewCharacters ? (
              <Spinner className={getClassName('spinner')} />
            ) : (
              <div className={getClassName('list')}>
                {error && (
                  <div className={getClassName('error')}>
                    <FormattedMessage
                      defaultMessage='Error loading institutions'
                      id='sputnik.MXInstitutionSearch__/9Db5J'
                    />
                  </div>
                )}
                {!showFavorites && <div className={getClassName('results')}>{numberOfSearchResults()}</div>}
                {data?.institutions?.map((institution) => (
                  <button
                    key={institution.code}
                    className={getClassName('institution')}
                    data-testid={makeTestId(`search-result-${institution.code}`)}
                    onClick={() => setSelectedInstitution(institution)}
                  >
                    <img
                      alt={`${institution.name} logo`}
                      height='48'
                      src={institution.smallLogoUrl}
                    />
                    <div className={getClassName('content')}>
                      <div className={getClassName('name')}>{institution.name}</div>
                      <div className={getClassName('url')}>{institution.url}</div>
                    </div>
                    <div className={getClassName('spacer')} />
                  </button>
                ))}
              </div>
            )}
          </>
        ) : (
          <div className={getClassName('too-many')}>
            <FormattedMessage
              defaultMessage='Keep typing - too many results to display'
              id='sputnik.MXInstitutionSearch__w3g83l'
            />
          </div>
        )}
      </div>
      <div css={bottomContentCss}>{bottomContent}</div>
    </>
  )
}

MXInstitutionSearch.propTypes = {
  bottomContent: node.isRequired,
  setSelectedInstitution: func.isRequired,
}

const institutionSearchCss = ({ type }) => css`
  display: flex;
  flex-direction: column;
  max-height: 624px;

  .MXInstitutionSearch-label {
    ${type.triFontDesktopBodyLarge}
    margin-bottom: var(--tri-space-200);
    margin-left: var(--tri-space-200);
  }

  .MXInstitutionSearch-spinner {
    margin-top: var(--tri-space-200);
    display: block;
    width: 100%;
    text-align: center;
  }

  .MXInstitutionSearch-most-popular {
    margin: 0 var(--tri-space-200);
    margin-top: var(--tri-space-200);
  }

  .MXInstitutionSearch-results,
  .MXInstitutionSearch-too-many {
    ${type.triFontDesktopBodySmall}
    margin-bottom: var(--tri-space-100);
  }

  .MXInstitutionSearch-list {
    overflow-y: scroll;
  }

  .MXInstitutionSearch-list,
  .MXInstitutionSearch-too-many {
    margin: 0 var(--tri-space-200);
    margin-top: var(--tri-space-150);
    margin-bottom: var(--tri-space-900);
  }

  .MXInstitutionSearch-institution {
    padding: var(--tri-space-150);
    border-radius: 8px;
    display: flex;
    width: 100%;
    &:hover {
      background: var--tri-color-stroke-secondary);
    }
    & img {
      margin-right: var(--tri-space-150);
    }
    & .MXInstitutionSearch-name {
      ${type.triFontDesktopBodyMediumEmphasis}
      text-align: left;
    }
    & .MXInstitutionSearch-url {
      ${type.triFontDesktopBodySmall}
      color: var(--tri-color-text-tertiary);
      text-align: left;
    }
  }

  .MXInstitutionSearch-error {
    ${type.triFontDesktopBodySmall}
    color: var(--tri-color-text-danger);
  }
`

const bottomContentCss = css`
  position: absolute;
  bottom: 40px;
  width: calc(100% - 96px);
`

export default MXInstitutionSearch

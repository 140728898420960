/* global __DivvyEnvironment */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// TODO: COM-7887 remove all instances of import React from 'react'
import React from 'react'
import { datadogRum } from '@datadog/browser-rum'
import '@bdc-libs/trinity.scoped-custom-elements'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { initDatadog } from '@divvy-web/utils.log'
import * as FS from '@fullstory/browser'
import { createRoot } from 'react-dom/client'
import Routes from './routes'
import AppProvider from './AppProvider'
import { URL } from './resources/constants'

initDatadog({
  clientToken: __DivvyEnvironment?.DATADOG_CLIENT_TOKEN || 'dd_client_token',
  env: __DivvyEnvironment.DATADOG_ENV ?? process.env.NODE_ENV,
  service: __DivvyEnvironment.DATADOG_SERVICE_ID,
})

datadogRum.init({
  // Enabling Datadog actions will cause PII and credit card numbers
  // to be logged to Datadog RUM unless they are masked. Do not turn
  // on trackUserInteractions unless all PII and other sensitive info
  // is masked.
  trackUserInteractions: false,
  allowedTracingOrigins: ['https://apply.divvy.co', 'https://apply-stg.divvy.co'],
  applicationId: __DivvyEnvironment?.DATADOG_RUM_APPLICATION_ID,
  clientToken: __DivvyEnvironment?.DATADOG_RUM_CLIENT_TOKEN,
  defaultPrivacyLevel: 'mask',
  env: window.location.host.includes('apply-stg') ? 'stg' : __DivvyEnvironment?.DATADOG_ENV,
  premiumSampleRate: 5,
  sampleRate: 100,
  service: __DivvyEnvironment?.DATADOG_SERVICE_ID,
  sessionReplaySampleRate: 5,
  site: 'datadoghq.com',
  trackInteractions: true,
  trackLongTasks: true,
  trackResources: true,
  version: __DivvyEnvironment?.DEPLOY_VERSION,
})

if (!FS.isInitialized()) {
  const devMode = window.location.host !== URL.APP_PROD
  FS.init({ devMode, orgId: __DivvyEnvironment.FULLSTORY_ORG_ID ?? 'fs_org_id' }, () =>
    datadogRum.setUser({
      fullStoryUrl: FS.getCurrentSessionURL(),
    }),
  )
}

const launchDarklyClientId = process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID
const launchDarklyRestToken = process.env.LAUNCH_DARKLY_REST_TOKEN
const container = document.getElementById('react-app')
const root = createRoot(container)

if (!launchDarklyClientId || !launchDarklyRestToken) {
  root.render(
    <AppProvider>
      <Routes />
    </AppProvider>,
  )
} else {
  asyncWithLDProvider({
    clientSideID: launchDarklyClientId,
    deferInitialization: true,
    options: {},
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  }).then((LaunchDarklyProvider) => {
    root.render(
      <LaunchDarklyProvider>
        <AppProvider>
          <Routes />
        </AppProvider>
      </LaunchDarklyProvider>,
    )
  })
}

import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import { useForm } from '@divvy-web/skylab.form'
import { bool, func, node, string } from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import { PAGE_NAME_STRINGS } from '../../resources/constants'
import { formButtonsCss } from './FormButtonsStyles'

const FormButtons = ({
  dataTestId,
  disableExit,
  disableNext,
  disablePrevious,
  isCentered,
  disableSaveOnInvalid,
  exitButtonText,
  handleExitClick,
  handleNextClick,
  handlePreviousClick,
  hideExit,
  hideNext,
  hidePrevious,
  isInline,
  isReview,
  isUpdateMode,
  nextButtonText,
  nextButtonType,
  pageName,
  previousButtonText,
  showExitSpinner,
  showNextSpinner,
}) => {
  const { PAGE_REVIEW_AND_SIGN, PAGE_SIGN_IN } = PAGE_NAME_STRINGS
  const isReviewAndSign = pageName === PAGE_REVIEW_AND_SIGN
  const isSignin = pageName === PAGE_SIGN_IN
  const navigate = useNavigate()
  const { appId } = useParams()

  const { resetForm, validationErrors } = useForm()

  const onPreviousClick = () => {
    handlePreviousClick ? handlePreviousClick() : navigate(-1)
  }

  const onUpdateCancel = () => {
    // If the user made any changes for the page we should revert them
    resetForm()
    // Navigate back to the review and sign page
    navigate(`/app/${appId}/section/review-and-sign`)
  }

  const isFormValid = !validationErrors
  const disableNextBtn = disableSaveOnInvalid ? disableNext || !isFormValid : disableNext
  const disableExitBtn = disableSaveOnInvalid ? disableExit || !isFormValid : disableExit

  return (
    <>
      <div
        className='FormButtons-container fs-unmask'
        css={formButtonsCss({
          isCentered,
          isInline,
          isReview,
          shouldUseNewReviewAndSignWidth: isReviewAndSign,
          shouldUseNewSignInWidth: isSignin,
          showSpinner: showNextSpinner,
        })}
      >
        <div className='button-group-container'>
          <div className='right-button-group'>
            {!hidePrevious && (
              <BasicButton
                color='neutral'
                dataTestId='previousFormPage'
                disabled={disablePrevious}
                type={BASIC_BUTTON_TYPE_FLAT}
                onClick={onPreviousClick}
              >
                {previousButtonText || (
                  <FormattedMessage
                    defaultMessage='Previous'
                    id='sputnik.FormButtons__JJNc3c'
                  />
                )}
              </BasicButton>
            )}
            {isUpdateMode && (
              <BasicButton
                color='neutral'
                type={BASIC_BUTTON_TYPE_FLAT}
                onClick={onUpdateCancel}
              >
                <FormattedMessage
                  defaultMessage='Cancel'
                  id='sputnik.FormButtons__47FYwb'
                />
              </BasicButton>
            )}
            <div className='next-button'>
              {!hideNext && (
                // This is the submit button for Review and Sign Page
                <BasicButton
                  buttonType={nextButtonType}
                  className='next-button'
                  dataTestId={`${dataTestId}submitFormPage`}
                  disabled={disableNextBtn}
                  showSpinner={showNextSpinner}
                  onClick={handleNextClick}
                >
                  {nextButtonText || (
                    <FormattedMessage
                      defaultMessage='Next'
                      id='sputnik.FormButtons__9+Ddtu'
                    />
                  )}
                </BasicButton>
              )}
            </div>
          </div>
          {!hideExit && (
            <div className='left-button-group'>
              <BasicButton
                color='neutral'
                dataTestId='saveExitFormPage'
                disabled={disableExitBtn}
                showSpinner={showExitSpinner}
                type={BASIC_BUTTON_TYPE_FLAT}
                onClick={handleExitClick}
              >
                {exitButtonText || (
                  <FormattedMessage
                    defaultMessage='Save and Exit'
                    id='sputnik.FormButtons__PDtJxC'
                  />
                )}
              </BasicButton>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

FormButtons.defaultProps = {
  dataTestId: '',
  disableExit: false,
  disableNext: false,
  isCentered: false,
  disablePrevious: false,
  disableSaveOnInvalid: true,
  handleExitClick: () => {},
  handleNextClick: () => {},
  hideExit: false,
  hideNext: false,
  hidePrevious: false,
  isInline: false,
  isReview: false,
  isUpdateMode: false,
  nextButtonType: 'button',
  pageName: '',
  previousButtonText: null,
  showExitSpinner: false,
  showNextSpinner: false,
}

FormButtons.propTypes = {
  dataTestId: string,
  disableExit: bool,
  isCentered: bool,
  disableNext: bool,
  disablePrevious: bool,
  disableSaveOnInvalid: bool,
  exitButtonText: node,
  handleExitClick: func,
  handleNextClick: func,
  handlePreviousClick: func,
  hideExit: bool,
  hideNext: bool,
  hidePrevious: bool,
  isInline: bool,
  isReview: bool,
  isUpdateMode: bool,
  nextButtonText: node,
  nextButtonType: string,
  pageName: string,
  previousButtonText: node,
  showExitSpinner: bool,
  showNextSpinner: bool,
}

export default FormButtons

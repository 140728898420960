export const SECURED_LINE_OFFER = 'secured_line'
export const VARIABLE_LINE_OFFER = 'auto_cash_underwriting'
export const STANDARD_LINE_OFFER = 'auto_europa_h20' || 'cross_sell'

export const getFilteredOrNull = (decisions, type) => {
  const filtered = decisions.filter((decision) => decision?.underwritingMethod === type)
  return filtered?.[0] || null
}

// deprecating since we now have the offerType on the offer object
export const getOfferType = (decisions, type) => {
  switch (type) {
    case SECURED_LINE_OFFER:
    case VARIABLE_LINE_OFFER:
      return getFilteredOrNull(decisions, type)
    default: {
      const filtered = decisions.filter(
        (decision) =>
          decision?.underwritingMethod !== SECURED_LINE_OFFER && decision?.underwritingMethod !== VARIABLE_LINE_OFFER,
      )
      return filtered?.[0] || null
    }
  }
}

import { useGetClassName, useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton from '@divvy-web/skylab.basicbutton'
import Tile from '@divvy-web/skylab.tile'
import { array, func, number, shape, string, bool } from 'prop-types'
import React, { useState } from 'react'
import ImageWrapper from '../ImageWrapper'
import { tileCss, webCss, mobileCss } from './docUploaderTileStyles'
import { getRejectionMessage, requiredDocumentMapper } from './requiredDocumentMapper'

/**
 * component that shows the popsheet options for required document uploads
 * both mobile and web pages
 */

const UploaderTile = ({
  accountNumberLastFour,
  desiredCredit,
  documentType,
  formattedNames,
  isMobile,
  remainingDocs,
  statusMessage,
  onSelect,
}) => {
  const [selectingDocument, setSelectingDocument] = useState(false)
  const isCompleted = !remainingDocs?.includes(documentType)
  const makeTestId = useMakeTestId('DocUploaderTile')
  const getClassName = useGetClassName('UploaderTile')
  const documentInfo = requiredDocumentMapper(documentType, formattedNames, accountNumberLastFour, desiredCredit)
  const isRejected = !!statusMessage

  const handleUploadClick = async () => {
    setSelectingDocument(true)
    await onSelect()
    setSelectingDocument(false)
  }

  return (
    <div css={tileCss({ isRejected })}>
      <Tile
        className={getClassName(`${documentType}-tile`)}
        dataTestId={makeTestId(`${documentType}-document`)}
        isSelected={isCompleted}
        selectedColor='success'
        onClick={isMobile ? handleUploadClick : null}
      >
        <div
          className='tile-outer-wrapper'
          css={isMobile ? mobileCss : webCss}
        >
          <div>
            <ImageWrapper
              alt={`${documentInfo?.image}`}
              imageName={`${documentInfo?.image}`}
            />
          </div>
          <div className='tile-title'>
            <div>
              <div className='status-title'>
                {isRejected && (
                  <FormattedMessage
                    defaultMessage='Rejected | '
                    id='sputnik.UploaderTile__Kw7OkH'
                  />
                )}
                {documentInfo?.title}
              </div>
              <div className='description-wrapper'>
                {isRejected && <div className='rejectionMessage'>{getRejectionMessage(statusMessage)}</div>}
                <div className='description-subheader'>{documentInfo?.descriptionSubheader}</div>
                <div className='description-content'> {documentInfo?.description} </div>
              </div>
            </div>
            <div className='button-container'>
              <div className='description-footer'>
                <u>{documentInfo?.descriptionFooter}</u>
              </div>
              <div>
                {!isMobile && (
                  <BasicButton
                    disabled={selectingDocument || isCompleted}
                    showSpinner={selectingDocument}
                    onClick={handleUploadClick}
                  >
                    <FormattedMessage
                      defaultMessage='Upload'
                      id='sputnik.UploaderTile__p4N05H'
                    />
                  </BasicButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </Tile>
    </div>
  )
}

UploaderTile.propTypes = {
  accountNumberLastFour: string.isRequired,
  desiredCredit: number.isRequired,
  documentType: string.isRequired,
  formattedNames: shape({
    authorizedSignerName: string.isRequired,
    beneficialOwner1Name: string,
    beneficialOwner2Name: string,
    beneficialOwner3Name: string,
    beneficialOwner4Name: string,
  }).isRequired,
  isMobile: bool,
  onSelect: func.isRequired,
  remainingDocs: array.isRequired,
  statusMessage: string,
}

export default UploaderTile

import React from 'react'
import PopSheet, { PopSheetControl } from '@divvy-web/skylab.popsheet'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT, BASIC_BUTTON_COLOR_NEUTRAL } from '@divvy-web/skylab.basicbutton'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { useIntersectionObserver } from 'usehooks-ts'
import Tooltip, { TOOLTIP_POSITION_TOP } from '@divvy-web/skylab.tooltip'
import { popsheetCss } from './agreementPopsheetStyles'

interface AgreementPopsheetProps {
  isAcceptingTilaAgreement: boolean
  isShowing: boolean
  hasAgreedToOfferSummary: boolean
  tilaContent: string
  onCancel: () => void
  onAgreeClick: () => void
}

const AgreementPopsheet = ({
  isAcceptingTilaAgreement,
  isShowing,
  hasAgreedToOfferSummary,
  tilaContent,
  onCancel,
  onAgreeClick,
}: AgreementPopsheetProps) => {
  const [getClassName, makeTestId] = useNameFormatter('AgreementPopsheet')
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 1,
  })
  const footerButtons = (
    <>
      {!hasAgreedToOfferSummary && (
        <Tooltip
          dataTestId={makeTestId('scroll-to-bottom-tooltip')}
          disableTooltip={isIntersecting}
          html={
            <FormattedMessage
              defaultMessage='Scroll to the bottom of the terms document to enable the agree button.'
              id='sputnik.AgreementPopsheet__7zcKKH'
            />
          }
          position={TOOLTIP_POSITION_TOP}
        >
          <BasicButton
            className={getClassName('popsheet-top-footer-button')}
            dataTestId={makeTestId('popsheet-review-offer')}
            disabled={isAcceptingTilaAgreement || !isIntersecting}
            type={BASIC_BUTTON_TYPE_FLAT}
            onClick={onAgreeClick}
          >
            <FormattedMessage
              defaultMessage='Agree'
              id='sputnik.AgreementPopsheet__ISly67'
            />
          </BasicButton>
        </Tooltip>
      )}
      <BasicButton
        className={getClassName('popsheet-bottom-footer-button')}
        color={BASIC_BUTTON_COLOR_NEUTRAL}
        dataTestId={makeTestId('dismiss-popsheet')}
        type={BASIC_BUTTON_TYPE_FLAT}
        onClick={onCancel}
      >
        <FormattedMessage
          defaultMessage='Cancel'
          id='sputnik.AgreementPopsheet__47FYwb'
        />
      </BasicButton>
    </>
  )

  return (
    <PopSheetControl isShowing={isShowing}>
      <PopSheet
        className={getClassName('')}
        css={popsheetCss}
        dataTestId={makeTestId('')}
        footer={footerButtons}
        title={''}
      >
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{tilaContent}</ReactMarkdown>
        <span
          ref={ref}
          className={getClassName('ref-container')}
          data-testId='ref-container'
        />
      </PopSheet>
    </PopSheetControl>
  )
}

export default AgreementPopsheet

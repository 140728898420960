import React from 'react'
import { css } from '@emotion/core'
import { bool, func, node, string } from 'prop-types'
import PopSheet, { PopSheetControl } from '@divvy-web/skylab.popsheet'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT, BASIC_BUTTON_COLOR_NEUTRAL } from '@divvy-web/skylab.basicbutton'
import ImageWrapper from '../../components/ImageWrapper'

const OfferInfoMobilePopSheet = ({
  isShowing,
  togglePopSheet,
  navigateToSelectedOffer,
  bottomCaption,
  subtitle,
  image,
  topCaption,
}) => {
  const [getClassName, makeTestId] = useNameFormatter('OfferInfoMobilePopSheet')

  const footerButtons = (
    <>
      <BasicButton
        className={getClassName('popsheet-top-footer-button')}
        dataTestId={makeTestId('popsheet-review-offer')}
        type={BASIC_BUTTON_TYPE_FLAT}
        onClick={() => navigateToSelectedOffer()}
      >
        <FormattedMessage
          defaultMessage='Review offer'
          id='sputnik.StandardCreditLineOfferPopSheet__nvR9jj'
        />
      </BasicButton>
      <BasicButton
        className={getClassName('popsheet-bottom-footer-button')}
        color={BASIC_BUTTON_COLOR_NEUTRAL}
        dataTestId={makeTestId('dismiss-popsheet')}
        type={BASIC_BUTTON_TYPE_FLAT}
        onClick={() => togglePopSheet()}
      >
        <FormattedMessage
          defaultMessage='Dismiss'
          id='sputnik.StandardCreditLineOfferPopSheet__TDaF6J'
        />
      </BasicButton>
    </>
  )

  return (
    <PopSheetControl isShowing={isShowing}>
      <PopSheet
        className={getClassName('')}
        css={offerInfoMobilePopSheetCss}
        dataTestId={makeTestId('')}
        footer={footerButtons}
        subtitle={subtitle}
        title={
          <ImageWrapper
            alt='offer-info-image'
            className='popsheet-title-image'
            dataTestId={makeTestId('popsheet-title-image')}
            imageName={image}
          />
        }
      >
        <p className={getClassName('body-text-top')}>{topCaption}</p>
        <p className={getClassName('body-text-bottom')}>{bottomCaption}</p>
      </PopSheet>
    </PopSheetControl>
  )
}

OfferInfoMobilePopSheet.propTypes = {
  isShowing: bool,
  togglePopSheet: func,
  navigateToSelectedOffer: func,
  topCaption: node,
  bottomCaption: node,
  subtitle: node,
  image: string,
}

const offerInfoMobilePopSheetCss = ({ type }) => css`
  display: flex;
  flex-direction: column;
  padding-bottom: var(--tri-space-500);

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: var(--tri-space-2800);
  }

  .OfferInfoMobilePopSheet {
    &-popsheet-title-image {
      max-width: var(--tri-space-2800);
    }

    &-header {
      text-align: center;
      padding: 0 var(--tri-space-300);
      margin: unset;
    }

    &-header-actions {
      margin: unset;
    }

    &-header-subtitle {
      ${type.triFontDesktopTitleMediumEmphasis};
      color: var(--tri-color-text-primary);
    }

    &-body-text-top,
    &-body-text-bottom {
      padding-top: var(--tri-space-200);
    }

    &-content {
      padding: 0 var(--tri-space-300);
    }

    &-popsheet-top-footer-button,
    &-popsheet-bottom-footer-button {
      border-radius: 0;
      height: var(--tri-space-600);
      width: 100%;
      border-top: 1px solid var(--neutral-soft);
    }
  }
`

export default OfferInfoMobilePopSheet

import {
  accountNumberValidation,
  emailValidation,
  illegalCharactersForBusinessNameMessage,
  illegalCharactersForHumanNameMessage,
  legalCharactersForBusinessNamePattern,
  legalCharactersForHumanNamePattern,
  routingNumberValidation,
} from '../../utils/validationUtils'

import { BI_MONTHLY, MONTHLY, PREPAY, WEEKLY } from '../../pages/FinanceInfo/utils/rewardUtils'
import { MAJOR_ACCOUNT_CHANGE, MIGRATION } from '../../resources/constants'

const billingOptions = [WEEKLY, BI_MONTHLY, MONTHLY, PREPAY]

const validateIfManualBankInfo = (validation) => (value, attributes) => {
  if (!attributes?.selectedBankInfo || attributes?.selectedBankInfo?.source === 'MANUAL') {
    if (validation instanceof Function) return validation(value, attributes)
    return validation
  }
}

const validateIfHasOutsourcedAccountant = (validation) => (value, attributes) => {
  if (attributes?.hasOutsourcedAccountant === 'yes') {
    if (validation instanceof Function) return validation(value, attributes)
    return validation
  }
}

// ensure there is at least one latin letter or a number at the beginning of name (i.e, '&&&'' would be invalid)
const bankNamePattern = "^[a-zA-Z\\d]+[\\da-zA-Z.'’ —:&-]*$"
const bankNameValidationErrorMessage = 'Please enter a valid bank name greater than 2 characters'
const revenueRequiredMessage = 'Please enter a valid annual revenue'
const validationMessage = 'Value must be greater than $0'
const desiredCreditRequiredMessage = 'Please enter a valid desired credit'
const desiredCreditExceedsMaximumAmount = 'Desired line of credit must be under $15,000,000'
const desiredCreditMustBeGreaterThanThousand = 'Please enter amount of $1,000 or greater'
const expectedSpendRequiredMessage = 'Please enter a valid expected monthly spending amount'
const expectedSpendExceedsMaximumAmount = 'The amount is too high'
const expectedSpendMustBeGreaterThanZero = 'The expected monthly spend must be greater than $0'

const nonNumRegex = /[^0-9]/g

const currencyValidation = (value) => {
  const newValue = value === '' || !value ? NaN : Number(value?.replace(nonNumRegex, ''))

  if (!isNaN(newValue) && newValue === 0) {
    return {
      type: {
        message: validationMessage,
        type: () => false,
      },
    }
  }

  return {
    presence: {
      message: revenueRequiredMessage,
    },
  }
}

const desiredCreditValidation = (value) => {
  const newValue = value === '' || !value ? NaN : Number(value?.replace(nonNumRegex, ''))
  if (!isNaN(newValue)) {
    if (newValue < 1000) {
      return {
        type: {
          message: desiredCreditMustBeGreaterThanThousand,
          type: () => false,
        },
      }
    }

    if (newValue > 15000000) {
      return {
        type: {
          message: desiredCreditExceedsMaximumAmount,
          type: () => false,
        },
      }
    }
  }

  return {
    presence: {
      message: desiredCreditRequiredMessage,
    },
  }
}

const expectedSpendValidation = (value) => {
  const newValue = value === '' || !value ? NaN : Number(value?.replace(nonNumRegex, ''))
  if (!isNaN(newValue)) {
    if (newValue === 0) {
      return {
        type: {
          message: expectedSpendMustBeGreaterThanZero,
          type: () => false,
        },
      }
    }
    if (newValue >= Number.MAX_SAFE_INTEGER) {
      return {
        type: {
          message: expectedSpendExceedsMaximumAmount,
          type: () => false,
        },
      }
    }
  }

  return {
    presence: {
      message: expectedSpendRequiredMessage,
    },
  }
}

export default ({ recordType, requestedCorrections }) => {
  if (recordType === MIGRATION || recordType === MAJOR_ACCOUNT_CHANGE) return {}

  const requestingCorrectionsOtherThanFinancialInfo =
    requestedCorrections?.length && !requestedCorrections.includes('FINANCIAL')

  return {
    accountHolderName: validateIfManualBankInfo({
      format: {
        flags: 'i',
        message: illegalCharactersForBusinessNameMessage,
        pattern: legalCharactersForBusinessNamePattern,
      },
      presence: {
        message: 'Please enter a valid account holder',
      },
    }),
    accountNumberDisplay: validateIfManualBankInfo(accountNumberValidation()),
    annualRevenue: (value, _attributes) => currencyValidation(value),
    bankName: validateIfManualBankInfo({
      format: {
        flags: 'i',
        message: bankNameValidationErrorMessage,
        pattern: bankNamePattern,
      },
      length: {
        message: bankNameValidationErrorMessage,
        minimum: 3,
      },
      presence: {
        message: bankNameValidationErrorMessage,
      },
    }),
    billingCycle: {
      inclusion: {
        message: 'Please select billing/rewards cycle',
        within: billingOptions,
      },
      presence: {
        message: 'Please select billing/rewards cycle',
      },
    },
    desiredCredit: (value, _attributes) => desiredCreditValidation(value),
    // Expected spend is a newly added field and older applications will not have it saved.
    // We won't require it if the application needs corrections but the finance info section is not editable.
    expectedSpend: (value, _attributes) =>
      requestingCorrectionsOtherThanFinancialInfo ? {} : expectedSpendValidation(value),
    hasOutsourcedAccountant: {
      presence: {
        message: 'Please select an option for outsourced accountant',
      },
    },
    oaEmail: validateIfHasOutsourcedAccountant(emailValidation),
    oaFirstName: validateIfHasOutsourcedAccountant({
      format: {
        flags: 'i',
        message: illegalCharactersForHumanNameMessage,
        pattern: legalCharactersForHumanNamePattern,
      },
      presence: {
        message: "Please enter accountant's first name",
      },
    }),
    oaLastName: validateIfHasOutsourcedAccountant({
      format: {
        flags: 'i',
        message: illegalCharactersForHumanNameMessage,
        pattern: legalCharactersForHumanNamePattern,
      },
      presence: {
        message: "Please enter accountant's last name",
      },
    }),
    routingNumber: validateIfManualBankInfo(routingNumberValidation()),
    selectedExternalId: {
      presence: {
        message: '',
      },
    },
  }
}

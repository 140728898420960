export const signinValidation = {
  signInEmail: {
    email: {
      message: 'Oops, this is not a valid email address',
    },
    presence: {
      message: 'Oops, this is not a valid email address',
    },
  },
}

import { css } from '@emotion/core'

export const VariableCreditLineOfferCss = ({ mq, type }) => css`
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;

  .main-container {
    flex: 2;
    margin: 0;
    padding: 0;
    overflow: scroll;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ${mq.xSmallMinWidth({ display: 'flex' })}
  }

  .main-container::-webkit-scrollbar {
    display: none;
  }

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: var(--tri-space-2000);
    ${mq.xSmallMinWidth({ display: 'none' })};
  }

  .counter-offer-details {
    padding-bottom: var(--tri-space-200);
  }

  .VariableCreditLineOffer {
    &-page-content {
      padding: 0 var(--tri-space-500) 0 var(--tri-space-1700);
      display: flex;
      flex-direction: column;
      height: 100%;
      ${mq.xSmallMaxWidth({
        padding: 'unset',
        height: 'auto',
      })}
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      ${mq.xSmallMaxWidth({
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 var(--tri-space-350) var(--tri-space-450)',
        textAlign: 'center',
        overflow: 'scroll',
      })}
    }

    &-title {
      ${type.triFontDesktopTitleLargeEmphasis}
      display: flex;
      flex-direction: column;
      padding-bottom: var(--tri-space-200);
      ${mq.xxSmallMaxWidth({
        flexDirection: 'row',
        gap: 'var(--tri-space-50)',
        paddingTop: 'var(--tri-space-700)',
      })}
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopTitleSmallEmphasis,
      })}
    }

    &-subtitle {
      ${type.triFontDesktopTitleSmall};
      color: var(--tri-color-text-secondary);
      padding-bottom: var(--tri-space-300);
      ${mq.xxSmallMaxWidth({
        paddingBottom: 'unset',
        paddingTop: 'var(--tri-space-100)',
      })}
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopBodyMediumEmphasis,
      })}
    }

    &-approved-amount {
      ${type.triFontDesktopCurrencyBodyLargeEmphasis}
      font-size: 45px;
      line-height: 52px;
      font-weight: 400;
      margin: var(--tri-space-200) 0;
      ${mq.xxSmallMaxWidth({
        textAlign: 'center',
      })}
    }

    &-approved-amount-caption {
      ${type.triFontDesktopBodyMedium}
      padding: var(--tri-space-400) 0;
      ${mq.xxSmallMinWidth({
        textAlign: 'start',
        paddingTop: 'var(--tri-space-300)',
      })};
      ${mq.xxSmallMaxWidth({
        padding: '0 0 var(--tri-space-200)',
      })}
      ${mq.mediumMinWidth({
        width: 'min(75%, 600px)',
      })};
    }

    &-variable-credit-line-details-list {
      padding: var(--tri-space-200) 0 var(--tri-space-200) var(--tri-space-200);
      ${mq.xxSmallMaxWidth({
        textAlign: 'left',
      })}
    }

    &-variable-credit-line-content-title {
      ${type.triFontDesktopTitleSmallEmphasis}
      padding-bottom: var(--tri-space-200);
      ${mq.xxSmallMaxWidth({
        textAlign: 'start',
      })}
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopBodyMediumEmphasis,
      })}
    }

    &-variable-credit-line-body-content {
      ${mq.xxSmallMaxWidth({
        textAlign: 'left',
      })};
    }

    &-body-caption {
      ${type.triFontDesktopLabelXSmall}
      padding-bottom: var(--tri-space-500);
      ${mq.xxSmallMaxWidth({
        paddingBottom: 'var(--tri-space-1100)',
        textAlign: 'start',
      })};
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopCaptionSmall,
      })};
      ${mq.mediumMinWidth({
        width: 'min(65%, 320px)',
      })};
    }

    &-buttons {
      display: flex;
      gap: var(--tri-space-300);
      ${mq.xxSmallMaxWidth({
        bottom: '0',
        flexDirection: 'row-reverse',
        gap: '0',
        height: '88px',
        left: '0',
        position: 'fixed',
        width: '100%',
      })}
    }

    &-dashboard,
    &-previous,
    &-mobile-dashboard-button {
      background-color: var(--tri-color-fill-primary);
    }

    &-accept,
    &-dashboard,
    &-previous {
      ${mq.xxSmallMaxWidth({
        borderRadius: '0',
        height: '100%',
        width: '100%',
      })}
    }
  }

  .sidebar-image {
    position: absolute;
    transform: scaleX(-1);
    bottom: 56px;
    right: -40px;
    ${mq.xSmallMinWidth({
      maxWidth: '275px',
      right: '-20px',
    })}
    ${mq.mediumMinWidth({
      maxWidth: '330px',
      right: '-20px',
    })}
      ${mq.largeMinWidth({
      maxWidth: '375px',
      right: '-27px',
    })};
    ${mq.xLargeMinWidth({
      maxWidth: '450px',
      right: '-30px',
    })};
    ${mq.xxLargeMinWidth({
      maxWidth: '550px',
      right: '-40px',
    })};
  }

  .counter-offer-side-bar-image {
    position: absolute;
    bottom: -71px;
    right: -149px;
    ${mq.xSmallMinWidth({
      bottom: '-45px',
      maxWidth: '450px',
      right: '-95px',
    })}
    ${mq.mediumMinWidth({
      maxWidth: '500px',
    })}
      ${mq.largeMinWidth({
      maxWidth: '550px',
    })};
    ${mq.xLargeMinWidth({
      maxWidth: '610px',
    })};
    ${mq.xxLargeMinWidth({
      maxWidth: '710px',
    })};
  }
`

import { useState, useEffect } from 'react'
import { getTokenFromStorage } from '../auth'
import { getExpirationFromToken } from '../auth/jwt'

const useExpiration = (expirationDate) => {
  const [shouldRender, setShouldRender] = useState(false)

  const token = getTokenFromStorage()
  const expirationDateInSeconds = token
    ? getExpirationFromToken(token) * 1000 - Date.now()
    : expirationDate - Date.now()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShouldRender(true)
    }, expirationDateInSeconds)

    return () => clearTimeout(timeoutId)
  }, [expirationDate, expirationDateInSeconds])

  return shouldRender
}

export default useExpiration

import React, { useState } from 'react'
import TextLink, { TextLinkSizes } from '@divvy-web/skylab.textlink'
import { useParams } from 'react-router-dom'
import { FormattedMessage } from '@divvy-web/i18n'
import InviteCollaborators from '../../pages/Dashboard/InviteCollaborators'

const InviteCollaboratorsLink = () => {
  const { appId } = useParams()
  const [isShowing, setIsShowing] = useState(false)
  const toggle = () => setIsShowing((status) => !status)

  return (
    <>
      <div data-testid='invite-collaborators-link'>
        <TextLink
          isDark
          className='invite'
          dataTestId='invite-collaborators-link'
          size={TextLinkSizes.SMALL}
          onClick={toggle}
        >
          <FormattedMessage
            defaultMessage='Invite a collaborator'
            id='sputnik.FormPageWrapper__s8trko'
          />
        </TextLink>
      </div>
      <InviteCollaborators
        appId={appId}
        isShowing={isShowing}
        setIsShowing={setIsShowing}
      />
    </>
  )
}

export default InviteCollaboratorsLink

export const FIXED_LINE = 'FIXED_LINE'
export const VARIABLE_LINE = 'VARIABLE_LINE'
export const SECURED_LINE = 'SECURED_LINE'

export const getSideBarImage = (isCounterOffer) => {
  if (isCounterOffer) {
    const counterOfferPath = 'https://app.divvy.co/assets/illustrations/gift-02'
    const counterOfferSrcSet = `${counterOfferPath}@3x.png, ${counterOfferPath}@2x.png, ${counterOfferPath}@1x.png`
    return counterOfferSrcSet
  }

  const businessTransactionPath = 'https://app.divvy.co/assets/illustrations/business-transaction-01'
  const businessTransactionSrcSet = `${businessTransactionPath}@3x.png, ${businessTransactionPath}@2x.png, ${businessTransactionPath}@1x.png`
  return businessTransactionSrcSet
}

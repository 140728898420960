import React, { ReactNode } from 'react'
import { useGetClassName } from '@divvy-web/hooks.usenameformatter'
import { curry } from '@divvy-web/utils'
import { css, Interpolation } from '@emotion/core'
import { PAGE_NAME_STRINGS } from '../../resources/constants'
import { replaceBreakableSpaces } from '../utils'
import SideSectionContent from '../ReviewsSideSection/SideSectionContent'

interface PageHeadingProps {
  dsaVersion?: string
  headingStyles?: (theme: any) => Interpolation
  isFormPage: boolean
  page: string
  pageCount: number
  pageIndex: number
  pageSubTitle?: ReactNode
  pageTitle: ReactNode
}

const PageHeading = ({
  dsaVersion = '',
  headingStyles,
  isFormPage = false,
  page,
  pageCount,
  pageIndex,
  pageSubTitle = '',
  pageTitle,
}: PageHeadingProps) => {
  const getClassName = useGetClassName(page + '-page-heading')
  const isDivvyProgramFitPage = page === PAGE_NAME_STRINGS.PAGE_DIVVY_PROGRAM_FIT
  const shouldShowAuthorizedSignerProTip = !(dsaVersion === 'crb_business_only' || dsaVersion === 'wex_business_only')

  return (
    <div className={getClassName('')}>
      <header
        className='heading-wrapper fs-unmask'
        css={headingStyles || defaultHeadingStyles({ isDivvyProgramFitPage })}
      >
        <div className={getClassName('header')}>
          <div
            className={getClassName('title')}
            data-testid='page-title'
          >
            {pageTitle}
          </div>
          {pageSubTitle && <div className={getClassName('subtitle')}>{pageSubTitle}</div>}
        </div>
        {isFormPage && (
          <div className={getClassName('index')}>{replaceBreakableSpaces(pageIndex + ' / ' + pageCount)}</div>
        )}
      </header>
      <div
        className={getClassName('pro-tips-mobile')}
        css={protipsStyles}
      >
        <SideSectionContent
          pageName={page}
          shouldShowAuthorizedSignerProTip={shouldShowAuthorizedSignerProTip}
        />
      </div>
    </div>
  )
}

const defaultHeadingStyles = curry(
  ({ isDivvyProgramFitPage }, { mq, type }) => css`
    display: flex;
    padding: var(--tri-space-1000) var(--tri-space-600) 0 var(--tri-space-1500);
    justify-content: space-between;

    div[class$='-page-heading-header'] {
      ${isDivvyProgramFitPage && 'width: 83%'}
    }

    ${mq.xSmallMaxWidth({
      backgroundColor: 'var(--tri-color-fill-accent-primary)',
      padding: 'var(--tri-space-300) var(--tri-space-400) var(--tri-space-300) var(--tri-space-300)',
    })}
    div[class$='-page-heading-title'] {
      ${type.triFontDesktopTitleLargeEmphasis};
      margin-bottom: var(--tri-space-100);

      ${mq.xSmallMaxWidth(css`
        ${type.triFontDesktopTitleMediumEmphasis};
        margin-bottom: 0;
      `)}
    }

    div[class$='-page-heading-subtitle'] {
      margin-bottom: ${isDivvyProgramFitPage ? 'var(--tri-space-400)' : 'var(--tri-space-600)'};
      ${type.triFontDesktopTitleSmall};

      ${mq.xSmallMaxWidth(type.triFontDesktopBodySmall)}
      ${mq.xSmallMaxWidth({
        marginBottom: '0',
        marginTop: 'var(--tri-space-100)',
      })};
    }

    div[class$='-page-heading-index'] {
      ${mq.xSmallMaxWidth({ minWidth: 'fit-content' })}
    }
  `,
)

const protipsStyles = curry(
  ({ mq }) => css`
    display: flex;
    justify-content: center;
    padding: 0 var(--tri-space-100);

    ${mq.smallMinWidth({ display: 'none' })}
  `,
)

export default PageHeading

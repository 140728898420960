import { useCallback, useState } from 'react'

const safeParseJson = (string) => {
  try {
    return JSON.parse(string)
  } catch {
    return undefined
  }
}

const useLocalStorage = (key, initialValue, isJson = false) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      const itemOrJson = isJson ? safeParseJson(item) : item
      // Parse stored json or if none return initialValue
      return itemOrJson || initialValue
    } catch (error) {
      // If error also return initialValue
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value
        // Save state
        setStoredValue(valueToStore)
        // Save to local storage
        const localStorageValue = isJson ? JSON.stringify(valueToStore) : valueToStore
        window.localStorage.setItem(key, localStorageValue)
      } catch (error) {
        // A more advanced implementation would handle the error case
      }
    },
    [isJson, key, storedValue],
  )

  return [storedValue, setValue]
}

export default useLocalStorage

const invalidSsnValueType = {
  type: {
    message: 'Please enter a valid ssn',
    type: () => false,
  },
}

const containsNineIdenticalNumbers = (ssn) => {
  // allow consective 3s for testing purposes
  switch (ssn) {
    case '000-00-0000':
    case '111-11-1111':
    case '222-22-2222':
    case '444-44-4444':
    case '555-55-5555':
    case '666-66-6666':
    case '777-77-7777':
    case '888-88-8888':
    case '999-99-9999':
      return true
    default:
      return false
  }
}

const ssnStartsWithInvalidNumbers = (ssn) => {
  if (ssn.startsWith('666')) return true
  if (ssn.startsWith('000')) return true
  return false
}

const ssnPatternMatch = (ssn, regex) => {
  return regex.test(ssn)
}

const ssnContainsNineConsecutiveNumbers = (ssn) => {
  switch (ssn) {
    case '123-45-6789':
    case '012-34-5678':
    case '987-65-4321':
      return true
    default:
      return false
  }
}

export const validateSsn = (ssn, ssnDisplay) => {
  if (!ssn && !ssnDisplay) return invalidSsnValueType
  if (ssn && ssn?.length < 11) return invalidSsnValueType
  if (ssn && containsNineIdenticalNumbers(ssn)) return invalidSsnValueType
  if (ssn && ssnStartsWithInvalidNumbers(ssn)) return invalidSsnValueType
  if (ssn && ssnPatternMatch(ssn, /^\d{3}-00.*$/)) return invalidSsnValueType
  if (ssn && ssnPatternMatch(ssn, /^\d{3}-\d{2}-0000$/)) return invalidSsnValueType
  if (ssn && ssnContainsNineConsecutiveNumbers(ssn)) return invalidSsnValueType
  return null
}

import React from 'react'
import useDidMount from '@divvy-web/hooks.usedidmount'
import { useAuth } from '../../auth'
import { logInfo } from '../../utils/loggerUtils'
import UploadContent from './UploadContent'
import MobileDocumentUploadStatus from './MobileDocumentUploadStatus'

const MobileDocumentUpload = () => {
  const { appIdFromToken, isAuthExpired, isAuthenticated } = useAuth()
  const creditApplicationId = appIdFromToken()

  useDidMount(() =>
    logInfo({
      attributes: {
        action: 'Scan QR Code',
        creditApplicationId,
        result: 'User switched to a mobile device for a document upload',
      },
      eventName: 'MobileDeviceQrDocumentUpload',
    }),
  )

  if (isAuthenticated && !isAuthExpired()) {
    return <UploadContent />
  } else {
    return <MobileDocumentUploadStatus page='invalidLink' />
  }
}

export default MobileDocumentUpload

import React from 'react'
import { FormDateInput } from '@divvy-web/skylab.form'
import { string, node, bool, func } from 'prop-types'
import FormElementWrapper from '../FormInputs/FormElementWrapper'

const DateInputField = (props) => {
  const { className, dataTestId, label, name, onChange, readOnly, showErrors, ...rest } = props

  return (
    <FormElementWrapper>
      <FormDateInput
        clearInvalidInput
        addCharactersAtPositions={{
          2: '/',
          5: '/',
        }}
        alwaysShowError={showErrors}
        className={className}
        dataTestId={dataTestId}
        formatOptions={{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }}
        label={label}
        name={name}
        readOnly={readOnly}
        shouldRenderCalendar={false}
        onDateInputChange={onChange}
        {...rest}
      />
    </FormElementWrapper>
  )
}

DateInputField.defaultProps = {
  onChange: (_) => {},
}

DateInputField.propTypes = {
  className: string,
  dataTestId: string,
  label: node,
  name: string,
  onChange: func,
  readOnly: bool,
  showErrors: bool,
}

export default DateInputField

import { css } from '@emotion/core'

export const documentUploadPageWrapperCss = ({ mq, type }) => css`
  display: flex;
  min-height: 100vh;
  background-color: var(--tri-color-fill-accent-primary);

  .top-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ${mq.xxSmallMaxWidth({ position: 'relative' })}
  }

  .page-area {
    margin: var(--tri-space-220) 0 var(--tri-space-1300) 0;
    ${mq.xxSmallMaxWidth({ margin: '0 0 var(--tri-space-1300) 0' })}
  }

  .page-content {
    display: flex;
    flex-direction: column;
    gap: var(--tri-space-200);
    margin-top: var(--tri-space-1200);
    ${mq.xxSmallMaxWidth({
      margin: 'var(--tri-space-100) var(--tri-space-300) 0 var(--tri-space-300)',
    })}
  }

  .main-title {
    ${type.triFontDesktopBodyLargeEmphasis};
  }

  .main-caption {
    margin: var(--tri-space-100) 0 var(--tri-space-200) 0;
    ${type.triFontDesktopBodyMedium};
  }

  .main-header {
    padding: var(--tri-space-400) var(--tri-space-400) 0 var(--tri-space-400);
  }

  .document-selector-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .document-selector-img-wrapper > img {
    max-width: 100%;
  }
`

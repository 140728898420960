import { FormattedMessage } from '@divvy-web/i18n'
import { BASIC_BUTTON_COLOR_ACTION, BASIC_BUTTON_COLOR_NEUTRAL } from '@divvy-web/skylab.basicbutton'
import Modal from '@divvy-web/skylab.modal'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import CancelConfirm from '@divvy-web/skylab.cancelconfirm'
import { useIntersectionObserver } from 'usehooks-ts'
import Tooltip, { TOOLTIP_POSITION_TOP } from '@divvy-web/skylab.tooltip'
import ImageWrapper from '../../../components/ImageWrapper'
import { modalCss } from './agreementModalStyles'

interface AgreementModalProps {
  isAcceptingTilaAgreement: boolean
  isShowing: boolean
  hasAgreedToOfferSummary: boolean
  tilaContent: string
  onCancel: () => void
  onAgreeClick: () => void
}

const AgreementModal = ({
  isAcceptingTilaAgreement,
  isShowing,
  hasAgreedToOfferSummary,
  tilaContent,
  onCancel,
  onAgreeClick,
}: AgreementModalProps) => {
  const [makeTestId] = useNameFormatter('AgreementModal')
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 1,
  })

  return (
    <div css={modalCss}>
      <Modal
        hasScrollableContent
        actions={
          !hasAgreedToOfferSummary && (
            <Tooltip
              dataTestId={makeTestId('scroll-to-bottom-tooltip')}
              disableTooltip={isIntersecting}
              html={
                <FormattedMessage
                  defaultMessage='Scroll to the bottom of the terms document to enable the agree button.'
                  id='sputnik.AgreementModal__YUeHYS'
                />
              }
              position={TOOLTIP_POSITION_TOP}
            >
              <CancelConfirm
                cancelColor={BASIC_BUTTON_COLOR_NEUTRAL}
                cancelText={
                  <FormattedMessage
                    defaultMessage='Cancel'
                    id='sputnik.AgreementModal__47FYwb'
                  />
                }
                confirmText={
                  <FormattedMessage
                    defaultMessage='Agree'
                    id='sputnik.AgreementModal__ISly67'
                  />
                }
                dataTestId={makeTestId('')}
                inactive={isAcceptingTilaAgreement || !isIntersecting}
                submitColor={BASIC_BUTTON_COLOR_ACTION}
                onCancel={onCancel}
                onConfirm={onAgreeClick}
              />
            </Tooltip>
          )
        }
        className='fs-unmask'
        dataTestId={makeTestId('')}
        headerColor='accent'
        headerImage={
          <ImageWrapper
            alt='hand holding paper'
            imageName='dashboard-application-persona-3'
          />
        }
        isShowing={isShowing}
        title={
          <FormattedMessage
            defaultMessage='Review your offer summary'
            id='sputnik.AgreementDisplayModal__962yi/'
          />
        }
        onCloseClick={onCancel}
      >
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{tilaContent}</ReactMarkdown>
        <span
          ref={ref}
          data-testId='ref-container'
        />
      </Modal>
    </div>
  )
}

export default AgreementModal

import { css, Global } from '@emotion/core'
import React from 'react'

export const GlobalRecaptchaStyle = () => <Global styles={globalRecaptchaCss} />
export const GlobalToastStyle = () => <Global styles={globalToastStyle} />

const globalRecaptchaCss = css`
  .grecaptcha-badge {
    visibility: hidden;
  }
`

const globalToastStyle = ({ mq }) => css`
  // The value of z-index for the toast is required to be this high
  // because it needs to supersede the z-index value of intercom launcher button
  // which is > 2100000000

  .Toast {
    ${mq.xxSmallMaxWidth({ zIndex: '2500000000 !important' })}
  }

  .Toast-container {
    ${mq.xxSmallMaxWidth({
      padding: 'var(--tri-space-150) var(--tri-space-300) !important',
      width: '90% !important',
    })}
  }

  .Toast-msg {
    ${mq.xxSmallMaxWidth({ fontSize: '4vw', overflowWrap: 'anywhere' })}
  }
`

import React, { useCallback, useContext, useEffect } from 'react'
import useDidMount from '@divvy-web/hooks.usedidmount'
import { FormattedMessage } from '@divvy-web/i18n'
import { FormContext, FormDropdown } from '@divvy-web/skylab.form'
import { bool } from 'prop-types'
import { useLocation, useSearchParams } from 'react-router-dom'
import FormElementWrapper from '../../components/FormInputs/FormElementWrapper'
import { formStyles } from '../styles/applicationPageSharedStyles'
import CompanyOwnersList from './CompanyOwnersList'

const CompanyOwnersForm = ({ alwaysShowError }) => {
  const { state } = useLocation()

  const { getFormValue, setFormValue } = useContext(FormContext)
  const [searchParams, setSearchParams] = useSearchParams()

  const ownerParam = searchParams.get('owner')
  const isUpdateMode = state?.isUpdateMode
  const selectedOwnerIndex = ownerParam !== null ? Number(ownerParam) - 1 : null
  const setSelectedOwner = useCallback(
    (ownerIdx) =>
      setSearchParams(ownerIdx !== null ? { owner: ownerIdx + 1 } : {}, {
        state: { isUpdateMode },
      }),
    [isUpdateMode, setSearchParams],
  )
  const showOwnersForm = getFormValue('majorityHolders') === 'yes'

  useDidMount(() => {
    if (getFormValue('majorityHolders') === 'yes' && !getFormValue('beneficialOwners')?.length) {
      setFormValue('majorityHolders', undefined)
    }
  })

  useEffect(() => {
    if (getFormValue('majorityHolders') === 'no') {
      setSelectedOwner(null)
    }
  }, [getFormValue, setSelectedOwner])

  return (
    <section css={(theme) => [formStyles({ isReview: false, readOnly: false }, theme)]}>
      <div className='field-row'>
        <div className='field-container fs-unmask'>
          <FormElementWrapper>
            <FormDropdown
              alwaysShowError={alwaysShowError}
              isSearchable={false}
              label={
                <FormattedMessage
                  defaultMessage='Do you have a company owner?'
                  id='sputnik.CompanyOwnersForm__IiVYWz'
                />
              }
              name='majorityHolders'
              options={[
                { label: 'No', value: 'no' },
                { label: 'Yes', value: 'yes' },
              ]}
              placeholder={
                <FormattedMessage
                  defaultMessage='Do you have a company owner?'
                  id='sputnik.CompanyOwnersForm__IiVYWz'
                />
              }
            />
          </FormElementWrapper>
        </div>
      </div>
      {showOwnersForm && (
        <CompanyOwnersList
          selectedOwnerIndex={selectedOwnerIndex}
          setSelectedOwner={setSelectedOwner}
        />
      )}
    </section>
  )
}

CompanyOwnersForm.defaultProps = {
  alwaysShowError: false,
}

CompanyOwnersForm.propTypes = {
  alwaysShowError: bool,
}

export default CompanyOwnersForm

export const validation = {
  __bankAgreementChecked: {
    inclusion: {
      message: '^You need to agree to the bank draft agreement.',
      within: [true],
    },
  },
  __closureAgreementChecked: {
    inclusion: {
      message: '^You need to agree to the account closure agreement.',
      within: [true],
    },
  },
  __creditAgreementChecked: {
    inclusion: {
      message: '^You need to agree to the credit limit agreement.',
      within: [true],
    },
  },
  __rewardAgreementChecked: {
    inclusion: {
      message: '^You need to agree to the rewards agreement.',
      within: [true],
    },
  },
  __signAgreement: {
    inclusion: {
      message: '^You need to electronically sign this agreement.',
      within: [true],
    },
  },
}

export const MONTHLY = 'MONTHLY'
export const BI_MONTHLY = 'BI_MONTHLY'
export const PREPAY = 'PREPAY'
export const WEEKLY = 'WEEKLY'
